import { Component, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { Router } from '@angular/router';

@Component({
  selector: 'prf-system',
  templateUrl: './system.component.html',
  styleUrls: ['./system.component.scss'],
})
export class SystemComponent implements OnInit {
  public tabLevel: number = 1;

  constructor(private meta: Meta, private router: Router) {
    this.meta.updateTag({ name: 'og:title', content: 'Система' });
  }

  ngOnInit() {
    this.router.url.includes('reports')
      ? (this.tabLevel = 1)
      : this.router.url.includes('statistics')
      ? (this.tabLevel = 2)
      : this.router.url.includes('/dashboard')
      ? (this.tabLevel = 3)
      : this.router.url.includes('/tests-dashboard')
      ? (this.tabLevel = 4)
      : this.router.url.includes('/tests-matrix')
      ? (this.tabLevel = 5)
      : null;
  }
}
