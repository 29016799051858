<div class="w-tab-pane w--tab-active">
  <div class="tab-pane adm">
    <div class="buttons-row">
      <div class="buttons-row">
        <a *ngIf="catalogType === 1" class="admin-large-btn w-button" (click)="addNewProfessionFlag = !addNewProfessionFlag">{{
          'SHARED.ADD' | translate
        }}</a>
        <a
          *ngIf="catalogType === 1"
          class="button-animate btn-activate"
          (click)="animateDwnldButton()"
          [ngClass]="{ disabled: isAccessDwnldDenied }"
          [class.btn-waiting]="buttonDwnldStates.waiting"
          [class.btn-activated]="buttonDwnldStates.activated"
          [class.btn-activate]="!buttonDwnldStates.active"
        >
          <span class="btn-icon"></span>
          <span class="btn-text" data-wait="Подождите" [attr.data-after]="'SHARED.DOWNLOAD_CATALOG' | translate">{{
            'SHARED.DOWNLOAD_CATALOG' | translate
          }}</span>
        </a>
      </div>
    </div>
    <prf-add-profession
      *ngIf="addNewProfessionFlag"
      (closeAddProfession)="addNewProfessionFlag = !addNewProfessionFlag"
      [fieldTypes]="fieldTypes"
      [courses]="courses"
      [profilClasses]="profilClasses"
      [specialities]="specialities"
    >
    </prf-add-profession>
    <h2>Список профессий</h2>

    <div class="territory-objects">
      <div class="regions-row">
        <div>
          <label for="region" class="region-label">{{ 'SHARED.REGION' | translate }}:</label>
          <div class="div-space"></div>
        </div>
        <div class="dropdown-inline cat" #regionFilter>
          <div class="dropdown-inline-container w-dropdown" [ngClass]="{ disabled: isRegion }">
            <div
              class="dd-input-field"
              #regionField
              (click)="regionDrop.classList.toggle('w--open'); regionField.classList.toggle('opened')"
            >
              <div class="login-field w-input" [class.active]="selectedRegion">
                {{ selectedRegion.name }}
              </div>
            </div>
            <nav class="dd-prof-filter-list w-dropdown-list" #regionDrop>
              <div class="dd-prof-filter-div">
                <div class="filter-list-row">
                  <div class="filter-list-heading">{{ 'SHARED.REGION' | translate }}</div>
                </div>
                <div class="prof-categories-links">
                  <a
                    class="prof-categories-link w-inline-block"
                    *ngFor="let region of regions"
                    (click)="selectRegion(region); regionDrop.classList.remove('w--open'); regionField.classList.remove('opened')"
                  >
                    <div class="category-text-link">{{ region.name }}</div>
                  </a>
                </div>
                <div
                  class="filter-list-reset"
                  (click)="resetData(); regionDrop.classList.remove('w--open'); regionField.classList.remove('opened')"
                >
                  Сбросить
                </div>
              </div>
            </nav>
          </div>
        </div>
      </div>

      <div class="municipalities-row" *ngIf="selectedRegion.id && !isPilotRegion && !isDefaultRegion">
        <div>
          <label for="municipality" class="region-label">Муниципалитет</label>
          <div class="div-space"></div>
        </div>
        <div class="dropdown-inline cat" #municipalityFilter>
          <div class="dropdown-inline-container w-dropdown" [ngClass]="{ disabled: isMunicipality }">
            <div
              class="dd-input-field"
              #municipalityField
              (click)="municipalityDrop.classList.toggle('w--open'); municipalityField.classList.toggle('opened')"
            >
              <div class="login-field w-input" [class.active]="selectedMunicipality">
                {{ selectedMunicipality.name }}
              </div>
            </div>
            <nav class="dd-prof-filter-list w-dropdown-list" #municipalityDrop>
              <div class="dd-prof-filter-div">
                <div class="filter-list-row">
                  <div class="filter-list-heading">Муниципалитет</div>
                </div>
                <div class="prof-categories-links">
                  <a
                    class="prof-categories-link w-inline-block"
                    *ngFor="let municipality of municipalities | slice : 1"
                    (click)="
                      selectMunicipality(municipality);
                      municipalityDrop.classList.remove('w--open');
                      municipalityField.classList.remove('opened')
                    "
                  >
                    <div class="category-text-link">{{ municipality.name }}</div>
                  </a>
                </div>
                <div
                  class="filter-list-reset"
                  (click)="
                    resetMunicipalityData(); municipalityDrop.classList.remove('w--open'); municipalityField.classList.remove('opened')
                  "
                >
                  Сбросить
                </div>
              </div>
            </nav>
          </div>
        </div>
      </div>
    </div>

    <mat-form-field class="full-width">
      <input matInput type="search" class="form-control" placeholder="Введите поисковый запрос" [formControl]="searchProfessionField" />
      <a (click)="clearSearch()" class="search-clear show w-inline-block"></a>
    </mat-form-field>

    <br />
    <div class="button-wrapper">
      <prf-dropdown-with-filter
        *ngIf="fieldTypes"
        [class.disabled-dropdown]="isFieldTypeDropdownDisabled"
        [items]="fieldTypes"
        [selectedItemByDefault]="defaultFieldType"
        (selectedItemEvent)="getCategoryList($event)"
        [placeholder]="'Выберите тип отраслей'"
      ></prf-dropdown-with-filter>

      <prf-profession-filter
        *ngIf="categoryList"
        [categoryList]="categoryList"
        (selectedCategory)="getProfessionsByCategory($event)"
      >
      </prf-profession-filter>

      <button class="admin-large-btn w-button" [class.disabled]="!selectedRegionId" (click)="syncObject()">Синхронизировать объекты</button>
    </div>
    <br />

    <div class="full-width">
      <mat-radio-group [(ngModel)]="visibilityIndex" (change)="setVisibilityFilter($event)" class="visibility-filter">
        <mat-radio-button *ngFor="let visibility of visibilities; index as index" [value]="index" [color]="'primary'">
          {{ visibility }}
        </mat-radio-button>
      </mat-radio-group>
    </div>
    <br />

    <div class="professions-count-row">
      <div class="courses-number">Количество профессий: {{ professionsCount }}</div>
    </div>

    <div class="wrapper">
      <div class="table">
        <div class="row table-header">
          <div class="cell">№</div>
          <div class="cell">Название</div>
          <!-- <div class="cell">Муниципалитет</div> -->
          <div class="cell">HRID</div>
          <div class="cell">Видимость</div>
          <div class="cell">средняя зарплата</div>
          <div class="cell">макс зарплата</div>
          <div class="cell">мин зарплата</div>
          <div class="cell">ссылка</div>
          <div class="cell" *ngIf="userRole !== 'schooladmin'">
            {{ 'SHARED.EDIT_BUTTON' | translate }}
          </div>
        </div>
        <div class="row search-row" *ngFor="let search of searches; let count = index">
          <div class="cell" data-title="number">{{ count + 1 }}</div>
          <div class="cell" data-title="name">{{ search.name }}</div>
          <!-- <div class="cell" data-title="municipiality">{{getMunicipalityName(search.municipalityId)}}</div> -->
          <div class="cell" data-title="hrid">{{ search.hrid }}</div>
          <div class="cell" data-title="visibility">
            <mat-slide-toggle
              [color]="'primary'"
              [checked]="search.isVisible"
              [(ngModel)]="search.isVisible"
              (change)="openChangeProfessionVisibilityWindow($event, search)"
            >
            </mat-slide-toggle>
          </div>
          <div class="cell" data-title="averageSalary">{{ search.averageSalary }}</div>
          <div class="cell" data-title="maxSalary">{{ search.maxSalary }}</div>
          <div class="cell" data-title="minSalary">{{ search.minSalary }}</div>
          <div class="cell" data-title="LINK">
            <a mat-button routerLink="/professions/{{ search.hrid }}" target="_self">Перейти</a>
          </div>
          <div class="cell" data-title="SETTINGS" *ngIf="userRole !== 'schooladmin'">
            <a (click)="setCurrentProfessionFromSearches(search)" class="event-edit-btn w-inline-block"></a>
            <!--<a class="delete-event w-inline-block" (click)="showDeleteProfession(search.id)"></a>-->
          </div>
        </div>
        <ng-container
          *ngIf="(filteredProfessions && filteredProfessions.length > 0) || (searches && searches.length > 0); else emptyProfessions"
        >
          <div class="row" *ngFor="let profession of filteredProfessions; let count = index">
            <div class="cell" data-title="number">{{ count + 1 }}</div>
            <div class="cell" data-title="name">{{ profession.name }}</div>
            <!-- <div class="cell" data-title="municipiality">{{getMunicipalityName(profession.municipalityId)}}</div> -->
            <div class="cell" data-title="hrid">{{ profession.hrid }}</div>
            <div class="cell" data-title="visibility">
              <mat-slide-toggle
                [color]="'primary'"
                [checked]="profession.isVisible"
                [(ngModel)]="profession.isVisible"
                (change)="openChangeProfessionVisibilityWindow($event, profession)"
              >
              </mat-slide-toggle>
            </div>
            <div class="cell" data-title="averageSalary">{{ profession.averageSalary }}</div>
            <div class="cell" data-title="maxSalary">{{ profession.maxSalary }}</div>
            <div class="cell" data-title="minSalary">{{ profession.minSalary }}</div>
            <div class="cell" data-title="LINK">
              <a mat-button target="_blank" routerLink="/professions/{{ profession.hrid }}" target="_self">Перейти</a>
            </div>
            <div class="cell" data-title="SETTINGS" *ngIf="userRole !== 'schooladmin'">
              <a (click)="setCurrentProfession(profession)" class="event-edit-btn w-inline-block"></a>
              <!--<a
                class="delete-event w-inline-block"
                (click)="showDeleteProfession(profession.id)"
              ></a>-->
            </div>
          </div>
        </ng-container>
      </div>
    </div>

    <div class="uploud-block">
      <a
        class="button-animate btn-activate"
        (click)="animateCatalogButton()"
        [ngClass]="{ disabled: isAccessCatalogDenied }"
        [class.btn-waiting]="buttonCatalogStates.waiting"
        [class.btn-activated]="buttonCatalogStates.activated"
        [class.btn-activate]="!buttonCatalogStates.active"
      >
        <span class="btn-icon"></span>
        <span class="btn-text" data-wait="Подождите" [attr.data-after]="'SHARED.REFRESH_CATALOG' | translate">{{
          'SHARED.REFRESH_CATALOG' | translate
        }}</span>
      </a>

      <input hidden (change)="onFileSelected($event)" #fileInput type="file" id="file" accept=".xlsx, .xls, .csv" />
    </div>

    <ng-container *ngIf="setting">
      <div class="perform-task-lb lb show">
        <div class="lb-bg"></div>
        <div class="lb-block task-lb edit-scroll">
          <a
            [routerLink]="['/admin/copy-profession', currentProfession.hrid]"
            *ngIf="!isCopy"
            class="copy-profession copy-event-button w-inline-block"
          >
            <div class="edit-event-button-row">
              <div class="edit-icon-div">
                <img src="./assets/images/icons/files-copy-interface-symbol.svg" class="icon-initial-state" />
              </div>
              <div>{{ 'SHARED.CREATE_COPY' | translate }}</div>
            </div>
          </a>
          <a class="close-lb w-inline-block" (click)="closeEditWindow()">
            <img src="./assets/images/icons/close-lb.svg" />
          </a>
          <div class="tasl-lb-row">
            <div class="task-lb-column">
              <h3 class="task-lb-heading">{{ editingTitle }} «{{ currentProfession.name }}»</h3>
              <prf-edit-profession
                (updateValue)="closeEditWindow($event)"
                [_profession]="currentProfession"
                [fieldTypes]="fieldTypes"
                [courses]="courses"
                [profilClasses]="profilClasses"
                [isCopy]="isCopy"
                [specialities]="specialities"
              >
              </prf-edit-profession>
            </div>
          </div>
        </div>
      </div>
    </ng-container>

    <!--<ng-container *ngIf="popUpConfirming">
      <div class="perform-task-lb lb show">
        <div class="lb-bg"></div>
        <div class="lb-block task-lb sm">
          <a class="close-lb w-inline-block" (click)="popUpConfirming = !popUpConfirming">
            <img src="./assets/images/icons/close-lb.svg" />
          </a>
          <div class="tasl-lb-row">
            <div class="task-lb-column">
              <h4 class="task-lb-heading-result">Удаление профессии</h4>
              <div class="task-result-text">Вы уверены, что хотите удалить профессию?</div>
              <a class="button attach-file-btn w-button" (click)="deleteProfession(removeID)">{{
                'BUTTONS.DELETE' | translate
              }}</a>
            </div>
          </div>
        </div>
      </div>
    </ng-container>-->

    <ng-template [ngIf]="popUpConfirmHidingProfession">
      <div class="perform-task-lb lb show">
        <div class="lb-bg"></div>
        <div class="lb-block task-lb sm">
          <a class="close-lb w-inline-block" (click)="closePopUpConfirmHidingProfession()">
            <img src="./assets/images/icons/close-lb.svg" />
          </a>
          <div class="tasl-lb-row">
            <div class="task-lb-column">
              <h4 class="task-lb-heading-result">Скрытие профессии</h4>
              <div class="task-result-text hiding-profession-warning-text">
                Скрытие профессии приведет к удалению факторов по этой профессии из всех тестов! Профессия пропадет из выдачи каталога! Вы
                точно хотите скрыть профессию "{{ professionToHide.name }}"?
              </div>
              <a class="button attach-file-btn w-button" (click)="hideProfession()">Скрыть</a>
            </div>
          </div>
        </div>
      </div>
    </ng-template>
  </div>
</div>

<ng-template #emptyProfessions>
  <div class="event-empty-block">
    <div>
      <img src="./assets/images/menu/grey/Professions.svg" width="80" class="no-profession-icon" />
    </div>
    <div class="event-empty-block-text">
      {{ 'SHARED.FOR_YOU_REQUEST_NOT_PROFESSIONS' | translate }}
    </div>
  </div>
</ng-template>
