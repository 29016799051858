import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AppSettingsService } from 'app/shared/services/appsettings.service';
import { takeUntil } from 'rxjs/operators';
import { Observable, of, Subject } from 'rxjs';
import { EUserTags } from 'app/shared/enums/user-types.enum';
import { PageHeaderService } from '../../../../shared/dashboard/page-header/page-header.service';

@Component({
  selector: 'prf-profession-cards',
  templateUrl: './profession-cards.component.html',
  styleUrls: ['./profession-cards.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ProfessionCardsComponent implements OnInit, OnDestroy {
  public loaded: boolean = false;
  public userRole: string = '';
  public testSessionId: string = '';
  public sessionStatus: string = '';
  public tabName: string = 'catalog'; // [catalog, recommendations]
  public testResults: any;
  public userId: string = '';
  public selectLanguage: boolean = false;
  isVGuser: boolean = false;

  private ngUnsubscribe$: Subject<any> = new Subject();

  constructor(private activatedRoute: ActivatedRoute, private pageHeaderService: PageHeaderService) {
    this.userRole = localStorage.getItem('userRole');
    this.testSessionId = localStorage.getItem('testSessionId');
    this.sessionStatus = localStorage.getItem('sessionStatus');
    this.isVGuser = localStorage.getItem('tag') === EUserTags[EUserTags.VorobieviGori].toString();
    // если выполняется совпадение regionId из appsettings с выбранным регионом пользователя и для данного региона разрешена смена языка (isAccessible)
    this.selectLanguage =
      AppSettingsService.settings.regionLanguages &&
      AppSettingsService.settings.regionLanguages.isAccessible &&
      AppSettingsService.settings.regionId == localStorage.getItem('regionId');

    this.activatedRoute.fragment.subscribe(param => {
      if (param === 'recommendations') {
        this.tabName = 'recommendations';
      }
    });
  }

  ngOnInit() {
    this.pageHeaderService.setTitle('Профессии');
    this.loadData()
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe(() => (this.loaded = true));
  }

  ngOnDestroy() {
    this.ngUnsubscribe$.next(null);
    this.ngUnsubscribe$.complete();
    this.pageHeaderService.setTitle(null);
  }

  private loadData(): Observable<any> {
    return of(null);
  }
}
