<form class="school-class__wrapper" [formGroup]="courseForm">
  <span class="school-class__name">{{ schoolClass.number }} {{ schoolClass.letter }}</span>

  <div class="dropdown-inline cat" #courseFilter>
    <div class="dropdown-inline-container w-dropdown">
      <div class="profile-field-div" #courseField (click)="courseDrop.classList.toggle('w--open'); courseField.classList.toggle('opened')">
        <input formControlName="course" type="text" class="login-field w-input" name="course" [class.active]="selectedCourseId" />
      </div>
      <nav class="dd-prof-filter-list w-dropdown-list" #courseDrop>
        <div class="dd-prof-filter-div limited-height">
          <div class="filter-list-row">
            <div class="filter-list-heading">{{ 'SHARED.COURSE' | translate }}</div>
          </div>
          <div class="prof-categories-links">
            <a
              class="prof-categories-link w-inline-block"
              (click)="patchCourse(null); courseDrop.classList.remove('w--open'); courseField.classList.remove('opened')"
            >
              <div class="category-text-link">Диагностика</div>
            </a>
            <a
              class="prof-categories-link w-inline-block"
              *ngFor="let course of courseMaterials"
              [class.dd-inline-current]="checkCourseHeader() === course"
              (click)="patchCourse(course); courseDrop.classList.remove('w--open'); courseField.classList.remove('opened')"
            >
              <div class="category-text-link">{{ course.name }}</div>
            </a>
          </div>
        </div>
      </nav>
    </div>
  </div>
</form>
