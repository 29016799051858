import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { FormControl, UntypedFormBuilder, Validators } from '@angular/forms';
import { combineLatest, Observable, of } from 'rxjs';
import { filter, switchMap, take, takeUntil, tap } from 'rxjs/operators';

import { TranslateService } from '@ngx-translate/core';
import { MatLegacyRadioChange as MatRadioChange } from '@angular/material/legacy-radio';

import { LessonsService } from 'app/pages/control-panel/admin/lessons/lessons.service';
import { OverlayBusyService } from 'app/shared/overlay-busy/overlay-busy.service';
import { UtilsService } from 'app/shared/dashboard/backend-services/utils.service';
import { IFilter } from 'app/shared/common-components/dropdown-with-filter/dropdown-with-filter.component';
import { AdminPanelService } from 'app/pages/control-panel/admin/admin-panel.service';
import { IProfession } from '../../../../../shared/interfaces/iprofession.interface';
import {
  IButterflyControlElement,
} from '../../../../../shared/common-components/butterfly-control/butterfly-control.component';
import { IRegion } from '../../../../../shared/interfaces/iregion';
import { RegionsService } from '../../regions/regions.service';
import {
  EditLessonHelperComponent,
} from '../../../../../shared/common-components/helpers/edit-lesson-helper/edit-lesson-helper.component';
import { ITaskFields, TinderTypes } from '../add-lesson/add-lesson.component';
import { ILesson } from 'app/shared/interfaces/ilesson';
import { ApiFieldsService } from '@profilum-library';

@Component({
  selector: 'prf-edit-lesson',
  templateUrl: './edit-lesson.component.html',
  styleUrls: ['./edit-lesson.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditLessonComponent extends EditLessonHelperComponent implements OnInit {
  public lessonModel: ILesson = {} as ILesson;
  public isLoading: boolean = true;
  public region: IFilter;
  public initialFieldType: IFilter;
  public defaultTryingOnStage: IFilter = null;
  private screeningTestId: string = '';
  private lessonId: string;
  private regionId: string;
  private profIds: any;
  protected readonly TinderTypes = TinderTypes;

  constructor(
    private fb: UntypedFormBuilder,
    private lessonsService: LessonsService,
    private overlayBusyService: OverlayBusyService,
    private meta: Meta,
    private activatedRoute: ActivatedRoute,
    private translateService: TranslateService,
    private adminPanelService: AdminPanelService,
    protected regionsService: RegionsService,
    protected utilsService: UtilsService,
    protected router: Router,
    protected changeDetectorRef: ChangeDetectorRef,
    protected apiFieldsService: ApiFieldsService,
  ) {
    super(utilsService, router, regionsService, changeDetectorRef, apiFieldsService);
    this.meta.updateTag({ name: 'og:title', content: 'Редактирование занятия' });
  }

  public async ngOnInit(): Promise<void> {
    await super.getAllRegions();
    this.overlayBusyService.show();
    this.activatedRoute.params
      .pipe(
        switchMap(params => {
          this.lessonId = params['lessonId'];

          if (!this.lessonId) {
            this.error();
            return;
          }

          return this.lessonsService.getOneLesson(this.lessonId);
        }),
        tap(lesson => {
          if (lesson) {
            this.lessonModel = {
              name: lesson.name,
              hrid: lesson.hrid,
              lessonMaterialId: lesson.lessonMaterialId,
              courseMaterialId: lesson.courseMaterialId,
              tasks: lesson.tasks,
              id: lesson.id,
              orderNumber: lesson.orderNumber,
              imageUrl: lesson.imageUrl,
              text: lesson.text,
              videoUrl: lesson.videoUrl,
            };

            const professionTryingOnStage: number = lesson.tasks?.[0]?.professionTryingOnStage;
            if (professionTryingOnStage){
              this.defaultTryingOnStage = {
                name: this.selectionStages[professionTryingOnStage-1].name,
                id: professionTryingOnStage-1
              }
            }
          }
        }),
        switchMap(() => {
          return this.apiFieldsService.getFieldTypes();
        }),
        switchMap((fieldTypes) => {
          Object.entries(fieldTypes).forEach(([key, value]) => {
            this.fieldTypes.push({ name: value, type: key });
          });

          const fieldId = this.lessonModel?.tasks[0]?.chooseProfessionsFromFieldTask?.fieldId;

          return fieldId ? this.apiFieldsService.getOneField(fieldId): of(null);
        }),
        switchMap(response => {
          if( response ) {
            this.initialFieldType = {
              id: response.field.type,
              name: this.fieldTypes[response.field.type].name,
              selected: true,
            }
          }
          return combineLatest([
            this.lessonsService.getCourseMaterials(),
            this.lessonsService.getTests(),
            response ? this.apiFieldsService.getAllFields(response.field.type):this.apiFieldsService.getAllFields(),
          ]);
        }),
        takeUntil(this.unsubscribe),
      )
      .subscribe({
        next: ([courseMaterials, tests, fieldsResponse]) => {
          this.courseMaterials = courseMaterials;
          this.tests = tests;
          this.fields = fieldsResponse.fields;
          this.initForm();
          this.setSelectedCourseAndLessonMaterial();
          this.setTask();
          this.isLoading = false;
          this.overlayBusyService.hide();
          this.changeDetectorRef.detectChanges();
        },
        error: () => {
          this.isLoading = false;
          this.error();
          this.overlayBusyService.hide();
        },
      });
  }

  public submit(): void {
    const tasks = this.taskCount > 0 ? this.createTasks() : null;
    const isValidForm = this.form.status === 'VALID' && (tasks === null || tasks.every(task => task !== null));

    this.submitted = true;

    if (isValidForm) {
      const lesson: ILesson = {
        id: this.lessonModel.id,
        hrid: this.formControls.hrid.value,
        name: this.formControls.name.value,
        courseMaterialId: this.formControls.courseMaterialId.value,
        lessonMaterialId: this.formControls.lessonMaterialId.value,
        orderNumber: this.formControls.orderNumber.value,
        imageUrl: this.formControls.imageUrl.value,
        text: this.formControls.text.value,
        videoUrl: this.formControls.videoUrl.value,
      };

      if (tasks) {
        lesson.tasks = tasks;
      }

      this.lessonsService
        .updateLesson(lesson)
        .pipe(take(1))
        .subscribe({
          next: () => {
            this.success();
          },
          error: () => {
            this.utilsService.openSnackBar('Ошибка добавления занятия', 'error');
          },
        });
    }
  }

  public selectedCourse(event: IFilter): void {
    this.setValue(event, this.courseMaterials, 'courseMaterialId', 'id');

    if (event) {
      this.lessonsMaterials = this.courseMaterials[event.id].lessonsMaterials;
      return;
    }

    this.lessonsMaterials = null;
  }

  public selectedLesson(event: IFilter): void {
    this.setValue(event, this.lessonsMaterials, 'lessonMaterialId', 'id');
  }

  public setLessonWithoutTasks(event: MatRadioChange): void {
    this.taskCount = event.value;
    this.isLessonWithoutTasks = !(this.taskCount > 0);
  }

  public setTaskType(event: MatRadioChange, index: number) {
    this.taskFields[index].taskType = event.value;
    this.variants = null;
  }

  public selectedTest(event: IFilter, index: number): void {
    this.variants = null;
    this.taskFields[index].selectedScreeningTestItem = null;
    this.taskFields[index].selectedVariantItem = null;

    if (event) {
      this.taskFields[index].screeningTestId = this.tests[event.id].screeningTestId;
      this.taskFields[index].tooltip = event.name;

      this.lessonsService
        .getTest(this.tests[event.id].screeningTestId)
        .pipe(take(1))
        .subscribe(test => {
          this.variants = test?.variants ?? [];
          this.changeDetectorRef.detectChanges();
        });

      return;
    }

    this.taskFields[index].screeningTestId = '';
    this.taskFields[index].tooltip = '';
  }

  public selectedVariant(event: IFilter, index: number): void {
    this.taskFields[index].selectedVariantItem = null;

    if (event) {
      this.taskFields[index].testVariantId = this.variants[event.id].id;

      return;
    }

    this.taskFields[index].testVariantId = '';
  }

  public selectedField(event: IFilter, index: number): void {
    this.taskFields[index].selectedFieldItem = null;

    if (event) {
      this.taskFields[index].fieldId = this.fields[event.id].id;
      this.taskFields[index].tooltip = this.taskFields[index].tooltip || event.name;
      this.taskFields[index].fieldName = event.name;

      this.updateProfessionsList(event.name, index);
      return;
    }

    this.taskFields[index].fieldId = '';
    this.taskFields[index].tooltip = '';
    this.taskFields[index].fieldName = '';
  }

  public uploadLogoLesson(event: Event): void {
    if ((<HTMLInputElement>event.target).files.item(0).type.match('image.*')) {
      this.selectedFiles = (<HTMLInputElement>event.target).files;
      this.adminPanelService
        .uploadImage(this.selectedFiles, this.lessonModel.id)
        .pipe(take(1), takeUntil(this.unsubscribe))
        .subscribe(r => {
          this.formControls.imageUrl.setValue(r.result);
          this.changeDetectorRef.detectChanges();
        });
    } else {
      this.utilsService.openSnackBar('👎 Некорректный формат файла', 'error');
    }
  }

  public setProfessions(event: IButterflyControlElement[], index: number): void {
    this.taskFields[index].chosenProfessions = event;
  }

  public selectedRegion(event: IFilter, index: number): void {
    const region: IRegion | null = event?.id ? this.regions[event.id] : null;
    this.regionId = region ? region.id : this.defaultRegionId;

    const { fieldId, fieldName, professionIds } = this.taskFields[index];
    this.profIds = new Set(professionIds);

    if (!fieldName || !fieldId) {
      return;
    }

    this.updateProfessionsList(fieldName, index);
  }

  public setProfessionTryingOnStage(event: IFilter, index: number): void {
    if (event) {
      this.taskFields[index].professionTryingOnStage = (event.id as number) + 1;
      return;
    }
    this.taskFields[index].professionTryingOnStage = null;
  }

  private error = (): void => {
    this.getTranslation('SHARED.ERROR_MSG')
      .pipe(
        tap((translation: string) => {
          this.utilsService.openSnackBar(translation, 'error');
        }),
      )
      .subscribe(() => {
        this.timer = setTimeout(_ => {
          this.router.navigate([`/admin/lessons`]);
        }, 2000);
      });
  };

  private success = (): void => {
    this.utilsService.openSnackBar(`👌 Занятие успешно обновлено`, 'success');

    this.timer = setTimeout(_ => {
      this.router.navigate(['/admin/lessons']);
    }, 2000);
  };

  private getTranslation(key: string): Observable<any> {
    return this.translateService.get(key);
  }

  private initForm(): void {
    this.form = this.fb.group({
      imageUrl: this.fb.control(this.lessonModel.imageUrl, []),
      videoUrl: this.fb.control(this.lessonModel.videoUrl, []),
      hrid: this.fb.control(this.lessonModel.hrid, [Validators.required]),
      name: this.fb.control(this.lessonModel.name, [Validators.required]),
      courseMaterialId: this.fb.control(this.lessonModel.courseMaterialId, [Validators.required]),
      lessonMaterialId: this.fb.control(this.lessonModel.lessonMaterialId, [Validators.required]),
      orderNumber: this.fb.control(this.lessonModel.orderNumber, [Validators.required, Validators.min(1)]),
      text: this.fb.control(this.lessonModel.text),
    });

    this.taskFields.forEach((taskField: ITaskFields, index: number) => {
      this.form.addControl('tooltipST' + index, new FormControl());
      this.form.addControl('tooltipCP' + index, new FormControl());
      this.form.addControl('externalLessonLink' + index, new FormControl());
      this.form.addControl('externalLessonTooltip' + index, new FormControl());
      this.form.addControl('required' + index, new FormControl());
      this.form.addControl('tinderType' + index, new FormControl());
    });
  }

  private setSelectedCourseAndLessonMaterial(): void {
    if (!(this.courseMaterials?.length && this.lessonModel.lessonMaterialId && this.lessonModel.courseMaterialId)) {
      return;
    }

    for (let i = 0; i < this.courseMaterials.length; i++) {
      if (this.courseMaterials[i].id === this.lessonModel.courseMaterialId) {
        this.selectedCourseItemByDefault = {
          name: this.courseMaterials[i].name,
          id: i,
        };

        this.lessonsMaterials = this.courseMaterials[i].lessonsMaterials;

        for (let j = 0, length = this.lessonsMaterials.length; j < length; j++) {
          if (this.courseMaterials[i].lessonsMaterials[j].id === this.lessonModel.lessonMaterialId) {
            const lesson = this.courseMaterials[i].lessonsMaterials[j];

            this.selectedLessonItemByDefault = {
              name: lesson.name,
              id: j,
            };

            break;
          }
        }

        break;
      }
    }
  }

  private setTask(): void {
    if (!this.lessonModel.tasks?.length) {
      return;
    }

    const tasks = this.lessonModel.tasks;

    this.taskCount = tasks.length;
    this.isLessonWithoutTasks = false;

    for (let i = 0; i < this.taskCount; i++) {
      const { chooseProfessionsFromFieldTask, text, type, screeningTestTask, required, tinderType, id } = tasks[i];

      this.taskFields[i].taskType = type;
      this.taskFields[i].required = !!required;
      this.taskFields[i].tinderType = tinderType;
      this.taskFields[i].id = id;

      if (tasks[i]?.externalUrl) {
        this.taskFields[i].externalLessonLink = tasks[i]?.externalUrl;
        this.taskFields[i].externalLessonTooltip = tasks[i]?.text;
      } else {
        this.taskFields[i].tooltip = text;
      }

      this.setChooseProfessionsFromFieldTask(chooseProfessionsFromFieldTask, i);
      this.setScreeningTestTask(screeningTestTask, i);
    }
  }

  private setChooseProfessionsFromFieldTask(
    chooseProfessionsFromFieldTask: { fieldId: string; professionIds?: string[] },
    index: number,
  ): void {
    if (chooseProfessionsFromFieldTask) {
      const { fieldId, professionIds } = chooseProfessionsFromFieldTask;

      this.taskFields[index].fieldId = fieldId ?? '';
      this.taskFields[index].professionIds = professionIds?.length ? professionIds : [];

      if (this.fields?.length) {
        const fieldIndex = this.fields.findIndex(field => field.id === chooseProfessionsFromFieldTask.fieldId);
        const fieldItem = this.fields[fieldIndex];

        if (fieldItem) {
          this.taskFields[index].fieldName = fieldItem.name;
          this.taskFields[index].selectedFieldItem = {
            name: fieldItem.name,
            id: fieldIndex,
          };
        }
      }

      if (this.taskFields[index].professionIds.length) {
        this.adminPanelService
          .getProfessionById(this.taskFields[index].professionIds[0])
          .pipe(filter(profession => profession !== null))
          .subscribe((profession: IProfession) => {
            const regionIndex = this.regions.findIndex(region => profession.regionId === region.id);

            if (regionIndex !== -1) {
              this.defaultRegionItem = { id: regionIndex, name: this.regions[regionIndex].name };
            }

            this.changeDetectorRef.detectChanges();
          });
      } else {
        const defaultRegionIndex = this.regions.findIndex(region => this.defaultRegionId === region.id);

        this.defaultRegionItem = defaultRegionIndex !== -1 ? { id: defaultRegionIndex, name: this.regions[defaultRegionIndex].name } : null;
      }
    }
  }

  private setScreeningTestTask(screeningTestTask: { screeningTestId: string; variantId: string }, index: number): void {
    if (screeningTestTask) {
      const { screeningTestId, variantId } = screeningTestTask;

      this.taskFields[index].screeningTestId = screeningTestId ?? '';
      this.taskFields[index].testVariantId = variantId ?? '';

      if (this.tests?.length) {
        const testIndex = this.tests.findIndex(test => test.screeningTestId === screeningTestTask.screeningTestId);
        const testItem = this.tests[testIndex];

        if (testItem) {
          this.taskFields[index].selectedScreeningTestItem = {
            name: testItem.name,
            id: testIndex,
          };

          this.lessonsService
            .getTest(testItem.screeningTestId)
            .pipe(take(1))
            .subscribe(test => {
              this.variants = test?.variants ?? [];

              const variantIndex = this.variants.findIndex(variant => variant.id === screeningTestTask.variantId);
              const variantItem = this.variants[variantIndex];

              if (variantItem) {
                this.taskFields[index].selectedVariantItem = {
                  name: variantItem.name,
                  id: variantIndex,
                };
              }
              this.changeDetectorRef.detectChanges();
            });
        }
      }
    }
  }

  private updateProfessionsList(fieldName: string, index: number): void {
    this.adminPanelService
      .getElasticProfessionsByFilters({ fields: [fieldName], regionId: this.regionId, isVisible: true, fieldType: this.initialFieldType.id as number })
      .pipe(take(1))
      .subscribe((professions: IProfession[]) => {
        this.taskFields[index].professions = professions?.map(profession => {
          return {
            id: profession.id,
            name: profession.name,
            text: this.regionsObj[profession.regionId]?.name ?? '',
            isSaved: this.profIds.has(profession.id),
            regionId: profession.regionId,
          };
        });
        this.taskFields[index].chosenProfessions = this.taskFields[index].professions.filter(profession => profession.isSaved);

        this.changeDetectorRef.detectChanges();
      });
  }
}
