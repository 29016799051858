import { Injectable } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { B2gSaasService, HttpService } from '../../api-services';
import { AppSettingsService, AuthHandlerService, UserDataHandlerService } from '../../utils-services';
import { StorageKeys, WebStorageService } from '../../logic-services';

export interface IUserProfileInfo {
  isLockedOut: boolean;
  isNotAllowed: boolean;
  isUnRegistered: boolean;
  requiresTwoFactor: boolean;
  role: string;
  succeeded: boolean;
  tag: string;
  userId: string;
}
@Injectable({
  providedIn: 'root',
})
export class AuthRoleGuard {
  private userInfo: IUserProfileInfo;
  private userRole: string;
  constructor(
    private router: Router,
    private http: HttpService,
    private appSettings: AppSettingsService,
    public route: ActivatedRoute,
    private b2gSaasService: B2gSaasService,
    private authHandlerService: AuthHandlerService,
    private userDataHandlerService: UserDataHandlerService,
    private webStorageService: WebStorageService,
  ) {}

  async canActivate(route: ActivatedRouteSnapshot): Promise<boolean> {
    this.userInfo = this.userDataHandlerService.getUserInfo();
    if (this.userInfo) {
      this.userRole = this.userInfo.role;
    } else {
      this.userRole = this.webStorageService.get(StorageKeys.UserRole);
    }
    console.log('Auth guard');
    const acceptedRoles = route.data.roles as Array<string>;

    if (acceptedRoles.includes(this.userRole)) {
      return await this.authGuardCanActivate();
    } else {
      if (!this.userRole) {
        this.router.navigate(['/']);
      }
      return await Promise.resolve(false);
    }
  }

  // full authGuard canActivate
  async authGuardCanActivate(): Promise<boolean> {
    console.log('authGuardCanActivate');
    return await this.b2gSaasService
      .getUserInfo()
      .toPromise()
      .then(ui => {
        let locationPrefix = this.appSettings.currentLocation + '-'; // Префикс роута
        locationPrefix = locationPrefix.replace('base-', ''); // Если базовый лендинг, то убираем префикс

        this.authHandlerService.checkCookie();
        if (this.userRole == undefined) {
          this.authHandlerService.logout();
          return false;
        }
        // проверяем needToUpdate, если true, перенаправляем ученика на обновление профиля
        if (this.userRole === 'pupil' && ui.needToUpdate) {
          this.router.navigate(['/update-user-info']);
          return false;
        }
        this.router.events.subscribe(e => {
          if (e instanceof NavigationStart && e.url === '/parent' && localStorage.getItem('tag') === 'MosRu') {
            // для mosru пользователей домашняя страница - '/mosruabout'
            this.router.navigate(['/mosruabout']);
            return false;
          }
          if (e instanceof NavigationEnd) {
            switch (e.url) {
              case '/admin':
              case '/cityadmin':
              case '/adminDO':
              case '/employer':
              case '/schooladmin':
              case '/director':
              case '/teacher':
              case '/pupil':
              case '/tutor': {
                this.router.navigate(['/' + this.userRole]);
                return true;
              }
              case '/parent': {
                if (localStorage.getItem('tag') === 'MosRu') {
                  // для mosru пользователей домашняя страница - '/mosruabout'
                  // срабатывает при переходе по внешней ссылке
                  this.router.navigate(['/mosruabout']);
                  return false;
                } else {
                  this.router.navigate(['/' + this.userRole]);
                  return false;
                }
              }
              default:
                break;
            }
          }
        });
        return true;
      })
      .catch(() => {
        this.router.navigate(['/']);
        return false;
      });
  }
}
