import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Meta } from '@angular/platform-browser';
import { UtilsService } from 'app/shared/dashboard/backend-services/utils.service';
import { IRegion } from 'app/shared/interfaces/iregion';
import * as moment from 'moment';
import { finalize, mergeMap, of, takeUntil } from 'rxjs';
import { IMunicipality } from '../../admin.interface';
import { MunicipalitiesService } from '../../municipalities/municipalities.service';
import { RegionsService } from '../../regions/regions.service';
import { SystemService } from '../system.service';
import { saveAs } from 'file-saver';
import { filter, take } from 'rxjs/operators';
import { IAnswersMatrixData, IListedItem, IShortTest, IShortVariant, MatrixForm } from '../system.interface';
import { UnsubscribeComponent } from '@profilum-components/unsubscribe/unsubscribe.component';

@Component({
  selector: 'prf-answers-matrix',
  templateUrl: './answers-matrix.component.html',
  styleUrls: ['./answers-matrix.component.scss'],
})
export class AnswersMatrixComponent extends UnsubscribeComponent implements OnInit {
  @ViewChild('variantInput') public variantInput: ElementRef<HTMLInputElement>;

  public form: FormGroup;
  public answersMatrixPrepearing: boolean = false;
  public listedRegions: IListedItem[] = [];
  public listedMunicipalities: IListedItem[] = [];
  public listedTests: IListedItem[] = [];
  public listedTestVariants: IShortVariant[] = [];
  public isMunicipalityDisabled: boolean = false;
  public isResetFilters: boolean;

  private regions: IRegion[] = [];
  private tests: IShortTest[] = [];
  private variantsByTest: IShortVariant[] = [];

  constructor(
    private meta: Meta,
    private systemService: SystemService,
    private utilsService: UtilsService,
    private regionsService: RegionsService,
    private municipalitiesService: MunicipalitiesService,
  ) {
    super();
    this.meta.updateTag({ name: 'og:title', content: 'Отчеты' });
  }

  public ngOnInit(): void {
    this.initForm();
    this.getRegions();
    this.getTests();

    this.form
      .get('region')
      .valueChanges.pipe(
        filter(value => value && value.data),
        mergeMap(value => {
          if (value.data === 'all') {
            this.form.get('municipality').setValue({ name: 'Все муниципалитеты', data: 'all' });
            this.isMunicipalityDisabled = true;

            return of(null);
          } else {
            this.isMunicipalityDisabled = false;
            let selectedRegion: IRegion = this.regions.find((region: IRegion) => region.id === value.id);
            this.form.get('municipality').reset();

            return this.municipalitiesService.getMunicipalitiesByRegion(selectedRegion.id);
          }
        }),
        takeUntil(this.unsubscribe),
      )
      .subscribe((municipalities: IMunicipality[]) => {
        if (municipalities && municipalities.length) {
          this.listedMunicipalities = [];

          this.listedMunicipalities.push({
            name: ' Все муниципалитеты',
            data: 'all',
          });

          municipalities.forEach((municipality: IMunicipality) =>
            this.listedMunicipalities.push({ name: municipality.name, data: municipality.hrid, id: municipality.id }),
          );
        }
      });

    this.form
      .get('test')
      .valueChanges.pipe(
        filter(value => value && value.data),
        mergeMap(value => {
          this.isResetFilters = true;

          this.timer = setTimeout(() => {
            this.isResetFilters = false;
          }, 100);

          let selectedTest: IShortTest = this.tests.find((test: IShortTest) => test.id === value.data);
          this.form.get('variant').reset();

          const variants: IShortVariant[] = this.tests.find((test: IShortTest) => test.id === selectedTest.id)?.variants;

          if (variants[0]?.id !== 'all') {
            variants.unshift({
              name: 'Все варианты',
              id: 'all',
            });
          }

          this.variantsByTest = variants;
          this.listedTestVariants = [];
          this.listedTestVariants = this.variantsByTest;

          return of(this.variantsByTest);
        }),
        takeUntil(this.unsubscribe),
      )
      .subscribe();
  }

  public resetFilters(): void {
    this.form.reset();
    this.listedTestVariants = [];
    this.listedMunicipalities = [];
  }

  public generateAnswersReportExcel(): void {
    if (!this.form.valid) {
      return;
    }

    const selectedRegionId: string = this.form.get('region').value?.id;
    const selectedMunicipalityId: string = this.form.get('municipality').value?.id;
    const selectedTestId: string = this.form.get('test').value?.data;
    const selectedVariantIds: IShortVariant[] = this.form.get('variant').value
      ? this.form.get('variant').value[0].id === 'all'
        ? []
        : this.form.get('variant').value
      : [];
    const dateFrom: Date = this.form.get('dateFrom').value ? new Date(this.form.get('dateFrom').value) : null;
    const dateTo: Date = this.form.get('dateTo').value ? new Date(this.form.get('dateTo').value) : new Date();

    this.answersMatrixPrepearing = true;

    const requestData: IAnswersMatrixData = {
      regionId: selectedRegionId,
      municipalityId: selectedMunicipalityId,
      dateFrom: dateFrom ? moment(dateFrom).format('YYYY-MM-DDThh:mm:ss.SSSZ') : null,
      dateTo: moment(dateTo).format('YYYY-MM-DDThh:mm:ss.SSSZ'),
      screeningTestId: selectedTestId,
      variantIds: selectedVariantIds ? selectedVariantIds.map((variant: IShortVariant) => variant.id) : null,
    };

    this.systemService
      .generateAnswersReportExcel(requestData)
      .pipe(
        takeUntil(this.unsubscribe),
        finalize(() => {
          this.answersMatrixPrepearing = false;
        }),
      )
      .subscribe({
        next: response => {
          this.utilsService.openSnackBar('Отчет получен', 'success');
          const blob: Blob = new Blob([response.body], { type: response.headers.get('content-type') });
          let fileName: string = this.parseFilenameFromContentDisposition(response.headers.get('content-disposition'));
          const file: File = new File([blob], fileName, { type: response.headers.get('content-type') });
          saveAs(file);
        },
        error: () => {
          this.utilsService.openSnackBar('Произошла ошибка', 'error');
        },
      });
  }

  private initForm(): void {
    this.form = new FormGroup<MatrixForm>({
      test: new FormControl<string | null>(null, [Validators.required]),
      variant: new FormControl<string | null>(null, []),
      region: new FormControl<string | null>(null, []),
      municipality: new FormControl<string | null>(null, []),
      dateFrom: new FormControl<string | null>(null, []),
      dateTo: new FormControl<string | null>(null, []),
    });
  }

  private getRegions(): void {
    this.regionsService
      .getAllRegions()
      .pipe(take(1), takeUntil(this.unsubscribe))
      .subscribe({
        next: (res: IRegion[]) => {
          this.regions = res;
          this.listedRegions.push({
            name: 'Все регионы',
            data: 'all',
          });
          this.regions.forEach((region: IRegion) =>
            this.listedRegions.push({
              name: region.name,
              data: region.hrid,
              id: region.id,
            }),
          );
        },
      });
  }

  private getTests(): void {
    this.systemService
      .getTestsVariantsInfo()
      .pipe(take(1), takeUntil(this.unsubscribe))
      .subscribe({
        next: (res: IShortTest[]) => {
          this.tests = res;
          this.tests.forEach((test: IShortTest) =>
            this.listedTests.push({
              name: test.name,
              data: test.id,
            }),
          );
        },
      });
  }

  private parseFilenameFromContentDisposition(contentDisposition: string): string {
    if (!contentDisposition) return null;
    let matches: RegExpExecArray = /filename="(.*?)"/g.exec(contentDisposition);
    return matches && matches.length > 1 ? matches[1] : 'untitled';
  }
}
