import { Component, OnDestroy, OnInit } from '@angular/core';
import { OverlayBusyService } from 'app/shared/overlay-busy/overlay-busy.service';

@Component({
  selector: 'prf-overlay-busy',
  templateUrl: './overlay-busy.component.html',
  styleUrls: ['./overlay-busy.component.scss'],
})
export class OverlayBusyComponent implements OnInit, OnDestroy {
  showLoader = false;
  text = 'COMMON.OVERLAY.LOADING';

  constructor(private overlayBusyService: OverlayBusyService) {}

  ngOnDestroy(): void {
    this.overlayBusyService.unregister();
  }

  ngOnInit() {
    this.overlayBusyService.register({
      show: text => (this.showLoader = true) && (this.text = text || 'COMMON.OVERLAY.LOADING'),
      hide: () => (this.showLoader = false),
    });
  }
}
