<div class="content-section">
  <div class="container container-1000 prof-page-cont">
    <div class="profession-page-section" *ngIf="profession && dataFetched">
      <div class="prof-page-white-column" [class.single-column]="!showSalary">
        <div *ngIf="checkProfession()" class="prof-municipality w-inline-block" style="position: relative">
          <img src="./assets/images/icons/fire-profession.svg" class="prof-municipality-image" />
          <div class="prof-municipality-text">
            {{ 'SHARED.PROFESSION_NARRATIVE' | translate }}
          </div>
        </div>
        <h1 class="profession-page-heading-1">{{ profession.name }}</h1>
        <div class="prof-page-text" innerHtml="{{ profession.longDescription }}"></div>
        <div class="prof-info-block">
          <h2 class="prof-h2">Путь к профессии "{{ profession.name | trim }}"</h2>
          <prf-steps-trajectory [hideButton]="true" [profession]="profession"></prf-steps-trajectory>
        </div>
        <div>
          <h2 class="prof-h2">Больше интересного о профессии</h2>
          <div class="prof-info-menu" #profInfoMenu>
            <div class="prof-info-menu-row">
              <a
                class="prof-menu-link"
                *ngFor="let item of contentMenu"
                [ngClass]="{ 'w--current': currentContent == item }"
                (click)="currentContent = item; prepareData()"
                >{{ item }}</a
              >
            </div>
          </div>
          <div class="prof-info-tabs">
            <div class="prof-info-tab-pane">
              <ng-container *ngIf="currentContent === 'Видео'">
                <ng-container *ngTemplateOutlet="videos"></ng-container>
              </ng-container>
              <ng-container *ngIf="currentContent === 'Фильмы'">
                <ng-container *ngTemplateOutlet="movies"></ng-container>
              </ng-container>
              <ng-container *ngIf="currentContent === 'Профессия в лицах'">
                <ng-container *ngTemplateOutlet="faces"></ng-container>
              </ng-container>
              <ng-container *ngIf="currentContent === 'Книги и статьи'">
                <ng-container *ngTemplateOutlet="articles"></ng-container>
              </ng-container>
            </div>
          </div>
        </div>

        <div class="mobile-block" *ngIf="showSalary">
          <h4 class="average-salary-heading">Средняя зарплата на разных этапах карьерного роста</h4>
          <ul class="average-salary-list w-list-unstyled">
            <li class="average-salary-li">
              <div class="average-salary-row">
                <div class="average-salary-post">Специалист высшей категории</div>
                <div class="wage-amount">{{ profession.maxSalary }} {{ 'COMMON.MONEY_SYMBOL' | translateByDefault }}</div>
              </div>
              <div class="average-salary-line-wrapper">
                <div class="average-salary-line"></div>
              </div>
            </li>
            <li class="average-salary-li">
              <div class="average-salary-row">
                <div class="average-salary-post">Специалист со средним опытом</div>
                <div class="wage-amount">{{ profession.averageSalary }} {{ 'COMMON.MONEY_SYMBOL' | translateByDefault }}</div>
              </div>
              <div class="average-salary-line-wrapper">
                <div class="average-salary-line _2"></div>
              </div>
            </li>
            <li class="average-salary-li">
              <div class="average-salary-row">
                <div class="average-salary-post">Начинающий специалист</div>
                <div class="wage-amount">{{ profession.minSalary }} {{ 'COMMON.MONEY_SYMBOL' | translateByDefault }}</div>
              </div>
              <div class="average-salary-line-wrapper">
                <div class="average-salary-line _3"></div>
              </div>
            </li>
          </ul>
        </div>
      </div>

      <div *ngIf="showSalary" class="prof-page-gray-column">
        <div *ngIf="showSalary">
          <h4 class="average-salary-heading">Средняя зарплата на разных этапах карьерного роста</h4>
          <ul class="average-salary-list w-list-unstyled">
            <li class="average-salary-li">
              <div class="average-salary-row">
                <div class="average-salary-post">Специалист высшей категории</div>
                <div class="wage-amount">{{ profession.maxSalary }} {{ 'COMMON.MONEY_SYMBOL' | translateByDefault }}</div>
              </div>
              <div class="average-salary-line-wrapper">
                <div class="average-salary-line"></div>
              </div>
            </li>
            <li class="average-salary-li">
              <div class="average-salary-row">
                <div class="average-salary-post">Специалист со средним опытом</div>
                <div class="wage-amount">{{ profession.averageSalary }} {{ 'COMMON.MONEY_SYMBOL' | translateByDefault }}</div>
              </div>
              <div class="average-salary-line-wrapper">
                <div class="average-salary-line _2"></div>
              </div>
            </li>
            <li class="average-salary-li">
              <div class="average-salary-row">
                <div class="average-salary-post">Начинающий специалист</div>
                <div class="wage-amount">{{ profession.minSalary }} {{ 'COMMON.MONEY_SYMBOL' | translateByDefault }}</div>
              </div>
              <div class="average-salary-line-wrapper">
                <div class="average-salary-line _3"></div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <prf-similar-professions></prf-similar-professions>
  </div>
</div>

<ng-template #faces>
  <div class="professionals-list">
    <a class="professional-card w-inline-block" *ngFor="let person of personsList; let i = index" (click)="checkPopup($event, popup)">
      <div class="professional-info-lb lb" #popup>
        <div class="lb-bg"></div>
        <div class="lb-block prof-popup">
          <div class="close-lb" (click)="popup.style.display = 'none'">
            <img src="./assets/images/icons/close-lb.svg" />
          </div>
          <div class="professional-info-row">
            <div class="professional-info-column _1">
              <p>{{ person.description }}</p>
            </div>
            <div class="professional-info-column _2">
              <div class="professional-photo prof-popup">
                <img src="{{ person.imagePath ? '/staticfiles' + person.imagePath : '/assets/images/dashboard/no-photo.svg' }}" />
              </div>
              <div class="professional-name prof-popup">{{ person.name }}</div>
              <div class="professional-post">{{ profession.name }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="professional-photo">
        <img src="{{ person.imagePath ? '/staticfiles' + person.imagePath : '/assets/images/dashboard/no-photo.svg' }}" />
      </div>
      <div class="professional-column">
        <div class="professional-name">{{ person.name }}</div>
        <div class="professional-post">{{ profession.name }}</div>
        <div class="professional-bio">
          <p class="professional-bio-text">{{ person.description }}</p>
          <div class="professional-read-more">подробнее</div>
        </div>
      </div>
    </a>
  </div>

  <div class="center-button" *ngIf="profession.persons.length > 2">
    <a
      class="white-button show-more w-button"
      (click)="personsList = profession.persons"
      *ngIf="personsList.length < profession.persons.length"
      >Показать всех</a
    >
    <a
      class="white-button show-more w-button"
      (click)="personsList = profession.persons.slice(0, 2); scroll()"
      *ngIf="personsList.length == profession.persons.length"
      >Скрыть</a
    >
  </div>
</ng-template>

<ng-template #movies>
  <ul class="films-list">
    <li *ngFor="let movieName of profession.movies">
      <div class="film-title">{{ movieName }}</div>
    </li>
  </ul>
</ng-template>

<ng-template #videos>
  <ng-container *ngFor="let video of videoList">
    <div class="frame-video">
      <iframe width="100%" height="380" [src]="video.url | safeUrl" frameborder="0" allowfullscreen></iframe>
    </div>
    <div class="prof-info-video-text">{{ video.name }}</div>
  </ng-container>
  <div class="center-button" *ngIf="profession.videos.length > 1">
    <a class="white-button show-more w-button" (click)="videoList = profession.videos" *ngIf="videoList.length < profession.videos.length"
      >Показать все видео</a
    >
    <a
      class="white-button show-more w-button"
      (click)="videoList = profession.videos.slice(0, 1); scroll()"
      *ngIf="videoList.length == profession.videos.length"
      >Скрыть видео</a
    >
  </div>
</ng-template>

<ng-template #articles>
  <ul class="articles-list">
    <li class="articles-list-item" *ngFor="let article of profession.articles">
      <a class="article-link" [href]="sanitizeUrl(article.url)" target="_blank">
        {{ article.name }}
      </a>
    </li>
  </ul>
</ng-template>
