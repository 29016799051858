import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { NgForm } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { IMunicipality } from 'app/shared/interfaces/imunicipality';
import { IRegion } from 'app/shared/interfaces/iregion';
import { Observable, of } from 'rxjs';
import { map, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { UtilsService } from 'app/shared/dashboard/backend-services/utils.service';
import { AdminPanelService } from '../../../admin-panel.service';
import { MunicipalitiesService } from '../../../municipalities/municipalities.service';
import { RegionsService } from '../../../regions/regions.service';
import { ICourses, IProfession } from 'app/shared/interfaces/iprofession.interface';
import { EmptyGuid } from 'app/shared/global-constants/constants';
import { ApiFieldsService } from '@profilum-library';
import { Helper } from '../../../../../../shared/common/helpers/helper';
import { MatLegacySlideToggleChange as MatSlideToggleChange } from '@angular/material/legacy-slide-toggle';
import { ICatalogSpeciality } from 'app/shared/interfaces/ispeciality.interface';
import { UnsubscribeComponent } from '@profilum-components/unsubscribe/unsubscribe.component';

@Component({
  selector: 'prf-add-profession',
  templateUrl: './add-profession.component.html',
  styleUrls: ['./add-profession.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AddProfessionComponent extends UnsubscribeComponent implements OnInit, OnDestroy {
  @Input() courses: ICourses[];
  @Input() profilClasses: any[];
  @Input() fieldTypes: any[];
  @Input() specialities: ICatalogSpeciality[];
  @Output() closeAddProfession = new EventEmitter<any>();
  error: boolean = false;
  educationList = [];
  demandRatings = [];
  booleanVariants = [];
  fields: any[];

  // key - field name, value - image path
  dictFieldImages = [
    {
      key: 'Административная работа, госслужба, управление',
      value: '/content/images/professions/default_icons/administration@3x.png',
    },
    {
      key: 'Архитектура и проектирование, промышленный дизайн',
      value: '/content/images/professions/default_icons/engineering@3x.png',
    },
    {
      key: 'Безопасность и обеспечение порядка, военное дело',
      value: '/content/images/professions/default_icons/security@3x.png',
    },
    {
      key: 'Добыча и переработка сырья',
      value: '/content/images/professions/default_icons/rawmaterials@3x.png',
    },
    {
      key: 'Информационные технологии и телекоммуникации',
      value: '/content/images/professions/default_icons/it@3x.png',
    },
    {
      key: 'Искусство, творчество, дизайн',
      value: '/content/images/professions/default_icons/art@3x.png',
    },
    {
      key: 'Маркетинг, реклама, масс-медиа',
      value: '/content/images/professions/default_icons/advertising@3x.png',
    },
    {
      key: 'Медицина и здравоохранение',
      value: '/content/images/professions/default_icons/medicine@3x.png',
    },
    {
      key: 'Образование и педагогика',
      value: '/content/images/professions/default_icons/education@3x.png',
    },
    {
      key: 'Продажи, закупки и торговое дело',
      value: '/content/images/professions/default_icons/trade@3x.png',
    },
    {
      key: 'Промышленность и производство',
      value: '/content/images/professions/default_icons/production@3x.png',
    },
    {
      key: 'Сельское хозяйство, агропромышленность',
      value: '/content/images/professions/default_icons/agriculture@3x.png',
    },
    {
      key: 'Сервис, туризм и питание',
      value: '/content/images/professions/default_icons/service@3x.png',
    },
    {
      key: 'Социальные и гуманитарные науки',
      value: '/content/images/professions/default_icons/social@3x.png',
    },
    { key: 'Спорт', value: '/content/images/professions/default_icons/sport@3x.png' },
    {
      key: 'Строительство и недвижимость',
      value: '/content/images/professions/default_icons/building@3x.png',
    },
    {
      key: 'Точные и естественные науки',
      value: '/content/images/professions/default_icons/natural-sciences@3x.png',
    },
    {
      key: 'Транспорт и перевозки',
      value: '/content/images/professions/default_icons/transportations@3x.png',
    },
    {
      key: 'Установка, обслуживание и ремонт',
      value: '/content/images/professions/default_icons/repairs@3x.png',
    },
    {
      key: 'Финансы, бизнес и экономика',
      value: '/content/images/professions/default_icons/economy@3x.png',
    },
    {
      key: 'Энергетика и коммунальное хозяйство',
      value: '/content/images/professions/default_icons/energetics@3x.png',
    },
    { key: 'Юриспруденция', value: '/content/images/professions/default_icons/jurisprudence@3x.png' },
  ];

  profession: IProfession = {
    id: null,
    _id: 0,
    hrid: null,
    name: null,
    longDescription: null,
    shortDescription: null,
    mediumDescription: null,
    mainImagePath: null,
    mainImageId: EmptyGuid,
    demandRating: null,
    minSalary: null,
    maxSalary: null,
    averageSalary: null,
    additionalTexts: {
      educationDescription: null,
      actualCompaniesText: null,
    },
    resposibilities: [],
    skills: [],
    abilities: [],
    movies: [],
    facts: [],
    productGroups: [],
    profilClasses: [],
    knowledge: [],
    additionalEducations: [],
    genitive: null,
    dative: null,
    title: null,
    metaDescription: null,
    metaKeywords: null,
    regionId: null,
    municipalityId: null,
    specialities: [],
    // images: [{}], // эта коллекция создается при добавлении значения
    // courses: [{}], // эта коллекция создается при добавлении значения
    // fields: [{}], // эта коллекция создается при добавлении значения
    // articles: [{}], // эта коллекция создается при добавлении значения
    // persons: [{}], // эта коллекция создается при добавлении значения
    // videos: [{}], // эта коллекция создается при добавлении значения
    // companies: [{}], // эта коллекция создается при добавлении значения
    // education: [{}], // эта коллекция создается при добавлении значения
    // specials: [{}] // эта коллекция создается при добавлении значения
  };
  public selectedOptions = [];
  addedEducation = {
    level: 1,
    isPreferred: false,
    perentage: 0,
  };
  selectedFilesLogo: File;

  addedPerson = {
    imagePath: '',
    name: '',
    description: '',
  };
  selectedFilesPerson: FileList;
  selectedFilesProfession: FileList;
  imagePath;
  professionFieldName: string;
  professionFieldType: any;

  public allRegions: IRegion[] = [];
  public currentRegion: IRegion;

  public municipalitiesByRegion: IMunicipality[] = [];
  public currentMunicipality: IMunicipality;

  constructor(
    private adminPanelService: AdminPanelService,
    private regionsService: RegionsService,
    private municipalitiesService: MunicipalitiesService,
    private utilsService: UtilsService,
    private translateService: TranslateService,
    private apiFieldsService: ApiFieldsService,
  ) {
    super();
    this.getTranslations([
      'SHARED.EDU.SECONDARY',
      'SHARED.EDU.VOCATIONAL',
      'SHARED.EDU.HIGHER',
      'SHARED.EDUCATION.LOW',
      'SHARED.EDUCATION.MEDIUM',
      'SHARED.EDUCATION.HIGH',
      'BUTTONS.YES',
      'BUTTONS.NO',
    ])
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(translations => {
        this.educationList = [
          { level: 1, viewValue: translations['SHARED.EDU.SECONDARY'] },
          { level: 2, viewValue: translations['SHARED.EDU.VOCATIONAL'] },
          { level: 3, viewValue: translations['SHARED.EDU.HIGHER'] },
        ];
        this.demandRatings = [
          { level: 1, viewValue: translations['SHARED.EDUCATION.LOW'] },
          { level: 2, viewValue: translations['SHARED.EDUCATION.MEDIUM'] },
          { level: 3, viewValue: translations['SHARED.EDUCATION.HIGH'] },
        ];
        this.booleanVariants = [
          { value: true, viewValue: translations['BUTTONS.YES'] },
          { value: false, viewValue: translations['BUTTONS.NO'] },
        ];
      });
  }

  ngOnInit() {
    this.profession.id = this.utilsService.newGuid();
    this.regionsService
      .getAllRegions()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(regions => {
        this.allRegions = regions;
      });
  }

  selectRegion(region) {
    this.setRegion(region).pipe(takeUntil(this.unsubscribe)).subscribe();
  }

  setRegion(region): Observable<any> {
    this.currentRegion = region;
    this.profession.regionId = region.id;
    this.resetMunicipality();
    return this.municipalitiesService.getMunicipalitiesByRegion(region.id).pipe(
      tap(municipalities => {
        this.municipalitiesByRegion = municipalities;
      }),
    );
  }

  setMunicipality(municipality) {
    if (municipality) {
      this.currentMunicipality = municipality;
      this.profession.municipalityId = municipality.id;
    }
    return;
  }

  resetMunicipality() {
    this.profession.municipalityId = EmptyGuid;
    this.currentMunicipality = null;
  }

  clearTerritoryData() {
    this.currentRegion = null;
    this.municipalitiesByRegion = [];
    this.currentMunicipality = null;
    return;
  }

  // ????
  // isProfessionHridBusy(hrid: string) {
  //   let profession = await this.adminPanelService.getProfessionByHrid(hrid);
  //   if (profession) {
  //     return true;
  //   }
  //   return false;
  // }

  /* Add new profession */
  public onSubmit(form: NgForm) {
    if (form.status == 'VALID') {
      this.profession.fields = [this.fields.find(el => el.name === this.professionFieldName)];

      if (this.profession.courses && this.profession.courses.length) {
        this.profession.courses = this.courses.filter(el => this.profession.courses.includes(el.name));
      }

      this.profession.isVisible = Helper.isDefined(this.profession.isVisible) ? this.profession.isVisible : true;
      this.adminPanelService
        .getProfessionByHrid(this.profession.hrid)
        .pipe(
          switchMap(profession => {
            let currentObs$: Observable<any>;
            if (profession) {
              this.utilsService.openSnackBar('👎 Профессия с таким Hrid уже существует', 'error');
              currentObs$ = of(null);
            } else {
              currentObs$ = this.adminPanelService.createProfessions(this.profession).pipe(
                tap(r => {
                  if (r) {
                    this.utilsService.openSnackBar('👌 Профессия добавлена', 'success');
                    form.reset();
                    this.clearTerritoryData();
                    this.closeAddProfession.emit(true);
                  }
                }),
              );
            }
            return currentObs$;
          }),
          takeUntil(this.unsubscribe),
        )
        .subscribe();
    } else {
      this.getTranslations(['SHARED.ERROR_MSG'])
        .pipe(take(1))
        .subscribe(translations => this.utilsService.openSnackBar(translations['SHARED.ERROR_MSG'], 'error'));
    }
  }

  // установка imagePath для профессии по сфере
  onModelChangeFieldNames(names) {
    let imagePath = null;
    if (names && names.length > 0) {
      for (let index = 0; index < names.length; index++) {
        let fieldImage = this.dictFieldImages.find(f => f.key === names);
        if (fieldImage) {
          imagePath = fieldImage.value;
          if (imagePath) {
            break;
          }
        }
      }
    }
    this.profession.mainImagePath = imagePath;
  }

  addMovie(movie: string) {
    this.utilsService.addStringValueToCollection(this.profession.movies, movie);
  }

  addSkill(skill: string) {
    this.utilsService.addStringValueToCollection(this.profession.skills, skill);
  }

  addResponsibility(responsibility: string) {
    this.utilsService.addStringValueToCollection(this.profession.resposibilities, responsibility);
  }

  addProductGroup(productGroup: string) {
    this.utilsService.addStringValueToCollection(this.profession.productGroups, productGroup);
  }

  addKnowledge(knowledge: string) {
    this.utilsService.addStringValueToCollection(this.profession.knowledge, knowledge);
  }

  addAbility(ability: string) {
    this.utilsService.addStringValueToCollection(this.profession.abilities, ability);
  }

  addFact(fact: string) {
    this.utilsService.addStringValueToCollection(this.profession.facts, fact);
  }

  addAdditionalEducation(additionalEducation: string) {
    this.utilsService.addStringValueToCollection(this.profession.additionalEducations, additionalEducation);
  }

  addEducation() {
    if (!this.profession.education) {
      this.profession.education = [
        {
          level: this.addedEducation.level,
          isPreferred: this.addedEducation.isPreferred,
          perentage: this.addedEducation.perentage,
        },
      ];
    } else {
      let indexEducation: number = this.profession.education.findIndex(d => d.level === this.addedEducation.level);
      if (indexEducation === -1) {
        let education = {
          level: this.addedEducation.level,
          isPreferred: this.addedEducation.isPreferred,
          perentage: this.addedEducation.perentage,
        };
        this.profession.education.push(education);
      }
    }
  }

  addVideo(videoName: string, videoUrl: string) {
    if (videoName && videoUrl) {
      if (!this.profession.videos) {
        this.profession.videos = [
          {
            name: videoName,
            url: videoUrl,
          },
        ];
      } else {
        let indexVideo: number = this.profession.videos.findIndex(d => d.name === videoName);
        if (indexVideo === -1) {
          let video = {
            name: videoName,
            url: videoUrl,
          };
          this.profession.videos.push(video);
        }
      }
    }
  }

  addCompany(companyName: string, companyDescription: string, companyUrl: string, companyImageId: string) {
    if (companyName && companyDescription) {
      if (!this.profession.companies) {
        this.profession.companies = [
          {
            name: companyName,
            description: companyDescription,
            url: companyUrl,
            imageId: companyImageId,
          },
        ];
      } else {
        let indexCompany: number = this.profession.companies.findIndex(d => d.name === companyName);
        if (indexCompany === -1) {
          let company = {
            name: companyName,
            description: companyDescription,
            url: companyUrl,
            imageId: companyImageId,
          };
          this.profession.companies.push(company);
        }
      }
    }
  }

  addArticle(articleName: string, articleUrl: string) {
    if (articleName && articleUrl) {
      if (!this.profession.articles) {
        this.profession.articles = [
          {
            name: articleName,
            url: articleUrl,
          },
        ];
      } else {
        let indexArticle: number = this.profession.articles.findIndex(d => d.name === articleName);
        if (indexArticle === -1) {
          let article = {
            name: articleName,
            url: articleUrl,
          };
          this.profession.articles.push(article);
        }
      }
    }
  }

  addPerson() {
    if (this.addedPerson.name && this.addedPerson.description && this.selectedFilesPerson) {
      this.adminPanelService
        .uploadImage(this.selectedFilesPerson, this.profession.id)
        .pipe(takeUntil(this.unsubscribe))
        .subscribe(personImagePath => {
          if (!this.profession.persons) {
            this.profession.persons = [
              {
                name: this.addedPerson.name,
                description: this.addedPerson.description,
                imagePath: personImagePath,
              },
            ];
          } else {
            let indexPerson: number = this.profession.persons.findIndex(d => d.name === this.addedPerson.name);
            if (indexPerson === -1) {
              let person = {
                name: this.addedPerson.name,
                description: this.addedPerson.description,
                imagePath: personImagePath,
              };
              this.profession.persons.push(person);
            }
          }
        });
      this.addedPerson.description = '';
      this.addedPerson.name = '';
    } else if (!this.selectedFilesPerson) {
      this.utilsService.openSnackBar('👎 Фото персоны не загружено', 'error');
    } else if (!this.addedPerson.name || !this.addedPerson.description) {
      this.utilsService.openSnackBar('👎 Необходимо заполнить все поля о персоне', 'error');
    }
  }

  addSpecial(specialName: string, specialEducationLevel: number) {
    if (specialName && specialEducationLevel) {
      if (!this.profession.specialities) {
        this.profession.specialities = [
          {
            name: specialName,
            educationLevel: specialEducationLevel,
          },
        ];
      } else {
        let indexSpecials: number = this.profession.specialities.findIndex(
          d => d.name === specialName && d.educationLevel === specialEducationLevel,
        );
        if (indexSpecials === -1) {
          let specials = {
            name: specialName,
            educationLevel: specialEducationLevel,
          };
          this.profession.specialities.push(specials);
        }
      }
    }
  }

  addProfessionImage(): void {
    if (!this.selectedFilesProfession) {
      this.utilsService.openSnackBar('👎 Изображение профессии не загружено', 'error');
    }

    this.adminPanelService
      .uploadImage(this.selectedFilesProfession, this.profession.id)
      .pipe(
        map((imgPath: string) => {
          return { path: imgPath };
        }),
        tap(imgData => {
          if (!this.profession.images) {
            this.profession.images = [imgData];
          } else {
            this.profession.images.push(imgData);
          }
        }),
        switchMap(imgData => this.adminPanelService.addImagePathsInProfession(this.profession.id, imgData.path)),
        takeUntil(this.unsubscribe),
      )
      .subscribe();
  }

  removeMovies() {
    this.selectedOptions.forEach(item => {
      this.utilsService.deleteItemFromStringArray(this.profession.movies, item);
    });
    this.selectedOptions = [];
  }

  removeSkills() {
    this.selectedOptions.forEach(item => {
      this.utilsService.deleteItemFromStringArray(this.profession.skills, item);
    });
    this.selectedOptions = [];
  }

  removeResponsibilities() {
    this.selectedOptions.forEach(item => {
      this.utilsService.deleteItemFromStringArray(this.profession.resposibilities, item);
    });
    this.selectedOptions = [];
  }

  removeProductGroups() {
    this.selectedOptions.forEach(item => {
      this.utilsService.deleteItemFromStringArray(this.profession.productGroups, item);
    });
    this.selectedOptions = [];
  }

  removeKnowledges() {
    this.selectedOptions.forEach(item => {
      this.utilsService.deleteItemFromStringArray(this.profession.knowledge, item);
    });
    this.selectedOptions = [];
  }

  removeAbilities() {
    this.selectedOptions.forEach(item => {
      this.utilsService.deleteItemFromStringArray(this.profession.abilities, item);
    });
    this.selectedOptions = [];
  }

  removeAdditionalEducations() {
    this.selectedOptions.forEach(item => {
      this.utilsService.deleteItemFromStringArray(this.profession.additionalEducations, item);
    });
    this.selectedOptions = [];
  }

  removeFacts() {
    this.selectedOptions.forEach(item => {
      this.utilsService.deleteItemFromStringArray(this.profession.facts, item);
    });
    this.selectedOptions = [];
  }

  removeArticles() {
    this.selectedOptions.forEach(item => {
      this.utilsService.deleteItemFromCollectionByName(this.profession.articles, item);
    });
    this.selectedOptions = [];
  }

  removeVideos() {
    this.selectedOptions.forEach(item => {
      this.utilsService.deleteItemFromCollectionByName(this.profession.videos, item);
    });
    this.selectedOptions = [];
  }

  removeCompanies() {
    this.selectedOptions.forEach(item => {
      this.utilsService.deleteItemFromCollectionByName(this.profession.companies, item);
    });
    this.selectedOptions = [];
  }

  removePersons() {
    this.selectedOptions.forEach(item => {
      this.utilsService.deleteItemFromCollectionByName(this.profession.persons, item);
    });
    this.selectedOptions = [];
  }

  removeEducations() {
    this.selectedOptions.forEach(item => {
      let indexEducation: number = this.profession.education.findIndex(d => d.level === item.level);
      if (indexEducation > -1) {
        this.profession.education.splice(indexEducation, 1);
      }
    });
    this.selectedOptions = [];
  }

  removeSpecials() {
    this.selectedOptions.forEach(item => {
      let indexSpecials: number = this.profession.specialities.findIndex(d => d.name === item.name && d.educationLevel === item.educationLevel);
      if (indexSpecials > -1) {
        this.profession.specialities.splice(indexSpecials, 1);
      }
    });
    this.selectedOptions = [];
  }

  selectFile(event: Event) {
    const input = event.target as HTMLInputElement;

    if (input?.files.item(0).type.match('image.*')) {
      if (input.id === 'image') {
        this.selectedFilesProfession = input?.files;
      }
      this.selectedFilesPerson = input?.files;
    } else {
      this.utilsService.openSnackBar('👎 Неверный формат изображения', 'error');
    }
  }

  // async selectFileLogo(event) {
  //   if (event.target.files.item(0).type.match('image.*')) {
  //     this.selectedFilesLogo = event.target.files;
  //     this.profession.mainImagePath = await this.adminPanelService.uploadImage(
  //       this.selectedFilesLogo,
  //       this.profession.id
  //     );
  //   } else {
  //     this.utilsService.openSnackBar('👎 Неверный формат изображения', 'error');
  //   }
  // }

  getTranslations(keys: string[]): Observable<any> {
    return this.translateService.get(keys);
  }

  ngOnDestroy() {
    this.unsubscribe.next(null);
    this.unsubscribe.complete();
  }

  public changeProfessionVisibility(event: MatSlideToggleChange): void {
    this.profession.isVisible = event.checked;
  }

  public selectedFieldType(): void {
    this.fields = null;
    this.apiFieldsService.getAllFields(this.professionFieldType).subscribe(response => {
      this.fields = response.fields;
    });
  }
}
