import { Component } from '@angular/core';
import { Meta } from '@angular/platform-browser';

@Component({
  selector: 'prf-elastic-courses',
  templateUrl: './elastic-courses.component.html',
  styleUrls: ['./elastic-courses.component.scss'],
})
export class ElasticCoursesComponent {
  public ActiveArchive: number = 1;

  constructor(private meta: Meta) {
    this.meta.updateTag({ name: 'og:title', content: 'Поисковый индекс' });
  }
}
