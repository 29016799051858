import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, UntypedFormGroup } from '@angular/forms';
import { Meta } from '@angular/platform-browser';
import { Observable, of } from 'rxjs';
import { map, mergeMap, takeUntil, tap } from 'rxjs/operators';

import { TranslateService } from '@ngx-translate/core';
import { saveAs } from 'file-saver';
import * as moment from 'moment';
import { B2gReportsService } from '@profilum-library';
import { UnsubscribeComponent } from '@profilum-components/unsubscribe/unsubscribe.component';


import { UtilsService } from 'app/shared/dashboard/backend-services/utils.service';
import { IMunicipality } from 'app/shared/interfaces/imunicipality';
import { IRegion } from 'app/shared/interfaces/iregion';
import { MunicipalitiesService } from '../../municipalities/municipalities.service';
import { RegionsService } from '../../regions/regions.service';
import { SystemService } from '../system.service';
import { IRegionStaticticReport } from 'app/shared/interfaces/isystem-report.interface';
import { IGeneratedRegionReportByEmailRequest } from '../../../../../shared/interfaces/icommon.interface';
import { SharedService } from '../../../../../shared/shared.service';
import { IUserInfo } from '../../users/users.interface';
import { IListedOption } from './system-reports.model';

interface IRegionReportForm {
  region: FormControl<string>;
  municipality: FormControl<string>;
  dateFrom: FormControl<string>;
  dateTo: FormControl<string>;
}

interface IMunicipalitReportForm {
  region: FormControl<string>;
  municipality: FormControl<string>;
}

// список регионов с выбором языков
// Kazahstan regionId
const multipleLanguagesRegions: string[] = ['aeba8896-64c1-49c0-9a47-099b6b192a55'];

@Component({
  selector: 'prf-system-reports',
  templateUrl: './system-reports.component.html',
  styleUrls: ['./system-reports.component.scss'],
})
export class SystemReportsComponent extends UnsubscribeComponent implements OnInit {
  public regions: IRegion[] = [];
  public listedRegions: IListedOption[] = [];
  public regionReportUpdating: boolean = false;
  public regionReportPrepearing: boolean = false;
  public regionPupilsUpdating: boolean = false;
  public regionPupilsPrepearing: boolean = false;
  public listedMunicipalities: IListedOption[] = [];
  public municipalityReportUpdating: boolean = false;
  public municipalityReportPrepearing: boolean = false;
  public professionsUpdating: boolean = false;
  public form: UntypedFormGroup;
  public generatingRegionReportIsOnProcess: boolean = false;
  public email$: Observable<string>;
  public isFullDiagnosticReportAvailable: boolean = false;

  private municipalitiesByRegion: IMunicipality[] = [];
  private userId: string;
  private userRole: string;

  constructor(
    private meta: Meta,
    private systemService: SystemService,
    private utilsService: UtilsService,
    private regionsService: RegionsService,
    private municipalitiesService: MunicipalitiesService,
    private translateService: TranslateService,
    private fb: FormBuilder,
    private sharedService: SharedService,
    private b2gReportsService: B2gReportsService,
  ) {
    super();
    this.meta.updateTag({ name: 'og:title', content: 'Отчеты' });
    this.userId = localStorage.getItem('userId');
    this.userRole = localStorage.getItem('userRole');
    this.email$ = this.sharedService.getUserInfoData().pipe(map((ui: IUserInfo) => ui.email));
    this.isFullDiagnosticReportAvailable = localStorage.getItem('isDefaultGeneralAdmin') !== 'true';
  }

  public ngOnInit(): void {
    this.initForm();
    this.getRegions();

    this.form
      .get('municipalityReport')
      .get('region')
      .valueChanges.pipe(
        mergeMap(value => this.setMunicipalitiesDropdown('municipalityReport', value)),
        takeUntil(this.unsubscribe),
      )
      .subscribe();

    this.form
      .get('regionReport')
      .get('region')
      .valueChanges.pipe(
        mergeMap(value => this.setMunicipalitiesDropdown('regionReport', value)),
        takeUntil(this.unsubscribe),
      )
      .subscribe();
  }

  private initForm(): void {
    this.form = this.fb.group({
      regionReport: new FormGroup<IRegionReportForm>({
        region: new FormControl<string | null>(null, []),
        municipality: new FormControl<string | null>(null, []),
        dateFrom: new FormControl<string | null>(null, []),
        dateTo: new FormControl<string | null>(null, []),
      }),
      pupilsRegion: new FormControl<string | null>(null, []),
      municipalityReport: new FormGroup<IMunicipalitReportForm>({
        region: new FormControl<string | null>(null, []),
        municipality: new FormControl<string | null>(null, []),
      }),
    });
  }

  private getRegions(): void {
    this.regionsService
      .getAllRegions()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((response: IRegion[]) => {
        this.regions = response;
        this.regions.forEach(region =>
          this.listedRegions.push({
            name: region.name,
            data: region.hrid,
          }),
        );
      });
  }

  private setMunicipalitiesDropdown(reportType: string, value: any): Observable<IMunicipality[] | null> {
    if (value && value.data) {
      const selectedRegion: IRegion = this.regions.find((region: IRegion): boolean => region.hrid === value.data);
      this.form.get(reportType).get('municipality').reset();

      return this.municipalitiesService.getMunicipalitiesByRegion(selectedRegion.id).pipe(
        tap((municipalities: IMunicipality[]): void => {
          this.municipalitiesByRegion = municipalities;
          this.listedMunicipalities = [];
          municipalities.forEach((municipality: IMunicipality) =>
            this.listedMunicipalities.push({ name: municipality.name, data: municipality.hrid, id: municipality.id }),
          );
        }),
      );
    } else {
      return of(null);
    }
  }

  public updateRegionReport(): void {
    const selectedRegion = this.regions.find(region => region.name === this.form.get('regionReport').get('region').value.name);
    if (selectedRegion) {
      this.regionReportUpdating = true;
      this.form.get('regionReport').get('region').disable();
      const dateFrom = new Date(this.form.get('regionReport').get('dateFrom').value);
      const dateTo = this.form.get('regionReport').get('dateTo').value
        ? new Date(this.form.get('regionReport').get('dateTo').value)
        : new Date();

      const requestData: IRegionStaticticReport = {
        regionId: selectedRegion.id,
        userId: this.userId,
        role: this.userRole,
        dateFrom: moment(dateFrom).format('YYYY-MM-DDThh:mm:ss.SSSZ'),
        dateTo: moment(dateTo).format('YYYY-MM-DDThh:mm:ss.SSSZ'),
      };

      this.systemService
        .updateRegionStatistic(requestData)
        .pipe(takeUntil(this.unsubscribe))
        .subscribe({
          next: () => {
            this.regionReportUpdating = false;
            this.form.get('regionReport').get('region').enable();
            this.utilsService.openSnackBar('Данные по региону обновлены, можно загружать отчет', 'success');
          },
          error: () => {
            this.regionReportUpdating = false;
            this.form.get('regionReport').get('region').enable();
            this.form.get('regionReport').get('region').setValue(null);
            this.utilsService.openSnackBar('Произошла ошибка', 'error');
          },
        });
    }
  }

  public getGeneratedRegionReportByEmail(): void {
    const selectedRegion: IRegion = this.regions.find(
      (region: IRegion) => region.name === this.form.get('regionReport').get('region').value.name,
    );

    const selectedMunicipality: IMunicipality = this.municipalitiesByRegion.find(
      (municipality: IMunicipality) => municipality.id === this.form.get('regionReport').get('municipality').value?.id,
    );

    if (!selectedRegion) {
      return;
    }

    this.regionReportUpdating = true;
    this.form.get('regionReport').get('region').disable();
    this.form.get('regionReport').get('municipality').disable();
    const dateFrom: Date = new Date(this.form.get('regionReport').get('dateFrom').value);
    const dateTo: Date = this.form.get('regionReport').get('dateTo').value
      ? new Date(this.form.get('regionReport').get('dateTo').value)
      : new Date();

    const requestData: IGeneratedRegionReportByEmailRequest = {
      regionId: selectedRegion.id,
      userId: this.userId,
      role: this.userRole,
      dateFrom: moment(dateFrom).format('YYYY-MM-DDThh:mm:ss.SSSZ'),
      dateTo: moment(dateTo).format('YYYY-MM-DDThh:mm:ss.SSSZ'),
    };

    if (selectedMunicipality?.id) {
      requestData.municipalityId = selectedMunicipality.id;
    }

    this.b2gReportsService
      .getGeneratedRegionReportByEmail(requestData)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe({
        next: () => {
          this.regionReportUpdating = false;
          this.generatingRegionReportIsOnProcess = true;
          this.form.get('regionReport').reset();
          this.utilsService.openSnackBar('Когда отчёт будет готов, вам на почту придёт письмо', 'success');
        },
        error: () => {
          this.regionReportUpdating = false;
          this.utilsService.openSnackBar('Произошла ошибка', 'error');
        },
      });
  }

  public getRegionReport(): void {
    const selectedRegion = this.regions.find(region => region.name === this.form.get('regionReport').get('region').value.name);

    if (selectedRegion) {
      this.regionReportPrepearing = true;
      this.form.get('regionReport').get('region').disable();
      const dateFrom = new Date(this.form.get('regionReport').get('dateFrom').value);
      const dateTo = this.form.get('regionReport').get('dateTo').value
        ? new Date(this.form.get('regionReport').get('dateTo').value)
        : new Date();

      const requestData: IRegionStaticticReport = {
        regionId: selectedRegion.id,
        userId: this.userId,
        role: this.userRole,
        dateFrom: moment(dateFrom).format('YYYY-MM-DDThh:mm:ss.SSSZ'),
        dateTo: moment(dateTo).format('YYYY-MM-DDThh:mm:ss.SSSZ'),
      };

      this.systemService
        .getRegionStatisticExcel(requestData)
        .pipe(takeUntil(this.unsubscribe))
        .subscribe({
          next: response => {
            this.regionReportPrepearing = false;
            this.form.get('regionReport').get('region').enable();
            this.form.get('regionReport').get('region').setValue(null);
            this.utilsService.openSnackBar('Отчет получен', 'success');
            const blob = new Blob([response.body], { type: response.headers.get('content-type') });
            const fileName = this.parseFilenameFromContentDisposition(response.headers.get('content-disposition'));
            const file = new File([blob], fileName, { type: response.headers.get('content-type') });
            saveAs(file);
          },
          error: () => {
            this.regionReportPrepearing = false;
            this.form.get('regionReport').get('region').enable();
            this.form.get('regionReport').get('region').setValue(null);
            this.utilsService.openSnackBar('Произошла ошибка', 'error');
          },
        });
    }
  }

  public updateRegionPupilsReport(): void {
    const selectedRegion = this.regions.find(region => region.name === this.form.get('pupilsRegion').value.name);
    if (selectedRegion) {
      this.regionPupilsUpdating = true;
      this.form.get('pupilsRegion').disable();
      this.systemService
        .updateRegionPupilsStatistic(selectedRegion.id)
        .pipe(takeUntil(this.unsubscribe))
        .subscribe({
          next: () => {
            this.regionPupilsUpdating = false;
            this.form.get('pupilsRegion').enable();
            this.utilsService.openSnackBar('Данные учеников по региону обновлены, можно загружать отчет', 'success');
          },
          error: () => {
            this.regionPupilsUpdating = false;
            this.form.get('pupilsRegion').enable();
            this.utilsService.openSnackBar('Произошла ошибка', 'error');
          },
        });
    }
  }
  public getRegionPupilsReport(): void {
    const selectedRegion = this.regions.find(region => region.name === this.form.get('pupilsRegion').value.name);
    if (selectedRegion) {
      this.regionPupilsPrepearing = true;
      this.form.get('pupilsRegion').disable();
      this.systemService
        .getRegionPupilsStatisticExcel(selectedRegion.id)
        .pipe(takeUntil(this.unsubscribe))
        .subscribe({
          next: response => {
            this.regionPupilsPrepearing = false;
            this.form.get('pupilsRegion').enable();
            this.form.get('pupilsRegion').setValue(null);
            this.utilsService.openSnackBar('Отчет получен', 'success');
            const blob = new Blob([response.body], { type: response.headers.get('content-type') });
            const fileName = this.parseFilenameFromContentDisposition(response.headers.get('content-disposition'));
            const file = new File([blob], fileName, { type: response.headers.get('content-type') });
            saveAs(file);
          },
          error: () => {
            this.regionPupilsPrepearing = false;
            this.form.get('pupilsRegion').enable();
            this.form.get('pupilsRegion').setValue(null);
            this.utilsService.openSnackBar('Произошла ошибка', 'error');
          },
        });
    }
  }

  public updateMunicipalityReport(): void {
    const selectedRegion = this.regions.find(region => region.name === this.form.get('municipalityReport').get('region').value.name);
    const selectedMunicipality = this.municipalitiesByRegion.find(
      municipality => municipality.name === this.form.get('municipalityReport').get('municipality').value.name,
    );
    const language = localStorage.getItem('selectedLanguage')
      ? localStorage.getItem('selectedLanguage')
      : this.translateService.getDefaultLang();
    if (selectedRegion && selectedMunicipality && language) {
      this.municipalityReportUpdating = true;
      this.form.get('municipalityReport').get('municipality').disable();
      this.systemService
        .updateMunicipalityStatistic(selectedRegion.id, selectedMunicipality.id, language)
        .pipe(takeUntil(this.unsubscribe))
        .subscribe({
          next: () => {
            this.municipalityReportUpdating = false;
            this.form.get('municipalityReport').get('municipality').enable();
            this.utilsService.openSnackBar('Данные по муниципалитету обновлены, можно загружать отчет', 'success');
          },
          error: () => {
            this.municipalityReportUpdating = false;
            this.form.get('municipalityReport').get('municipality').enable();
            this.utilsService.openSnackBar('Произошла ошибка', 'error');
          },
        });
    }
  }
  public getMunicipalityReport(): void {
    const selectedRegion = this.regions.find(region => region.name === this.form.get('municipalityReport').get('region').value.name);
    const selectedMunicipality = this.municipalitiesByRegion.find(
      municipality => municipality.name === this.form.get('municipalityReport').get('municipality').value.name,
    );
    const language = localStorage.getItem('selectedLanguage')
      ? localStorage.getItem('selectedLanguage')
      : this.translateService.getDefaultLang();
    if (selectedRegion && selectedMunicipality && language) {
      this.municipalityReportPrepearing = true;
      this.form.get('municipalityReport').get('municipality').disable();
      this.systemService
        .getMunicipalityStatisticExcel(selectedRegion.id, selectedMunicipality.id, language)
        .pipe(takeUntil(this.unsubscribe))
        .subscribe({
          next: response => {
            this.municipalityReportPrepearing = false;
            this.form.get('municipalityReport').get('municipality').enable();
            this.form.get('municipalityReport').get('region').setValue(null);
            this.form.get('municipalityReport').get('municipality').setValue(null);
            this.utilsService.openSnackBar('Отчет получен', 'success');
            const blob = new Blob([response.body], { type: response.headers.get('content-type') });
            const fileName = this.parseFilenameFromContentDisposition(response.headers.get('content-disposition'));
            const file = new File([blob], fileName, { type: response.headers.get('content-type') });
            saveAs(file);
          },
          error: () => {
            this.municipalityReportPrepearing = false;
            this.form.get('municipalityReport').get('municipality').enable();
            this.form.get('municipalityReport').get('region').setValue(null);
            this.form.get('municipalityReport').get('municipality').setValue(null);
            this.utilsService.openSnackBar('Произошла ошибка', 'error');
          },
        });
    }
  }

  public updateProfessions(): void {
    this.professionsUpdating = true;
    this.systemService
      .updateProfessions()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe({
        next: () => {
          this.professionsUpdating = false;
        },
        error: () => {
          this.professionsUpdating = false;
          this.utilsService.openSnackBar('Произошла ошибка', 'error');
        },
      });
  }

  private parseFilenameFromContentDisposition(contentDisposition): string {
    if (!contentDisposition) return null;
    const matches = /filename="(.*?)"/g.exec(contentDisposition);
    return matches && matches.length > 1 ? matches[1] : 'untitled';
  }

  public setCookiesToLocalStore(): void {
    // получаем все доступные cookies
    const cookies = document.cookie.split(';');

    cookies.forEach(el => {
      // каждую cookie разделяем и получаем массив где первый элемент название, а второй значение
      const cookie = el.split('=');
      localStorage.setItem(cookie[0], cookie[1]);
    });
  }

  public checkDate(date: string): boolean {
    return date && new Date(date) < new Date();
  }
}
