<div class="catalog-events-row" *ngIf="currentInternships">
  <ng-container *ngIf="searches.length == 0">
    <ng-container *ngIf="currentInternships.length > 0; else emptyInternships">
      <div class="events-column" [class.short-view]="viewValue" *ngFor="let internship of currentInternships">
        <prf-internship-card [internship]="internship" [viewValue]="viewValue"></prf-internship-card>
      </div>
    </ng-container>
  </ng-container>

  <ng-container *ngIf="searches.length > 0">
    <div class="events-column" [class.short-view]="viewValue" *ngFor="let internship of searches">
      <prf-internship-card [internship]="internship" [viewValue]="viewValue"></prf-internship-card>
    </div>
  </ng-container>
</div>

<ng-template #emptyInternships>
  <div class="event-empty-block">
    <div>
      <img src="./assets/images/menu/grey/Events.svg" width="80" class="no-profession-icon" />
    </div>
    <div class="event-empty-block-text">
      {{ 'SHARED.FOR_YOU_REQUEST_NOT_INTERSHIPS' | translate }}
    </div>
  </div>
</ng-template>
