import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ISchool } from 'app/shared/interfaces/ischool.interface';
import { ISchoolClass } from 'app/shared/interfaces/ischoolclass.interface';
import { SharedService } from 'app/shared/shared.service';
import { AdminPanelService } from 'app/pages/control-panel/admin/admin-panel.service';
import { IUserInfo } from '../users.interface';
import { UnsubscribeComponent } from '@profilum-components/unsubscribe/unsubscribe.component';

@Component({
  selector: 'prf-reassign-teacher',
  templateUrl: './reassign-teacher.component.html',
  styleUrls: ['./reassign-teacher.component.scss'],
})
export class ReassignTeacherComponent extends UnsubscribeComponent {
  @Input() teacher: IUserInfo;

  @Input() allSchoolsByCity: ISchool[] = [];

  @Output() updateTable: EventEmitter<void> = new EventEmitter();

  public reassignTeacherFlag: boolean = false;
  public loaded: boolean = false;
  public transferFromSchoolClassFlag: boolean = true;
  public onlyAddNewClass: boolean = false;

  public form: UntypedFormGroup = new UntypedFormGroup({
    sourceSchool: new UntypedFormControl(''),
    sourceSchoolClass: new UntypedFormControl(''),

    targetSchool: new UntypedFormControl(''),
    targetSchoolClass: new UntypedFormControl(''),

    newSchool: new UntypedFormControl(''),
    newSchoolClass: new UntypedFormControl(''),
  });
  public allSchoolClassesBySchool: ISchoolClass[] = [];
  public targetSchoolClasses: ISchoolClass[] = [];
  public newSchoolClasses: ISchoolClass[] = [];
  public allSchoolClassesByTeacher: ISchoolClass[] = [];
  public allSchoolsByTeacher: ISchool[] = [];

  private teacherId: string;
  private hide = new Subject<void>();

  constructor(private adminPanelService: AdminPanelService, private sharedService: SharedService) {
    super();
  }

  public openModal(): void {
    this.reassignTeacherFlag = true;
    this.fetchData();
  }

  public closeModal(): void {
    this.reassignTeacherFlag = false;
    this.hide.next();
    this.form.reset();
  }

  public toggleReassignMode(isTransfer: boolean): void {
    this.transferFromSchoolClassFlag = isTransfer;
  }

  public getSchoolClassesBySchool(schoolId: string, eventOrigin?: string): void {
    if (!schoolId) {
      return;
    }

    this.loaded = false;
    this.adminPanelService
      .getAllSchoolClassesBySchoolId(schoolId)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(result => {
        switch (eventOrigin) {
          case 'source':
            this.setSourceClasses(result.schoolClasses);
            break;
          case 'target':
            this.setTargetClasses(result.schoolClasses);
            break;
          case 'new':
            this.setNewClasses(result.schoolClasses);
            break;
          default:
            this.setInitialValues(result.schoolClasses);
            break;
        }
        this.loaded = true;
      });
  }

  public getButtonText(): string {
    if (this.onlyAddNewClass || !this.transferFromSchoolClassFlag) {
      return 'Привязать новый класс';
    } else {
      return 'Перевести в другой класс';
    }
  }

  public onSubmit(): void {
    let targetSchoolClassId;
    let sourceSchoolClassId;
    if (!this.transferFromSchoolClassFlag) {
      targetSchoolClassId = this.form.get('newSchoolClass').value.id;
    } else {
      targetSchoolClassId = this.form.get('targetSchoolClass').value.id;
      sourceSchoolClassId = this.form.get('sourceSchoolClass').value.id;
    }

    this.adminPanelService
      .reassignTeacherForSchoolClass(this.teacherId, targetSchoolClassId, sourceSchoolClassId)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(() => {
        this.closeModal();
        this.updateTable.emit();
      });
  }

  private fetchData(): void {
    this.getSchoolClassesBySchool(this.teacher?.schoolId);
  }

  private setInitialValues(classes: ISchoolClass[]): void {
    // Собрать массив школ, к которым привязан учитель, и выставить значение по-умолчанию
    this.teacher.allSchoolIds.forEach(schoolId => {
      this.allSchoolsByTeacher.push(this.allSchoolsByCity.find(school => school.id === schoolId));
    });
    this.form.patchValue({
      sourceSchool: this.allSchoolsByTeacher[0],
      targetSchool: this.allSchoolsByTeacher[0],
      newSchool: this.allSchoolsByTeacher[0],
    });

    // Собрать массив классов, к которым привязан учитель, и выставить значение по-умолчанию
    if (this.teacher.allSchoolClassIds.length < 1) {
      this.onlyAddNewClass = true;
      this.transferFromSchoolClassFlag = false;
      return;
    } else {
      this.setSourceClasses(classes);
      this.setTargetClasses(classes);
      this.setNewClasses(classes);

      this.form.patchValue({ sourceSchoolClass: this.allSchoolClassesByTeacher[0] });
    }

    this.setTeacherId();

    this.initWatchers();
  }

  private setSourceClasses(classes: ISchoolClass[]): void {
    this.allSchoolClassesByTeacher = [];
    this.teacher.allSchoolClassIds.forEach(schoolClassId => {
      const schoolClass = classes.find(schoolClass => schoolClass.id === schoolClassId);
      if (schoolClass) {
        this.allSchoolClassesByTeacher.push(schoolClass);
      }
    });
  }

  private setTargetClasses(classes: ISchoolClass[]) {
    this.targetSchoolClasses = classes.filter(schoolClass => !this.teacher.allSchoolClassIds.includes(schoolClass.id));
  }

  private setNewClasses(classes: ISchoolClass[]) {
    this.newSchoolClasses = classes.filter(schoolClass => !this.teacher.allSchoolClassIds.includes(schoolClass.id));
  }

  private setTeacherId(): void {
    this.sharedService
      .getTeachers(this.teacher.schoolId)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(teachers => {
        const foundTeacher = teachers.find(teacher => teacher.userId === this.teacher.userId);
        this.teacherId = foundTeacher.teacherId ? foundTeacher.teacherId : this.teacher.userId;
      });
  }

  private initWatchers(): void {
    this.form
      .get('sourceSchool')
      .valueChanges.pipe(takeUntil(this.hide))
      .subscribe((school: ISchool) => {
        this.getSchoolClassesBySchool(school?.id, 'source');
      });
    this.form
      .get('targetSchool')
      .valueChanges.pipe(takeUntil(this.hide))
      .subscribe((school: ISchool) => {
        this.getSchoolClassesBySchool(school?.id, 'target');
      });
    this.form
      .get('newSchool')
      .valueChanges.pipe(takeUntil(this.hide))
      .subscribe((school: ISchool) => {
        this.getSchoolClassesBySchool(school?.id, 'new');
      });

    this.loaded = true;
  }
}
