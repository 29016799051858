import { Injectable } from '@angular/core';
import { catchError, filter, map, switchMap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import {
  ApiUserStorageService, B2gSaasService,
  IGetAllDataResponse,
  IGetValueResponse,
  IUpdateValueRequest, IUserInfo,
  IUserStorage,
} from '../../api-services';
import { Helper } from '../../helpers';
import { IncludedToUserStorageKeys, StorageKeys } from '../web-storage';

@Injectable({
  providedIn: 'root',
})
export class UserStorageService {
  private _userId: string | null;

  constructor(private apiUserStorageService: ApiUserStorageService, private b2gSaasService: B2gSaasService) {
    this.getUserId();
  }

  public set setUserId(userId: string | null) {
    this._userId = userId;
  }

  private get getUserIdObserver(): Observable<string> {
    return Helper.isDefined(this._userId) ? of(this._userId).pipe(filter((userId: string | null) => !!userId)) : this.getUserId();
  }

  public get<T>(key: StorageKeys): Observable<T | null> {
    if (IncludedToUserStorageKeys.has(key)) {
      return this.getValueFromUserStorage(key);
    }
  }

  public set<T>(key: StorageKeys, value: T): void {
    if (!Helper.isDefined(value)) {
      return;
    }

    if (IncludedToUserStorageKeys.has(key)) {
      this.setValueToUserStorage(key, value);
    }
  }

  public clear(key: StorageKeys): void {
    if (IncludedToUserStorageKeys.has(key)) {
      this.deleteValueFromUserStorage(key);
    }
  }

  public clearAll(): void {
    this.deleteAllValuesFromUserStorage();
  }

  public getAllValuesFromUserStorage(): Promise<IUserStorage | null> {
    return this.getUserIdObserver
      .pipe(
        switchMap((userId: string) => {
          return this.apiUserStorageService.getAllData(userId);
        }),
        filter((res: IGetAllDataResponse) => Helper.isObject(res?.userStorage?.storage)),
        map((res: IGetAllDataResponse) => {
          const storedData: IUserStorage = {
            userId: res.userStorage.userId,
            storage: res.userStorage.storage,
          };

          for (const key in storedData.storage) {
            if (storedData.hasOwnProperty(key)) {
              storedData[key] = this.parseValue(storedData[key]);
            }
          }

          return storedData;
        }),
        catchError(() => of(null)),
      )
      .toPromise();
  }

  private getValueFromUserStorage<T>(key: StorageKeys): Observable<T | null> {
    return this.getUserIdObserver.pipe(
      switchMap((userId: string) => {
        return this.apiUserStorageService.getValue(userId, key);
      }),
      map((res: IGetValueResponse) => {
        return Helper.isDefined(res?.value) ? this.parseValue<T>(res.value) : null;
      }),
      catchError(() => of(null)),
    );
  }

  private setValueToUserStorage<T>(key: StorageKeys, value: T): void {
    this.getUserIdObserver
      .pipe(
        switchMap((userId: string) => {
          return this.apiUserStorageService.updateValue(this.createStoredDataObject(userId, key, value));
        }),
      )
      .subscribe();
  }

  private deleteValueFromUserStorage(key: StorageKeys): void {
    this.getUserIdObserver
      .pipe(
        switchMap((userId: string) => {
          return this.apiUserStorageService.deleteValue({ userId, key });
        }),
      )
      .subscribe();
  }

  private deleteAllValuesFromUserStorage(): void {
    this.getUserIdObserver
      .pipe(
        switchMap((userId: string) => {
          return this.apiUserStorageService.deleteAllData({ userId });
        }),
      )
      .subscribe();
  }

  private getUserId(): Observable<string> {
    return this.b2gSaasService.getUserInfo().pipe(
      map((userInfo: IUserInfo) => {
        const userId: string = userInfo?.userId;

        if (!userId) {
          throw new Error('userId is not defined!');
        }

        this._userId = userId;

        return this._userId;
      }),
    );
  }

  private createStoredDataObject(userId: string, key: StorageKeys, value: any): IUpdateValueRequest {
    const valueStr: string = Helper.isString(value) ? <string>value : JSON.stringify(value);

    return { userId, key, value: valueStr };
  }

  private parseValue<T>(value: string): T {
    try {
      return <T>JSON.parse(value);
    } catch (e) {
      return <T>value;
    }
  }
}
