import { IHttpStatus } from '../http';

export interface IAddUserRequest {
  firstName?: string;
  lastName?: string;
  middleName?: string;
  schoolClassIds?: string[];
  schoolId?: string;
  role?: string;
  schoolClassNumber?: number;
  schoolClassLetter?: string;
  birthday?: string;
  gender?: string;
  city?: string;
  regionId?: string;
  municipalityId?: string;
  tag?: string;
  phoneNumber?: string;
  isAcceptPersonalInformation?: boolean;
}

export interface IAddUserResponse extends IHttpStatus {
  firstName: string;
  lastName: string;
  middleName: string;
  roleName: string;
  schoolId: string;
  schoolClassIds: string[];
  userId: string;
  registrationCode: string;
  userHrid: number;
  parentUserId: string;
  schoolClassNumber: number;
  schoolClassLetter: string;
  birthday: string;
  gender: string;
  city: string;
  regionId: string;
  municipalityId: string;
  tag: string;
}

export interface IUserInfo {
  userHrid: number;
  firstName: string;
  lastName: string;
  middleName: string;
  imagePath: string;
  email: string;
  phoneNumber: string;
  registrationCode: string;
  id: string;
  userId: string;
  schoolId: string;
  city: string;
  regionId: string;
  municipalityId: string;
  gender: string;
  tag: string;
  age: number;
  birthday: string;
  metroStation?: string;
  parentUserId?: string;
  isConsentToMailing: true;
  isTested?: boolean;
  isAcceptPersonalInformation?: boolean;
  currentStep?: number;
  companyId?: string;
  position?: string;
  children?: IChildren[]; //check IChildrenInfo
  parents?: IParent[]; //check IParentInfo then need to delete these interfaces + IUserInfo
  pupils?: IPupil[];
  classEnrolls?: IClassEnroll[];
  portfolio?: IPortfolio;
  needToUpdate?: boolean;
  status?: string;
  comment?: string;
}

export interface IChildren {
  id: string;
  userId: string;
  firstName: string;
  lastName: string;
  middleName: string;
  gender: string;
  age: number;
  birthday: string;
  email: string;
  imagePath: string;
  phoneNumber: string;
  city: string;
  registrationCode: string;
  schoolId: string;
  schoolName: string;
  schoolClassId: string;
  schoolClassNumber: number;
  schoolClassLetter: string;
  isTested: boolean;
  isConsentToMailing: boolean;
  isAcceptPersonalInformation: boolean;
}

export interface IParent {
  id: string;
  userId: string;
  firstName: string;
  lastName: string;
  middleName: string;
  gender: string;
  age: number;
  birthday: string;
  email: string;
  imagePath: string;
  phoneNumber: string;
  city: string;
  registrationCode: string;
  isTested: boolean;
  isConsentToMailing: boolean;
  isAcceptPersonalInformation: boolean;
}

export interface IPupil {
  id: string;
  userId: string;
  firstName: string;
  lastName: string;
  middleName: string;
  gender: string;
  age: number;
  birthday: string;
  email: string;
  imagePath: string;
  phoneNumber: string;
  city: string;
  registrationCode: string;
  schoolId: string;
  schoolName: string;
  schoolClassId: string;
  schoolClassNumber: number;
  schoolClassLetter: string;
  isTested: boolean;
  isConsentToMailing: boolean;
  isAcceptPersonalInformation: boolean;
}

export interface IClassEnroll {
  id: string;
  classId: string;
  userProfileId: string;
  userId: string;
  status: string;
  isDeleted: true;
  createdDate: string;
}

export interface IPortfolio {
  id: string;
  userId: string;
  userProfileId: string;
  files: IFileInfo[];
}

export interface IFileInfo {
  id: string;
  portfolioId: string;
  filePath: string;
  name: string;
  type: string;
}

export interface IChangeUserPhoneNumber {
  userId: string;
  newPhoneNumber: string;
}

export interface IChangeUserPassword {
  currentPassword: string;
  newPassword: string;
  newPassword2: string;
}

export interface IResultError {
  code: string;
  description: string;
}

export interface IIdentityResult {
  succeeded: boolean;
  errors?: IResultError[];
}

export interface IResponseChangedPassword extends IHttpStatus {
  userId: string;
  role: string;
  userName: string;
  identityResult: IIdentityResult;
}
export interface ITeacherGroup {
  color: string;
  groupName: string;
  id: string;
  progress: { passed: number; total: number };
}
export interface ICcuGroupsStatus {
  comment: string;
  groups: ITeacherGroup[];
  senderService: string;
  status: string;
}

export interface ILastSession {
  comment: string;
  senderService: string;
  sessionId: string;
  status: string;
}

export interface ILoginResponseData {
  role: string;
  userId: string;
  tag: string;
  isUnRegistered: boolean;
  succeeded: boolean;
  isLockedOut: boolean;
  isNotAllowed: boolean;
  requiresTwoFactor: boolean;
}

export interface ISchoolClassesClass {
  id: string;
  teacherId: string;
  letter: string;
  number: number;
  schoolId: string;
  pupilsCount: number;
}

export interface IStatusAnswer {
  status?: string;
  comment?: string;
  senderService?: string;
}

export interface ICheckFreeUsername extends IStatusAnswer {
  isFree: boolean;
}

export interface IRegistrationResponse extends IStatusAnswer {
  identityResult: {
    succeeded: boolean;
    errors: {
      code: string;
      description: string;
    }[];
  };
  userId: string;
  role: string;
  userName: string;
  userHrid: number;
}

export interface IOpenRegistrationData {
  password?: string;
  externalAppUserId?: string;
  tag?: string;
  isConsentToMailing?: boolean;
  isAcceptPersonalInformation?: boolean;
  userName?: string;
  firstName?: string;
  middleName?: string;
  lastName?: string;
  phoneNumber?: string;
  email?: string;
  role?: string;
  schoolId?: string;
  schoolClassNumber?: number;
  schoolClassLetter?: string;
  birthday?: string;
  gender?: string;
  city?: string;
  regionId?: string;
  municipalityId?: string;
  registeredByInstitutionId?: string;
  universityCourse?: number;
  additionalData?: string;
  age?: number;
  companyId?: string;
  position?: string;
  utM_SOURCE?: string;
  utM_CAMPAIGN?: string;
  utM_MEDIUM?: string;
  userId?: string;
  IsAcceptPersonalInformation?: boolean;
  schoolClassId?: string;
}

export interface IUserRegistrationInfo {
  userId: string;
  userHrid: number;
  schoolId: string;
  regionId: string;
  municipalityId: string;
  firstName?: string;
  lastName?: string;
  middleName?: string;
  phoneNumber?: string;
  registrationCode?: string;
  schoolClassIds?: string[];
  birthday?: string;
  gender?: string;
  city?: string;
  schoolClassNumber?: number;
  schoolClassLetter?: string;
  parentUserId?: string;
  roleName?: string;
  tag?: string;
}

export interface IUserRegistrationInfoExtended extends IUserRegistrationInfo {
  schoolName?: string;
  teacherName?: string;
}

export interface IUserRegistrationByCodeRequest {
  registrationCode: string;
  userName: string;
  newPassword: string;
  newPassword2: string;
  isConsentToMailing: boolean;
}

export interface IUserRegistrationByCodeResponse extends IHttpStatus {
  userId: string;
  role: string;
  userName: string;
}

export interface IAnswerSendSms {
  code: null;
  status: string;
  comment: string;
  senderService: string;
}

export interface INews {
  id?: string;
  imageURL?: string;
  title: string;
  description: string;
  buttons?: [
    {
      id?: string;
      name: string;
      iconType: string;
      url: string;
    },
  ];
  groupId?: string;
  eventType: string;
}

export interface IGetUserRecommendationsRequest {
  recommendationType: string;
  addresserRole: string;
  userId?: string;
}

export interface IKruzhkiUserInfo {
  email?: string;
  firstName?: string;
  gender?: string;
  passwordResetToken?: string;
  phone?: string;
}

export interface ILoginForCampTestUserResponse {
  userId: string;
  tag: string;
  isUnRegistered: boolean;
  succeeded: boolean;
  leadId?: string;
  isLockedOut: boolean;
  isNotAllowed: boolean;
  requiresTwoFactor: boolean;
}

export interface ILoginForCampTestUserModel {
  leadDto: {
    city: string;
    gender: string;
    schoolClassNumber: number;
  };
}

export interface IQuestionary {
  openResultsMoQuestionary: {
    id?: string;
    sessionId: string;
    regionId: string;
    municipalityId: string;
    questionsAnswers: Object;
  };
}

export interface IResponse {
  status: string;
  comment: string;
  senderService: string;
}
