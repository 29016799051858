import { Component, HostBinding, HostListener, Input } from '@angular/core';
import { Router } from '@angular/router';
import { IProfession } from 'app/shared/interfaces/iprofession.interface';
import { AppSettingsService } from 'app/shared/services/appsettings.service';

const DESCRIPTION_LENGTH: number = 123;

@Component({
  selector: 'prf-profession-card-new',
  templateUrl: './profession-card-new.component.html',
  styleUrls: ['./profession-card-new.component.scss'],
})
export class ProfessionCardNewComponent {
  @HostBinding('class.professions-column-2') hostClass: boolean = true;

  @Input()
  set profession(profession: IProfession) {
    if (!profession || !profession.name) {
      return;
    }

    this._profession = Object.assign({}, profession);

    if (this._profession.mainImagePath) {
      this._profession.mainImagePath = AppSettingsService.settings.staticFiles.base + this._profession.mainImagePath;
    } else {
      this._profession.mainImagePath = '/assets/images/icons/no-prof-icon.png';
    }

    this._profession.shortDescription = this._profession.shortDescription.replace(/<p>/i, '').replace(/<\/p>/i, '');

    if (this._profession.shortDescription.length > DESCRIPTION_LENGTH) {
      this._profession.shortDescription = this._profession.shortDescription.substring(0, DESCRIPTION_LENGTH - 3) + '...';
    }
  }

  get profession(): IProfession {
    return this._profession;
  }

  private _profession: IProfession;
  public showSalary: boolean = false;

  isMobile: boolean = false;

  constructor(private router: Router) {
    this.showSalary = AppSettingsService.settings.professionViewConfiguration.showSalary;
    this.isMobile = window.innerWidth <= 991;
  }

  checkProfession(profession: any) {
    let regionId = localStorage.getItem('regionId');
    let municipalityId = localStorage.getItem('municipalityId');
    if (profession.regionId === regionId && profession.municipalityId === municipalityId) {
      return true;
    } else {
      return false;
    }
  }

  public cutText(text: string, maxLen: number): string {
    if (text && text.length > maxLen) {
      text = text.substring(0, maxLen) + '..';
    }
    return text;
  }

  public openProfession() {
    this.router.navigate(['/professions', this.profession.hrid]);
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    event.target?.innerWidth;
    this.isMobile = event.target.innerWidth <= 991;
  }
}
