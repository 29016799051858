import { Component } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { UnsubscribeComponent } from '@profilum-components/unsubscribe/unsubscribe.component';

@Component({
  selector: 'prf-all-career-education-courses',
  templateUrl: './all-career-education-courses.component.html',
  styleUrls: ['./all-career-education-courses.component.scss'],
})
export class AllCareerEducationCoursesComponent extends UnsubscribeComponent {
  constructor(private meta: Meta) {
    super();
    this.meta.updateTag({ name: 'og:title', content: 'Все курсы' });
  }
}
