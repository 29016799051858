import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormBuilder, FormControl, ReactiveFormsModule, UntypedFormGroup, Validators } from '@angular/forms';
import { filter, map, startWith, switchMap, take, takeUntil } from 'rxjs/operators';
import { Observable, of } from 'rxjs';

import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatLegacyFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule } from '@angular/material/legacy-input';
import { MatLegacyOptionModule } from '@angular/material/legacy-core';
import { MatLegacySelectModule } from '@angular/material/legacy-select';
import { MatLegacyAutocompleteModule } from '@angular/material/legacy-autocomplete';
import * as moment from 'moment/moment';
import { IRegion, UtilsService } from '@profilum-library';
import { UnsubscribeComponent } from '@profilum-components/unsubscribe/unsubscribe.component';

import { ALL_AGGREGATES_OPTION_VALUE, DataTypeList, ResultsPointsList } from '../system-reports.constants';
import { IShortTest, IShortVariant } from '../../system.interface';
import { OverlayBusyService } from '../../../../../../shared/overlay-busy/overlay-busy.service';
import { IListedOption } from '../system-reports.model';
import { SystemService } from '../../system.service';
import { SharedService } from '../../../../../../shared/shared.service';
import { AdminPanelService } from '../../../admin-panel.service';
import { IGetUniversalTestingReportsByEmailRequest } from '../../../admin.interface';
import { SharedModule } from 'app/shared/shared.module';

@Component({
  selector: 'prf-full-diagnostic-report',
  standalone: true,
  imports: [
    CommonModule,
    MatDatepickerModule,
    MatLegacyFormFieldModule,
    MatLegacyInputModule,
    MatLegacyOptionModule,
    MatLegacySelectModule,
    ReactiveFormsModule,
    SharedModule,
    MatLegacyAutocompleteModule,
  ],
  templateUrl: './full-diagnostic-report.component.html',
  styleUrls: ['./full-diagnostic-report.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FullDiagnosticReportComponent extends UnsubscribeComponent implements OnInit {
  public form: UntypedFormGroup;
  public isTestSelected: boolean = false;
  public isTestVariantSelected: boolean = false;
  public tests: IShortTest[] = [];
  public listedTestVariants: IShortVariant[] = [];
  public filteredTestsList: Observable<string[]>;
  public listedAggregates: IListedOption[] = [];
  public listedDataTypes: IListedOption[] = DataTypeList;
  public listedResultsPoints: IListedOption[] = ResultsPointsList;
  public listedRegionsForFullDiagnosticReport: IListedOption[] = [];
  public fullDiagnosticReportDateConfig = {
    minDateInit: new Date(moment().subtract(1, 'days').subtract(1, 'months').toString()),
    maxDateInit: new Date(moment().subtract(1, 'days').toString()),
    minDateRange: new Date(moment().subtract(5, 'years').toString()),
    maxDateRange: new Date(moment().subtract(1, 'days').toString()),
  };

  @Input() public regions: IRegion[] = [];

  private chosenTestId: string = '';
  private chosenVariantId: string = '';

  constructor(
    private formBuilder: FormBuilder,
    private systemService: SystemService,
    private utilsService: UtilsService,
    private overlayBusyService: OverlayBusyService,
    private sharedService: SharedService,
    private changeDetectorRef: ChangeDetectorRef,
    private adminPanelService: AdminPanelService,
  ) {
    super();
  }

  public ngOnInit(): void {
    this.initForm();
  }

  private initForm(): void {
    this.form = this.formBuilder.group({
      testId: new FormControl<string | null>(null, [Validators.required]),
      variantTestId: new FormControl<string | null>(null, [Validators.required]),
      regions: new FormControl<string | null>(null),
      aggregates: new FormControl<string | null>(null, [Validators.required]),
      dataType: new FormControl<number | null>(null, [Validators.required]),
      resultsPoints: new FormControl<number | null>(null, [Validators.required]),
      dateFrom: new FormControl<Date | null>(this.fullDiagnosticReportDateConfig.minDateInit, [Validators.required]),
      dateTo: new FormControl<Date | null>(this.fullDiagnosticReportDateConfig.maxDateInit, [Validators.required]),
    });

    this.getTests();
  }

  private initFullDiagnosticReportHandlers(): void {
    this.onTestAutoComplete();
    this.onTestVariantDropdownChange();
    this.onDataTypeDropdownChange();
    this.onAggregatesDropdownChange();
  }

  private onTestAutoComplete(): void {
    this.filteredTestsList = this.form.get('testId').valueChanges.pipe(
      startWith(''),
      map(value => this.filterTestOptions(value || '')),
      takeUntil(this.unsubscribe),
    );
  }

  public onTestDropdownClick(): void {
    if (this.form.get('testId').value) {
      this.form.get('testId').setValue(null);
      this.setErrorForTestField();
    }
  }

  public setErrorForTestField(): void {
    this.form.get('testId').setErrors({ testNotSelectedBySelect: true });
  }

  public onTestDropdownChange(event: any): void {
    if (!event?.option?.value) return;
    const selectedTest: IShortTest = this.tests.find((test: IShortTest) => test.name === event.option.value);
    this.chosenTestId = selectedTest.id;
    this.form.get('regions').reset();
    this.form.get('aggregates').reset();
    this.form.get('dataType').reset();
    this.form.get('resultsPoints').reset();
    this.listedTestVariants = [...this.tests.find((test: IShortTest) => test.name === selectedTest.name).variants];
    if (!this.listedTestVariants.length) {
      this.isTestSelected = false;
      this.utilsService.openSnackBar('👎 Произошла ошибка, попробуйте снова: остутствуют привязанные к тесту варианты теста', 'error');
    } else {
      this.isTestSelected = true;
    }
    this.form.get('variantTestId').reset();
    this.isTestVariantSelected = false;
    this.form.get('testId').setErrors(null);
  }

  private filterTestOptions(value: string): string[] {
    return this.tests
      .filter(option => {
        return option.name.toLowerCase().includes(value.toLowerCase());
      })
      .map(option => option.name);
  }

  private onAggregatesDropdownChange(): void {
    this.form
      .get('aggregates')
      .valueChanges.pipe(takeUntil(this.unsubscribe))
      .subscribe(value => {
        if (value?.includes(ALL_AGGREGATES_OPTION_VALUE) && value?.length > 1) {
          this.form.get('aggregates').setValue([ALL_AGGREGATES_OPTION_VALUE]);
        }
      });
  }

  private onTestVariantDropdownChange(): void {
    this.form
      .get('variantTestId')
      .valueChanges.pipe(
        filter(chosenVariantTestId => chosenVariantTestId !== null),
        switchMap(chosenVariantTestId => {
          this.chosenVariantId = chosenVariantTestId;
          this.listedRegionsForFullDiagnosticReport = [];
          this.listedAggregates = [];
          this.overlayBusyService.show();

          return this.adminPanelService.getRegionIdsByScreeningTestId(this.chosenTestId);
        }),
        switchMap(regionsIdsForFullDiagnosticReportRequest => {
          if (regionsIdsForFullDiagnosticReportRequest.length) {
            regionsIdsForFullDiagnosticReportRequest.forEach(regionIdItem => {
              const foundedRegion: IRegion = this.regions.find(usualRegion => usualRegion.id === regionIdItem) || null;
              if (foundedRegion) {
                this.listedRegionsForFullDiagnosticReport.push({
                  name: foundedRegion.name,
                  data: foundedRegion.id,
                });
              }
            });
          }
          return this.sharedService.getAggregatesByTestVariantId(this.chosenTestId, this.chosenVariantId);
        }),
        takeUntil(this.unsubscribe),
      )
      .subscribe(aggregatesRequest => {
        if (aggregatesRequest.length) {
          this.listedAggregates.push({
            name: 'Все агрегаты',
            data: ALL_AGGREGATES_OPTION_VALUE,
          });
          aggregatesRequest.forEach(aggregateItem => {
            this.listedAggregates.push({
              name: aggregateItem.name,
              data: aggregateItem.id,
            });
          });
          this.isTestVariantSelected = true;
        } else {
          this.form.get('variantTestId').setErrors({ incorrect: true });
          this.utilsService.openSnackBar(
            '👎 Произошла ошибка, попробуйте снова: остутствуют привязанные к варианту теста агрегаты',
            'error',
          );
          this.isTestVariantSelected = false;
        }
        this.changeDetectorRef.markForCheck();
        this.overlayBusyService.hide();
      });
  }

  public dateChange(event: any): void {
    if (event.value) {
      const chosenDateFrom = moment(this.form.get('dateFrom').value);
      const chosenDateTo = moment(event.value);
      if (chosenDateTo.diff(chosenDateFrom, 'years', true) > 1) {
        const validDateTo = chosenDateFrom.add(1, 'years');
        this.form.get('dateTo').setValue(validDateTo);
        this.utilsService.openSnackBar(
          `Максимальный диапазон - 1 год, дата "до" была изменена на ${moment(validDateTo).format('DD/MM/YYYY') as unknown as string}`,
          'success',
        );
      }
    }
  }

  public isFullDiagnosticReportFormInvalid(): boolean {
    return this.form.status === 'VALID' ? this.form.get('dataType').value !== 1 && !this.form.get('resultsPoints').value : true;
  }

  public generateFullDiagnosticReport(): void {
    this.overlayBusyService.show();
    const requestBody: IGetUniversalTestingReportsByEmailRequest = {
      screeningTestId: this.chosenTestId,
      variantId: this.chosenVariantId,
      regionIds: this.form.get('regions').value ? this.form.get('regions').value : [],
      aggregateIds: this.form.get('aggregates').value.includes(ALL_AGGREGATES_OPTION_VALUE) ? [] : this.form.get('aggregates').value,
      from: moment(this.form.get('dateFrom').value).format('YYYY-MM-DDThh:mm:ss.SSSZ'),
      to: moment(this.form.get('dateTo').value).format('YYYY-MM-DDThh:mm:ss.SSSZ'),
      reportDataType: this.form.get('dataType').value,
      ...(this.form.get('dataType').value !== 1 && { resultValueType: this.form.get('resultsPoints').value }),
    };

    this.adminPanelService
      .getUniversalTestingReportsByEmail(requestBody)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(request => {
        if (request?.requestReportId) {
          this.resetFullDiagnosticForm();
        }
      });
  }

  private getTests(): void {
    this.overlayBusyService.show();
    this.systemService
      .getTestsVariantsInfo()
      .pipe(take(1), takeUntil(this.unsubscribe))
      .subscribe((response: IShortTest[]) => {
        this.tests = response;
        this.initFullDiagnosticReportHandlers();
        this.changeDetectorRef.markForCheck();
        this.overlayBusyService.hide();
      });
  }

  private onDataTypeDropdownChange(): void {
    this.form
      .get('dataType')
      .valueChanges.pipe(takeUntil(this.unsubscribe))
      .subscribe(dataTypeDropdownValue => {
        if (dataTypeDropdownValue === 1) {
          this.form.get('resultsPoints').reset();
          this.form.get('resultsPoints').disable();
        } else {
          this.form.get('resultsPoints').enable();
        }

        return of(dataTypeDropdownValue);
      });
  }

  private resetFullDiagnosticForm(): void {
    this.form.reset({
      dateFrom: this.fullDiagnosticReportDateConfig.minDateInit,
      dateTo: this.fullDiagnosticReportDateConfig.maxDateInit,
    });
    this.isTestSelected = false;
    this.isTestVariantSelected = false;
    this.listedRegionsForFullDiagnosticReport.length = 0;
    this.listedAggregates.length = 0;
    this.overlayBusyService.hide();
    this.utilsService.openSnackBar('👌 Ваш отчет отправлен на почту. Формирование отчета может занять время.', 'success');
  }

  protected readonly ALL_AGGREGATES_OPTION_VALUE = ALL_AGGREGATES_OPTION_VALUE;
}
