import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpService } from 'app/core/services/http.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { catchError, map, take, tap } from 'rxjs/operators';
import { Helper } from 'app/shared/common/helpers/helper';
import {
  ICountOfLessons,
  ICourseMaterialsModel,
  IGetAllCourseMaterials,
  IGetCourseMaterial,
  ILessonMaterialModel,
  ILessonsBlockNames,
  IParsedLessons,
  IStatus,
} from 'app/pages/catalog/career-education-courses-page/interfaces/career-education-courses.interface';

@Injectable()
export class CareerEducationCoursesService {
  private _countOfLessons: BehaviorSubject<ICountOfLessons[]>;
  private _countOfCourses: BehaviorSubject<number>;
  private _countOfBlocks: BehaviorSubject<number>;
  private _blockNames: BehaviorSubject<ILessonsBlockNames[]>;

  constructor(private httpService: HttpService) {
    this._countOfLessons = new BehaviorSubject<ICountOfLessons[]>([]);
    this._countOfCourses = new BehaviorSubject<number>(0);
    this._countOfBlocks = new BehaviorSubject<number>(0);
    this._blockNames = new BehaviorSubject<ILessonsBlockNames[]>([]);
  }

  get countOfCourses(): Observable<number> {
    return this._countOfCourses.asObservable();
  }

  private set setCountOfCourses(count: number) {
    this._countOfCourses.next(count);
  }

  private set countOfLessons(countOfLessons: ICountOfLessons) {
    this._countOfLessons.pipe(take(1)).subscribe(counts => {
      this._countOfLessons.next([...counts, countOfLessons]);
    });
  }

  private set blockNames(blockNames: ILessonsBlockNames) {
    this._blockNames.pipe(take(1)).subscribe(blocks => {
      this._blockNames.next([...blocks, blockNames]);
    });
  }

  public static updateMinMax(min: number, max: number, newValue: number): { min: number; max: number } {
    const minValue = !min || min > newValue ? newValue : min;
    const maxValue = !max || max < newValue ? newValue : max;

    return { min: minValue, max: maxValue };
  }

  public static updateMinMaxAfterRemoving(min: number, max: number, removedValue: number): { min: number; max: number } {
    const minValue = min === removedValue ? min + 1 : min;
    const maxValue = max === removedValue ? max - 1 : max;

    return { min: minValue, max: maxValue };
  }

  public static updateMinMaxAfterUpdating(min: number, max: number, oldValue: number, newValue: number): { min: number; max: number } {
    const minValue = min === oldValue ? newValue : min;
    const maxValue = max === oldValue ? newValue : max;

    return { min: minValue, max: maxValue };
  }

  public getCountOfLessons(courseId: string): Observable<ICountOfLessons> {
    return this._countOfLessons.asObservable().pipe(
      map(countOfLessons => {
        return countOfLessons.find(countOfLesson => countOfLesson.courseId === courseId) ?? null;
      }),
    );
  }

  public getBlockNames(courseId: string): Observable<ILessonsBlockNames> {
    return this._blockNames.asObservable().pipe(
      map(blockNames => {
        return blockNames.find(blockName => blockName.courseId === courseId) ?? null;
      }),
    );
  }

  public getAllCourseMaterials(): Observable<IGetAllCourseMaterials> {
    return this.httpService.get('/api/v1.0/lessons/coursesmaterials/all').pipe(
      map(res => res),
      catchError((err: HttpErrorResponse) => {
        this._blockNames.next([]);
        this._countOfLessons.next([]);
        this._countOfCourses.next(0);

        return Helper.handleError(err);
      }),
      tap(res => {
        const courses = res?.courseMaterials ?? [];

        this.setCountOfCourses = courses.length;

        courses.forEach(course => {
          const lessons = course?.lessonsMaterials ?? [];
          const blockNames = lessons.reduce((prevVal, currentVal) => {
            prevVal[currentVal.blockName] = (prevVal[currentVal] ?? 0) + 1;

            return prevVal;
          }, {});

          this.blockNames = { courseId: course.id, blockNames };
          this.countOfLessons = { courseId: course.id, count: lessons.length };
        });
      }),
    );
  }

  public getCourseMaterial(courseId: string): Observable<IGetCourseMaterial> {
    return this.httpService.get(`/api/v1.0/lessons/coursesmaterials/one?id=${courseId}`).pipe(
      map(res => res),
      catchError((err: HttpErrorResponse) => Helper.handleError(err)),
    );
  }

  public deleteCourseMaterial(id: string): Observable<IStatus> {
    return this.httpService.delete(`/api/v1.0/lessons/coursesmaterials`, { id }).pipe(
      map(res => res),
      catchError((err: HttpErrorResponse) => Helper.handleError(err)),
    );
  }

  public createCourseMaterial(courseData: ICourseMaterialsModel): Observable<ICourseMaterialsModel> {
    return this.httpService.post('/api/v1.0/lessons/coursesmaterials', courseData).pipe(
      map(res => res),
      catchError((err: HttpErrorResponse) => Helper.handleError(err)),
    );
  }

  public updateCourseMaterial(courseData: ICourseMaterialsModel): Observable<IStatus> {
    return this.httpService.put('/api/v1.0/lessons/coursesmaterials', courseData).pipe(
      map(res => res),
      catchError((err: HttpErrorResponse) => Helper.handleError(err)),
    );
  }

  public addLessonMaterial(lessonData: ILessonMaterialModel): Observable<IGetCourseMaterial> {
    return this.httpService.post('/api/v1.0/lessons/coursesmaterials/addlesson', lessonData).pipe(
      map(res => res),
      catchError((err: HttpErrorResponse) => Helper.handleError(err)),
    );
  }

  public updateLessonMaterial(lessonData: ILessonMaterialModel): Observable<IStatus> {
    return this.httpService.put('/api/v1.0/lessons/coursesmaterials/updatelesson', lessonData).pipe(
      map(res => res),
      catchError((err: HttpErrorResponse) => Helper.handleError(err)),
    );
  }

  public deleteLesson(courseId: string, lessonId: string): Observable<IStatus> {
    return this.httpService
      .deleteWithObject(`/api/v1.0/lessons/coursesmaterials/deletelesson`, {
        courseMaterialId: courseId,
        lessonMaterialId: lessonId,
      })
      .pipe(
        map(res => res),
        catchError((err: HttpErrorResponse) => Helper.handleError(err)),
      );
  }

  public parseLessons(course: IGetCourseMaterial): IGetCourseMaterial {
    const lessonsMaterials = course?.courseMaterial?.lessonsMaterials ?? [];
    const parsedLessons = lessonsMaterials.reduce((prevVal, currentVal) => {
      const { number } = currentVal;
      const parsedLesson = { ...currentVal };
      const previousData = prevVal[parsedLesson.blockName] ?? {};
      const { min, max } = CareerEducationCoursesService.updateMinMax(previousData.minNumber, previousData.maxNumber, number);

      previousData.minNumber = min;
      previousData.maxNumber = max;

      if (Array.isArray(previousData.lessons)) {
        previousData.lessons.push(parsedLesson);
      } else {
        previousData.lessons = [parsedLesson];
      }

      prevVal[parsedLesson.blockName] = previousData;

      return prevVal;
    }, {} as IParsedLessons);

    course.courseMaterial.parsedLessons = Object.entries(parsedLessons);

    return course;
  }
}
