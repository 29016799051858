import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { Observable, of } from 'rxjs';
import { IListedItem, IShortVariant } from '../../../pages/control-panel/admin/system/system.interface';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { FormControl, NG_VALUE_ACCESSOR, UntypedFormControl } from '@angular/forms';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { MatChipsModule } from '@angular/material/chips';
import { MatInputModule } from '@angular/material/input';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
@Component({
  selector: 'prf-dropdown-multi',
  standalone: true,
  imports: [CommonModule, MatChipsModule, MatInputModule, MatAutocompleteModule, MatFormFieldModule, MatIconModule],
  templateUrl: './dropdown-multi.component.html',
  styleUrls: ['./dropdown-multi.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DropdownMultiComponent),
      multi: true,
    },
  ],
})
export class DropdownMultiComponent {
  @ViewChild('variantInput') public variantInput: ElementRef<HTMLInputElement>;
  @Input() public label: string;
  @Input() public placeholder: string;
  @Input() public set disabledInput(value: boolean) {
    if (typeof value === 'boolean') {
      this.isDisabled = value;
    }
    this.isDisabled ? this.control.disable() : this.control.enable();
  }
  @Input()
  public set options(data: any[]) {
    this._options = data;
    this.filteredOptions = of(this._options.slice());
  }
  public selectedOptions: any[] = [];

  @Input()
  public set resetOptions(data: boolean) {
    if (data) {
      this.selectedOptions = [];
    }
  }

  @Output() public selectedEvent: EventEmitter<any> = new EventEmitter<any>();
  public filteredOptions: Observable<any[]>;
  public separatorKeysCodes: number[] = [ENTER, COMMA];
  private _currentControl: any = null;
  public control: FormControl<any> = new UntypedFormControl();
  public _options: any[];
  public isDisabled: boolean = false;

  constructor(private changeDetectorRef: ChangeDetectorRef, matIconRegistry: MatIconRegistry, sanitizer: DomSanitizer) {
    matIconRegistry.addSvgIcon('icon_close', sanitizer.bypassSecurityTrustResourceUrl('/assets/images/icons/close-lb.svg'));
  }
  public propagateChange = (control: any) => {};
  public propagateTouch = (control: any) => {};
  public registerOnChange(fn: () => {}): void {
    this.propagateChange = fn;
  }
  public registerOnTouched(fn: () => {}): void {
    this.propagateTouch = fn;
  }

  public set currentControl(control: any) {
    this._currentControl = control;
    this.propagateChange(control);
    this.propagateTouch(control);
  }

  public writeValue(obj: any): void {
    const valueView = obj && obj.hasOwnProperty('id') ? obj.id : obj;
    this.control.setValue(valueView);
    this.control.updateValueAndValidity();
    this.currentControl = obj;
    this.changeDetectorRef.markForCheck();
  }

  public selected(event: MatAutocompleteSelectedEvent): void {
    if (this.selectedOptions.find(item => item.id === 'all')) {
      return;
    }
    const repeat = this.selectedOptions.find(variant => variant.id === event.option.value.id);

    if (event.option.value.id === 'all') {
      this.selectedOptions = [];
    }

    if (!repeat) {
      this.selectedOptions.push(event.option.value);
      this.variantInput.nativeElement.value = '';
      this.writeValue(this.selectedOptions);
    }
  }

  public remove(data: string): void {
    const selected: IListedItem = this.selectedOptions.find((variant: IListedItem) => variant.data === data);
    const index: number = this.selectedOptions.indexOf(selected);

    if (index >= 0) {
      this.selectedOptions.splice(index, 1);
      this.writeValue(this.selectedOptions);
    }
  }

  public trackByFn(index: number, item: IShortVariant): string {
    return item.id;
  }
}
