import { Observable, of as observableOf, switchMap, throwError } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpService } from 'app/core/services/http.service';
import { IAllInstitutionsStatistic } from 'app/shared/interfaces/iallinstitutionsstatistic';
import { ICity } from 'app/shared/interfaces/icity';
import { catchError, map, take } from 'rxjs/operators';
import { InstitutionClass } from 'app/shared/classes/institution.class';
import { ICompany } from 'app/shared/interfaces/icompany.interface';
import { INews } from 'app/shared/interfaces/inews.interface';
import { ISchool } from 'app/shared/interfaces/ischool.interface';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { IProfessionFilter } from 'app/shared/interfaces/iprofessionfilter.interface';
import { IFindUsersFilter } from 'app/shared/interfaces/ifindusersfilter.interface';
import { IProfession } from 'app/shared/interfaces/iprofession.interface';
import { IResponseStatus } from './professions/professions/iprofessions.interface';
import { Helper } from 'app/shared/common/helpers/helper';
import { IInstitution } from 'app/shared/interfaces/IInstitution.interface';
import { IStatus } from '../../catalog/career-education-courses-page/interfaces/career-education-courses.interface';
import { IAddUserInterface } from '../../../shared/interfaces/iregistration.interface';
import { ICatalogSpeciality, ISpecialities } from 'app/shared/interfaces/ispeciality.interface';
import { IGenerateResetPasswordLinkResponse } from './users/users.interface';
import { IGetUniversalTestingReportsByEmailRequest, ISchoolStatisticReport, IUpdateSchoolStatisticRequest } from './admin.interface';

@Injectable()
export class AdminPanelService {
  constructor(private http: HttpService) {}

  getCatalogSchools(): Observable<any> {
    return this.http.get('/api/v1.0/catalog/schools').pipe(
      map(r => r.schools),
      catchError(Helper.handleError),
    );
  }

  setSchool(dataSchool): Observable<any> {
    return this.http.post('/api/v1.0/catalog/schools', dataSchool).pipe(
      map(r => r.id),
      catchError(Helper.handleError),
    );
  }

  updateAdminsAllSchoolsList(regionId): Observable<any> {
    let urlParams = '';
    if (regionId) {
      urlParams += '?regionId=' + regionId;
    }
    return this.http.post('\n' + '/saas/v1.0/admins/updateadminsallschoolslist' + urlParams).pipe(
      map(r => r),
      catchError(Helper.handleError),
    );
  }

  changeUserInfo(dataUser): Observable<any> {
    return this.http.post('/saas/v1.0/admins/changeuserinfo', dataUser).pipe(
      map(r => r),
      catchError(Helper.handleError),
    );
  }

  totalDeleteUser(userId): Observable<any> {
    return this.http.delete('/saas/v1.0/admins/deleteuser?userId=' + userId).pipe(
      map(r => r),
      catchError(Helper.handleError),
    );
  }

  createUserByRole(dataUser: IAddUserInterface): Observable<any> {
    return this.http.post('/saas/v1.0/saas/adduser', dataUser).pipe(
      map(r => r),
      catchError(Helper.handleError),
    );
  }

  createAdminUser(dataUser): Observable<any> {
    return this.http.post('/saas/v1.0/admins/addadmin', dataUser).pipe(
      map(r => r),
      catchError(Helper.handleError),
    );
  }

  findUsersByFilters(filters: IFindUsersFilter): Observable<any> {
    const credentials = {
      filters: filters,
    };
    return this.http.post('/saas/v1.0/admins/findusers', credentials).pipe(
      map(r => r),
      catchError(Helper.handleError),
    );
  }

  getSchoolAdmins(userRegionId: string, userMunicipalityId: string): Observable<any> {
    const data = {
      regionId: userRegionId,
      municipalityId: userMunicipalityId,
    };
    return this.http.get('/saas/v1.0/admins/getschooladmins', data).pipe(
      map(r => r),
      catchError(Helper.handleError),
    );
  }

  getDirectors(userRegionId: string, userMunicipalityId: string): Observable<any> {
    const data = {
      regionId: userRegionId,
      municipalityId: userMunicipalityId,
    };
    return this.http.get('/saas/v1.0/admins/getdirectors', data).pipe(
      map(r => r),
      catchError(Helper.handleError),
    );
  }

  getAdminDOUsers(userRegionId: string, userMunicipalityId: string): Observable<any> {
    const data = {
      regionId: userRegionId,
      municipalityId: userMunicipalityId,
    };
    return this.http.get('/saas/v1.0/admins/getadmindos', data).pipe(
      map(r => r),
      catchError(Helper.handleError),
    );
  }

  getAdminUsers(userRegionId: string, userMunicipalityId: string): Observable<any> {
    const params = [];
    let url = '/saas/v1.0/admins/getregionadmins';
    userRegionId ? params.push(`RegionId=${userRegionId}`) : null;
    userMunicipalityId ? params.push(`MunicipalityId=${userMunicipalityId}`) : null;
    if (params.length) {
      url += encodeURI('?' + params.join('&'));
    }
    return this.http.get(url).pipe(
      map(r => r),
      catchError(Helper.handleError),
    );
  }

  getEmployerUsers(): Observable<any> {
    return this.http.get('/saas/v1.0/admins/getemployerusers').pipe(
      map(r => r),
      catchError(Helper.handleError),
    );
  }

  getTutorUsers(): Observable<any> {
    return this.http.get('/saas/v1.0/admins/gettutors').pipe(
      map(r => r.tutors),
      catchError(Helper.handleError),
    );
  }

  createProfessions(profession: IProfession): Observable<any> {
    const credentials = {
      profession: profession,
    };
    return this.http.post('/api/v1.0/catalog/professions/mongo/create', credentials).pipe(
      map(r => r),
      catchError(Helper.handleError),
    );
  }

  getProfessionByHrid(hrid: string): Observable<any> {
    return this.http.get(`/api/v1.0/catalog/professions/mongo/onebyhrid?hrid=${hrid}`).pipe(
      map(r => r.profession),
      catchError((err: HttpErrorResponse) => {
        if (err.status == 404) {
          return observableOf(null);
        }

        return Helper.handleError(err);
      }),
    );
  }

  getProfessionsByFilters(filters: IProfessionFilter): Observable<any> {
    return this.http.post('/api/v1.0/catalog/professions/mongo/search/filters', filters).pipe(
      map(r => r.professions),
      catchError(Helper.handleError),
    );
  }

  getElasticProfessionsByFilters(filters: IProfessionFilter): Observable<any> {
    return this.http.post('/api/v1.0/catalog/professions/elasticsearch/search/filters', filters).pipe(
      map(r => r),
      catchError(Helper.handleError),
    );
  }

  getAllProfessionsByRegion(regionId): Observable<any> {
    const filters: IProfessionFilter = {};
    Object.assign(filters, { regionId: regionId });
    const credentials = Object.assign({}, { filters });
    return this.http.post('/api/v1.0/catalog/professions/mongo/search/filters', credentials).pipe(
      map(r => r.professions),
      catchError(Helper.handleError),
    );
  }

  getAllElasticProfessionsByRegion(regionId): Observable<any> {
    const filters: IProfessionFilter = {};
    Object.assign(filters, { regionId: regionId });

    return this.http.post('/api/v1.0/catalog/professions/elasticsearch/search/filters', filters).pipe(
      map(r => r),
      catchError(Helper.handleError),
    );
  }

  getProfessionCount(filters) {
    return this.http.post('/api/v1.0/catalog/professions/mongo/count/filters', filters).pipe(
      map(r => r.count),
      catchError(Helper.handleError),
    );
  }
  getElasticProfessionCount(filters) {
    return this.http.post('/api/v1.0/catalog/professions/elasticsearch/count/filters', filters).pipe(
      map(r => r),
      catchError(Helper.handleError),
    );
  }

  getAllProfessionsByMunicipality(municipalityId): Observable<any> {
    const filters: IProfessionFilter = {};
    Object.assign(filters, { municipalityId: municipalityId });
    const credentials = Object.assign({}, { filters });

    return this.http.post('/api/v1.0/catalog/professions/mongo/search/filters', credentials).pipe(
      map(r => r.professions),
      catchError(Helper.handleError),
    );
  }

  public getProfessionsQuery(query, regionId?): Observable<any> {
    const credentials = {
      query: query,
    };
    regionId ? Object.assign(credentials, { regionId: regionId }) : null;
    return this.http.post('/api/v1.0/catalog/professions/mongo/search/query', credentials).pipe(
      map(r => r.professions),
      catchError(Helper.handleError),
    );
  }
  public getElasticProfessionsQuery(query, regionId?): Observable<any> {
    return this.http.post('/api/v1.0/catalog/professions/search/query', { query, regionId }).pipe(
      map(r => r.professions),
      catchError(Helper.handleError),
    );
  }

  getProfessionsByIds(ids: string[]): Observable<any> {
    const filters: IProfessionFilter = {};
    ids && ids.length ? Object.assign(filters, { ids: ids }) : null;
    const credentials = Object.assign({}, { filters });

    return this.http.post('/api/v1.0/catalog/professions/mongo/search/query', credentials).pipe(
      map(r => r.professions),
      catchError(Helper.handleError),
    );
  }

  public getProfessionById(professionId: string): Observable<IProfession> {
    return this.http.get(`/api/v1.0/catalog/professions/one?professionId=${professionId}`).pipe(
      map(r => r),
      catchError(Helper.handleError),
    );
  }

  public setProfessionVisibility(hrid: string, isVisible: boolean): Observable<IStatus> {
    return this.getProfessionByHrid(hrid).pipe(
      switchMap((profession: IProfession) => {
        if (Helper.isDefined(profession?.id)) {
          profession.isVisible = isVisible;

          return this.changeProfessions(profession);
        }

        return throwError(() => new Error(`Profession with hrid: ${hrid} not found!`));
      }),
    );
  }

  changeProfessions(value: IProfession): Observable<any> {
    const credentials = {
      id: value.id,
      profession: value,
    };
    return this.http.put('/api/v1.0/catalog/professions/mongo/update', credentials).pipe(
      map(r => r),
      catchError(Helper.handleError),
    );
  }

  addImagePathsInProfession(professionId: string, imagePath: string): Observable<IResponseStatus> {
    const regExp = /[0-9A-Fa-f]{8}-[0-9A-Fa-f]{4}-[0-9A-Fa-f]{4}-[0-9A-Fa-f]{4}-[0-9A-Fa-f]{12}/;
    const imageId = imagePath.match(regExp);

    const body = {
      professionId,
      images: [
        {
          id: imageId?.[0],
          path: imagePath,
        },
      ],
    };
    return this.http.post('/api/v1.0/catalog/professions/mongo/addimagespathsinprofession', body).pipe(
      map(r => r),
      catchError(Helper.handleError),
    );
  }

  deleteImagePathsInProfession(professionId: string, imageId: string, imagePath: string): Observable<IResponseStatus> {
    const body = {
      professionId,
      images: [
        {
          id: imageId,
          path: imagePath,
        },
      ],
    };
    return this.http.post('/api/v1.0/catalog/professions/mongo/deleteimagespathsinprofession', body).pipe(
      map(r => r),
      catchError(Helper.handleError),
    );
  }

  getNews(from, to, groupId): Observable<any> {
    const urlParams = '?' + 'From=' + from + '&To=' + to + '&groupId=' + groupId;
    return this.http.get('/saas/v1.0/saas/getevents' + urlParams).pipe(
      map(r => r),
      catchError(Helper.handleError),
    );
  }

  createNews(value: INews): Observable<any> {
    return this.http.post('/saas/v1.0/saas/createevent', value).pipe(
      map(r => r),
      catchError(Helper.handleError),
    );
  }

  uploadImage(image: File | FileList, id: string): Observable<any> {
    const formData: FormData = new FormData();
    formData.append(image[0].name, image[0]);
    return this.http.postImage('/saas/v1.0/utils/upload/' + id, formData).pipe(
      map(r => r['result']),
      catchError(Helper.handleError),
    );
  }

  uploadProfessions(file: File): Observable<any> {
    const formData: FormData = new FormData();
    formData.append(file.name, file);
    return this.http.postFileFormData('/api/v1.0/catalog/professions/uploadprofessionsfromexcel', formData).pipe(
      map(r => r),
      catchError(Helper.handleError),
    );
  }

  getGroups(): Observable<any> {
    return this.http.get('/api/v1.0/catalog/fielddos/all').pipe(
      map(r => r.fieldDOs),
      catchError(Helper.handleError),
    );
  }

  getFields(): Observable<any> {
    return this.http.get('/api/v1.0/catalog/fields/all').pipe(
      map(r => r.fields),
      catchError(Helper.handleError),
    );
  }

  getCourses(): Observable<any> {
    return this.http.get('/api/v1.0/catalog/courses/all').pipe(
      map(r => r.courses),
      catchError(Helper.handleError),
    );
  }

  getProfilClasses(): Observable<any> {
    return this.http.get('/api/v1.0/catalog/search/profilclasses').pipe(
      map(r => r.result),
      catchError(Helper.handleError),
    );
  }

  getInstitutionsAll(): Observable<IInstitution[]> {
    return this.http.get('/api/v1.0/catalog/institutions/all').pipe(
      map(r => r.institutions),
      catchError(Helper.handleError),
    );
  }

  getInstitution(institutionId): Observable<any> {
    return this.http.get('/api/v1.0/catalog/institutions/one?id=' + institutionId).pipe(
      map(r => r),
      catchError(Helper.handleError),
    );
  }

  createInstitutions(institution: InstitutionClass): Observable<any> {
    return this.http.post('/api/v1.0/catalog/institutions', institution).pipe(
      map(r => r),
      catchError(Helper.handleError),
    );
  }

  updateInstitutions(institution: InstitutionClass): Observable<any> {
    return this.http.put('/api/v1.0/catalog/institutions?institutionId=' + institution.id, institution).pipe(
      map(r => r),
      catchError(Helper.handleError),
    );
  }

  deleteInstitutions(institutionId): Observable<any> {
    return this.http.delete('/api/v1.0/catalog/institutions?institutionId=' + institutionId).pipe(
      map(r => r),
      catchError(Helper.handleError),
    );
  }

  removeProfession(professionID): Observable<any> {
    return this.http.delete('/api/v1.0/catalog/professions/mongo/delete?professionId=' + professionID).pipe(
      map(r => r),
      catchError(Helper.handleError),
    );
  }

  public getAllSchoolsStatistic(
    regionId?: string,
    municipalityId?: string,
    city?: string,
    date?: Date,
    from?: number,
    size?: number,
    year?: number,
  ): Observable<ISchoolStatisticReport> {
    let urlParams: string = '';
    if (regionId) {
      urlParams += '?regionId=' + regionId;
    }
    if (municipalityId) {
      urlParams += urlParams ? '&municipalityId=' + municipalityId : '?municipalityId=' + municipalityId;
    }
    if (city) {
      urlParams += urlParams ? '&city=' + city : '?city=' + city;
    }
    if (date) {
      urlParams += urlParams ? '&date=' + date : '?date=' + date;
    }
    if (size) {
      urlParams += urlParams ? '&size=' + size : '?size=' + size;
    }
    if (from) {
      urlParams += urlParams ? '&from=' + from : '?from=' + from;
    }
    if (year != null && year >= 0) {
      urlParams += urlParams ? '&year=' + year : '?year=' + year;
    }

    return this.http.post('/saas/v1.0/admins/getallschoolsstatisticbyfilters' + urlParams).pipe(
      map((r: ISchoolStatisticReport) => r),
      catchError(Helper.handleError),
    );
  }

  public updateAllSchoolsStatistic(updateData: IUpdateSchoolStatisticRequest): Observable<any> {
    return this.http.post('/saas/v1.0/admins/updateallschoolsstatistic', updateData).pipe(
      map(r => r),
      catchError(Helper.handleError),
    );
  }

  getAllInstitutionsStatistic(): Observable<IAllInstitutionsStatistic[]> {
    return this.http.get('/saas/v1.0/admins/getallinstitutionsstatistic').pipe(
      map(r => r),
      catchError(Helper.handleError),
    );
  }

  updateAllInstitutionsStatistic(): Observable<any> {
    return this.http.post('/saas/v1.0/admins/updateallinstitutionsstatistic').pipe(
      map(r => r),
      catchError(Helper.handleError),
    );
  }

  getSchoolByID(schoolID): Observable<any> {
    return this.http.get('/api/v1.0/catalog/schools/onebyid?schoolId=' + schoolID).pipe(
      map(r => r.school),
      catchError(Helper.handleError),
    );
  }

  updateSchool(schoolClass: ISchool): Observable<any> {
    return this.http.put('/api/v1.0/catalog/schools/update?schoolId=' + schoolClass.id, schoolClass).pipe(
      map(r => r.school),
      catchError(Helper.handleError),
    );
  }

  deleteSchool(schoolID): Observable<any> {
    return this.http.delete('/api/v1.0/catalog/schools?id=' + schoolID).pipe(
      map(r => r),
      catchError(Helper.handleError),
    );
  }

  getAllCities(): Observable<ICity[]> {
    return this.http.get('/api/v1.0/catalog/cities/all').pipe(
      map(r => r.cities),
      catchError(Helper.handleError),
    );
  }

  getRegionCities(regionId: string): Observable<ICity[]> {
    return this.http.get('/api/v1.0/catalog/cities/getbyregion?regionId=' + regionId).pipe(
      map(r => r.cities),
      catchError(Helper.handleError),
    );
  }

  getCity(cityId): Observable<any> {
    return this.http.get('/api/v1.0/catalog/cities/one?id=' + cityId).pipe(
      map(r => r.city),
      catchError(Helper.handleError),
    );
  }

  updateCity(city): Observable<any> {
    return this.http.put('/api/v1.0/catalog/cities?cityId=' + city.id, city).pipe(
      map(r => r),
      catchError(Helper.handleError),
    );
  }

  deleteCity(cityId): Observable<any> {
    return this.http.delete('/api/v1.0/catalog/cities?cityId=' + cityId).pipe(
      map(r => r),
      catchError(Helper.handleError),
    );
  }

  createCity(city): Observable<any> {
    return this.http.post('/api/v1.0/catalog/cities', city).pipe(
      map(r => r),
      catchError(Helper.handleError),
    );
  }

  getRegionTestInfo(): Observable<any> {
    return this.http.get('/api/v1.0/player/regiontestinfo/list').pipe(
      map(r => r),
      catchError(Helper.handleError),
    );
  }

  public getRegionIdsByScreeningTestId(screeningTestId: string): Observable<any> {
    return this.http.get('/api/v1.0/player/regiontestinfo/getregionidsbyscreeningtestid', { screeningTestId }).pipe(take(1));
  }

  public getUniversalTestingReportsByEmail(body: IGetUniversalTestingReportsByEmailRequest): Observable<any> {
    return this.http.post('/saas/v1.0/reports/getuniversaltestingreportsbyemail', body).pipe(take(1));
  }

  getAllCompanies(): Observable<ICompany[]> {
    return this.http.get('/api/v1.0/companies/companies/all').pipe(
      map(r => r.companies),
      catchError(Helper.handleError),
    );
  }

  createRegisterOpen(credentials): Observable<any> {
    return this.http.post('/saas/v1.0/saas/registeropen', credentials).pipe(
      map(r => r),
      catchError(Helper.handleError),
    );
  }

  public downloadCatalogExcel(regionId: string, dataType: string): Observable<Response | HttpResponse<Blob>> {
    return this.http.getFile(`/saas/v1.0/admins/getcatalogreportdata?regionId=${regionId}&dataType=${dataType}`).pipe(
      map((r: Response | HttpResponse<Blob>) => r),
      catchError(Helper.handleError),
    );
  }

  generatePasswordResetLink(phoneNumber: string): Observable<IGenerateResetPasswordLinkResponse> {
    const body = { phoneNumber: phoneNumber };
    return this.http.post('/saas/v1.0/saas/generatepasswordresetlink', body).pipe(
      map(r => r),
      catchError(Helper.handleError),
    );
  }

  getAllSchoolClassesBySchoolId(schoolId: string): Observable<any> {
    return this.http.get(`/saas/v1.0/saas/getschoolclassesbyschool?schoolId=${schoolId}`).pipe(
      map(r => r),
      catchError(Helper.handleError),
    );
  }

  public reassignTeacherForSchoolClass(teacherId: string, schoolClassId: string, oldSchoolClassId?: string): Observable<IResponseStatus> {
    let params = `teacherId=${teacherId}&schoolClassId=${schoolClassId}`;
    if (oldSchoolClassId) {
      params += `&oldSchoolClassId=${oldSchoolClassId}`;
    }

    return this.http.post(`/saas/v1.0/admins/reassignteacherforschoolclass?${params}`).pipe(
      map(r => r),
      catchError(Helper.handleError),
    );
  }

  public syncProfessionObjects(regionId: string): Observable<IResponseStatus> {
    return this.http.post('/api/v1.0/catalog/professions/synchronizeprofessionobjects?', regionId).pipe(
      map(r => r),
      catchError(Helper.handleError),
    );
  }

  public getAllSpecialties(): Observable<ICatalogSpeciality[]> {
    return this.http.get('/api/v1.0/catalog/specialities/all').pipe(
      map((res: ISpecialities) => res.specialities),
      catchError(Helper.handleError),
    );
  }

  public softSchoolClassChange(body: any): Observable<IResponseStatus> {
    return this.http.post('/api/v1.0/admins/softschoolclasschange', body).pipe(
      map(r => r),
      catchError(Helper.handleError),
    );
  }

  public findSchoolClasses(params: any): Observable<any> {
    return this.http.get('/api/v1.0/schoolclasses/schoolclasses/find', params).pipe(take(1), catchError(Helper.handleError));
  }

  public transferPupilToClass(userId: string, schoolClassId: string): Observable<any> {
    return this.http
      .post(`/saas/v1.0/admins/transferpupiltoclass?userId=${userId}&schoolClassId=${schoolClassId}`)
      .pipe(take(1), catchError(Helper.handleError));
  }
}
