import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpService } from 'app/core/services/http.service';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { IStatusSendResult, IUserParameters } from 'app/shared/interfaces/icommon.interface';
import { IStatus } from 'app/pages/catalog/career-education-courses-page/interfaces/career-education-courses.interface';
import { IRegionStaticticReport } from 'app/shared/interfaces/isystem-report.interface';
import { Helper } from 'app/shared/common/helpers/helper';
import { IAnswersMatrixData, IShortTest } from './system.interface';

@Injectable({
  providedIn: 'root',
})
export class SystemService {
  constructor(private http: HttpService) {}

  getCatalogSchools(): Observable<any> {
    return this.http.get('/api/v1.0/catalog/schools').pipe(
      map(r => r.schools),
      catchError((err: HttpErrorResponse) => {
        return Helper.handleError(err);
      }),
    );
  }

  updateAllSchoolsStatistic(): Observable<any> {
    return this.http.post('/saas/v1.0/admins/updateallschoolclassesextendedpupils').pipe(
      map(r => r),
      catchError((err: HttpErrorResponse) => {
        return Helper.handleError(err);
      }),
    );
  }

  updateSchoolStatistic(schoolId): Observable<any> {
    return this.http.post(`/saas/v1.0/admins/updateextendedpupils?schoolId=${schoolId}`).pipe(
      map(r => r),
      catchError((err: HttpErrorResponse) => {
        return Helper.handleError(err);
      }),
    );
  }

  updateAllSchoolsAnalytics(regionId, dateFrom, dateTo): Observable<any> {
    let credentials = {
      regionId: regionId,
      dateFrom: dateFrom,
      dateTo: dateTo,
    };
    return this.http.post('/saas/v1.0/admins/calculateallpartialschoolireport', credentials).pipe(
      map(r => r),
      catchError((err: HttpErrorResponse) => {
        return Helper.handleError(err);
      }),
    );
  }
  updateSchoolAnalytics(schoolId, dateFrom, dateTo): Observable<any> {
    let credentials = {
      schoolId: schoolId,
      dateFrom: dateFrom,
      dateTo: dateTo,
    };
    return this.http.post('/saas/v1.0/admins/calculatepartialschoolireport', credentials).pipe(
      map(r => r),
      catchError((err: HttpErrorResponse) => {
        return Helper.handleError(err);
      }),
    );
  }

  calculatePartialRegioniReport(regionId: string) {
    let credentials = {
      regionId: regionId,
      dateFrom: '',
      dateTo: '',
    };
    return this.http.post('/saas/v1.0/admins/calculatepartialregionireport', credentials).pipe(
      map(r => r.id),
      catchError((err: HttpErrorResponse) => {
        return Helper.handleError(err);
      }),
    );
  }

  calculatePartialMunicipalityiReport(regionId, municipalityId) {
    let credentials = {
      regionId: regionId,
      municipalityId: municipalityId,
      dateFrom: '',
      dateTo: '',
    };
    return this.http.post('/saas/v1.0/admins/calculatepartialmunicipalityireport', credentials).pipe(
      map(r => r.id),
      catchError((err: HttpErrorResponse) => {
        return Helper.handleError(err);
      }),
    );
  }

  updateRegionStatistic(data: IRegionStaticticReport) {
    return this.http.post('/saas/v1.0/reports/updateregionstatisticexcel', data).pipe(
      map(r => r),
      catchError((err: HttpErrorResponse) => {
        return Helper.handleError(err);
      }),
    );
  }
  getRegionStatisticExcel(data: IRegionStaticticReport) {
    const { regionId, userId, role, dateFrom, dateTo } = data;
    let urlParam: string = `?regionId=${regionId}&userId=${userId}&role=${role}&dateFrom=${dateFrom}&dateTo=${dateTo}`;
    return this.http.getFile('/saas/v1.0/reports/getregionstatisticexcel' + urlParam).pipe(
      map(r => r),
      catchError((err: HttpErrorResponse) => {
        return Helper.handleError(err);
      }),
    );
  }

  updateRegionPupilsStatistic(regionId) {
    let urlParam: string = `?regionId=${regionId}`;
    return this.http.post('/saas/v1.0/reports/updatepupilusersinfoforregion' + urlParam).pipe(
      map(r => r),
      catchError((err: HttpErrorResponse) => {
        return Helper.handleError(err);
      }),
    );
  }
  getRegionPupilsStatisticExcel(regionId) {
    let urlParam: string = `?regionId=${regionId}`;
    return this.http.getFile('/saas/v1.0/reports/getpupilusersinfoforregionexcel' + urlParam).pipe(
      map(r => r),
      catchError((err: HttpErrorResponse) => {
        return Helper.handleError(err);
      }),
    );
  }

  updateMunicipalityStatistic(regionId, municipalityId, language) {
    return this.http
      .post(`/saas/v1.0/reports/updateregionstatisticexcel?regionId=${regionId}&municipalityId=${municipalityId}&language=${language}`)
      .pipe(
        map(r => r),
        catchError((err: HttpErrorResponse) => {
          return Helper.handleError(err);
        }),
      );
  }
  getMunicipalityStatisticExcel(regionId, municipalityId, language): Observable<any> {
    return this.http
      .getFile(`/saas/v1.0/reports/getregionstatisticexcel?regionId=${regionId}&municipalityId=${municipalityId}&language=${language}`)
      .pipe(
        map(r => r),
        catchError((err: HttpErrorResponse) => {
          return Helper.handleError(err);
        }),
      );
  }

  updateProfessions() {
    return this.http.post('/api/v1.0/catalog/professions/reloadprofessionselasticindex').pipe(
      map(r => r),
      catchError((err: HttpErrorResponse) => {
        return Helper.handleError(err);
      }),
    );
  }

  public getStatusSendResultsByProviderId(providerId: string): Observable<IStatusSendResult[] | IStatus> {
    return this.http.get(`/saas/v1.0/admins/getstatussendresultsbyproviderid?providerId=${providerId}`).pipe(
      map(r => (Array.isArray(r?.results) ? r.results : r)),
      catchError((err: HttpErrorResponse) => {
        return Helper.handleError(err);
      }),
    );
  }

  public sendUserResultsToProvider(userParameters: IUserParameters): Observable<void> {
    return this.http.post(`/saas/v1.0/admins/senduserresultstoprovider`, userParameters).pipe(
      catchError((err: HttpErrorResponse) => {
        return Helper.handleError(err);
      }),
    );
  }

  public generateAnswersReportExcel(data: IAnswersMatrixData): Observable<any> {
    const { regionId, municipalityId, dateFrom, dateTo, screeningTestId, variantIds }: IAnswersMatrixData = data;

    const requestParams: IAnswersMatrixData = {
      regionId: regionId ? regionId : null,
      municipalityId: municipalityId ? municipalityId : null,
      dateFrom: dateFrom ? dateFrom : null,
      dateTo: dateTo ? dateTo : null,
      screeningTestId: screeningTestId ? screeningTestId : null,
      variantIds: variantIds ? variantIds : null,
      isAllVariants: variantIds && !variantIds.length,
      isAllRegions: !regionId,
      isAllMunipalitys: !municipalityId,
      isAllDate: !dateFrom,
    };

    return this.http.postFileDownload(`/saas/v1.0/reports/generatematrixanswersreportexcel`, requestParams).pipe(
      map(r => r),
      catchError((err: HttpErrorResponse) => Helper.handleError(err)),
    );
  }

  public getTestsVariantsInfo(): Observable<IShortTest[]> {
    return this.http.get('/api/v1.0/player/tests/gettestsvarinatsinfo').pipe(
      map(r => r.tests),
      catchError((err: HttpErrorResponse) => Helper.handleError(err)),
    );
  }
}
