import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { SharedModule } from 'app/shared/shared.module';
import { EditUserProfileOutletComponent } from './user-profile-outlet/edit-user-profile-outlet/edit-user-profile-outlet.component';
import { UserProfileOutletComponent } from './user-profile-outlet/user-profile-outlet.component';
import { UserProfileOutletService } from './user-profile-outlet/user-profile-outlet.service';
import { TextMaskModule } from '@matheo/text-mask';

@NgModule({
  imports: [BrowserModule, RouterModule, SharedModule, TextMaskModule],
  declarations: [UserProfileOutletComponent, EditUserProfileOutletComponent],
  providers: [UserProfileOutletService],
  bootstrap: [],
})
export class UserProfileModule {}
