import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import {
  IChoosesProfessionsReport,
  IGeneratedRegionReportByEmailRequest,
  IGeneratedRegionReportByEmailResponse,
} from './b2g-reports.interface';
import { B2gReportsUrls } from './b2g-reports.collections';
import { HttpService } from '../http';

@Injectable({
  providedIn: 'root',
})
export class B2gReportsService {
  private readonly httpService: HttpService = inject(HttpService);

  public getGeneratedRegionReportByEmail(data: IGeneratedRegionReportByEmailRequest): Observable<IGeneratedRegionReportByEmailResponse> {
    return this.httpService.post<IGeneratedRegionReportByEmailResponse>(B2gReportsUrls.GetGeneratedRegionReportByEmail, data).pipe(take(1));
  }

  public updateRegionStatistic(regionId, language?) {
    return this.httpService.post(B2gReportsUrls.UpdateRegionStatisticExcel, { regionId, language }).pipe(take(1));
  }

  public updateMunicipalityStatistic(regionId, municipalityId, language) {
    return this.httpService.post(B2gReportsUrls.UpdateRegionStatisticExcel, { regionId, municipalityId, language }).pipe(take(1));
  }

  public getRegionStatisticExcel(regionId, language?) {
    return this.httpService.get(B2gReportsUrls.GetRegionStatisticExcel, { regionId, language }).pipe(take(1));
  }

  public getMunicipalityStatisticExcel(regionId, municipalityId, language): Observable<any> {
    return this.httpService.get(B2gReportsUrls.GetRegionStatisticExcel, { regionId, municipalityId, language }).pipe(take(1));
  }

  public updateRegionPupilsStatistic(regionId) {
    return this.httpService.post(B2gReportsUrls.UpdatePupilUserInfo, { regionId }).pipe(take(1));
  }

  public getRegionPupilsStatisticExcel(regionId) {
    return this.httpService.get(B2gReportsUrls.GetPupilUserInfoExcel, { regionId }).pipe(take(1));
  }

  public generateReportProfessionsFromField(schoolClassId: string): Observable<IChoosesProfessionsReport> {
    return this.httpService
      .post<IChoosesProfessionsReport>(B2gReportsUrls.GenerateReportProfessionsFromField, { schoolClassId })
      .pipe(take(1));
  }

  public getReportProfessionsFromField(schoolClassId: string): Observable<any> {
    return this.httpService.post(B2gReportsUrls.GetReportProfessionsFromField, { schoolClassId }).pipe(take(1));
  }
}
