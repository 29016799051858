import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { HttpService } from 'app/core/services/http.service';
import { IProgrammCard } from 'app/shared/interfaces/iprogrammcard.interface';
import { ResultsService } from 'app/shared/services/results.service';
import { catchError, map, takeUntil } from 'rxjs/operators';
import { Stands } from 'app/shared/enums/stands.enums';
import { Observable, of, Subject, throwError, TimeoutError } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';

export interface IAppData {
  name: string;
  description: string;
  audience: number[];
  unitDiscount: number;
  unitPrice: number;
  realPrice: number;
}

export interface IAgeRange {
  startRange: string;
  endRange: string;
}

//методы для пользователей (эластик)
@Injectable()
export class CourseService {
  private courseForm: any = null;
  private cardsCache = {};
  private audienceTextRanges = {
    ZeroPlus: {
      startRange: '0',
      endRange: '',
    },
    FourSix: {
      startRange: '2',
      endRange: '6',
    },
    SevenTen: {
      startRange: '7',
      endRange: '10',
    },
    ElevenThirteen: {
      startRange: '11',
      endRange: '13',
    },
    FourteenSixteen: {
      startRange: '14',
      endRange: '16',
    },
    SixteenPlus: {
      startRange: '16',
      endRange: '',
    },
  };

  private ngUnsubscribe$: Subject<any> = new Subject();

  constructor(private http: HttpService, private resultsService: ResultsService, private translateService: TranslateService) {
    this.getTranslations([
      'SHARED.EVENT_OR_MASTERCLASS',
      'SHARED.EXCURSION',
      'SHARED.SHORT_TERM_COURSE',
      'SHARED.LONG_TERM_COURSE',
      'SHARED.CAMPSITE',
      'SHARED.AND_OLDER',
    ])
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe(translations => {
        this.courseForm = {
          EventOrMasterClass: translations['SHARED.EVENT_OR_MASTERCLASS'],
          Excursion: translations['SHARED.EXCURSION'],
          ShortCourse: translations['SHARED.SHORT_TERM_COURSE'],
          LongCourse: translations['SHARED.LONG_TERM_COURSE'],
          Camping: translations['SHARED.CAMPSITE'],
        };
        this.audienceTextRanges.ZeroPlus.endRange = translations['SHARED.AND_OLDER'];
        this.audienceTextRanges.SixteenPlus.endRange = translations['SHARED.AND_OLDER'];
      });
  }

  // обработка ошибок
  handleError(err: any): Observable<any> {
    if (err instanceof TimeoutError) {
      console.error(`Frontend returned timeout error: ${err['error']}`);
      return throwError(err['error']);
    }
    if (err.error instanceof Error) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', err.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(`Backend returned code ${err.status}, body was: ${err.error}`);
      let errorText = err.error ? (err.error.comment ? err.error.comment : err.error) : null;
    }
    throw throwError(err);
  }

  // private audienceRanges = [ // In case of number as bit mask for age ranges
  //   {
  //     key: 1,
  //     startRange: '0',
  //     endRange: 'и старше'
  //   },
  //   {
  //     key: 2,
  //     startRange: '2',
  //     endRange: '6',
  //   },
  //   {
  //     key: 4,
  //     startRange: '7',
  //     endRange: '10'
  //   },
  //   {
  //     key: 8,
  //     startRange: '11',
  //     endRange: '13'
  //   },
  //   {
  //     key: 16,
  //     startRange: '14',
  //     endRange: '16'
  //   },
  //   {
  //     key: 32,
  //     startRange: '16',
  //     endRange: 'и старше'
  //   }
  // ]

  public getClassByHRId(hrid: string) {
    return this.http.get(`/api/v1.0/catalog/classes/mongo/onebyhrid?hrid=${hrid}`).pipe(map(response => response));
  }

  removeCurrentClass(classID: any): Observable<any> {
    return this.http.delete('/api/v1.0/catalog/classes/elasticsearch/delete?classesId=' + classID).pipe(
      map(r => r),
      catchError((err: HttpErrorResponse) => {
        if (err.status == 404) {
          return of('NOT_FOUND');
        }
        return this.handleError(err);
      }),
    );
  }

  getSimilarEventsOrCourses(courses: string[], isCourse: boolean) {
    let typeUrl = '&ClassesFormat=ShortCourse&ClassesFormat=LongCourse';
    if (!isCourse) {
      typeUrl =
        '&ClassesFormat=MasterClass&ClassesFormat=Excursion&ClassesFormat=Festival&ClassesFormat=Action&ClassesFormat=Meeting&ClassesFormat=Competition&ClassesFormat=Profproba&ClassesFormat=OpenDay';
    }
    let talentsRequest = courses.map(t => encodeURI(t)).join('&Courses=');
    let municipalityParams = `&municipalityId=${localStorage.getItem('municipalityId')}`;

    let parameters = `?Courses=${talentsRequest}&From=0&Size=13` + typeUrl + municipalityParams;
    parameters += `&Stand=${Stands.Talent}`;

    return this.http.post(`/api/v1.0/catalog/classes/elasticsearch/search/filters` + parameters).pipe(map(response => response));
  }

  public getOtherEventsOrCourses(institutionId: string, isCourse: boolean) {
    let typeUrl = '&ClassesFormat=ShortCourse&ClassesFormat=LongCourse';
    if (!isCourse) {
      typeUrl =
        '&ClassesFormat=MasterClass&ClassesFormat=Excursion&ClassesFormat=Festival&ClassesFormat=Action&ClassesFormat=Meeting&ClassesFormat=Competition&ClassesFormat=Profproba&ClassesFormat=OpenDay';
    }
    let municipalityParams = `&municipalityId=${localStorage.getItem('municipalityId')}`;

    let parameters = `?InstitutionId=${institutionId}&From=0&Size=4` + typeUrl + municipalityParams;
    parameters += `&Stand=${Stands.Talent}`;

    return this.http.post(`/api/v1.0/catalog/classes/elasticsearch/search/filters` + parameters).pipe(map(response => response));
  }

  public calcRealPrice(program: IAppData) {
    let discount = +program.unitDiscount;
    let price = +program.unitPrice;
    if (discount <= 0) {
      return price;
    }
    return price - price * discount * 0.01;
  }

  // public getAudienceRange(value) {  // In case of number as bit mask for age ranges
  //   let
  //     startRange: String = null,
  //     endRange: String = null;
  //   value = +value;
  //   this.audienceRanges.forEach(r => {
  //     if ((value & r.key) == r.key) {
  //       startRange = startRange || r.startRange;
  //       endRange = r.endRange
  //     }
  //   })
  //   return [startRange, endRange].join(' - ');
  // }

  public getAudienceRange(values: string[]): Observable<any> {
    let startRange: string = null;
    let endRange: string = null;
    let range: IAgeRange;
    values.forEach(v => {
      range = this.audienceTextRanges[v];
      startRange = startRange || range.startRange;
      endRange = range.endRange;
    });
    // TODO нет корректного ответа для английского языка
    return this.getTranslations(['SHARED.FROM', 'SHARED.AND_OLDER', 'SHARED.BEFORE', 'COURCES.CATALOG.YEARS']).pipe(
      map(translations => {
        let string =
          `
        ${translations['SHARED.FROM']} ${startRange}
        ` +
          `${
            endRange == translations['SHARED.AND_OLDER']
              ? ' ' + endRange
              : ` ${translations['SHARED.BEFORE']} ` + endRange + ` ${translations['COURCES.CATALOG.YEARS']}`
          }`;

        return string;
      }),
      takeUntil(this.ngUnsubscribe$),
    );
  }

  public getClassesFormat(code) {
    return this.courseForm[code];
  }

  public transformCoursesToProgrammCards(courses: any[]) {
    return courses.map(course => {
      if (this.cardsCache[course.id] != null) {
        return this.cardsCache[course.id];
      }
      let card: IProgrammCard = {
        id: null,
        descr: '',
        discount: 0,
        firstUserLike: false,
        secondUserLike: false,
        imageSrc: null,
        name: '',
        place: '',
        price: 0,
        title: '',
        url: null,
        discountPrice: 0,
        classFormat: '',
        hrid: '',
        shortDescription: '',
      };
      card.id = course.id;
      card.descr = course.description;
      card.shortDescription = course.shortDescription;
      card.discount = +course.unitDiscount;
      card.imageSrc = course.mainImagePath
        ? document.location.origin + course.mainImagePath
        : course.courseGroups.length
        ? this.resultsService.programmsImages[course.courseGroups[0]]
        : '';
      card.name = course.name;
      card.place = course.address;
      card.price = +course.unitPrice;
      card.title = course.name;
      card.url = course.url;
      card.discountPrice = this.calcRealPrice(course);
      card.classFormat = this.courseForm[course.classesFormat];
      card.hrid = course.hrid;
      this.cardsCache[card.id] = card;
      return card;
    });
  }

  public isRu() {
    return this.translateService.currentLang === 'ru';
  }

  getTranslations(keys: string[]): Observable<any> {
    return this.translateService.get(keys);
  }

  ngOnDestroy() {
    this.ngUnsubscribe$.next(null);
    this.ngUnsubscribe$.complete();
  }
}
