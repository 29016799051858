import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpService } from 'app/core/services/http.service';
import { IExtendedPupil } from 'app/shared/interfaces/iextendedpupil.interface';
import { IProfessionFilter } from 'app/shared/interfaces/iprofessionfilter.interface';
import { of, throwError, TimeoutError, Observable, BehaviorSubject } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { UserControlService } from '../../backend-services/user-control.service';

@Injectable({
  providedIn: 'root',
})
export class AdminClassService {
  private showPupil = new BehaviorSubject({ showPupilPage: false });
  private editPupils = new BehaviorSubject({ editPupils: false });

  constructor(private http: HttpService, private userControlService: UserControlService) {}
  // обработка ошибок
  handleError(err: any): Observable<any> {
    if (err instanceof TimeoutError) {
      console.error(`Frontend returned timeout error: ${err['error']}`);
      return throwError(err['error']);
    }
    if (err.error instanceof Error) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', err.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(`Backend returned code ${err.status}, body was: ${err.error}`);
      let errorText = err.error ? (err.error.comment ? err.error.comment : err.error) : null;
    }
    throw throwError(err);
  }

  getSchoolClass(classID): Observable<any> {
    let apiControlName: string = this.userControlService.userControlDomain;

    return this.http.get(`/saas/v1.0/${apiControlName}/getschoolclass?schoolClassId=` + classID).pipe(
      map(r => r),
      catchError((err: HttpErrorResponse) => {
        const CANT_FIND = "Can't find schoolclass";
        let include = err.error.Message.includes(CANT_FIND);
        if (include) {
          return 'CANT_FIND';
        } else {
          return throwError(err);
        }
      }),
    );
  }

  getTeacher(schoolClassId: string, schoolId: string): Observable<any> {
    let url = '';
    let apiControlName: string = this.userControlService.userControlDomain;
    switch (apiControlName) {
      case 'admins':
        url = `/saas/v1.0/admins/getteacher?schoolId=${schoolId}&schoolClassId=${schoolClassId}`;
        break;
      case 'schooladmins':
        url = `/saas/v1.0/schooladmins/getteacher?schoolClassId=${schoolClassId}`;
        break;
      default:
        url = `​/saas​/v1.0​/saas​/getteacher?schoolClassId=${schoolClassId}`;
        break;
    }
    return this.http.get(url).pipe(
      map(r => r),
      catchError((err: HttpErrorResponse) => {
        return this.handleError(err);
      }),
    );
  }

  getExtendedPupils(schoolClassId: any): Observable<IExtendedPupil[]> {
    let apiControlName: string = this.userControlService.userControlDomain;
    return this.http.get(`/saas/v1.0/${apiControlName}/getextendedpupils?schoolClassId=` + schoolClassId).pipe(
      map(r => r),
      catchError((err: HttpErrorResponse) => {
        return this.handleError(err);
      }),
    );
  }

  getPupils(schoolClassId: any): Observable<any[]> {
    let apiControlName: string = this.userControlService.userControlDomain;
    return this.http.get(`/saas/v1.0/${apiControlName}/getpupilsbyclass?schoolClassId=` + schoolClassId).pipe(
      map(r => r),
      catchError((err: HttpErrorResponse) => {
        return this.handleError(err);
      }),
    );
  }

  getUserProfessionsInGoalsByIdsMongo(ids: any): Observable<any> {
    let url = '/api/v1.0/catalog/professions/mongo/search/filters';
    let credentials: { filters: IProfessionFilter } = { filters: {} };
    if (ids.length) {
      credentials.filters.ids = [];
      ids.forEach(id => {
        credentials.filters.ids.push(id);
      });
    } else {
      //если нет ids, то возвращаем []
      return of([]);
    }
    return this.http.post(url, credentials).pipe(
      map(r => r.professions),
      catchError((err: HttpErrorResponse) => {
        return this.handleError(err);
      }),
    );
  }
  getUserProfessionsInGoalsByIdsElastic(ids: any): Observable<any> {
    let url = '/api/v1.0/catalog/professions/elasticsearch/search/filters';
    let credentials: IProfessionFilter = {};
    if (ids.length) {
      credentials.ids = [];
      ids.forEach(id => {
        credentials.ids.push(id);
      });
    } else {
      return of([]);
    }
    return this.http.post(url, credentials).pipe(
      map(r => r),
      catchError((err: HttpErrorResponse) => {
        return this.handleError(err);
      }),
    );
  }

  showPupilPage() {
    this.showPupil.next({ showPupilPage: true });
  }

  closePupilPage() {
    this.showPupil.next({ showPupilPage: false });
  }

  getPupilPage(): Observable<{ showPupilPage: boolean }> {
    return this.showPupil.asObservable();
  }
}
