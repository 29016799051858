<ng-container *ngIf="loaded">
  <h2 class="professions-h2">{{ 'SHARED.RECOMMENDED_PROFESSIONS' | translate }}</h2>

  <div>
    <div class="professions-menu">
      <div class="professions-menu-row-2">
        <a
          (click)="setProfessionMenu('testResults')"
          (mouseover)="showMenuPopupResult = true"
          (mouseleave)="showMenuPopupResult = false"
          [class.w--current]="professionMenu === 'testResults'"
          class="professions-menu-link"
        >
          <div>{{ 'SHARED.RESULT.BY_TEST' | translate }}</div>
        </a>
        <a
          (click)="setProfessionMenu('fromParents')"
          (mouseover)="showMenuPopupParent = true"
          (mouseleave)="showMenuPopupParent = false"
          [class.w--current]="professionMenu === 'fromParents'"
          [class.no-active]="parentProfessionIdList.length === 0"
          class="professions-menu-link"
        >
          <div>От родителей</div>
          <div
            *ngIf="showMenuPopupParent && parentProfessionIdList.length === 0"
            class="info-popup recommendation w-hidden-small w-hidden-tiny"
          >
            <p>{{ 'SHARED.NOT_RECOMMENDATION_YET' | translate }}</p>
            <img src="./assets/images/icons/info-triangle.png" width="12" class="info-triangle" />
          </div>
        </a>
      </div>
    </div>

    <prf-parent-test-panel
      *ngIf="professionMenu === 'testResults' && resultProfessionIdList.length === 0"
      [pupilUserId]="userId"
      [isPupil]="true"
    >
    </prf-parent-test-panel>

    <div *ngIf="professionIdList.length > 0" class="container-swiper">
      <swiper-container
        prfSwiperElement
        init="false"
        #swiperComponent
        [config]="swiperConfig"
        class="professions-row"
      >
        <swiper-slide class="swiper-slide" *ngFor="let profession of professionListBySource">
          <prf-profession-card-new [profession]="profession"></prf-profession-card-new>
    </swiper-slide>

    </swiper-container>

      <a (click)="prevPage()" [class.hide]="!isEnabledPrev()" [class.back]="isEnabledPrev()" class="arrow-link prev w-inline-block">
        <img src="./assets/images/icons/back_1.svg" />
      </a>

      <a (click)="nextPage()" [class.hide]="!isEnabledNext()" [class.back]="isEnabledNext()" class="arrow-link next w-inline-block">
        <img src="./assets/images/icons/next_1.svg" />
      </a>
    </div>
  </div>

  <div class="profession-line"></div>
</ng-container>
