import { Component, ElementRef, EventEmitter, HostListener, Input, Output, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ICity } from 'app/shared/interfaces/icity';
import { IMunicipality } from 'app/shared/interfaces/imunicipality';
import { IRegion } from 'app/shared/interfaces/iregion';
import { catchError, concatMap, map, switchMap, takeUntil, tap, toArray } from 'rxjs/operators';
import { IAllSchoolsStatistic } from '../../../../../shared/interfaces/iallschoolsstatistic';
import { ISchool } from '../../../../../shared/interfaces/ischool.interface';
import { UtilsService } from 'app/shared/dashboard/backend-services/utils.service';
import { AdminPanelService } from '../../admin-panel.service';
import { CitiesService } from '../../cities/cities.service';
import { MunicipalitiesService } from '../../municipalities/municipalities.service';
import { RegionsService } from '../../regions/regions.service';
import { forkJoin as observableForkJoin, from, Observable, of, Subject } from 'rxjs';
import { ISchoolClass } from 'app/shared/interfaces/ischoolclass.interface';
import {
  ICourseMaterial,
} from 'app/pages/catalog/career-education-courses-page/interfaces/career-education-courses.interface';
import { IClass } from 'app/shared/interfaces/iclass.interface';
import { SharedService } from 'app/shared/shared.service';
import { LessonsService } from 'app/pages/control-panel/admin/lessons/lessons.service';

@Component({
  selector: 'prf-edit-school',
  templateUrl: './edit-school.component.html',
  styleUrls: ['./edit-school.component.scss'],
})
export class EditSchoolComponent {
  @Input() set setCurrentSchool(school: IAllSchoolsStatistic) {
    observableForkJoin([
      this.getSchoolByID(school.schoolId),
      this.getRegions(),
      this.getSchoolClasses(school.schoolId),
      this.getAllCourseMaterials(),
    ])
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe(([school, regions, classes, courseMaterials]) => {
        this.schoolClasses = classes;
        this.formBuilder();
        this._loaded = true;
      });
  }
  @Output() closeEditing = new EventEmitter<boolean>();

  _form: UntypedFormGroup;
  _loaded: boolean = false;
  _submitted: boolean = false;

  school: ISchool;
  schoolClasses: any[];
  courseMaterials: ICourseMaterial[];

  _types: string[] = ['Государственная школа', 'Частная школа'];

  public buttonWaiting: boolean = false;
  public buttonActivated: boolean = false;
  public buttonActivate: boolean = false;

  public allRegions: IRegion[] = [];
  public currentRegion: IRegion = {
    id: '',
    hrid: '',
    name: '',
  };
  public municipalitiesByRegion: IMunicipality[] = [];
  public currentMunicipality: IMunicipality = {
    id: '',
    hrid: '',
    name: '',
    regionId: '',
  };
  public citiesByMunicipality: ICity[] = [];
  public currentCity: ICity = {
    id: '',
    name: '',
    hrid: '',
    region: '',
    regionId: '',
    municipality: '',
    municipalityId: '',
  };

  public selectedType: any;

  private ngUnsubscribe$: Subject<any> = new Subject();

  @ViewChild('cityFilter') public readonly cityFilter: ElementRef;
  @ViewChild('cityDrop') public readonly cityDrop: ElementRef;
  @ViewChild('cityField') public readonly cityField: ElementRef;

  @ViewChild('regionFilter') public readonly regionFilter: ElementRef;
  @ViewChild('regionDrop') public readonly regionDrop: ElementRef;
  @ViewChild('regionField') public readonly regionField: ElementRef;

  @ViewChild('municipalityFilter') public readonly municipalityFilter: ElementRef;
  @ViewChild('municipalityDrop') public readonly municipalityDrop: ElementRef;
  @ViewChild('municipalityField') public readonly municipalityField: ElementRef;

  @ViewChild('typeFilter') public readonly typeFilter: ElementRef;
  @ViewChild('typeDrop') public readonly typeDrop: ElementRef;
  @ViewChild('typeField') public readonly typeField: ElementRef;

  constructor(
    private fb: UntypedFormBuilder,
    private adminPanelService: AdminPanelService,
    private regionsService: RegionsService,
    private municipalitiesService: MunicipalitiesService,
    private citiesService: CitiesService,
    private utilsService: UtilsService,
    private lessonsService: LessonsService,
    private sharedService: SharedService,
  ) {}

  ngOnDestroy() {
    this.ngUnsubscribe$.next(null);
    this.ngUnsubscribe$.complete();
  }

  getSchoolByID(schoolId): Observable<any> {
    return this.adminPanelService.getSchoolByID(schoolId).pipe(
      switchMap(school => {
        this.school = school;
        return this.getSchoolCity(this.school.cityId);
      }),
    );
  }

  getSchoolClasses(schoolId: string) {
    return this.sharedService
      .getSchoolClassesExtended(schoolId)
      .pipe(switchMap(schoolClasses => this.getSchoolClassCourses(schoolClasses)));
  }

  getSchoolClassCourses(schoolClasses: ISchoolClass[]) {
    const classesIds: string[] = schoolClasses.map(schoolClass => schoolClass.id);
    return from(classesIds).pipe(
      concatMap(id => this.lessonsService.getSchoolClassCourses(id).pipe(catchError(() => of(null)))),
      map((course: IClass, index: number) => [schoolClasses[index], course?.courseIds?.[0] ?? null]),
      toArray(),
    );
  }

  getAllCourseMaterials() {
    return this.lessonsService.getCourseMaterials().pipe(tap(courseMaterials => (this.courseMaterials = courseMaterials)));
  }

  getSchoolCity(cityId): Observable<any> {
    return this.citiesService.getCity(cityId).pipe(
      switchMap(city => {
        this.currentCity = city;

        return observableForkJoin(
          this.municipalitiesService.getMunicipality(this.currentCity.municipalityId),
          this.regionsService.getRegion(this.currentCity.regionId),
        ).pipe(
          tap(([municipality, region]) => {
            this.currentMunicipality = municipality;
            this.currentRegion = region;
          }),
        );
      }),
    );
  }

  getRegions(): Observable<any> {
    return this.regionsService.getAllRegions().pipe(
      tap(r => {
        this.allRegions = r;
      }),
    );
  }

  selectRegion(region) {
    this.setRegion(region).pipe(takeUntil(this.ngUnsubscribe$)).subscribe();
  }

  setRegion(region): Observable<any> {
    this.currentRegion = region;
    return this.municipalitiesService.getMunicipalitiesByRegion(region.id).pipe(
      tap(r => {
        this.municipalitiesByRegion = r;
        this.f.region.setValue(this.currentRegion.name);
        this.clearCurrentMunicipality();
        this.clearCurrentCity();
        this.citiesByMunicipality = [];
      }),
    );
  }

  selectMunicipality(municipality) {
    this.setMunicipality(municipality).pipe(takeUntil(this.ngUnsubscribe$)).subscribe();
  }

  setMunicipality(municipality): Observable<any> {
    this.currentMunicipality = municipality;
    return this.citiesService.getAllCitiesByMunicipality(municipality.id).pipe(
      tap(r => {
        this.citiesByMunicipality = r;
        this.f.municipality.setValue(this.currentMunicipality.name);
        this.clearCurrentCity();
      }),
    );
  }

  setCity(city) {
    if (city) {
      this.currentCity = city;
      this.f.city.setValue(this.currentCity.name);
    }
    return;
  }

  onAssignCourseForClass([schoolClassId, courseId]): void {
    this.lessonsService.assignCourseForSchoolClass(schoolClassId, courseId).pipe(takeUntil(this.ngUnsubscribe$)).subscribe();
  }

  onUpdateCourseForClass([schoolClassId, courseId]): void {
    this.lessonsService.updateCourseForSchoolClass(schoolClassId, courseId).pipe(takeUntil(this.ngUnsubscribe$)).subscribe();
  }

  onDeleteCourseForClass(schoolClassId): void {
    this.lessonsService.deleteCourseForSchoolClass(schoolClassId).pipe(takeUntil(this.ngUnsubscribe$)).subscribe();
  }

  formBuilder() {
    this._form = this.fb.group({
      id: new UntypedFormControl(this.school.id, [Validators.required]),
      region: new UntypedFormControl(this.currentRegion.name, [Validators.required]),
      municipality: new UntypedFormControl(this.currentMunicipality.name, [Validators.required]),
      city: new UntypedFormControl(this.currentCity.name, [Validators.required]),
      number: new UntypedFormControl(this.school.number, [Validators.required]),
      address: new UntypedFormControl(this.school.address, [Validators.required]),
      type: new UntypedFormControl(this.school.type, [Validators.required]),
      other: new UntypedFormControl(this.school.other, [
        // Validators.required,
      ]),
      urlMskObr: new UntypedFormControl(this.school.urlMskObr, [
        // Validators.required,
      ]),
      externalObjectId: new UntypedFormControl(this.school.externalObjectId, []),
      externalServiceName: new UntypedFormControl(this.school.externalServiceName, []),
      isDeleted: new UntypedFormControl(this.school.isDeleted, []),
      createdDate: new UntypedFormControl(this.school.createdDate, []),
    });
  }

  get f() {
    return this._form.controls;
  }

  fieldFocusOutRegion() {
    const region = this.allRegions.filter(t => t === this._form.value.region);
    if (region.length == 0) {
      this.f.region.setValue('');
    }
  }

  fieldFocusOutMunicipality() {
    const municipality = this.municipalitiesByRegion.filter(t => t === this._form.value.municipality);
    if (municipality.length == 0) {
      this.f.municipality.setValue('');
    }
  }

  fieldFocusOutCity(e) {
    const city = this.citiesByMunicipality.filter(t => t === this._form.value.city);
    if (city.length == 0) {
      this.f.city.setValue('');
    }
  }

  /*region*/
  fieldFocusRegion(e) {
    if (this._form.value.region) {
      this.f.region.setValue('');
    }
  }

  checkRegionHeader(): any {
    if (this.currentRegion) {
      return this.allRegions.find(el => el === this.currentRegion);
    }
  }

  checkMunicipalityHeader(): any {
    if (this.currentMunicipality) {
      return this.municipalitiesByRegion.find(el => el === this.currentMunicipality);
    }
  }

  checkCityHeader(): any {
    if (this.currentCity) {
      return this.citiesByMunicipality.find(el => el === this.currentCity);
    }
  }

  clearCurrentMunicipality() {
    this.currentMunicipality = {
      id: '',
      hrid: '',
      name: '',
      regionId: '',
    };
    this.f.municipality.setValue('');
    return;
  }

  clearCurrentCity() {
    this.currentCity = {
      id: '',
      hrid: '',
      name: '',
      region: '',
      regionId: '',
      municipality: '',
      municipalityId: '',
    };
    this.f.city.setValue('');
    return;
  }

  clearTerritoryData() {
    this.currentRegion = {
      id: '',
      hrid: '',
      name: '',
    };
    this.municipalitiesByRegion = [];
    this.currentMunicipality = {
      id: '',
      hrid: '',
      name: '',
      regionId: '',
    };
    this.citiesByMunicipality = [];
    this.currentCity = {
      id: '',
      name: '',
      hrid: '',
      region: '',
      regionId: '',
      municipality: '',
      municipalityId: '',
    };
    return;
  }

  // setRegion(region: any) {
  //   this.f.region.setValue(region);
  //   this.currentRegion = region;
  // }

  /*type*/
  fieldFocusType(e) {
    if (this._form.value.type) {
      this.f.type.setValue('');
    }
  }

  fieldFocusOutType(e) {
    const type = this._types.filter(t => t === this._form.value.type);
    if (type.length == 0) {
      this.f.type.setValue('');
    }
  }

  checkTypeHeader(): any {
    if (this.selectedType) {
      return this._types.filter(el => el === this.selectedType);
    }
  }

  setType(type: any) {
    this.f.type.setValue(type);
    this.selectedType = type;
  }

  public isAccessAllowed() {
    return this._form.value.number && this._form.value.city && this._form.value.type && this._form.value.address;
  }

  animateEdit() {
    this.buttonActivate = true;
    this.buttonWaiting = true;
    this.submit();
  }

  removeWaiting() {
    this.buttonWaiting = false;
    this.buttonActivated = true;
  }

  failWaiting() {
    this.buttonWaiting = false;
    this.buttonActivate = false;
  }

  submit() {
    this._submitted = true;
    if (this._form.valid) {
      this.school = {
        id: this._form.value.id,
        number: this._form.value.number,
        city: this.currentCity.name,
        cityId: this.currentCity.id,
        district: '',
        address: this._form.value.address,
        type: this._form.value.type,
        urlMskObr: this._form.value.urlMskObr,
        other: this._form.value.other,
        externalObjectId: this._form.value.externalObjectId,
        externalServiceName: this._form.value.externalServiceName,
        isDeleted: this._form.value.isDeleted,
        createdDate: this._form.value.createdDate,
      };

      this.adminPanelService
        .updateSchool(this.school)
        .pipe(takeUntil(this.ngUnsubscribe$))
        .subscribe(
          response => {
            this.removeWaiting();
            this.utilsService.openSnackBar('👌 Школа изменена', 'success');
            this.clearTerritoryData();
            return setTimeout(() => {
              this.closeEditing.emit(true);
            }, 900);
          },
          err => {
            this.failWaiting();
            return this.utilsService.openSnackBar('👎 Произошла ошибка, попробуйте позже', 'error');
          },
        );
    } else {
      this.failWaiting();
    }
  }

  @HostListener('document:click', ['$event.target'])
  checkClick(target) {
    if (this._loaded) {
      if (this.cityFilter && !this.cityFilter?.nativeElement.contains(target)) {
        this.cityDrop.nativeElement.classList.remove('w--open');
        this.cityField.nativeElement.classList.remove('opened');
      }
      if (this.regionFilter && !this.regionFilter?.nativeElement.contains(target)) {
        this.regionDrop.nativeElement.classList.remove('w--open');
        this.regionField.nativeElement.classList.remove('opened');
      }
      if (this.municipalityFilter && !this.municipalityFilter?.nativeElement.contains(target)) {
        this.municipalityDrop.nativeElement.classList.remove('w--open');
        this.municipalityField.nativeElement.classList.remove('opened');
      }
      if (this.typeFilter && !this.typeFilter?.nativeElement.contains(target)) {
        this.typeDrop.nativeElement.classList.remove('w--open');
        this.typeField.nativeElement.classList.remove('opened');
      }
    }
  }
}
