<div class="welcome-row-3">
  <div class="welcome-column-stud-2">
    <h3 class="welcome-h3-stud-2">
      <span class="header-text">У вас нет ни одной опубликованной стажировки </span>
    </h3>

    <div class="main-screen-buttons-row">
      <a (click)="navigateStartTest()" class="button welcome-button-stud-2 w-button"> Создать новую стажировку </a>
    </div>
  </div>

  <img src="./assets/images/dashboard/welc-1.svg" alt="" class="welcome-image-stud" />
</div>
