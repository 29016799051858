import { ChangeDetectorRef, Component, ElementRef, forwardRef, HostListener, Input } from '@angular/core';
import { ControlValueAccessor, UntypedFormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

interface selectItem {
  title: string;
  value: string;
}

@Component({
  selector: 'prf-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DropdownComponent), // replace name as appropriate
      multi: true,
    },
  ],
})
export class DropdownComponent implements ControlValueAccessor {
  @Input() itemsList: selectItem[] = [];
  control = new UntypedFormControl();

  selectedItem: selectItem;

  public open: boolean = false;

  @HostListener('document:click', ['$event'])
  clickout(event) {
    if (this.eRef && !this.eRef?.nativeElement.contains(event.target)) {
      if (this.open) {
        this.writeValue('');
      }
      this.open = false;
    }
  }

  constructor(private eRef: ElementRef, private cd: ChangeDetectorRef, matIconRegistry: MatIconRegistry, sanitizer: DomSanitizer) {
    matIconRegistry.addSvgIcon('icon_dropdown', sanitizer.bypassSecurityTrustResourceUrl('/assets/images/icons/icon_dropdown.svg'));
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  itemSelect(item: selectItem) {
    this.writeValue(item.value);
    this.onTouched();
    this.open = false;
  }

  toggleOpen() {
    this.open = !this.open;
  }
  get isOpen() {
    return this.open;
  }

  writeValue(value: any): void {
    const selectedEl = this.itemsList.find(el => el.value === value);
    this.selectedItem = selectedEl || null;
    selectedEl ? this.onChange(this.selectedItem.value) : this.onChange(null);
  }

  onTouched: any = () => {};
  onChange: any = () => {};
}
