<div class="w-tab-pane w--tab-active">
  <div class="tab-pane adm" *ngIf="currentUniversities">
    <div class="admin-menu">
      <div class="admin-menu-col">
        <a class="admin-add-event w-button" [routerLink]="['/admin/create-university']">{{ 'SHARED.ADD' | translate }}</a>
      </div>
    </div>

    <div class="courses-row-1">
      <div class="courses-number">{{ countUniversities(currentUniversities.length) }}</div>
    </div>

    <div class="catalog-events-row">
      <ng-container *ngIf="currentUniversities.length > 0; else emptyUniversities">
        <div class="events-column" [class.short-view]="viewValue" *ngFor="let university of currentUniversities">
          <prf-university-card [university]="university"></prf-university-card>
        </div>
      </ng-container>
    </div>
  </div>
</div>

<ng-template #emptyUniversities>
  <div class="event-empty-block">
    <div>
      <img src="./assets/images/menu/grey/Courses.svg" width="80" class="no-profession-icon" />
    </div>
    <div class="event-empty-block-text">
      {{ 'SHARED.FOR_YOU_REQUEST_NOT_UNIVERSITIES' | translate }}
    </div>
  </div>
</ng-template>
