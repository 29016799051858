import { Observable, throwError, TimeoutError } from 'rxjs';
import { EducationalLevels } from '../../../pages/catalog/programs-page/api-programs-spo-vpo/api-program-spo-vpo.collections';

export abstract class Helper {
  public static handleError = (err: any): Observable<any> => {
    if (err instanceof TimeoutError) {
      console.error(`Frontend returned timeout error: ${err['error']}`);

      return throwError(err['error']);
    }

    if (err?.error instanceof Error) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', err.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(`Backend returned code ${err.status}, body was: ${err.error}`);

      // let errorText = err?.error ? (err.error.comment ? err.error.comment : err.error) : null;
    }

    throw throwError(err);
  };

  public static isDefined(data: any): boolean {
    return typeof data !== 'undefined';
  }

  public static isString(data: any, isNotEmpty: boolean = false): boolean {
    return isNotEmpty ? typeof data === 'string' && !!data : typeof data === 'string';
  }

  public static scrollToTop(): void {
    window?.scrollTo(0, 0);
  }

  public static setEducationalLevel(value: any, returnShort: boolean = false): string {
    switch (value) {
      case EducationalLevels.SO:
        return returnShort ? 'СО' : 'Среднее образование';
      case EducationalLevels.SPO:
        return returnShort ? 'СПО' : 'Среднее профессиональное образование';
      case EducationalLevels.VO:
        return returnShort ? 'ВО' : 'Высшее образование';
      default:
        return 'Нет информации';
    }
  }
}
