<div class="reports-page" [formGroup]="form">
  <prf-full-diagnostic-report *ngIf="isFullDiagnosticReportAvailable" [regions]="regions" />
  <section formGroupName="regionReport">
    <div class="section-title">Отчёт по результатам тестирований</div>
    <div class="dropdown-container">
      <prf-dropdown-with-search
        formControlName="region"
        [label]="'SHARED.REGIONS' | translate"
        [options]="listedRegions"
      ></prf-dropdown-with-search>
      <prf-dropdown-with-search
        formControlName="municipality"
        [label]="'SHARED.MUNICIPALITIES' | translate"
        [options]="listedMunicipalities"
        [isOptional]="true"
      ></prf-dropdown-with-search>
    </div>
    <div class="create-event-row">
      <label class="section-text" for="regiondateFrom">Начальная дата диапазона обновления:</label>
      <input
        matInput
        [matDatepicker]="regionPickerStart"
        formControlName="dateFrom"
        class="create-event-field date w-input"
        maxlength="256"
        placeholder="Date"
        name="dateFrom"
        data-name="dateFrom"
        id="regiondateFrom"
      />
      <mat-datepicker-toggle matSuffix [for]="regionPickerStart"></mat-datepicker-toggle>
      <mat-datepicker #regionPickerStart></mat-datepicker>
    </div>
    <div class="create-event-row">
      <label class="section-text" for="regiondateTo">Конечная дата диапазона обновления <br />(если не указано, то текущее число):</label>
      <input
        matInput
        [matDatepicker]="regionPickerEnd"
        formControlName="dateTo"
        class="create-event-field date w-input"
        maxlength="256"
        placeholder="Date"
        name="dateTo"
        data-name="dateTo"
        id="regiondateTo"
      />
      <mat-datepicker-toggle matSuffix [for]="regionPickerEnd"></mat-datepicker-toggle>
      <mat-datepicker #regionPickerEnd></mat-datepicker>
    </div>
    <div *ngIf="generatingRegionReportIsOnProcess" class="create-event-row">
      <p>Когда отчёт будет готов, вам на почту {{ email$ | async }} придёт письмо</p>
    </div>
    <div class="section-row">
      <a
        class="button-animate btn-activate"
        [class.btn-waiting]="regionReportUpdating"
        [class.disabled-btn]="!checkDate(form.get('regionReport').get('dateFrom').value) || regionReportUpdating"
        (click)="getGeneratedRegionReportByEmail()"
      >
        <span class="btn-icon"></span>
        <span class="btn-text" data-wait="Подождите" data-after="Получен">Получить</span></a
      >
    </div>
  </section>
  <section>
    <div class="section-title">Ученики по региону</div>
    <div class="dropdown-container">
      <prf-dropdown-with-search
        formControlName="pupilsRegion"
        [label]="'SHARED.REGIONS' | translate"
        [options]="listedRegions"
      ></prf-dropdown-with-search>
    </div>
    <div class="section-row">
      <a
        class="btn-prf-outline"
        class="button-animate btn-activate"
        [class.btn-waiting]="regionPupilsUpdating"
        [class.disabled]="!form.get('pupilsRegion').value || regionPupilsPrepearing"
        (click)="updateRegionPupilsReport()"
      >
        <span class="btn-icon"></span>
        <span class="btn-text" data-wait="Подождите" data-after="Получен">Обновить данные</span></a
      >
      <a
        class="btn-prf-outline"
        class="button-animate btn-activate"
        [class.btn-waiting]="regionPupilsPrepearing"
        [class.disabled]="!form.get('pupilsRegion').value || regionPupilsUpdating"
        (click)="getRegionPupilsReport()"
      >
        <span class="btn-icon"></span>
        <span class="btn-text" data-wait="Подождите" data-after="Получен">Получить</span></a
      >
    </div>
  </section>

  <section formGroupName="municipalityReport">
    <div class="section-title">Отчет по муниципалитету</div>
    <div class="dropdown-container">
      <prf-dropdown-with-search
        formControlName="region"
        [label]="'SHARED.REGIONS' | translate"
        [options]="listedRegions"
      ></prf-dropdown-with-search>
    </div>
    <div class="dropdown-container">
      <prf-dropdown-with-search
        formControlName="municipality"
        [label]="'SHARED.MUNICIPALITIES' | translate"
        [options]="listedMunicipalities"
      ></prf-dropdown-with-search>
    </div>
    <div class="section-row">
      <a
        class="btn-prf-outline"
        class="button-animate btn-activate"
        [class.btn-waiting]="municipalityReportUpdating"
        [class.disabled]="!form.get('municipalityReport').get('municipality').value || municipalityReportPrepearing"
        (click)="updateMunicipalityReport()"
      >
        <span class="btn-icon"></span>
        <span class="btn-text" data-wait="Подождите" data-after="Получен">Обновить данные</span></a
      >
      <a
        class="btn-prf-outline"
        class="button-animate btn-activate"
        [class.btn-waiting]="municipalityReportPrepearing"
        [class.disabled]="!form.get('municipalityReport').get('municipality').value || municipalityReportUpdating"
        (click)="getMunicipalityReport()"
      >
        <span class="btn-icon"></span>
        <span class="btn-text" data-wait="Подождите" data-after="Получен">Получить</span></a
      >
    </div>
  </section>

  <section>
    <div class="section-title">Обновить данные по профессиям</div>
    <a class="btn-prf-outline" class="button-animate btn-activate" [class.btn-waiting]="professionsUpdating" (click)="updateProfessions()">
      <span class="btn-icon"></span>
      <span class="btn-text" data-wait="Подождите" data-after="Получен">Обновить данные</span></a
    >
  </section>

  <section>
    <div class="section-title">Получить cookies</div>
    <a class="btn-prf-outline" class="button-animate btn-activate" (click)="setCookiesToLocalStore()">
      <span class="btn-icon"></span>
      <span class="btn-text" data-wait="Подождите" data-after="Получен">Получить</span></a
    >
  </section>
</div>
