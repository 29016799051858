<ng-container>
  <div *ngIf="showDeletePopUp" class="popup-overlay overlay">
    <div class="popup-container">
      <div class="popup-title">Сброс пароля</div>
      <div class="popup-content">
        <div class="content-text">
          <div class="popup-text _1">Вы уверены, что хотите изменить учителю пароль на код для входа?</div>
          <div class="info-text">
            <img src="./assets/images/icons/info-purple.svg" class="" />
            <div class="popup-text _2">Если учитель изменит пароль в личном кабинете, вход по коду будет недоступен.</div>
          </div>
          <div class="buttons">
            <a class="button w-button popup-button _red" (click)="resetPasswordToTeacher()"> Сбросить </a>
            <a class="button w-button popup-button _white" (click)="closeResetPopUp()"> Отмена </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
<ng-container *ngIf="pupils && dataFetched">
  <div *ngIf="!showPupilPage; else pupilPage">
    <div class="content-row">
      <div class="content-schooladmin">
        <div class="class-number">
          <h1>
            {{ 'SHARED.CLASS_WITH_LETTER' | translate: { number: schoolClass.number, letter: schoolClass.letter } }}
          </h1>
          <a *ngIf="userRole == userRoles.schooladmin" class="school-edit-link" (click)="showEditClass()">{{
            'SHARED.EDIT_BUTTON' | translate
          }}</a>
        </div>
        <div class="teacher-container">
          <a
            class="class-photo w-inline-block"
            *ngIf="teacher"
            [ngStyle]="{
              'background-image': teacher.imagePath ? 'url(' + teacher.imagePath + ')' : ''
            }"
          >
          </a>
          <div class="teacher-container-item">
            <a class="teacher-container-lastName no-pointer" *ngIf="teacher">
              <span>{{ teacher.lastName }} {{ teacher.firstName }} {{ teacher.middleName }}</span>
            </a>
            <small>Классный руководитель</small>
          </div>
          <div class="teacher-code-registration" *ngIf="teacher?.registrationCode">
            <div>
              <span>{{ 'SHARED.ENTRANCE_CODE' | translate }} — </span>
              <span class="code">{{ teacher.registrationCode }}</span>
              <div class="info-div-class">
                <div class="info-icon" (mouseenter)="showElement($event)" (mouseleave)="hideElement($event)"></div>
                <div class="info-popup stud-code">
                  <p>Если учитель изменил пароль в личном кабинете, вход по коду будет недоступен.</p>
                  <img src="./assets/images/icons/info-triangle.png" width="12" class="info-triangle stud-code" />
                </div>
              </div>
            </div>
            <ng-container *ngIf="teacher?.isActivated; else notRegistered">
              <span>{{ teacher?.email }}</span>
              <a (click)="showResetPopUp()">{{ 'SHARED.RESET_PASSWORD' | translate }}</a>
            </ng-container>
          </div>
        </div>
        <div class="devider"></div>
        <div class="class-div" *ngIf="pupils.length > 0">
          <div class="students-edit">
            <h1>Ученики</h1>
            <a *ngIf="userRole == userRoles.schooladmin" class="school-edit-link" (click)="showEditPupils()">
              {{ 'SHARED.EDIT_BUTTON' | translate }}
            </a>
          </div>
          <div class="class-header _2" [class.fixed]="isFixed">
            <div class="class-row class-header-row-3">
              <div class="class-column _9"></div>
              <div class="class-column _4">
                <div>{{ pupils.length }} учеников</div>
              </div>
              <div class="class-column _8">
                <div>Профессия</div>
              </div>
              <div class="class-column _6">
                <div>Тест прошел</div>
                <div class="class-test-row">
                  <div class="class-test-column mobile-hidden">
                    <div>Дата последнего теста</div>
                  </div>
                  <div class="class-test-column">
                    <div>Родитель</div>
                  </div>
                  <div class="class-test-column">
                    <div>Ученик</div>
                  </div>
                  <div class="class-test-column">
                    <div></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="schooladmin-list-block _2">
            <div class="students-list">
              <!--Pupil-->
              <div
                class="class-list-item-2"
                *ngFor="let pupil of pupils; let i = index"
                [ngClass]="pupils.length === i ? 'no-underline' : ''"
              >
                <div>
                  <div class="class-row-2">
                    <!--Number-->
                    <div class="class-column _9">
                      <div class="class-header-number _2">{{ i + 1 }}</div>
                    </div>

                    <!--Check registration-->
                    <!--if registrated-->
                    <div class="class-row-div" *ngIf="pupil.isActivated; else notRegistered">
                      <div class="class-column _7">
                        <div class="class-column _2 _10">
                          <!--Avatar-->
                          <div class="class-photo-column">
                            <a
                              (click)="showSelectedPupil(pupil)"
                              class="class-photo w-inline-block"
                              [ngStyle]="{ 'background-image': 'url(' + getImageUrl(pupil) + ')' }"
                            ></a>
                            <!-- <a class="class-photo w-inline-block"></a> -->
                          </div>
                        </div>
                        <!--details-->
                        <div class="class-column _4">
                          <div class="student-name">
                            <a (click)="showSelectedPupil(pupil)" class="student-name-link"> {{ pupil.firstName }} {{ pupil.lastName }} </a>
                          </div>
                          <!--<div class="student-online-status">Была только что???</div>-->
                        </div>
                      </div>
                      <!-- Professions -->
                      <div class="class-column _8">
                        <div class="prof-row">
                          <ng-container *ngIf="favoriteProfessions && pupil.favoriteProfessions.length > 0">
                            <div
                              class="prof-icon"
                              *ngFor="let prof of pupil.favoriteProfessions | slice: 0:2"
                              routerLink="/professions/{{ prof.hrid }}"
                            >
                              <img
                                src="{{ getImagePath(prof) }}"
                                class="student-prof-icon"
                                (mouseenter)="showElement($event)"
                                (mouseleave)="hideElement($event)"
                              />
                              <div class="info-popup stud-prof">
                                <p>{{ prof.name }}</p>
                                <img src="./assets/images/icons/info-triangle.png" width="12" class="info-triangle stud-prof" />
                              </div>
                            </div>
                          </ng-container>

                          <!--<div class="prof-icon">-->
                          <!--<img src="./assets/images/icons/scheme-icon.svg"-->
                          <!--class="student-prof-icon"-->
                          <!--(mouseenter)="showElement($event)"-->
                          <!--(mouseleave)="hideElement($event)"-->
                          <!--&gt;-->
                          <!--<div class="info-popup stud-prof">-->
                          <!--<p>Оператор каршеринга</p>-->
                          <!--<img src="./assets/images/icons/info-triangle.png" width="12" class="info-triangle stud-prof">-->
                          <!--</div>-->
                          <!--</div>-->
                          <div class="prof-icon num" *ngIf="pupil.favoriteProfessions.length > 2">
                            {{ '+' + (pupil.favoriteProfessions.length - 2) }}
                          </div>
                        </div>
                      </div>
                      <!--Test-->
                      <div class="class-column _6">
                        <div class="class-test-row">
                          <!--last-test-date-->
                          <div class="class-test-column test mobile-hidden">
                            <a class="last-test-date">{{ pupil.lastTestDate | date: 'dd/MM/yyyy' }}</a>
                          </div>
                          <!--Parent-test-->
                          <div class="class-test-column test">
                            <div class="test-check" [class.complete]="pupil.lastParentSessionCompleted"></div>
                          </div>
                          <!--Pupil-Test-->
                          <div class="class-test-column test" *ngIf="!isTestOld(pupil.lastTestDate)">
                            <div class="test-check" [class.complete]="pupil.lastSessionCompleted"></div>
                          </div>
                          <div class="class-test-column test" *ngIf="isTestOld(pupil.lastTestDate)">
                            <div
                              class="test-check"
                              (mouseenter)="showElement($event)"
                              (mouseleave)="hideElement($event)"
                              [class.old]="pupil.lastSessionCompleted"
                            ></div>
                            <div class="info-popup test-date" [class.disabled]="!pupil.lastSessionCompleted || !isTestOld">
                              <p>Данные диагностики могли устареть.</p>
                            </div>
                          </div>
                        </div>
                        <!-- <div class="class-test-row">

                          <div class="test-check" [class.complete]="pupil.lastParentSessionCompleted"></div>
                          <div class="class-test-column test" *ngIf="pupil.lastParentSessionCompleted === true; else testNotCompleted">
                            <div class="test-check complete"></div>
                          </div>

                          <div class="test-check" [class.complete]="pupil.lastSessionCompleted"></div>
                          <div class="class-test-column test" *ngIf="pupil.lastSessionCompleted === true; else testNotCompleted">
                            <div class="test-check complete"></div>
                          </div>
                        </div> -->
                      </div>

                      <ng-template #testNotCompleted>
                        <div class="test-check"></div>
                      </ng-template>

                      <!--if last class class-line no-underline-->
                      <div class="class-line" [ngClass]="pupils.length === i ? 'no-underline' : ''"></div>
                    </div>

                    <!--if NOT registrated-->
                    <ng-template #notRegistered>
                      <div class="class-row-div">
                        <div class="class-column _7">
                          <div class="class-column _2 _10">
                            <div class="class-photo-column">
                              <a class="class-photo no-photo w-inline-block"></a>
                            </div>
                          </div>
                          <div class="class-column _4">
                            <div class="student-name">
                              <a class="student-name-link no-link">{{ pupil.firstName }} {{ pupil.lastName }}</a>
                            </div>
                            <div class="student-online-status">Не зарегистрирован</div>
                          </div>
                        </div>
                        <div class="class-column _10">
                          <div>
                            Код для входа:
                            <span class="student-code">{{ pupil.registrationCode }}</span>
                          </div>
                          <div class="info-div-class">
                            <div class="info-icon" (mouseenter)="showElement($event)" (mouseleave)="hideElement($event)"></div>
                            <div class="info-popup stud-code">
                              <p>Передайте этот код ученику для регистрации.</p>
                              <img src="./assets/images/icons/info-triangle.png" width="12" class="info-triangle stud-code" />
                            </div>
                          </div>
                          <!--Delete-pupil-->
                          <div class="class-test-column test">
                            <div class="delete-student w-inline-block" (click)="deleteStudent(pupil)"></div>
                          </div>
                        </div>
                        <div class="class-line"></div>
                      </div>
                    </ng-template>

                    <!--ContexMenu-->
                    <!--<div class="class-dropdown w-dropdown">
                      <div class="tabs-dropdown-toggle w-dropdown-toggle" (click)="nav.classList.toggle('w&#45;&#45;open')">
                        <img src="./assets/images/icons/dots-dd.svg" class="tabs-dropdown-icon">
                      </div>
                      <nav class="class-dropdown-list w-dropdown-list" #nav>
                        <div class="class-dd-bg">
                          <a class="class-dd-option" (click)="nav.classList.toggle('w&#45;&#45;open')">Опция 1</a>
                          <a class="class-dd-option" (click)="nav.classList.toggle('w&#45;&#45;open')">Опция 2</a>
                        </div>
                      </nav>
                    </div>-->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--if NOT HAVE pupils -->
        <div class="class-div" *ngIf="pupils.length == 0">
          <div class="empty-class">
            <div class="empty-class-item">
              <img src="./assets/images/control-panel/logo-empty.png" alt="" />
              <div class="info">Вы можете добавить учеников самостоятельно или это может сделать классный руководитель.</div>
              <a class="adding-students button w-button" (click)="showEditPupils()"> Добавить учеников </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>

<ng-template #notRegistered>
  <small>Не зарегистрирован</small>
</ng-template>

<ng-template #pupilPage>
  <prf-teacher-student-page [pupil]="selectedPupil"></prf-teacher-student-page>
</ng-template>
