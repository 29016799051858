import { inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { take, tap } from 'rxjs/operators';
import { B2gSaasService, IUserInfo } from '../../api-services';
import { StorageKeys, WebStorageService } from '../../logic-services';
import { IUserProfileInfo } from '../../core';
import { UsersRestrictionService } from '../user-restriction';

@Injectable({
  providedIn: 'root',
})
export class UserDataHandlerService {
  private _userData: BehaviorSubject<IUserInfo> = new BehaviorSubject<IUserInfo>(null);
  private readonly usersRestrictionService: UsersRestrictionService = inject(UsersRestrictionService);

  constructor(
    private b2gSaasService: B2gSaasService,
    private webStorageService: WebStorageService,
  ) {
    this.receiveUserDataFromSources();
  }

  public getUserData(): BehaviorSubject<IUserInfo> {
    return this._userData;
  }

  public updateUserInfo(): void {
    this.sendApiRequest().pipe(take(1)).subscribe();
  }

  private receiveUserDataFromSources(): void {
    if (this.webStorageService.get(StorageKeys.UserData)) {
      this._userData.next(this.webStorageService.get(StorageKeys.UserData));
    } else {
      this.updateUserInfo();
    }
  }

  private sendApiRequest(): Observable<IUserInfo> {
    return this.b2gSaasService.getUserInfo().pipe(
      take(1),
      tap((response: any) => {
        this._userData.next(response);
        this.webStorageService.set(StorageKeys.UserData, response);
      }),
    );
  }

  public isUserRestricted(): boolean {
    return this.usersRestrictionService.checkUserAccess(this._userData.getValue().regionId);
  }

  public getUserInfo(): IUserProfileInfo {
    return this.webStorageService.get(StorageKeys.UserProfileInfo);
  }
}
