import { Meta } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { FormControl, UntypedFormBuilder, Validators } from '@angular/forms';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { switchMap, take, takeUntil } from 'rxjs/operators';
import { combineLatest } from 'rxjs';

import { MatLegacyRadioChange as MatRadioChange } from '@angular/material/legacy-radio';

import { ILesson } from 'app/shared/interfaces/ilesson';
import { IFilter } from 'app/shared/common-components/dropdown-with-filter/dropdown-with-filter.component';
import { OverlayBusyService } from 'app/shared/overlay-busy/overlay-busy.service';
import { UtilsService } from 'app/shared/dashboard/backend-services/utils.service';
import { AdminPanelService } from 'app/pages/control-panel/admin/admin-panel.service';
import {
  IButterflyControlElement,
} from '../../../../../shared/common-components/butterfly-control/butterfly-control.component';
import { IProfession } from '../../../../../shared/interfaces/iprofession.interface';
import { RegionsService } from '../../regions/regions.service';
import { IRegion } from '../../../../../shared/interfaces/iregion';
import {
  EditLessonHelperComponent,
} from '../../../../../shared/common-components/helpers/edit-lesson-helper/edit-lesson-helper.component';
import { LessonsService } from '../lessons.service';
import { ApiFieldsService } from '@profilum-library';

export enum TaskTypes {
  RadioButton,
  CheckBox,
  Match,
  Partition,
  MultiMatch,
  Blocking,
  ScreeningTest,
  ChooseProfessionsFromField,
  SelectionOfProfessions,
  WithoutTask,
  ExternalUrl,
}

export enum TryingStage {
  FilteringByInterests = 1,
  FilteringByTalents = 2,
  IndustrySequestering = 3,
  Ranking = 4,
}

export enum TinderTypes {
  ByFields,
  ByInterests,
  ByTalents,
}

export interface ITaskFields {
  id?: string;
  taskType: TaskTypes;
  tooltip: string;
  screeningTestId?: string;
  testVariantId?: string;
  fieldId?: string;
  fieldName?: string;
  professionIds?: string[];
  selectedFieldItem?: IFilter;
  selectedScreeningTestItem?: IFilter;
  selectedVariantItem?: IFilter;
  professions?: IButterflyControlElement[];
  chosenProfessions?: IButterflyControlElement[];
  required?: boolean;
  tinderType?: number;
  professionTryingOnStage?: number;
  externalLessonLink?: string;
  externalLessonTooltip?: string;
}

@Component({
  selector: 'prf-add-lesson',
  templateUrl: './add-lesson.component.html',
  styleUrls: ['./add-lesson.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddLessonComponent extends EditLessonHelperComponent implements OnInit {
  public isLoading: boolean = true;
  private regionId: string;
  protected readonly TinderTypes = TinderTypes;
  constructor(
    private fb: UntypedFormBuilder,
    private lessonsService: LessonsService,
    private overlayBusyService: OverlayBusyService,
    private meta: Meta,
    private adminPanelService: AdminPanelService,
    protected regionsService: RegionsService,
    protected utilsService: UtilsService,
    protected router: Router,
    protected changeDetectorRef: ChangeDetectorRef,
    protected apiFieldsService: ApiFieldsService,
  ) {
    super(utilsService, router, regionsService, changeDetectorRef, apiFieldsService);
    this.meta.updateTag({ name: 'og:title', content: 'Добавление занятия' });
  }

  public async ngOnInit(): Promise<void> {
    await super.getAllRegions();
    this.overlayBusyService.show();
    combineLatest([this.lessonsService.getCourseMaterials(), this.lessonsService.getTests(), this.apiFieldsService.getFieldTypes()])
      .pipe(
        switchMap(([courseMaterials, tests, fieldTypes]) => {
          this.courseMaterials = courseMaterials;
          this.tests = tests;
          Object.entries(fieldTypes).forEach(([key, value]) => {
            this.fieldTypes.push({ name: value, type: key });
          });


          this.overlayBusyService.hide();
          this.isLoading = false;
          this.changeDetectorRef.detectChanges();

          return this.lessonsService.dataForCreatingLessonObserver;
        }),
        takeUntil(this.unsubscribe),
      )
      .subscribe(lessonData => {
        const courseMaterialIndex = this.courseMaterials.findIndex(courseMaterial => courseMaterial.id === lessonData.courseMaterialId);

        if (courseMaterialIndex !== -1) {
          const courseMaterial = this.courseMaterials[courseMaterialIndex];
          const { lessonsMaterials } = courseMaterial;
          const lessonMaterialIndex = lessonsMaterials.findIndex(lessonMaterial => lessonMaterial.id === lessonData.lessonMaterialId);
          const lessonMaterial = lessonsMaterials[lessonMaterialIndex];

          this.selectedCourseItemByDefault = { name: courseMaterial.name, id: courseMaterialIndex };
          this.selectedLessonItemByDefault = lessonMaterial ? { name: lessonMaterial.name, id: lessonMaterialIndex } : null;
          this.selectedCourse(this.selectedCourseItemByDefault);
          this.selectedLesson(this.selectedLessonItemByDefault);
          this.lessonsService.dataForCreatingLesson = null;
          this.changeDetectorRef.detectChanges();
        }
      });

    this.initForm();
  }

  public submit(): void {
    const tasks = this.taskCount > 0 ? this.createTasks() : null;
    const isValidForm = this.form.status === 'VALID' && (tasks === null || tasks.every(task => task !== null));

    this.submitted = true;

    if (isValidForm) {
      const lesson: ILesson = {
        hrid: this.formControls.hrid.value,
        name: this.formControls.name.value,
        courseMaterialId: this.formControls.courseMaterialId.value,
        lessonMaterialId: this.formControls.lessonMaterialId.value,
        orderNumber: this.formControls.orderNumber.value,
        imageUrl: this.formControls.imageUrl.value,
        text: this.formControls.text.value,
        videoUrl: this.formControls.videoUrl.value,
      };

      if (tasks) {
        lesson.tasks = tasks;
      }

      this.lessonsService
        .createLesson({ lesson })
        .pipe(take(1))
        .subscribe({
          next: () => {
            this.success();
          },
          error: () => {
            this.utilsService.openSnackBar('Ошибка добавления занятия', 'error');
          },
        });
    }
  }

  public selectedCourse(event: IFilter): void {
    this.setValue(event, this.courseMaterials, 'courseMaterialId', 'id');

    if (event) {
      this.lessonsMaterials = this.courseMaterials[event.id].lessonsMaterials;
      return;
    }

    this.lessonsMaterials = null;
  }

  public selectedLesson(event: IFilter): void {
    this.setValue(event, this.lessonsMaterials, 'lessonMaterialId', 'id');
  }

  public uploadLogoLesson(event: Event): void {
    if ((<HTMLInputElement>event.target).files.item(0).type.match('image.*')) {
      this.selectedFiles = (<HTMLInputElement>event.target).files;
      this.adminPanelService
        .uploadImage(this.selectedFiles, this.newGuid)
        .pipe(take(1), takeUntil(this.unsubscribe))
        .subscribe(r => {
          this.formControls.imageUrl.setValue(r.result);
          this.changeDetectorRef.detectChanges();
        });
    } else {
      this.utilsService.openSnackBar('👎 Некорректный формат файла', 'error');
    }
  }

  public setLessonWithoutTasks(event: MatRadioChange): void {
    this.taskCount = event.value;
    this.isLessonWithoutTasks = !(this.taskCount > 0);
  }

  public setTaskType(event: MatRadioChange, index: number) {
    this.taskFields[index].taskType = event.value;
    this.variants = null;
  }

  public selectedTest(event: IFilter, index: number): void {
    this.variants = null;

    if (event) {
      this.taskFields[index].screeningTestId = this.tests[event.id].screeningTestId;
      this.taskFields[index].tooltip = event.name;

      this.lessonsService
        .getTest(this.tests[event.id].screeningTestId)
        .pipe(take(1))
        .subscribe(test => {
          this.variants = test?.variants ?? [];
          this.changeDetectorRef.detectChanges();
        });

      return;
    }

    this.taskFields[index].screeningTestId = '';
    this.taskFields[index].tooltip = '';
  }

  public selectedVariant(event: IFilter, index: number): void {
    if (event) {
      this.taskFields[index].testVariantId = this.variants[event.id].id;

      return;
    }

    this.taskFields[index].testVariantId = '';
  }

  public selectedField(event: IFilter, index: number): void {
    this.taskFields[index].professions = null;

    if (event) {
      this.taskFields[index].fieldId = this.fields[event.id].id;
      this.taskFields[index].tooltip = event.name;
      this.taskFields[index].fieldName = event.name;

      this.updateProfessionsList(index);

      return;
    }

    this.taskFields[index].fieldId = '';
    this.taskFields[index].fieldName = '';
    this.taskFields[index].tooltip = '';
  }

  public setProfessionTryingOnStage(event: IFilter, index: number): void {
    if (event) {
      this.taskFields[index].professionTryingOnStage = (event.id as number) + 1;
      return;
    }

    this.taskFields[index].professionTryingOnStage = null;
  }

  public selectedRegion(event: IFilter, index: number): void {
    const region: IRegion | null = event?.id ? this.regions[event.id] : null;
    this.regionId = region ? region.id : this.defaultRegionId;

    this.updateProfessionsList(index);
  }

  private initForm(): void {
    this.form = this.fb.group({
      imageUrl: this.fb.control('', []),
      videoUrl: this.fb.control('', []),
      hrid: this.fb.control('', [Validators.required]),
      name: this.fb.control('', [Validators.required]),
      courseMaterialId: this.fb.control('', [Validators.required]),
      lessonMaterialId: this.fb.control('', [Validators.required]),
      orderNumber: this.fb.control(1, [Validators.required, Validators.min(1)]),
      text: this.fb.control(''),
    });

    this.taskFields.forEach((taskField: ITaskFields, index: number) => {
      this.form.addControl('tooltipST' + index, new FormControl());
      this.form.addControl('tooltipCP' + index, new FormControl());
      this.form.addControl('tooltipSP' + index, new FormControl());
      this.form.addControl('externalLessonLink' + index, new FormControl());
      this.form.addControl('externalLessonTooltip' + index, new FormControl());
      this.form.addControl('required' + index, new FormControl());
      this.form.addControl('tinderType' + index, new FormControl(TinderTypes.ByFields));
    });
  }

  private success = (): void => {
    this.utilsService.openSnackBar(`👌 Занятие успешно создано`, 'success');

    this.timer = window.setTimeout(_ => {
      this.router.navigate(['/admin/lessons']);
    }, 2000);
  };

  private updateProfessionsList(index: number): void {
    this.adminPanelService
      .getElasticProfessionsByFilters({
        fields: [this.taskFields[index].fieldName],
        regionId: this.regionId,
        isVisible: true,
        fieldType: this.defaultFieldItem?.id as number,
      })
      .pipe(take(1))
      .subscribe((professions: IProfession[]) => {
        this.taskFields[index].professions = professions?.map(profession => {
          return {
            id: profession.id,
            name: profession.name,
            text: this.regionsObj[profession.regionId]?.name ?? '',
            isSaved: false,
          };
        });

        this.changeDetectorRef.detectChanges();
      });
  }
}
