import { Component, OnDestroy, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { take } from 'rxjs/operators';
import { ResultsService } from '../../../shared/services/results.service';
import { PageHeaderService } from '../../../shared/dashboard/page-header/page-header.service';

@Component({
  selector: 'prf-internship-cards',
  templateUrl: './internship-cards.component.html',
  styleUrls: ['./internship-cards.component.scss'],
})
export class InternshipCardsComponent implements OnInit, OnDestroy {
  public loaded: boolean = false;
  public userRole: string = '';
  public testSessionId: string = '';
  public sessionStatus: string = '';
  public tabName: string = 'catalog';
  public testResults: any;
  public userId: string = '';

  constructor(private meta: Meta, private resultsService: ResultsService, private pageHeaderService: PageHeaderService) {
    this.userRole = localStorage.getItem('userRole');
    this.testSessionId = localStorage.getItem('testSessionId');
    this.sessionStatus = localStorage.getItem('sessionStatus');
  }
  public ngOnInit(): void {
    this.loadData();
    this.pageHeaderService.setTitle('Стажировки');
  }

  private loadData(): void {
    if (this.sessionStatus === 'Success') {
      let sessionIds = [];
      sessionIds.push(this.testSessionId);
      let getResultsObject = {
        regionId: localStorage.getItem('regionId'),
        municipalityId: localStorage.getItem('municipalityId'),
        sessionIds: sessionIds,
        role: localStorage.getItem('userRole'),
      };

      this.resultsService
        .getResultPages(getResultsObject)
        .pipe(take(1))
        .subscribe(results => {
          if (results.length > 0) {
            this.testResults = results;
          }
        });
    }

    this.loaded = true;
  }

  public ngOnDestroy(): void {
    this.pageHeaderService.setTitle(null);
  }
}
