import { Routes } from '@angular/router';
import { ConfirmEmailComponent } from './catalog/settings-page/confirm-email/confirm-email.component';
import { SendLinkComponent } from './catalog/settings-page/send-link/send-link.component';
import { PaymentFormComponent } from '../shared/dashboard/order/payment-form/payment-form.component';
import { PromotestCourseDetailsComponent } from 'app/shared/dashboard/courses/promotest-courses/promotest-course-details/promotest-course-details.component';

export const routeConfig: Routes = [
  {
    path: 'vorobievi-gori/partners-courses-all/:hrid',
    component: PromotestCourseDetailsComponent,
  },
  {
    path: 'order/payment',
    component: PaymentFormComponent,
  },
  {
    path: 'emailconfirmation',
    component: ConfirmEmailComponent,
  },
  {
    path: 'sendconfirmationlink',
    component: SendLinkComponent,
  },
];
