import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { SystemService } from 'app/pages/control-panel/admin/system/system.service';
import { filter, map, take, takeUntil } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { UtilsService } from 'app/shared/dashboard/backend-services/utils.service';
import { UserTagLabels } from 'app/shared/enums/user-types.enum';
import { IStatusSendResult, ITestsStatus, IUserParameters } from 'app/shared/interfaces/icommon.interface';
import { IStatus } from 'app/pages/catalog/career-education-courses-page/interfaces/career-education-courses.interface';
import { MatLegacySelectChange as MatSelectChange } from '@angular/material/legacy-select';
import { UnsubscribeComponent } from '@profilum-components/unsubscribe/unsubscribe.component';

enum Errors {
  InvalidProviderId = 'Введите provider id',
  UserNotFound = 'Пользователь не найден',
  TestNotCompleted = 'пользователь ещё не до конца прошел тест.',
  MissingTestingSession = 'пользователь не проходил тест.',
}

enum ReceivedResultsStatus {
  ProviderWasNotReceivedResults = 'результаты теста не приняты на стороне провайдера.',
  ProviderWasReceivedResults = 'результаты теста отправлены провайдеру.',
}

@Component({
  selector: 'prf-tests-dashboard',
  templateUrl: './tests-dashboard.component.html',
  styleUrls: ['./tests-dashboard.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TestsDashboardComponent extends UnsubscribeComponent {
  public settings: ITestsStatus = { providerId: '' };
  public loadingTestResultsInfo: boolean = false;
  public sendingTestResults: boolean = false;

  constructor(private systemService: SystemService, private utilsService: UtilsService, private changeDetectorRef: ChangeDetectorRef) {
    super();
  }

  public getStatusAndDate = (): void => {
    if (this.loadingTestResultsInfo) {
      return;
    }

    this.loadingTestResultsInfo = true;
    this.resetSettings();

    if (!this.settings.providerId) {
      this.settings.errorMessage = Errors.InvalidProviderId;
      this.loadingTestResultsInfo = false;
      return;
    }

    this.getStatusSendResultsByProviderId()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        results => {
          this.settings.results = results;
          this.settings.isPossibleToSendAgain = true;
          this.loadingTestResultsInfo = false;
          this.changeDetectorRef.detectChanges();
        },
        () => {
          this.loadingTestResultsInfo = false;
          this.changeDetectorRef.detectChanges();
        },
      );
  };

  public setProviderNameToSendResults(event: MatSelectChange): void {
    this.settings.tag = UserTagLabels.get(parseInt(event.value, 10));
  }

  public sendAgainTestResults(): void {
    if (this.sendingTestResults) {
      return;
    }

    this.sendingTestResults = true;

    const { providerId, tag } = this.settings;
    const userParameters: IUserParameters = {
      providerId,
      providerName: tag,
    };

    this.systemService
      .sendUserResultsToProvider(userParameters)
      .pipe(take(1))
      .subscribe(
        () => {
          this.sendingTestResults = false;
          this.utilsService.openSnackBar(`👌 Результаты отправлены`, 'success');
          this.timer = window.setTimeout(() => this.getStatusAndDate(), 3000);
          this.changeDetectorRef.detectChanges();
        },
        () => {
          this.sendingTestResults = false;
          this.utilsService.openSnackBar('Ошибка отправки результатов теста!', 'error');
          this.changeDetectorRef.detectChanges();
        },
      );
  }

  private getStatusSendResultsByProviderId(): Observable<IStatusSendResult[]> {
    return this.systemService.getStatusSendResultsByProviderId(this.settings.providerId).pipe(
      filter((results: IStatusSendResult[] | IStatus) => {
        if (Array.isArray(results)) {
          return true;
        }

        const comment = results?.comment;

        if (comment) {
          if (comment.includes('Not found user')) {
            this.settings.errorMessage = Errors.UserNotFound;
            this.loadingTestResultsInfo = false;
            this.changeDetectorRef.detectChanges();
            return false;
          }

          if (comment.includes('Test not completed')) {
            this.settings.errorMessage = Errors.TestNotCompleted;
            this.loadingTestResultsInfo = false;
            this.changeDetectorRef.detectChanges();
            return false;
          }

          if (comment.includes('Missing testing session')) {
            this.settings.errorMessage = Errors.MissingTestingSession;
            this.loadingTestResultsInfo = false;
            this.changeDetectorRef.detectChanges();
            return false;
          }

          this.settings.errorMessage = comment;
        }

        return true;
      }),
      map((results: IStatusSendResult[]) => {
        return (
          results?.map(result => {
            return {
              ...result,
              createDate: new Intl.DateTimeFormat('ru-RU', {
                year: 'numeric',
                month: 'long',
                day: 'numeric',
                hour: 'numeric',
                minute: 'numeric',
                second: 'numeric',
              }).format(new Date(result.createDate)),
              receivedResultsStatus: result.isDelivered
                ? ReceivedResultsStatus.ProviderWasReceivedResults
                : ReceivedResultsStatus.ProviderWasNotReceivedResults,
            };
          }) ?? []
        );
      }),
    );
  }

  private resetSettings(): void {
    this.settings.errorMessage = '';
    this.settings.isPossibleToSendAgain = false;
    this.settings.results = null;
  }
}
