<div class="radarChart-container">
  <h1>{{ 'SHARED.TESTS_RESULTS' | translate }}</h1>
  <div class="radarChart-container__circle">
    <canvas baseChart [datasets]="radarChartData" [labels]="radarChartLabels" [options]="radarChartOptions"> </canvas>
  </div>
</div>

<!--toDo: в директиву baseChart больше нельзя напрямую передавать colors и chartType-->
<!--
[colors]="radarChartColors"
[chartType]="radarChartType"-->
