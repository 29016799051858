<div class="event-page-section create">
  <h1 class="event-h1">{{ 'SHARED.ADDING' | translate }} курса</h1>
  <div class="create-event-form w-form">
    <form [formGroup]="addCourseFrom">
      <div class="create-form-row">
        <div class="create-form-column _1">
          <label class="create-form-label" for="name">Название*</label>
        </div>
        <div class="create-form-column">
          <input
            type="text"
            class="create-event-field w-input"
            [class.error-field]="submitted && formControls.name.errors"
            maxlength="256"
            name="name"
            placeholder="Название"
            id="name"
            formControlName="name"
          />
          <ng-container *ngIf="submitted && formControls.name.errors">
            <mat-error *ngIf="formControls.name.errors.required">
              {{ 'SHARED.FORM.FILL_INPUT' | translate }}
            </mat-error>
          </ng-container>
        </div>
      </div>
      <div class="create-form-row">
        <div class="create-form-column _1">
          <label class="create-form-label" for="description">Описание*</label>
        </div>
        <div class="create-form-column">
          <quill-editor
            [styles]="{ height: '150px', border: '2px solid rgba(53, 53, 53, 0.1)' }"
            [class.error-field]="submitted && formControls.description.errors"
            id="description"
            name="description"
            placeholder="Описание"
            formControlName="description"
          >
          </quill-editor>
          <ng-container *ngIf="submitted && formControls.description.errors">
            <mat-error *ngIf="formControls.description.errors.required">
              {{ 'SHARED.FORM.FILL_INPUT' | translate }}
            </mat-error>
          </ng-container>
        </div>
      </div>
      <div class="edit-event-buttons-row">
        <input (click)="submit()" type="submit" value="Добавить курс" class="button green-btn w-button" />
        <a class="white-button edit-event w-button" [routerLink]="'/admin/career-education'"> Отмена </a>
      </div>
    </form>
  </div>
</div>
