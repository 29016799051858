import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { take } from 'rxjs/operators';

import { ApiFavoritesService, IFavoriteProfessionInterface, ProductTypes } from '../../api-services';
import { UserDataHandlerService } from '../user-data-handler';
import { WebStorageService } from '../../logic-services';

@Injectable({
  providedIn: 'root',
})
export class FavoritesDataHandlerService {
  public needsUpdate: Subject<ProductTypes> = new Subject<ProductTypes>();
  private _favoriteConsultations: BehaviorSubject<IFavoriteProfessionInterface[]> = new BehaviorSubject<IFavoriteProfessionInterface[]>(
    null,
  );
  private _favoriteClasses: BehaviorSubject<IFavoriteProfessionInterface[]> = new BehaviorSubject<IFavoriteProfessionInterface[]>(null);
  private _favoriteProfessions: BehaviorSubject<IFavoriteProfessionInterface[]> = new BehaviorSubject<IFavoriteProfessionInterface[]>(null);
  private userId: string = '';

  constructor(
    private apiFavoritesService: ApiFavoritesService,
    private userDataHandlerService: UserDataHandlerService,
    private webStorageService: WebStorageService,
  ) {
    this.needsUpdate.subscribe(this.updateValue);
    this.setInitialValue();
  }

  public getFavoriteConsultations(): BehaviorSubject<IFavoriteProfessionInterface[]> {
    return this._favoriteConsultations;
  }

  public getFavoriteClasses(): BehaviorSubject<IFavoriteProfessionInterface[]> {
    return this._favoriteClasses;
  }

  public getFavoriteProfessions(): BehaviorSubject<IFavoriteProfessionInterface[]> {
    return this._favoriteProfessions;
  }

  public updateValue = (productType: ProductTypes): void => {
    this.sendApiRequest(productType).subscribe((response: IFavoriteProfessionInterface[]) => {
      switch (productType) {
        case ProductTypes.Consultation:
          this._favoriteConsultations.next(response);
          break;
        case ProductTypes.Class:
          this._favoriteClasses.next(response);
          break;
        case ProductTypes.Profession:
          this._favoriteProfessions.next(response);
          break;
        default:
          break;
      }
    });
  }

  private setInitialValue(): void {
    this.userId = this.userDataHandlerService.getUserData().getValue()?.userId;
    this.sendApiRequest().subscribe((response: IFavoriteProfessionInterface[]) => {
      this._favoriteConsultations.next(response.filter(favorite => favorite.productType === ProductTypes.Consultation));
      this._favoriteClasses.next(response.filter(favorite => favorite.productType === ProductTypes.Class));
      this._favoriteProfessions.next(response.filter(favorite => favorite.productType === ProductTypes.Profession));
    });
  }

  private sendApiRequest(productType?: ProductTypes): Observable<IFavoriteProfessionInterface[]> {
    return this.apiFavoritesService.getFavorites(this.userId, productType && productType).pipe(take(1));
  }

  public deleteFavoriteProfession(favoriteId: string): void {
    const favoriteProfessions: IFavoriteProfessionInterface[] = this._favoriteProfessions.value;
    const deletedProfession: IFavoriteProfessionInterface = favoriteProfessions.find(
      (profession: IFavoriteProfessionInterface) => profession.id === favoriteId,
    );

    this.apiFavoritesService
      .deleteFromFavorites(favoriteId)
      .pipe(take(1))
      .subscribe(() => {
        favoriteProfessions.splice(favoriteProfessions.indexOf(deletedProfession), 1);
        this._favoriteProfessions.next(favoriteProfessions);
      });
  }

  public addToFavorites(favoriteProfession: any): void {
    const favoriteProfessions: IFavoriteProfessionInterface[] = this._favoriteProfessions.value;
    favoriteProfessions.push(favoriteProfession);
    this._favoriteProfessions.next(favoriteProfessions);
  }
}
