import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { MaterialModule } from 'app/shared/modules/material.module';
import { FilterByNamePipe } from 'app/shared/pipes/filter-by-name.pipe';
import { SharedModule } from '../shared/shared.module';
import { LandingBaseModule } from './base/landing-base.module';
import { LandingRoutingModule } from './landing-routing.config';
import { LandingComponent } from './landing/landing.component';
import { LoginComponent } from './login/login.component';
import { LoginService } from './login/login.service';
import { SeamlessEntryModule } from './seamlessentry/seamlessentry.module';
import { TokenauthComponent } from './tokenauth/tokenauth.component';

@NgModule({
  imports: [CommonModule, LandingBaseModule, SharedModule, SeamlessEntryModule, LandingRoutingModule],
  declarations: [LandingComponent, TokenauthComponent, LoginComponent],
  providers: [FilterByNamePipe, LoginService],
})
export class LandingModule {}
