import { Component, OnDestroy, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { UtilsService } from 'app/shared/dashboard/backend-services/utils.service';
import * as moment from 'moment';
import { catchError, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { OverlayBusyService } from '../../../../shared/overlay-busy/overlay-busy.service';
import {
  COMPLEXITYS,
  COURSES_TYPES,
} from '../../courses-page/all-courses/active-courses/add-course/add-course.component';
import { CourseService } from '../course.service';
import { CourseDetailsService } from './course-details.service';
import { MetroColors } from '../../courses-page/all-courses/active-courses/add-course/select-metro/metro-colors';
import { CoursesService } from '../../courses-page/courses.service';
import { Observable, of, Subject, throwError } from 'rxjs';
import { forkJoin as observableForkJoin } from 'rxjs/internal/observable/forkJoin';
import { SchoolAdminPanelService } from 'app/shared/services/schooladmin-panel.service';
import { PageHeaderService } from '../../../../shared/dashboard/page-header/page-header.service';

@Component({
  selector: 'prf-course-details',
  templateUrl: './course-details.component.html',
  styleUrls: ['./course-details.component.scss'],
})
export class CourseDetailsComponent implements OnInit, OnDestroy {
  course: any;
  types: any = COURSES_TYPES;
  classesFormat: string;
  isFavorite: boolean = false;
  otherCourses: any = [];
  similarCourses: any = [];
  userId: string;

  hrid: string;
  title: string;
  mapHeader: string;
  userRole: string;
  adminLevel: string = '';
  color: string;
  isCourse: boolean;
  startDate: string;
  complexity: string = '';

  isEnrolled: boolean = false;
  dataFetched: boolean = false;
  popUpConfirming: boolean = false;
  addtoArchive: boolean = false;
  mapsLoaded: boolean = false;

  moment: any;
  metroColors: MetroColors = new MetroColors();

  private fromString: string = '';
  private beforeString: string = '';
  private yearsString: string = '';
  private ngUnsubscribe$ = new Subject<any>();

  constructor(
    private meta: Meta,
    private courseDetailsService: CourseDetailsService,
    private courseService: CourseService,
    private route: ActivatedRoute,
    private coursesService: CoursesService,
    private utilsService: UtilsService,
    private overlayService: OverlayBusyService,
    private translateService: TranslateService,
    private schoolAdminPanelService: SchoolAdminPanelService,
    private router: Router,
    private pageHeaderService: PageHeaderService,
  ) {
    this.getTranslations(['SHARED.COURSE', 'SHARED.FROM', 'SHARED.BEFORE', 'COURCES.CATALOG.YEARS'])
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe(translations => {
        this.meta.updateTag({ name: 'og:title', content: translations['SHARED.COURSE'] });
        this.fromString = translations['SHARED.FROM'];
        this.beforeString = translations['SHARED.BEFORE'];
        this.yearsString = translations['COURCES.CATALOG.YEARS'];
      });
    this.userRole = localStorage.getItem('userRole');
    if (this.userRole.includes('admin')) {
      this.adminLevel = localStorage.getItem('adminLevel');
    }
    this.userId = localStorage.getItem('userId');
    this.moment = moment;
    this.moment.locale('ru');
  }

  public ngOnInit(): void {
    this.overlayService.show();
    this.setTitles();
    this.route.params
      .pipe(
        switchMap(params => {
          this.hrid = params['hrid'];
          window.scrollTo(0, 0);
          return this.loadCurrentCourse();
        }),
        takeUntil(this.ngUnsubscribe$),
      )
      .subscribe(() => this.overlayService.hide());
  }

  loadCurrentCourse(): Observable<any> {
    return this.courseService.getClassByHRId(this.hrid).pipe(
      tap(data => {
        if (data.status == 'Not Found') {
          this.utilsService.openSnackBar(`Не удалось найти курс с hrid = ${this.hrid}`, 'error');
          this.router.navigate(['/admin/courses']);
        }
        this.course = data.class;
        const type = this.types.find(el => el.value === this.course.classesFormat);
        this.classesFormat = type.viewValue;
        const complexity = COMPLEXITYS.find(c => c.value === this.course.complexity);
        this.complexity = complexity ? complexity.viewValue : '';
        this.color = type.color.length > 0 ? type.color : '';
        const startTime = this.moment(this.course.startDate).format('HH:mm');
        if (startTime == '00:00') {
          this.startDate = this.moment(this.course.startDate).format('D MMMM YYYY');
        } else {
          this.startDate = this.moment(this.course.startDate).format('D MMMM YYYY в HH:mm');
        }
      }),
    );
  }

  archive(course) {
    course.isArchived = true;
    const observables: Observable<any>[] = [];
    // mongo
    if (this.userRole == 'schooladmin') {
      observables.push(this.schoolAdminPanelService.replaceClassDO(course));
    } else {
      // admin
      observables.push(this.coursesService.changeClassesDO(course));
    }
    observableForkJoin(observables)
      .pipe(
        switchMap(() =>
          this.getTranslations(['SHARED.COURSE_ADDED_TO_ARCHIVE']).pipe(
            tap(translations => {
              this.utilsService.openSnackBar(translations['SHARED.COURSE_ADDED_TO_ARCHIVE'], 'success');
              this.addtoArchive = !this.addtoArchive;
            }),
          ),
        ),
        takeUntil(this.ngUnsubscribe$),
      )
      .subscribe();
  }

  goToCourse() {
    if (!this.isEnrolled) {
      let currentObservable$: Observable<any> = of(null);
      if (this.userRole === 'parent') {
        currentObservable$ = this.courseDetailsService.addClassEnrollParent(this.course.id);
      }
      if (this.userRole === 'pupil') {
        currentObservable$ = this.courseDetailsService.addClassEnrollPupil(this.course.id);
      }
      currentObservable$.pipe(takeUntil(this.ngUnsubscribe$)).subscribe(() => (this.isEnrolled = true));
    }
  }

  cancelClassEnroll() {
    if (this.isEnrolled) {
      this.courseDetailsService
        .removeClassEnroll(this.course.id, this.userId)
        .pipe(take(1))
        .subscribe(() => (this.isEnrolled = false));
    }
  }

  public getAges(): string {
    if (this.course.maxAgeAudience != 100 && this.course.minAgeAudience != 0) {
      return (
        this.fromString +
        ' ' +
        this.course.minAgeAudience +
        ' ' +
        this.beforeString +
        ' ' +
        this.course.maxAgeAudience +
        ' ' +
        this.yearsString
      );
    } else if (this.course.maxAgeAudience == 100) {
      return this.course.minAgeAudience + '+ ' + this.yearsString;
    } else if (this.course.minAgeAudience == 0) {
      return this.beforeString + ' ' + this.course.maxAgeAudience + ' ' + this.yearsString;
    }
  }

  calculateRealPrice() {
    return this.courseDetailsService.calculateRealPrice(this.course);
  }

  private setTitles(): void {
    const path = this.router.routerState.snapshot.url;
    if (path.includes('/events/')) {
      this.setHeaderData('Мероприятия', '/admin/courses', 'Все мероприятия');
      this.getTranslations(['SHARED.EVENTS', 'SHARED.EVENT_ON_MAP'])
        .pipe(takeUntil(this.ngUnsubscribe$))
        .subscribe(translations => {
          this.title = translations['SHARED.EVENTS'];
          this.mapHeader = translations['SHARED.EVENT_ON_MAP'];
          this.isCourse = false;
        });
    }
    if (path.includes('/courses/')) {
      this.setHeaderData('Курсы', '/admin/courses', 'Все курсы');
      this.getTranslations(['COURCES.CATALOG.COURCES', 'SHARED.EVENT_ON_MAP'])
        .pipe(takeUntil(this.ngUnsubscribe$))
        .subscribe(translations => {
          this.title = translations['COURCES.CATALOG.COURCES'];
          this.mapHeader = translations['SHARED.COURSE_ON_MAP'];
          this.isCourse = true;
        });
    }
  }

  private setHeaderData(title: string, url: string, buttonName: string): void {
    this.pageHeaderService.setTitle(title);
    this.pageHeaderService.setBackButtonData(url, buttonName);
  }

  deleteCourse(courseID) {
    const observables: Observable<any>[] = [];
    // mongo
    if (this.userRole == 'schooladmin') {
      observables.push(this.schoolAdminPanelService.deleteClassDO(courseID));
    } else {
      // admin
      observables.push(this.coursesService.removeCurrentClass(courseID));
    }
    // elastic
    observables.push(this.courseService.removeCurrentClass(courseID));
    observableForkJoin(observables)
      .pipe(
        catchError(err => {
          this.popUpConfirming = !this.popUpConfirming;
          this.utilsService.openSnackBar('👎 Ошибка на сервере, попробуйте позже', 'error');
          return throwError(err);
        }),
        takeUntil(this.ngUnsubscribe$),
      )
      .subscribe(([delMongo, delElastic]) => {
        if (delMongo.status == 'Success') {
          this.utilsService.openSnackBar(`👌 Курс удален`, 'success');
        }
        this.popUpConfirming = !this.popUpConfirming;
        return this.router.navigate(['/admin/courses']);
      });
  }

  getTranslations(keys: string[]): Observable<any> {
    return this.translateService.get(keys);
  }

  public ngOnDestroy(): void {
    this.ngUnsubscribe$.next(null);
    this.ngUnsubscribe$.complete();
    this.pageHeaderService.setTitle(null);
    this.pageHeaderService.setBackButtonData(null, null);
  }
}
