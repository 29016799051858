import { Component, ElementRef, HostBinding, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import {
  COURSES_TYPES,
} from 'app/shared/dashboard/courses/courses-catalog/courses-filter/courses-types-filter/courses-types-filter.component';
import { IInternship } from 'app/shared/interfaces/iinternships.interface';
import * as moment from 'moment';
import { InternshipDetailsService } from '../internship-details/internship-details.service';

@Component({
  selector: 'prf-internship-card-new',
  templateUrl: './internship-card-new.component.html',
  styleUrls: ['./internship-card-new.component.scss'],
})
export class InternshipCardNewComponent implements OnInit {
  @Input() internship: IInternship;
  types = COURSES_TYPES;
  currentType: any = { color: '' };
  isFavorite: boolean = false;
  userId: string;
  moment: any = moment;
  startDate: string;

  public userRole: string = '';

  @HostBinding('class.events-column') hostClass: boolean = true;
  @ViewChild('heart') heart: ElementRef;
  @ViewChild('heartActive') heartActive: ElementRef;

  constructor(private internshipDetailsService: InternshipDetailsService, private router: Router) {
    this.moment.locale('ru');
  }

  public ngOnInit(): void {
    this.userRole = localStorage.getItem('userRole');
    this.userId = localStorage.getItem('userId');
    this.startDate = this.moment(this.internship.startDate).format('D MMMM YYYY');
  }

  public cutText(text: string, maxLen: number): string {
    if (text && text.length > maxLen) {
      text = text.substring(0, maxLen - 2) + '..';
    }
    return text;
  }

  public checkAction(): void {
    this.router.navigate(['/internships', this.internship.hrid]);
  }
}
