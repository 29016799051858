<div class="statistic-page" [formGroup]="form">
  <section>
    <div class="section-title">Обновить данные по всем школам</div>
    <a class="button-animate btn-activate" [class.btn-waiting]="allSchoolsUpdating" (click)="updateAllSchools()">
      <span class="btn-icon"></span>
      <span class="btn-text" data-wait="Подождите" data-after="Обновлено">Обновить</span></a
    >
  </section>
  <section formGroupName="schoolUpdate">
    <div class="section-title">Обновить данные по школе</div>

    <div class="dropdown-container">
      <prf-dropdown-with-search
        formControlName="schoolRegion"
        [label]="'Регион школы'"
        [options]="listedRegions"
      ></prf-dropdown-with-search>
    </div>
    <div class="dropdown-container">
      <prf-dropdown-with-search
        formControlName="schoolMunicipality"
        [label]="'Муниципалитет школы'"
        [options]="schoolRegionMunicipalities"
      ></prf-dropdown-with-search>
    </div>
    <div class="dropdown-container">
      <prf-dropdown-with-search
        formControlName="school"
        [label]="'SHARED.LIST_OF_SCHOOLS' | translate"
        [options]="listedSchools"
      ></prf-dropdown-with-search>
    </div>
    <a
      class="button-animate btn-activate"
      [class.btn-waiting]="f.schoolUpdate.get('school').status === 'DISABLED'"
      [class.disabled]="!f.schoolUpdate.get('school').value"
      (click)="updateSchool()"
      ><span class="btn-icon"></span> <span class="btn-text" data-wait="Подождите" data-after="Обновлено">Обновить</span></a
    >
  </section>

  <section formGroupName="updateWithYear">
    <div class="section-title">Обновить данные по всем школам за определенный год для вкладки Школы</div>

    <div class="dropdown-container">
      <prf-dropdown-with-search
        formControlName="year"
        [label]="'SHARED.YEAR' | translate"
        [options]="reportYears"
      ></prf-dropdown-with-search>
    </div>

    <div class="dropdown-container">
      <prf-dropdown-with-search formControlName="region" [label]="'Регион школы'" [options]="listedRegions"></prf-dropdown-with-search>
    </div>

    <a
      class="button-animate btn-activate"
      [class.btn-waiting]="allSchoolsForYearUpdating"
      [class.disabled]="!f.updateWithYear.get('year').value || !f.updateWithYear.get('region').value"
      (click)="updateAllSchoolsForYear()"
      ><span class="btn-icon"></span> <span class="btn-text" data-wait="Подождите" data-after="Обновлено">Обновить</span></a
    >
  </section>

  <section formGroupName="analyticsUpdate">
    <ng-container formGroupName="regionSchools">
      <div class="section-title">Данные для вкладки Аналитика в ЛК Директора</div>
      <div class="section-subtitle">Расчет Аналитики для всех школ региона</div>
      <prf-dropdown-with-search formControlName="region" [label]="'Регион школы'" [options]="listedRegions"></prf-dropdown-with-search>
      <div class="create-event-row">
        <label class="section-text" for="regionSchoolsStartDate">Начальная дата диапазона обновления:</label>
        <input
          matInput
          [matDatepicker]="regionSchoolsPickerStart"
          formControlName="startDate"
          class="create-event-field date w-input"
          maxlength="256"
          placeholder="Date"
          name="startDate"
          data-name="startDate"
          id="regionSchoolsStartDate"
          #myDate
        />
        <mat-datepicker-toggle matSuffix [for]="regionSchoolsPickerStart"></mat-datepicker-toggle>
        <mat-datepicker #regionSchoolsPickerStart></mat-datepicker>
      </div>
      <div class="create-event-row">
        <label class="section-text" for="regionSchoolsEndDate"
          >Конечная дата диапазона обновления <br />(если не указано, то текущее число):</label
        >
        <input
          matInput
          [matDatepicker]="regionSchoolsPickerEnd"
          formControlName="endDate"
          class="create-event-field date w-input"
          maxlength="256"
          placeholder="Date"
          name="endDate"
          data-name="endDate"
          id="regionSchoolsEndDate"
          #myDate
        />
        <mat-datepicker-toggle matSuffix [for]="regionSchoolsPickerEnd"></mat-datepicker-toggle>
        <mat-datepicker #regionSchoolsPickerEnd></mat-datepicker>
      </div>
      <a
        class="button-animate btn-activate"
        [class.btn-waiting]="allSchoolsAnalyticsUpdating"
        [class.disabled]="
          !f.analyticsUpdate.get('regionSchools').get('region').value ||
          !checkDate(f.analyticsUpdate.get('regionSchools').get('startDate').value)
        "
        (click)="updateAllSchoolsAnalytics()"
      >
        <span class="btn-icon"></span>
        <span class="btn-text" data-wait="Подождите" data-after="Обновлено">Обновить</span></a
      >
    </ng-container>
    <br />
    <br />
    <br />
    <ng-container formGroupName="oneSchool">
      <div class="section-subtitle">Расчет Аналитики для одной школы</div>

      <div class="dropdown-container">
        <prf-dropdown-with-search formControlName="region" [label]="'Регион школы'" [options]="listedRegions"></prf-dropdown-with-search>
      </div>
      <div class="dropdown-container">
        <prf-dropdown-with-search
          formControlName="municipality"
          [label]="'Муниципалитет школы'"
          [options]="schoolRegionMunicipalities"
        ></prf-dropdown-with-search>
      </div>
      <div class="dropdown-container">
        <prf-dropdown-with-search
          formControlName="school"
          [label]="'SHARED.LIST_OF_SCHOOLS' | translate"
          [options]="listedSchoolsAnalytics"
        ></prf-dropdown-with-search>
      </div>
      <div class="create-event-row">
        <label class="section-text" for="oneSchoolStartDate">Дата начала обновления:</label>
        <input
          matInput
          [matDatepicker]="oneSchoolPickerStart"
          formControlName="startDate"
          class="create-event-field date w-input"
          maxlength="256"
          placeholder="Date"
          name="startDate"
          data-name="startDate"
          id="oneSchoolStartDate"
          #myDate
        />
        <mat-datepicker-toggle matSuffix [for]="oneSchoolPickerStart"></mat-datepicker-toggle>
        <mat-datepicker #oneSchoolPickerStart></mat-datepicker>
      </div>
      <div class="create-event-row">
        <label class="section-text" for="oneSchoolEndDate"
          >Конечная дата диапазона обновления <br />(если не указано, то текущее число):</label
        >
        <input
          matInput
          [matDatepicker]="oneSchoolPickerEnd"
          formControlName="endDate"
          class="create-event-field date w-input"
          maxlength="256"
          placeholder="Date"
          name="endDate"
          data-name="endDate"
          id="oneSchoolEndDate"
          #myDate
        />
        <mat-datepicker-toggle matSuffix [for]="oneSchoolPickerEnd"></mat-datepicker-toggle>
        <mat-datepicker #oneSchoolPickerEnd></mat-datepicker>
      </div>
      <a
        class="button-animate btn-activate"
        [class.btn-waiting]="f.analyticsUpdate.get('oneSchool').get('school').status === 'DISABLED'"
        [class.disabled]="
          !f.analyticsUpdate.get('oneSchool').get('school').value || !checkDate(f.analyticsUpdate.get('oneSchool').get('startDate').value)
        "
        (click)="updateOneSchoolAnalytics()"
        ><span class="btn-icon"></span> <span class="btn-text" data-wait="Подождите" data-after="Обновлено">Обновить</span></a
      >
    </ng-container>
  </section>
</div>
