import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';

import { HttpService } from '../http';
import { EiType } from '../b2g-reports';
import { B2gEurochemUrls } from './b2g-eurochem.collections';
import {
  ICheckReferralResponse,
  ICodeSendResponse,
  ICodeVerifyResponse,
  ICitiesResponse,
  IEisResponse,
  IInviteResponse,
  IRegisterBody,
  IRegisterResponse,
} from './b2g-eurochem.interface';

@Injectable({
  providedIn: 'root',
})
export class B2gEurochemService {
  private readonly httpService: HttpService = inject(HttpService);

  public checkReferral(id: string, consent?: boolean): Observable<ICheckReferralResponse> {
    return this.httpService.get<ICheckReferralResponse>(`${B2gEurochemUrls.CheckReferral}/${id}`, { consent }).pipe(take(1));
  }

  public sendCode(id: string, phone: string): Observable<ICodeSendResponse> {
    return this.httpService.post<ICodeSendResponse>(`${B2gEurochemUrls.CodeSend}/${id}`, null, { phone }).pipe(take(1));
  }

  public verifyCode(id: string, code: string): Observable<ICodeVerifyResponse> {
    return this.httpService.post<ICodeVerifyResponse>(`${B2gEurochemUrls.CodeVerify}/${id}`, null, { code }).pipe(take(1));
  }

  public invite(id: string): Observable<IInviteResponse> {
    return this.httpService.post<IInviteResponse>(`${B2gEurochemUrls.Invite}/${id}`).pipe(take(1));
  }

  public register(id: string, body: IRegisterBody): Observable<IRegisterResponse> {
    return this.httpService.post<IRegisterResponse>(`${B2gEurochemUrls.Register}/${id}`, body).pipe(take(1));
  }

  public getCities(): Observable<ICitiesResponse> {
    return this.httpService.get<ICitiesResponse>(B2gEurochemUrls.Cities).pipe(take(1));
  }

  public getEis(city: string, eiType: EiType): Observable<IEisResponse> {
    return this.httpService.get<IEisResponse>(B2gEurochemUrls.Eis, { city, eiType }).pipe(take(1));
  }
}
