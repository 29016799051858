import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { ReplaySubject } from 'rxjs';
import { take } from 'rxjs/operators';
import { YmItems, YmItemsByRole } from './yandex-metrics.collections';
import {
  IYandexMetricsConfig,
  IYmExtLinkOptions,
  IYmFileOptions,
  IYmFirstPartyParams,
  IYmHitOptions,
  IYmNotBounce,
  IYmParams,
  IYmUTMParams,
  ymFileExtension,
} from './yandex-metrics.interface';
import { Helper, IStorageHelper, StorageHelper, UserRoles } from '../../helpers';
import { StorageKeys, Storages } from '../web-storage';
import { IObject } from '../../api-services';

@Injectable({
  providedIn: 'root',
})
export class YandexMetricsService {
  private _ym: (
    counterNumber: number,
    label: string,
    params?: IObject | string,
    options?: IObject | string,
    callback?: () => void,
    ctx?: IObject,
  ) => void;
  private readonly yaCounterIsReadyToUse$: ReplaySubject<boolean>;
  private storageHelper: IStorageHelper;
  private config: IYandexMetricsConfig = { enabled: false, ymId: null };

  constructor(@Inject(DOCUMENT) private document: Document) {
    this.storageHelper = new StorageHelper(Storages.Local);
    this.yaCounterIsReadyToUse$ = new ReplaySubject<boolean>(1);
    this.initLogger();
  }

  public init(config: IYandexMetricsConfig): void {
    if (!config?.enabled || !config.ymId) {
      return;
    }

    this.config.enabled = config.enabled && Helper.isNumber(config.ymId);
    this.config.ymId = config.ymId;
    this.initService();
  }

  public addFileExtension(ymItem: YmItems, extension: ymFileExtension | ymFileExtension[]): void {
    if (!this.config.enabled || !this.checkRole(ymItem)) {
      return;
    }

    if (Helper.isString(extension, true) || Helper.isArray(extension, true)) {
      this.dispatchYmItem(() => {
        this._ym(this.getYandexMetricId(), 'addFileExtension', extension);
      });
    }
  }

  public extLink(ymItem: YmItems, url: string, options?: IYmExtLinkOptions): void {
    if (!this.config.enabled || !this.checkRole(ymItem)) {
      return;
    }

    if (Helper.isString(url, true)) {
      this.dispatchYmItem(() => {
        this._ym(this.getYandexMetricId(), 'extLink', url, options);
      });
    }
  }

  public file(ymItem: YmItems, url: string, options?: IYmFileOptions): void {
    if (!this.config.enabled || !this.checkRole(ymItem)) {
      return;
    }

    if (Helper.isString(url, true)) {
      this.dispatchYmItem(() => {
        this._ym(this.getYandexMetricId(), 'file', url, options);
      });
    }
  }

  public firstPartyParams(ymItem: YmItems, parameters: IYmFirstPartyParams): void {
    if (!this.config.enabled || !this.checkRole(ymItem)) {
      return;
    }

    if (Helper.isObject(parameters, true)) {
      this.dispatchYmItem(() => {
        this._ym(this.getYandexMetricId(), 'firstPartyParams', parameters);
      });
    }
  }

  public getClientID(ymItem: YmItems, callback: (clientId: string) => string): void {
    if (!this.config.enabled || !this.checkRole(ymItem)) {
      return;
    }

    if (Helper.isFunction(callback)) {
      this.dispatchYmItem(() => {
        this._ym(this.getYandexMetricId(), 'getClientID', callback);
      });
    }
  }

  public hit(): void {
    if (!this.config.enabled) {
      return;
    }

    this.dispatchYmItem(() => {
      this._ym(this.getYandexMetricId(), 'hit');
    });
  }

  public notBounce(ymItem: YmItems, parameters?: IYmNotBounce): void {
    if (!this.config.enabled || !this.checkRole(ymItem)) {
      return;
    }

    this.dispatchYmItem(() => {
      this._ym(this.getYandexMetricId(), 'notBounce', parameters);
    });
  }

  public params(ymItem: YmItems, parameters: IYmParams | IYmParams[]): void {
    if (!this.config.enabled || !this.checkRole(ymItem)) {
      return;
    }

    if (Helper.isObject(parameters, true) || Helper.isArray(parameters, true)) {
      this.dispatchYmItem(() => {
        this._ym(this.getYandexMetricId(), 'params', parameters);
      });
    }
  }

  public reachGoal(ymItem: YmItems | string, params?: IYmParams, callback?: () => void, ctx?: IObject): void {
    if (!this.config.enabled || !this.checkRole(ymItem as YmItems)) {
      return;
    }
    const target: string = this.setDynamicValues(ymItem as YmItems, params);

    if (Helper.isString(target, true)) {
      this.dispatchYmItem(() => {
        this._ym(this.getYandexMetricId(), 'reachGoal', target, params, Helper.isFunction(callback) ? callback : undefined, ctx);
      });
    }
  }

  public setUserID(ymItem: YmItems, id: string): void {
    if (!this.config.enabled || !this.checkRole(ymItem)) {
      return;
    }

    if (Helper.isString(id, true)) {
      this.dispatchYmItem(() => {
        this._ym(this.getYandexMetricId(), 'setUserID', id);
      });
    }
  }

  public userParams(ymItem: YmItems, userParams: Record<string, any>): void {
    if (!this.config.enabled || !this.checkRole(ymItem)) {
      return;
    }

    if (Helper.isObject(userParams, true)) {
      this.dispatchYmItem(() => {
        this._ym(this.getYandexMetricId(), 'userParams', userParams);
      });
    }
  }

  public ymForQrCode(url: string, params: IYmUTMParams): string {
    if (!this.config.enabled) {
      return;
    }

    const paramsArray: string[][] = Object.entries(params);
    const urlObj: URL = new URL(url);
    const urlSearchParams: URLSearchParams = new URLSearchParams(urlObj.search);

    paramsArray.forEach((parameter: string[]) => urlSearchParams.append(parameter[0], parameter[1]));
    urlObj.search = urlSearchParams.toString();

    return urlObj.toString();
  }

  private dispatchYmItem = (callback: () => void): void => {
    this.yaCounterIsReadyToUse$.pipe(take(1)).subscribe(callback);
  };

  private initService(): void {
    if (Helper.isFunction(window.ym) && this.config.enabled) {
      this.document.addEventListener(`yacounter${this.getYandexMetricId()}inited`, this.checkYaCounter);
      this._ym = window.ym;
      this._ym(this.getYandexMetricId(), 'init', {
        clickmap: true,
        trackLinks: true,
        accurateTrackBounce: true,
        webvisor: true,
        triggerEvent: true,
      });
    }
  }

  private checkYaCounter = (): void => {
    this.yaCounterIsReadyToUse$.next(true);
    this.document.removeEventListener(`yacounter${this.getYandexMetricId()}inited`, this.checkYaCounter);
  };

  private getYandexMetricId(): number {
    return this.config.ymId;
  }

  private initLogger(): void {
    // tslint:disable
    window.ymHelper = (): void => {
      console.log(`1. Чтобы проверить наличие счетчика на странице, введите ymCounterExist() в консоль`);
      console.log(`2. Чтобы проверить инициализацию счетчика на странице, введите ymCounterInitialized() в консоль`);
      console.log(`3. Чтобы увидеть отправленные ивенты, введите ymData() в консоль`);
    };

    window.ymCounterExist = (): void => {
      const url: URL = new URL(window.location.href);

      url.searchParams.append('_ym_status-check', `${this.getYandexMetricId()}&_ym_lang=ru`);
      window.location.href = url.toString();
    };

    window.ymCounterInitialized = (): void => {
      this.yaCounterIsReadyToUse$.pipe(take(1)).subscribe(() => {
        console.log(`Счетчик ${this.getYandexMetricId()} можно использовать`);
      });
    };

    window.ymData = (): void => {
      const url: URL = new URL(window.location.href);

      url.searchParams.append('_ym_debug', `1`);
      window.location.href = url.toString();
    };
  }

  private checkRole(ymItem: YmItems): boolean {
    const userRole: string = this.storageHelper.get(StorageKeys.UserRole);

    if (!userRole || YmItemsByRole.get('none' as UserRoles).has(ymItem)) {
      return true;
    }

    return !!YmItemsByRole.get(userRole as UserRoles)?.has(ymItem);
  }

  private setDynamicValues(ymItem: YmItems, params?: IYmParams): string {
    if (!Helper.isArray(params?.dynamic_values, true)) {
      return ymItem;
    }

    let index: number = 0;

    return ymItem.toString().replace(/\{.*?\}/gm, (): string => {
      const value: string | number = params.dynamic_values[index] ?? '';

      index += 1;

      return value.toString();
    });
  }
}
