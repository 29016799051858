import { throwError as observableThrowError, Observable } from 'rxjs';
import { APP_INITIALIZER, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { HttpService } from 'app/core/services/http.service';
import { map } from 'rxjs/operators';
import { IAppSettings } from '../interfaces/iappsettings.interface';

@Injectable()
export class AppSettingsService {
  static settings: IAppSettings;
  fileUrl: string;

  private defaultTranslation: any;

  constructor(private http: HttpService, private translate: TranslateService) {
    this.load();
  }

  load() {
    this.fileUrl = this.getFileUrl();

    return new Promise((resolve, reject) => {
      this.http.getLocalFile(this.fileUrl).subscribe(
        data => {
          AppSettingsService.settings = data;
          resolve(true);
        },
        (error: any) => {
          console.error(error);
          return observableThrowError(error.json().error || 'Server error');
        },
      );
    });
  }

  private getFileUrl(): string {
    const isLocalhost = location.origin.includes('localhost');
    // данные условия надо менять при локальном тестировании регионов
    const isDevelopment = location.origin.includes('talentdev');
    const isMosObl = location.origin.includes('mosreg');

    if (isMosObl) {
      return isDevelopment || isLocalhost
        ? 'assets/appsettings/appsettings.mosobl.dev.json'
        : 'assets/appsettings/appsettings.mosobl.prod.json';
    } else if (isLocalhost) {
      return 'assets/appsettings/appsettings.default.dev.json';
    } else {
      return isDevelopment ? 'assets/appsettings/appsettings.default.dev.json' : 'assets/appsettings/appsettings.default.prod.json';
    }
  }

  /**
   * Метод для получения текущего лендинга
   *
   */
  get currentLocation(): 'mo' | 'kz' | 'base' {
    if (location.href.includes('mosreg')) {
      return 'mo';
    }
    if (location.href.includes('kz')) {
      return 'kz';
    }

    return 'base';
  }

  setDefaultTranslation() {
    this.translate
      .getTranslation(this.translate.defaultLang)
      .pipe(map(translations => (this.defaultTranslation = translations)))
      .subscribe();
  }
  getByDefaultLocale(key) {
    let result = this.defaultTranslation;
    if (!result) return;
    for (let p of key.split('.')) {
      if (result[p] == undefined) {
        return null;
      }
      result = result[p];
    }
    return result;
  }
}

export function AppsettingsFactory(appsettingsService: AppSettingsService) {
  return () => appsettingsService.load();
}

export function init() {
  return {
    provide: APP_INITIALIZER,
    useFactory: AppsettingsFactory,
    deps: [AppSettingsService],
    multi: true,
  };
}

const AppsettingsModule = {
  init: init,
};

export { AppsettingsModule };
