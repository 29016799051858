<div class="tasks-row">
  <div class="tasks-column">
    <div class="task-block done">
      <div class="task-div">
        <div class="task-icon-div done">
          <img src="./assets/images/dashboard/task-icon-1.svg" class="task-icon" />
        </div>
        <p class="task-text width-180">{{ 'SHARED.INVITE_PARENTS' | translate }}</p>
        <div class="task-date-text">{{ 'SHARED.SEND_3_SEPTEMBER' | translate }}</div>
      </div>
    </div>
  </div>
  <div class="tasks-column">
    <div class="task-block done">
      <div class="task-div">
        <div class="task-icon-div done">
          <img src="./assets/images/dashboard/task-icon-2.svg" class="task-icon" />
        </div>
        <p class="task-text">{{ 'SHARED.CHANGE_SUBJECT' | translate }}</p>
        <div class="subjects">
          <div class="subject" *ngFor="let subject of data | slice: 0:3">{{ subject }}</div>
        </div>
      </div>
    </div>
  </div>
  <div class="tasks-column">
    <div class="task-block">
      <div class="task-div">
        <div class="task-icon-div">
          <img src="./assets/images/dashboard/task-icon-3.svg" class="task-icon" />
        </div>
        <p class="task-text">{{ 'SHARED.CHANGE_AND_SIGN_UP' | translate }}</p>
      </div>
      <a href="#" class="button tasks-button w-button" (click)="openGoal('courses')">{{ 'SHARED.CHANGE' | translate }}</a>
    </div>
  </div>
  <div class="tasks-column">
    <div class="task-block">
      <div class="task-div">
        <div class="task-icon-div">
          <img src="./assets/images/dashboard/task-icon-3.svg" class="task-icon" />
        </div>
        <p class="task-text">{{ 'SHARED.CHANGE_EVENT' | translate }}</p>
      </div>
      <a href="#" class="button tasks-button w-button">{{ 'SHARED.CHANGE' | translate }}</a>
    </div>
  </div>
  <div class="tasks-column">
    <div class="task-block">
      <div class="task-div">
        <div class="task-icon-div">
          <img src="./assets/images/dashboard/task-icon-3.svg" class="task-icon" />
        </div>
        <p class="task-text">
          {{ 'SHARED.CHANGE_EXCURSION' | translate }}
        </p>
      </div>
      <a href="#" class="button tasks-button w-button">{{ 'SHARED.CHANGE' | translate }}</a>
    </div>
  </div>
</div>
