import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { IAllSchoolsStatistic } from 'app/shared/interfaces/iallschoolsstatistic';
import { ICity } from 'app/shared/interfaces/icity';
import { IMunicipality } from 'app/shared/interfaces/imunicipality';
import { IRegion } from 'app/shared/interfaces/iregion';
import { OverlayBusyService } from '../../../../shared/overlay-busy/overlay-busy.service';
import { AdminPanelService } from '../admin-panel.service';
import { CitiesService } from '../cities/cities.service';
import { MunicipalitiesService } from '../municipalities/municipalities.service';
import { RegionsService } from '../regions/regions.service';
import { AppSettingsService } from 'app/shared/services/appsettings.service';
import { Observable, of } from 'rxjs';
import { switchMap, takeUntil, tap } from 'rxjs/operators';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { EmptyGuid } from 'app/shared/global-constants/constants';
import { ICounters, ISchoolStatistic, ISchoolStatisticReport } from '../admin.interface';
import { UnsubscribeComponent } from '@profilum-components/unsubscribe/unsubscribe.component';

const PAGE_SIZE: number = 100;
const TRIGGER_SCROLL_BOTTOM: number = 350;

interface IReportYear {
  name: string;
  value: number;
}

@Component({
  selector: 'prf-schools',
  templateUrl: './schools.component.html',
  styleUrls: ['./schools.component.scss'],
})
export class SchoolsComponent extends UnsubscribeComponent implements OnInit {
  @ViewChild('regionDrop') public readonly regionDrop: ElementRef;
  @ViewChild('regionField') public readonly regionField: ElementRef;
  @ViewChild('municipalityDrop') public readonly municipalityDrop: ElementRef;
  @ViewChild('municipalityField') public readonly municipalityField: ElementRef;
  @ViewChild('cityDrop') public readonly cityDrop: ElementRef;
  @ViewChild('cityField') public readonly cityField: ElementRef;

  public schoolsStatistic: IAllSchoolsStatistic[] = [];
  public schoolsStatisticCounters: ICounters;
  public sortSelectedSchoolName: boolean = false;
  public sortSelectedTotalPupils: boolean = false;
  public sortSelectedTotalRegPupils: boolean = false;
  public sortSelectedTotalRegParents: boolean = false;
  public sortSelectedTestCompleted: boolean = false;
  public sortSelectedTestCompletedRussian: boolean = false;
  public sortSelectedTestCompletedForeign: boolean = false;
  public sortSelectedTotalClasses: boolean = false;
  public sortSelectedTotalTeachers: boolean = false;
  public selectedIndex: number = 1;
  public editing: boolean;
  public currentSchool: IAllSchoolsStatistic;
  public allRegions: IRegion[] = [];
  public municipalitiesByRegion: IMunicipality[] = [];
  public citiesByMunicipality: ICity[] = [];
  public reportYears: IReportYear[] = [
    { name: 'За все время', value: 0 },
    { name: '2020', value: 2020 },
    { name: '2021', value: 2021 },
    { name: '2022', value: 2022 },
    { name: '2023', value: 2023 },
  ];
  public deleteConfirming: boolean = false;
  public schoolToDeleteId: string = '';
  public regionLanguages: { default: string | null; native: string | null } = {
    default: AppSettingsService.settings.regionLanguages ? AppSettingsService.settings.regionLanguages.defaultLanguage : null,
    native: AppSettingsService.settings.regionLanguages ? AppSettingsService.settings.regionLanguages.regionLanguage : null,
  };
  public allCities: ICity[];
  public pageNumber: number = 0; // номер загруженной страницы
  public currentGroupElementsCount: number = 0; // количество загруженных профессий в текущей группе
  public form: FormGroup;

  private allSchoolsStatistic: IAllSchoolsStatistic[] = [];
  private loading: boolean = false;
  private loadedAll: boolean = false;
  private isRegion: boolean = false;
  private isMunicipality: boolean = false;
  private reportDate: Date;
  private reportYear: number;

  constructor(
    private meta: Meta,
    private adminPanelService: AdminPanelService,
    private regionsService: RegionsService,
    private municipalitiesService: MunicipalitiesService,
    private citiesService: CitiesService,
    private overlayService: OverlayBusyService,
    private fb: FormBuilder,
  ) {
    super();
    this.meta.updateTag({ name: 'og:title', content: 'Школы' });
  }

  public ngOnInit(): void {
    this.form = this.fb.group({
      region: new FormControl<string | null>(null, []),
      municipality: new FormControl<string | null>(null, []),
      city: new FormControl<string | null>(null, []),
      year: new FormControl<string | null>(null, []),
    });

    // по дефолту отображаем статистику за последний год
    this.f.year.setValue(this.reportYears[this.reportYears.length - 1]);
    this.reportYear = this.reportYears[this.reportYears.length - 1].value;

    this.loadTerritory().pipe(takeUntil(this.unsubscribe)).subscribe();
  }

  get f() {
    return this.form.controls;
  }

  // sorting by param
  public sortByParamName(paramName: string): void {
    switch (paramName) {
      case 'school':
        {
          this.sortSchoolStatistic('schoolName', true, this.sortSelectedSchoolName);
          this.sortSelectedSchoolName = !this.sortSelectedSchoolName;
          this.selectedIndex = 1;
        }
        break;
      case 'pupils':
        {
          this.sortSchoolStatistic('totalPupilsCount', false, this.sortSelectedTotalPupils);
          this.sortSelectedTotalPupils = !this.sortSelectedTotalPupils;
          this.selectedIndex = 2;
        }
        break;
      case 'regPupils':
        {
          this.sortSchoolStatistic('registeredPupilsCount', false, this.sortSelectedTotalRegPupils);
          this.sortSelectedTotalRegPupils = !this.sortSelectedTotalRegPupils;
          this.selectedIndex = 3;
        }
        break;
      case 'parents':
        {
          this.sortSchoolStatistic('totalParentsCount', false, this.sortSelectedTotalRegParents);
          this.sortSelectedTotalRegParents = !this.sortSelectedTotalRegParents;
          this.selectedIndex = 8;
        }
        break;
      case 'testCompleted':
        {
          this.sortSchoolStatistic('completedTestsCount', false, this.sortSelectedTestCompleted);
          this.sortSelectedTestCompleted = !this.sortSelectedTestCompleted;
          this.selectedIndex = 4;
        }
        break;
      case 'testCompletedRussian':
        {
          this.sortSchoolStatistic('completedRussianTestsCount', false, this.sortSelectedTestCompletedRussian);
          this.sortSelectedTestCompletedRussian = !this.sortSelectedTestCompletedRussian;
          this.selectedIndex = 4;
        }
        break;
      case 'testCompletedForeign':
        {
          this.sortSchoolStatistic('completedForeignTestsCount', false, this.sortSelectedTestCompletedForeign);
          this.sortSelectedTestCompletedForeign = !this.sortSelectedTestCompletedForeign;
          this.selectedIndex = 7;
        }
        break;
      case 'classes':
        {
          this.sortSchoolStatistic('classesCount', false, this.sortSelectedTotalClasses);
          this.sortSelectedTotalClasses = !this.sortSelectedTotalClasses;
          this.selectedIndex = 5;
        }
        break;
      case 'teachers': {
        this.sortSchoolStatistic('teachersCount', false, this.sortSelectedTotalTeachers);
        this.sortSelectedTotalTeachers = !this.sortSelectedTotalTeachers;
        this.selectedIndex = 6;
      }
    }
  }

  public edit(schoolID: string): void {
    this.toggleModal('edit');
    this.currentSchool = this.allSchoolsStatistic.find((school: IAllSchoolsStatistic) => school.schoolId === schoolID);
  }

  public toggleModal(type: 'edit' | 'delete'): void {
    if (type === 'edit') {
      this.editing = !this.editing;
      this.editing
        ? document.getElementsByTagName('body')[0].classList.add('modal-open')
        : document.getElementsByTagName('body')[0].classList.remove('modal-open');
    } else if (type === 'delete') {
      this.deleteConfirming = !this.deleteConfirming;
      this.deleteConfirming
        ? document.getElementsByTagName('body')[0].classList.add('modal-open')
        : document.getElementsByTagName('body')[0].classList.remove('modal-open');
    }
  }

  public deleteSchool(): void {
    if (this.schoolToDeleteId) {
      this.toggleModal('delete');
      this.adminPanelService
        .deleteSchool(this.schoolToDeleteId)
        .pipe(takeUntil(this.unsubscribe))
        .subscribe(() => {
          this.toggleModal('delete');
        });
      this.allSchoolsStatistic = [];
      this.loadStatistic(this.f.region.value.id).pipe(takeUntil(this.unsubscribe)).subscribe();
    }
  }

  public closeEditing(event: boolean): void {
    this.toggleModal('edit');
    if (event) {
      this.allSchoolsStatistic = [];
      this.loadStatistic(this.f.region.value.id).pipe(takeUntil(this.unsubscribe)).subscribe();
    }
  }

  public selectRegion(region: IRegion): void {
    this.setRegion(region).pipe(takeUntil(this.unsubscribe)).subscribe();
  }

  public selectMunicipality(municipality: IMunicipality): void {
    this.setMunicipality(municipality).pipe(takeUntil(this.unsubscribe)).subscribe();
  }

  public schoolAdded(schoolId: string): void {
    if (schoolId) {
      this.allSchoolsStatistic = [];
      this.loadStatistic(this.f.region.value.id).pipe(takeUntil(this.unsubscribe)).subscribe();
    }
  }

  public selectCity(city: ICity): void {
    if (city) {
      this.resetPageNumber();
      this.f.city.setValue(city);
      // загружаем статистику с указанием города
      this.loadStatistic(this.f.region.value.id, null, this.f.city.value.name, this.reportDate, this.reportYear)
        .pipe(takeUntil(this.unsubscribe))
        .subscribe();
    }
    return;
  }

  public setYear(year: IReportYear): void {
    if (year) {
      this.f.year.setValue(year);
      this.reportYear = year.value;
      this.resetPageNumber();
      const region: string | null = this.f.region.value ? this.f.region.value.id : null;
      const municipality: string | null = this.f.municipality.value ? this.f.municipality.value.id : null;
      const city: string | null = this.f.city.value ? this.f.city.value.name : null;
      this.loadStatistic(region, municipality, city, null, this.reportYear).pipe(takeUntil(this.unsubscribe)).subscribe();
    }
  }

  // методы сброса фильтров
  public deselectMunicipality(): void {
    this.setRegion(this.f.region.value)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(() => {
        this.f.municipality.setValue(null);
        this.f.city.setValue(null);
      });
  }

  public deselectCity(): void {
    this.setMunicipality(this.f.municipality.value)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(() => this.f.city.setValue(null));
  }

  public resetData(): void {
    this.resetPageNumber();
    this.f.region.setValue(null);
    this.f.municipality.setValue(null);
    this.f.city.setValue(null);
    this.loadStatisticWithLoader(null, null, null, this.reportDate).pipe(takeUntil(this.unsubscribe)).subscribe();
  }

  public trackByFn(index: number): number {
    return index;
  }

  private loadStatisticWithLoader(
    regionId?: string,
    municipalityId?: string,
    cityName?: string,
    reportDate?: Date,
    reportYear?: number,
  ): Observable<ISchoolStatisticReport> {
    this.overlayService.show();
    return this.loadStatistic(regionId, municipalityId, cityName, reportDate, reportYear).pipe(tap(() => this.overlayService.hide()));
  }

  private loadStatistic(
    regionId?: string,
    municipalityId?: string,
    cityName?: string,
    reportDate?: Date,
    reportYear?: number,
  ): Observable<ISchoolStatisticReport> {
    this.loading = true;
    return this.adminPanelService.getAllSchoolsStatistic(regionId, municipalityId, cityName, reportDate, 0, 0, reportYear).pipe(
      tap((statistics: ISchoolStatisticReport) => {
        if (statistics) {
          statistics.schoolStatistics.forEach((p: ISchoolStatistic) => {
            this.allSchoolsStatistic.push(p);
          });
        }
        this.schoolsStatistic = this.allSchoolsStatistic;
        this.schoolsStatisticCounters = statistics?.counters;
        this.pageNumber++;
        this.currentGroupElementsCount = this.currentGroupElementsCount + PAGE_SIZE;
        this.loadedAll = statistics?.schoolStatistics?.length < PAGE_SIZE;
        this.loading = false;
      }),
    );
  }

  private sortSchoolStatistic(fieldName: string, isStringField: boolean, orderByDesc: boolean): void {
    this.schoolsStatistic.sort((a: IAllSchoolsStatistic, b: IAllSchoolsStatistic) => {
      let fieldA: string = a[fieldName];
      let fieldB: string = b[fieldName];
      if (isStringField) {
        fieldA = fieldA.toUpperCase(); // to ignore upper and lowercase
        fieldB = fieldB.toUpperCase();
      }
      if (orderByDesc) {
        if (fieldA > fieldB) {
          return -1;
        }
        if (fieldA < fieldB) {
          return 1;
        }
      } else {
        if (fieldA < fieldB) {
          return -1;
        }
        if (fieldA > fieldB) {
          return 1;
        }
      }
      return 0;
    });
  }

  private getRegions(): Observable<IRegion[]> {
    return this.regionsService.getAllRegions().pipe(
      tap((r: IRegion[]) => {
        this.allRegions = r.filter((r: IRegion) => r.id != EmptyGuid);
      }),
    );
  }

  private setRegion(region: IRegion): Observable<IMunicipality[]> {
    if (region) {
      if (region != this.f.region.value) {
        this.f.municipality.setValue(null);
        this.f.city.setValue(null);
      }
      this.resetPageNumber();
      this.f.region.setValue(region);
      return this.loadStatistic(region.id, null, null, this.reportDate, this.reportYear).pipe(
        switchMap(() => {
          return this.municipalitiesService
            .getMunicipalitiesByRegion(region.id)
            .pipe(tap((r: IMunicipality[]) => (this.municipalitiesByRegion = r)));
        }),
      );
    }
  }

  private setMunicipality(municipality: IMunicipality): Observable<ICity[] | null> {
    if (municipality) {
      this.resetPageNumber();
      this.f.city.setValue(null);
      this.f.municipality.setValue(municipality);
      return this.loadStatistic(this.f.region.value.id, this.f.municipality.value.id, null, this.reportDate, this.reportYear).pipe(
        switchMap(() => {
          return this.citiesService.getAllCitiesByMunicipality(municipality.id).pipe(tap((r: ICity[]) => (this.citiesByMunicipality = r)));
        }),
      );
    } else {
      return of(null);
    }
  }

  private loadTerritory(): Observable<IMunicipality[]> {
    return this.getRegions().pipe(
      switchMap(() => {
        const region: IRegion = this.allRegions[0];
        return this.setRegion(region);
      }),
    );
  }

  private resetPageNumber(): void {
    this.allSchoolsStatistic = [];
    this.currentGroupElementsCount = 0;
    this.pageNumber = 0;
    this.loading = false;
    this.loadedAll = false;
    this.resetSorting();
  }

  private resetSorting(): void {
    this.sortSelectedSchoolName = false;
    this.sortSelectedTotalPupils = false;
    this.sortSelectedTotalRegPupils = false;
    this.sortSelectedTestCompleted = false;
    this.sortSelectedTestCompletedRussian = false;
    this.sortSelectedTestCompletedForeign = false;
    this.sortSelectedTotalClasses = false;
    this.sortSelectedTotalTeachers = false;
    this.selectedIndex = 1;
  }

  @HostListener('window:scroll', [])
  private onScroll(): void {
    if (window.innerHeight + window.scrollY >= document.body.offsetHeight - TRIGGER_SCROLL_BOTTOM) {
      if (!this.loading && !this.loadedAll) {
        const region: string | null = this.f.region.value ? this.f.region.value.id : null;
        const municipality: string | null = this.f.municipality.value ? this.f.municipality.value.id : null;
        const city: string | null = this.f.city.value ? this.f.city.value.name : null;
        this.loadStatistic(region, municipality, city, this.reportDate, this.reportYear).pipe(takeUntil(this.unsubscribe)).subscribe();
      }
    }
  }

  @HostListener('click', ['$event'])
  private checkClick(event: Event): void {
    if (this.regionDrop) {
      if (this.regionDrop.nativeElement.classList.contains('w--open')) {
        if (!this.regionDrop.nativeElement.contains(event.target) && !this.regionField.nativeElement.contains(event.target)) {
          this.regionDrop.nativeElement.classList.remove('w--open');
        }
      }
    }
    if (this.municipalityDrop) {
      if (this.municipalityDrop.nativeElement.classList.contains('w--open')) {
        if (!this.municipalityDrop.nativeElement.contains(event.target) && !this.municipalityField.nativeElement.contains(event.target)) {
          this.municipalityDrop.nativeElement.classList.remove('w--open');
        }
      }
    }
    if (this.cityDrop) {
      if (this.cityDrop.nativeElement.classList.contains('w--open')) {
        if (!this.cityDrop.nativeElement.contains(event.target) && !this.cityField.nativeElement.contains(event.target)) {
          this.cityDrop.nativeElement.classList.remove('w--open');
        }
      }
    }
  }
}
