import { Location } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs/internal/Subscription';
import { take } from 'rxjs/operators';
import { CourseService } from '../../../pages/catalog/courses-page-without-filters/course.service';
import { IResult, IResultsData } from '../../interfaces/results.interface';
import { ResultsService } from '../../services/results.service';
import { ReportsService } from './reports.service';

@Component({
  selector: 'prf-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss'],
})

//TODO: нигде не вызывается (перенаправила методы из ReportsService на контроллер учителя на беке)
export class ReportsComponent implements OnInit {
  @Input() schoolClassId: any;
  subscription: Subscription;
  public skills: IResult[] = [];
  public interests: IResult[] = [];
  public talents: IResult[] = [];

  public interestsData = [];
  public skillsData = [];
  public talentsData = [];
  private urlsTalentImg: any = {};
  private programsAmount: number = 1;

  sessionId: string = null;
  reportsMiddle: boolean = false;
  reportsSlash: boolean = false;
  resultsLoading: boolean = true;
  reportLoadDataFlag: boolean = false;
  loadAggregatesFlag: boolean = false;

  skillsGroupChart: IResult[] = [];
  interestsGroupChart: IResult[] = [];
  talentsGroupChart: IResult[] = [];
  fieldsDataGroupChart = [];
  dataLoadedGroupChart: boolean = false;
  sessionIdGroupChart: string = null;
  interestsDataGroupChart = [];
  skillsDataGroupChart = [];
  talentsDataGroupChart = [];

  constructor(
    private reportsService: ReportsService,
    private route: ActivatedRoute,
    private resultService: ResultsService,
    private courseService: CourseService,
    private translate: TranslateService,
    private location: Location,
  ) {
    this.programsAmount = 9;
  }

  ngOnInit() {
    this.getResultsChart();
    this.subscription = this.route.data.subscribe(() => {
      setTimeout(() => {
        this.parseResults();
      }, 1500);
    });
  }

  private getResultsChart() {
    this.reportsService
      .getSchoolClassPage(this.schoolClassId)
      .pipe(take(1))
      .subscribe(response => {
        if (response) {
          const resultsData: IResultsData = response['resultsData']
            ? response['resultsData'].json()
            : {
                results: response['results'],
              };
          if (resultsData.results != null) {
            this.skillsGroupChart = resultsData.results.filter(d => d.objectType === 'SkillAttainment');

            this.interestsGroupChart = resultsData.results.filter(d => d.objectType === 'TalentAttainment');

            this.talentsGroupChart = resultsData.results.filter(d => d.objectType === 'Talent');

            let fieldsDataGroupChart = resultsData.results.filter(d => d.objectType === 'Field');
            this.fieldsDataGroupChart = fieldsDataGroupChart.sort((a, b) =>
              a.results[0]['transformedValue'] > b.results[0]['transformedValue'] ? -1 : 1,
            );

            //TODO исправить и проверить логику этой чарты!!!
            // this.interestsDataGroupChart = this.resultService.transformInterests(
            //   this.interestsGroupChart
            // );
            // this.skillsDataGroupChart = this.resultService.transformSkills(
            //   this.skillsGroupChart,
            //   this.sessionIdGroupChart
            // );

            // получаем урл картинок для талантов
            this.resultService
              .getTalentsUrls()
              .pipe(take(1))
              .subscribe(data => {
                // преобразовываем массив с урлами талантов объект
                data.forEach(item => {
                  this.urlsTalentImg[item.name] = item.icon;
                });

                this.talentsDataGroupChart = this.preTransformTalents(this.talentsGroupChart);
                this.dataLoadedGroupChart = true;
              });
          } else {
            return (this.resultsLoading = false);
          }
        }
      });
  }

  private preTransformTalents(talents) {
    return talents
      .map(t => {
        return {
          name: t.name,
          description: t.description,
          value: t.results.length ? t.results[0].transformedValue : 0,
          imageUrl: document.location.origin + this.location.prepareExternalUrl(t.imageUrl || this.urlsTalentImg[t.name]),
          title: this.isEn() ? t.name : t.data.DativeCase,
        };
      })
      .sort((a, b) => b.value - a.value)
      .slice(0, this.resultService.TALENTS_TO_CHOOSE);
  }

  // Parse data charts
  private parseResults() {
    this.reportsService
      .getSchoolClassPercentsPage(this.schoolClassId)
      .pipe(take(1))
      .subscribe(response => {
        if (response) {
          if (response.aggregates === null) {
            this.loadAggregatesFlag = true;
          }
          const resultsData: IResultsData = response['resultsData'] ? response['resultsData'].json() : { results: response['aggregates'] };
          if (resultsData.results != null) {
            this.reportLoadDataFlag = true;
            this.skills = resultsData.results.filter(d => d.objectType === 'SkillAttainment');
            this.interests = resultsData.results.filter(d => d.objectType === 'TalentAttainment');
            this.talents = resultsData.results.filter(d => d.objectType === 'Talent');
            this.skillsData = this.skills[0].objects;
            this.interestsData = this.interests[0].objects;

            // Получаем урл картинок для талантов
            this.resultService
              .getTalentsUrls()
              .pipe(take(1))
              .subscribe(data => {
                // преобразовываем массив с урлами талантов объект
                data.forEach(item => {
                  this.urlsTalentImg[item.name] = item.icon;
                });
                this.talentsData = this.talents[0].objects;
              });
          } else {
            return (this.resultsLoading = false);
          }
        }
      });
  }

  private isEn = () => this.translate.currentLang === 'en';

  /* Что бы первый был открыт при загрузке reportsMiddle везде у него флаг true кроме него самого */
  public toggleChartControl(report) {
    if (report === 'reportsMiddle') {
      this.reportsMiddle = false;
      this.reportsSlash = false;
    } else if (report === 'reportSlash') {
      this.reportsSlash = true;
      this.reportsMiddle = true;
    }
  }
}
