import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { routeConfig } from 'app/pages/pages.router.config';
import { StepsTrajectoryComponent } from './professions/steps-trajectory/steps-trajectory.component';
import {
  NotSelectedProfessionComponent,
} from './professions/not-selected-profession/not-selected-profession.component';
import { ProfessionsService } from './professions/professions.service';
import { ParentTestPanelComponent } from './parent-test-panel/parent-test-panel.component';
import { QuestionBarComponent } from './question-bar/question-bar.component';

import { ReportsService } from './reports/reports.service';
import { ReportsComponent } from './reports/reports.component';
import { ClassResultsComponent } from './reports/class-results/class-results.component';
import {
  InterestProgressBarComponent,
} from './reports/class-results/interest-progress-bar/interest-progress-bar.component';
import { RadarChartComponent } from './reports/class-results/radar-chart/radar-chart.component';
import { TalentsComponent } from './reports/talents/talents.component';
import { SkillsComponent } from './reports/skills/skills.component';
import { InterestsComponent } from './reports/interests/interests.component';
import { PageToastComponent } from './page-toast/page-toast.component';
import { NotPassedTestComponent } from './not-passed-test/not-passed-test.component';

import { CoursesSearchComponent } from './courses/courses-catalog/courses-search/courses-search.component';
import { CourseCardComponent } from './courses/courses-catalog/course-card/course-card.component';
import { CoursesFilterComponent } from './courses/courses-catalog/courses-filter/courses-filter.component';
import {
  CoursesTypesFilterComponent,
} from './courses/courses-catalog/courses-filter/courses-types-filter/courses-types-filter.component';
import {
  CoursesGroupFilterComponent,
} from './courses/courses-catalog/courses-filter/courses-group-filter/courses-group-filter.component';
import {
  CoursesFormatFilterComponent,
} from './courses/courses-catalog/courses-filter/courses-format-filter/courses-format-filter.component';
import { CoursesCatalogComponent } from './courses/courses-catalog/courses-catalog.component';
import { CoursesFiltersService } from './courses/courses-catalog/courses-filter/courses-filters.service';
import { PaymentFormComponent } from './order/payment-form/payment-form.component';
import { GoalRecommendationComponent } from './goals/goal-recommendation/goal-recommendation.component';
import { NewsComponent } from './news/news.component';
import { AddNewsComponent } from './news/add-news/add-news.component';
import {
  DetailedProfessionCardComponent,
} from './professions/detailed-profession-card/detailed-profession-card.component';
import { GoalsTaskCardComponent } from 'app/shared/dashboard/goals/goals-task-card/goals-task-card.component';
import {
  InterestsProgressBarComponent,
} from 'app/shared/dashboard/results/interests-progress-bar/interests-progress-bar.component';
import { CompareModalComponent } from 'app/shared/dashboard/results/compare-modal/compare-modal.component';
import {
  DiagramCompareModalComponent,
} from 'app/shared/dashboard/results/diagram-compare-modal/diagram-compare-modal.component';
import { TestSwitchComponent } from 'app/shared/dashboard/results/test-switch/test-switch.component';
import { SimilarProfessionsComponent } from './professions/similar-professions/similar-professions.component';
import { ResultsService } from 'app/shared/services/results.service';
import { GoalRecommendationService } from './goals/goal-recommendation/goal-recommendation.service';
import { PupilTasksComponent } from './goals/pupil-tasks/pupil-tasks.component';
import { SharedBaseModule } from '../shared-base.module';
import { InternshipsModule } from './internships/internships.module';
import { SharedService } from 'app/shared/shared.service';
import { UtilsService } from './backend-services/utils.service';
import { HistoryService } from './backend-services/history.service';
import { ProgrammsService } from './backend-services/programms.service';
import { AdminMenuComponent } from './menus/admin-menu/admin-menu.component';
import { TestResultComponent } from './goals/test-widget/test-result.component';
import { AdminClassesComponent } from './admin-classes/admin-classes.component';
import { AdminClassComponent } from './admin-classes/admin-class/admin-class.component';
import { ContentLoaderComponent } from './content-loader/content-loader.component';
import { SchooladminWelcomeComponent } from './admin-classes/schooladmin-welcome/schooladmin-welcome.component';
import { UserControlService } from './backend-services/user-control.service';
import { AdminClassService } from './admin-classes/admin-class/admin-class.service';
import { LogoutComponent } from './logout/logout.component';
import {
  PromotestCourseCardComponent,
} from './courses/promotest-courses/promotest-course-card/promotest-course-card.component';
import { ProfessionFilterComponent } from './professions/profession-filter/profession-filter.component';
import {
  PromotestCourseDetailsComponent,
} from './courses/promotest-courses/promotest-course-details/promotest-course-details.component';
import { PageSwitcherComponent } from './page-switcher/page-switcher.component';
import { ProfessionCardNewComponent } from './professions/profession-card-new/profession-card-new.component';
import { PupilTalantCardComponent } from './cards/pupil-talant-card/pupil-talant-card.component';
import { MenuService } from './menus/admin-menu/menu.service';
import { GoalsService } from './goals/goals.service';
import { SwiperDirective } from '@profilum-directives/swiper/swiper.directive';
import { BaseChartDirective } from 'ng2-charts';
import { SpiderChartCustomComponent } from '@profilum-components/spider-chart-custom/spider-chart-custom.component';

@NgModule({
  declarations: [
    //professions
    StepsTrajectoryComponent,
    NotSelectedProfessionComponent,
    DetailedProfessionCardComponent,
    SimilarProfessionsComponent,
    ProfessionFilterComponent,
    ProfessionCardNewComponent,

    //reports
    ReportsComponent,
    ClassResultsComponent,
    InterestProgressBarComponent,
    RadarChartComponent,
    TalentsComponent,
    SkillsComponent,
    InterestsComponent,

    //courses
    CourseCardComponent,
    CoursesFilterComponent,
    CoursesSearchComponent,
    CoursesTypesFilterComponent,
    CoursesGroupFilterComponent,
    CoursesFormatFilterComponent,
    CoursesCatalogComponent,
    PromotestCourseCardComponent,
    PromotestCourseDetailsComponent,

    //results
    CompareModalComponent,
    DiagramCompareModalComponent,
    InterestsProgressBarComponent,
    TestSwitchComponent,
    PupilTalantCardComponent,

    //goals
    PupilTasksComponent,
    GoalsTaskCardComponent,
    GoalRecommendationComponent,

    //others
    AdminMenuComponent,

    ParentTestPanelComponent,
    QuestionBarComponent,
    TestResultComponent,
    PageToastComponent,
    NotPassedTestComponent,
    PaymentFormComponent,

    NewsComponent,
    AddNewsComponent,

    AdminClassesComponent,
    AdminClassComponent,
    ContentLoaderComponent,
    SchooladminWelcomeComponent,
    LogoutComponent,
    PageSwitcherComponent,
  ],
  imports: [SharedBaseModule, RouterModule.forChild(routeConfig), InternshipsModule, SwiperDirective, BaseChartDirective, SpiderChartCustomComponent],
  exports: [
    InternshipsModule,

    //professions
    StepsTrajectoryComponent,
    NotSelectedProfessionComponent,
    DetailedProfessionCardComponent,
    SimilarProfessionsComponent,
    ProfessionFilterComponent,
    ProfessionCardNewComponent,

    //reports
    ReportsComponent,
    ClassResultsComponent,
    InterestProgressBarComponent,
    RadarChartComponent,
    TalentsComponent,
    SkillsComponent,
    InterestsComponent,

    //courses
    CourseCardComponent,
    CoursesCatalogComponent,
    PromotestCourseCardComponent,
    PromotestCourseDetailsComponent,

    //results
    CompareModalComponent,
    DiagramCompareModalComponent,
    InterestsProgressBarComponent,
    TestSwitchComponent,
    PupilTalantCardComponent,

    //goals
    PupilTasksComponent,
    GoalsTaskCardComponent,
    GoalRecommendationComponent,

    //others
    AdminMenuComponent,
    PageSwitcherComponent,

    ParentTestPanelComponent,
    QuestionBarComponent,
    TestResultComponent,
    PageToastComponent,
    NotPassedTestComponent,
    PaymentFormComponent,

    NewsComponent,
    AddNewsComponent,

    AdminClassesComponent,
    AdminClassComponent,
    ContentLoaderComponent,
    SchooladminWelcomeComponent,
    LogoutComponent,
  ],
  providers: [
    SharedService,
    UtilsService,
    HistoryService,
    ProgrammsService,
    MenuService,
    ProfessionsService,
    ReportsService,
    GoalsService,
    CoursesFiltersService,
    ResultsService,
    GoalRecommendationService,
    UserControlService,
    AdminClassService,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class DashboardModule {}
