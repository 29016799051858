import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { SharedModule } from 'app/shared/shared.module';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { ChangePasswordService } from './change-password/change-password.service';
import { ConfirmEmailComponent } from './confirm-email/confirm-email.component';
import { ConfirmEmailService } from './confirm-email/confirm-email.service';
import { RestorePasswordComponent } from './restore-password/restore-password.component';
import { RestorePasswordService } from './restore-password/restore-password.service';
import { SendLinkComponent } from './send-link/send-link.component';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  imports: [BrowserModule, RouterModule, SharedModule, TranslateModule],
  declarations: [ChangePasswordComponent, RestorePasswordComponent, ConfirmEmailComponent, SendLinkComponent],
  providers: [ChangePasswordService, ConfirmEmailService, RestorePasswordService],
  bootstrap: [],
})
export class SettingsModule {}
