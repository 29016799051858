<div class="login-section">
  <div class="login-container">
    <a routerLink="/" class="login-logo w-inline-block">
      <img class="login-logo-img" src="./assets/images/dashboard/profilum-logo.svg" alt="Profilum logo" />
    </a>

    <div class="tabs login w-tabs">
      <div class="tabs-content w-tab-content">
        <div class="w-tab-pane w--tab-active">
          <div class="tab-pane login">
            <div class="user-data-form-block w-form">
              <form [formGroup]="form" (keyup.enter)="onClickLogin()" autocomplete="false">
                <div class="login-form-row">
                  <label for="email" class="login-label">{{ 'LOGIN_KZ.EMAIL' | translate }}</label>
                  <div class="login-field-column">
                    <input
                      type="email"
                      autocomplete="false"
                      class="login-field w-input"
                      maxlength="70"
                      name="email"
                      data-name="email"
                      id="email"
                      [class.error-field]="!loginForm.email['focused'] && isLoginInvalid"
                      (focusin)="loginForm.email['focused'] = true"
                      (focusout)="loginForm.email['focused'] = false"
                      formControlName="email"
                    />
                    <mat-error *ngIf="!loginForm.email['focused'] && isLoginInvalid">
                      {{ 'SHARED.FORM.CHECK_EMAIL' | translate }}
                    </mat-error>
                  </div>
                </div>
                <div class="login-form-row">
                  <label for="password" class="login-label">{{ 'SHARED.FORM.PASSWORD' | translate }}</label>
                  <div class="login-field-column">
                    <input
                      [type]="isPasswordVisible ? 'text' : 'password'"
                      autocomplete="false"
                      class="login-field w-input"
                      maxlength="25"
                      name="password"
                      data-name="password"
                      id="password"
                      [class.error-field]="!loginForm.password['focused'] && isPasswordInvalid"
                      (focusin)="loginForm.password['focused'] = true"
                      (focusout)="loginForm.password['focused'] = false"
                      formControlName="password"
                    />
                    <i (click)="togglePasswordVisibility()" class="eye" [class.closed]="isPasswordVisible"></i>
                    <mat-error *ngIf="isPasswordInvalid || passFailed">
                      {{ 'LOGIN_KZ.INCORRECT_PASSWORD' | translate }}
                    </mat-error>
                    <div class="under-field-text">
                      {{ 'LOGIN_KZ.ACCESS_CODE' | translate }}
                    </div>
                  </div>
                </div>
                <div class="w-center-content">
                  <a
                    (click)="animateLogin()"
                    [ngClass]="{ disabled: isAccessDenied }"
                    [class.btn-waiting]="buttonStates.waiting"
                    [class.btn-activated]="buttonStates.activated"
                    [class.btn-activate]="!buttonStates.active"
                    class="button-animate btn-activate"
                    id="btnActivation"
                  >
                    <span class="btn-icon"></span>
                    <span class="btn-text" data-wait="Подождите" [attr.data-after]="'SHARED.ENTRANCE' | translate">{{
                      'LOGIN_KZ.ENTER' | translate
                    }}</span>
                  </a>
                </div>
              </form>
            </div>
            <a class="recovery-link" [routerLink]="['/recovery_password']"> Забыли пароль? </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
