import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TestsDashboardComponent } from './tests-dashboard.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TestsDashboardRoutingModule } from 'app/shared/common-components/tests-dashboard/tests-dashboard-routing.module';
import { MaterialModule } from 'app/shared/modules/material.module';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';

@NgModule({
  declarations: [TestsDashboardComponent],
  imports: [CommonModule, FormsModule, ReactiveFormsModule, MatInputModule, TestsDashboardRoutingModule, MaterialModule],
  exports: [TestsDashboardComponent],
})
export class TestsDashboardModule {}
