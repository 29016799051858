import { Injectable } from '@angular/core';
import { catchError, map } from 'rxjs/operators';
import { Observable, throwError, TimeoutError } from 'rxjs';
import { HttpService } from '../../../../core/services/http.service';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable()
export class UserProfileOutletService {
  constructor(private http: HttpService) {}

  // обработка ошибок
  handleError(err: any): Observable<any> {
    if (err instanceof TimeoutError) {
      console.error(`Frontend returned timeout error: ${err['error']}`);
      return throwError(err['error']);
    }
    if (err.error instanceof Error) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', err.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(`Backend returned code ${err.status}, body was: ${err.error}`);
      let errorText = err.error ? (err.error.comment ? err.error.comment : err.error) : null;
    }
    throw throwError(err);
  }

  updateUserProfile(user: any): Observable<any> {
    return this.http.put('/api/v1.0/profiles/userprofile/update', user).pipe(
      map(r => r),
      catchError((err: HttpErrorResponse) => {
        return this.handleError(err);
      }),
    );
  }

  changeEmail(email: string): Observable<any> {
    return this.http.post('/saas/v1.0/saas/changeemail', { newEmail: email }).pipe(
      map(r => r),
      catchError((err: HttpErrorResponse) => {
        return this.handleError(err);
      }),
    );
  }

  changeUserAvatar(image: File): Observable<any> {
    let formData: FormData = new FormData();
    formData.append(image[0].name, image[0]);
    return this.http.postImage('/api/v1.0/profiles/userprofile/uploadavatar', formData).pipe(
      map(r => r),
      catchError((err: HttpErrorResponse) => {
        return this.handleError(err);
      }),
    );
  }

  getUserFavorites(userId): Observable<any> {
    return this.http.get('/api/v1.0/profiles/favorites/getfavorites?userId=' + userId).pipe(
      map(r => r),
      catchError((err: HttpErrorResponse) => {
        return this.handleError(err);
      }),
    );
  }

  changePassword(passwords: any): Observable<any> {
    return this.http.post('/saas/v1.0/saas/changepassword', passwords).pipe(
      map(r => r),
      catchError((err: HttpErrorResponse) => {
        return this.handleError(err);
      }),
    );
  }

  // получить класс ученика
  getSchoolClass(): Observable<any> {
    return this.http.get('/saas/v1.0/pupils/getschoolclass').pipe(
      map(r => r),
      catchError((err: HttpErrorResponse) => {
        return this.handleError(err);
      }),
    );
  }
}
