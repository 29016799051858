<ng-template [ngIf]="!isLoading" [ngIfElse]="loader">
  <div class="event-page-section create">
    <h1 class="event-h1">Редактирование курса</h1>
    <div class="create-event-form w-form">
      <form [formGroup]="addCourseFrom">
        <div class="create-form-row">
          <div class="create-form-column _1">
            <label class="create-form-label" for="name">Название*</label>
          </div>
          <div class="create-form-column">
            <input
              type="text"
              class="create-event-field w-input"
              [class.error-field]="submitted && formControls.name.errors"
              maxlength="256"
              name="name"
              placeholder="Название"
              id="name"
              formControlName="name"
            />
            <ng-container *ngIf="submitted && formControls.name.errors">
              <mat-error *ngIf="formControls.name.errors.required">
                {{ 'SHARED.FORM.FILL_INPUT' | translate }}
              </mat-error>
            </ng-container>
          </div>
        </div>
        <div class="create-form-row">
          <div class="create-form-column _1">
            <label class="create-form-label" for="description">Описание*</label>
          </div>
          <div class="create-form-column">
            <quill-editor
              [styles]="{ height: '150px', border: '2px solid rgba(53, 53, 53, 0.1)' }"
              [class.error-field]="submitted && formControls.description.errors"
              id="description"
              name="description"
              placeholder="Описание"
              formControlName="description"
            >
            </quill-editor>
            <ng-container *ngIf="submitted && formControls.description.errors">
              <mat-error *ngIf="formControls.description.errors.required">
                {{ 'SHARED.FORM.FILL_INPUT' | translate }}
              </mat-error>
            </ng-container>
          </div>
        </div>
        <div class="create-form-row">
          <div class="create-form-column _1">
            <label class="create-form-label">Регион</label>
          </div>
          <div class="create-form-column">
            <prf-dropdown-with-search
              formControlName="region"
              [options]="listedRegions"
              [type]="DDType.SHORT"
              [isOptional]="true"
              [label]="'Выберите регион'"
              [optionsWrapperHeight]="'250px'"
            ></prf-dropdown-with-search>
            <ng-container *ngIf="submitted && formControls.region.errors">
              <mat-error *ngIf="formControls.region.errors.required">
                {{ 'SHARED.FORM.FILL_INPUT' | translate }}
              </mat-error>
            </ng-container>
          </div>
        </div>
        <div class="create-form-row">
          <div class="create-form-column _1">
            <label class="create-form-label">Класс</label>
          </div>
          <div class="create-form-column">
            <prf-dropdown-with-search
              formControlName="grade"
              [options]="DropdownClassVariants"
              [type]="DDType.SHORT"
              [isOptional]="true"
              [label]="'SHARED.CLASS' | translate"
              [optionsWrapperHeight]="'160px'"
            ></prf-dropdown-with-search>
          </div>
        </div>

        <prf-attach-media [attachedMedia]="course.files" (changedMedia)="onChangeMedia($event)"></prf-attach-media>

        <div class="edit-event-buttons-row">
          <input type="submit" (click)="submit()" value="{{ 'SHARED.SAVE_CHANGES' | translate }}" class="button green-btn w-button" />
          <a
            class="white-button edit-event w-button"
            [class.disabled]="isRegionInvalid"
            [routerLink]="'/admin/career-education'"
            aria-label="Выйти без сохранения"
          >
            {{ 'SHARED.EXIT_WITHOUT_SAVE' | translate }}
          </a>
        </div>
      </form>
    </div>
  </div>
</ng-template>

<ng-template #loader>
  <div class="loader-container">
    <prf-content-loader></prf-content-loader>
  </div>
</ng-template>
