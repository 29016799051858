<section class="tests-dashboard">
  <form novalidate class="tests-dashboard-form">
    <mat-form-field class="tests-dashboard-form-mat-form-field">
      <input matInput type="text" name="providerId" [(ngModel)]="settings.providerId" placeholder="Введите provider id" required />
    </mat-form-field>
    <mat-error *ngIf="settings.errorMessage">{{ settings.errorMessage }}</mat-error>
    <div class="tests-dashboard-form-test-info">
      <ng-template [ngIf]="settings.results">
        <div *ngFor="let result of settings.results" class="tests-dashboard-form-test-info-panel">
          <span><strong>Провайдер: </strong>{{ result.providerName }}</span>
          <span><strong>Дата отправки: </strong>{{ result.createDate }}</span>
          <span><strong>Статус отправки: </strong>{{ result.receivedResultsStatus }}</span>
        </div>
      </ng-template>
    </div>
    <a
      class="tests-dashboard-form-button button-animate btn-activate"
      [class.btn-waiting]="loadingTestResultsInfo"
      (click)="getStatusAndDate()"
    >
      <span class="btn-icon"></span>
      <span class="btn-text" data-wait="Подождите" data-after="Получено"> Получить статус отправки результатов </span>
    </a>
    <mat-form-field class="tests-dashboard-form-mat-form-field">
      <mat-label>Выберите провайдера для отправки результатов</mat-label>
      <mat-select [required]="true" (selectionChange)="setProviderNameToSendResults($event)" [disabled]="!settings.isPossibleToSendAgain">
        <mat-option value="8">Bvb</mat-option>
        <mat-option value="9">Unti2035</mat-option>
      </mat-select>
    </mat-form-field>
    <a
      class="tests-dashboard-form-button button-animate btn-activate"
      [class.btn-waiting]="sendingTestResults"
      [class.disabled]="!(settings.isPossibleToSendAgain && settings.tag)"
      (click)="sendAgainTestResults()"
    >
      <span class="btn-icon"></span>
      <span class="btn-text" data-wait="Подождите" data-after="Отправлено"> Отправить повторно </span>
    </a>
  </form>
</section>
