import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'prf-delete-lesson',
  templateUrl: './delete-lesson.component.html',
  styleUrls: ['./delete-lesson.component.scss'],
})
export class DeleteLessonComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
