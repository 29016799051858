<div class="content-section">
  <div class="container">
    <div class="admin-header-event-page" *ngIf="course">
      <div class="edit-event-column event-page-edit">
        <!--        <div class="edit-event-col-2">-->
        <!--          <a class="delete-event w-inline-block" (click)="popUpConfirming = !popUpConfirming"></a>-->
        <!--        </div>-->
        <div class="edit-event-col">
          <prf-career-education-add-lesson (updateCourse)="updateCourse($event)"></prf-career-education-add-lesson>
        </div>
      </div>
    </div>

    <div class="event-page-section" *ngIf="course">
      <ng-template [ngIf]="!isLoading" [ngIfElse]="loader">
        <div class="catalog-course-row">
          <div class="course-column">
            <prf-career-education-course-card [course]="course"></prf-career-education-course-card>
            <div *ngIf="course?.parsedLessons.length">
              <h2 class="blocknames-title">Разделы</h2>
            </div>
            <div *ngFor="let lessonsData of course.parsedLessons" class="lesson-card">
              <prf-career-education-lesson-card
                (removeSection)="removeSection($event)"
                [lessonsData]="lessonsData"
              ></prf-career-education-lesson-card>
            </div>
          </div>
        </div>
      </ng-template>
    </div>
  </div>
</div>

<ng-template [ngIf]="popUpConfirming">
  <div class="perform-task-lb lb show">
    <div class="lb-bg"></div>
    <div class="lb-block task-lb">
      <a class="close-lb w-inline-block" (click)="popUpConfirming = !popUpConfirming">
        <img src="./src/assets/images/icons/close-lb.svg" />
      </a>
      <div class="tasl-lb-row">
        <div class="task-lb-column _2">
          <h4 class="task-lb-heading-result">{{ 'SHARED.DELETING_A_COURSE' | translate }}</h4>
          <div class="task-result-text">{{ 'SHARED.WANT_TO_DELETE_COURSE' | translate }}</div>
          <a class="button attach-file-btn w-button" (click)="removeCourse()">{{ 'BUTTONS.DELETE' | translate }}</a>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #loader>
  <div class="loader-container">
    <prf-content-loader></prf-content-loader>
  </div>
</ng-template>
