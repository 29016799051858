<div class="overlay" *ngIf="showLoader">
  <div class="overlay-loader">
    {{ text | translate }}
    <div class="load">
      <div class="circle circle1"></div>
      <div class="circle circle2"></div>
      <div class="circle circle3"></div>
    </div>
  </div>
</div>
