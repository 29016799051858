import { IRoleView, IUserRole } from '../users.interface';

export const USER_ROLES: IUserRole[] = [
  { name: 'Ученик', value: 'pupil' },
  { name: 'Родитель', value: 'parent' },
  { name: 'Учитель', value: 'teacher' },
  { name: 'Школьный администратор', value: 'schooladmin' },
  { name: 'Директор', value: 'director' },
  { name: 'Региональный/муниципальный администратор', value: 'admin' },
  { name: 'Администратор центра ДО', value: 'adminDO' },
  { name: 'Тьютор', value: 'tutor' },
  { name: 'Работодатель', value: 'employer' },
];

export const USER_VIEW_ROLES: IRoleView[] = [
  {
    value: 'director',
    viewValue: 'Директор',
    genitiveValue: 'Директора',
  },
  {
    value: 'schooladmin',
    viewValue: 'Школьный администратор',
    genitiveValue: 'Школьного администратора',
  },
  {
    value: 'admin',
    viewValue: 'Региональный/муниципальный администратор',
    genitiveValue: 'Регионального/муниципального администратора',
  },
  {
    value: 'adminDO',
    viewValue: 'Администратор центра ДО',
    genitiveValue: 'Администратора центра ДО',
  },
  {
    value: 'employer',
    viewValue: 'Работодатель',
    genitiveValue: 'Работодателя',
  },
  {
    value: 'tutor',
    viewValue: 'Тьютор',
    genitiveValue: 'Тьютора',
  },
  {
    value: 'parent',
    viewValue: 'Родитель',
    genitiveValue: 'Родителя',
  },
];
