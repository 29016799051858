import { Component, ElementRef, HostBinding, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { COURSES_TYPES } from 'app/shared/dashboard/courses/courses-catalog/courses-filter/courses-types-filter/courses-types-filter.component';
import * as moment from 'moment';
import { IClass } from '../../../../interfaces/iclass.interface';
import { CourseDetailsService } from '../../../../../pages/catalog/courses-page-without-filters/course-details/course-details.service';
import { MetroColors } from 'app/pages/catalog/courses-page/all-courses/active-courses/add-course/select-metro/metro-colors';

@Component({
  selector: 'prf-course-card',
  templateUrl: './course-card.component.html',
  styleUrls: ['./course-card.component.scss'],
})
export class CourseCardComponent implements OnInit {
  @Input() course: IClass;
  types = COURSES_TYPES;
  currentType: any = { color: '' };
  isCourse: boolean;
  userId: string;
  metroColors: MetroColors = new MetroColors();
  moment: any = moment;
  public userRole: string = '';

  @HostBinding('class.events-column') hostClass: boolean = true;
  @ViewChild('heart') heart: ElementRef;
  @ViewChild('heartActive') heartActive: ElementRef;

  constructor(private courseDetailsService: CourseDetailsService, private router: Router) {
    this.moment.locale('ru');
  }

  public ngOnInit(): void {
    this.userRole = localStorage.getItem('userRole');
    this.isCourse = this.course?.classesFormat === 'ShortCourse' || this.course?.classesFormat === 'LongCourse';
    this.currentType = this.getTypeCourse(this.course.classesFormat);
    this.userId = localStorage.getItem('userId');
  }

  calculateRealPrice() {
    return this.courseDetailsService.calculateRealPrice(this.course);
  }

  public cutText(text: string, maxLen: number): string {
    if (text && text.length > maxLen) {
      text = text.substring(0, maxLen - 2) + '..';
    }
    return text;
  }

  private getTypeCourse(value: string) {
    let type = this.types.filter(el => el.value === value);
    return type[0];
  }

  public checkAction(): void {
    this.isCourse ? this.router.navigate(['/courses', this.course.hrid]) : this.router.navigate(['/events', this.course.hrid]);
  }
}
