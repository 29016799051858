<section class="courses">
  <div class="admin-section">
    <div class="tabs w-tabs">
      <div class="tabs-menu w-tab-menu">
        <a
          (click)="tabLevel = 1"
          [class.w--current]="tabLevel === 1"
          [routerLink]="['./all-courses']"
          class="tab-link adm w-inline-block w-tab-link"
        >
          <div>Все курсы</div>
        </a>
      </div>
      <div class="tabs-content w-tab-content">
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
</section>
