<section [formGroup]="form">
  <div class="section-title">Полный отчет по диагностикам</div>
  <div class="dropdown-container" *ngIf="tests.length">
    <mat-form-field>
      <input
        (input)="setErrorForTestField()"
        (click)="onTestDropdownClick()"
        type="text"
        placeholder="Тесты"
        matInput
        [matAutocomplete]="auto"
        formControlName="testId"
      />
      <mat-autocomplete
        #auto="matAutocomplete"
        class="create-event-field single-autocomplete w-select"
        (optionSelected)="onTestDropdownChange($event)"
      >
        <mat-option *ngFor="let test of filteredTestsList | async" [value]="test">{{ test }}</mat-option>
      </mat-autocomplete>
      <mat-error *ngIf="form.get('testId').hasError('testNotSelectedBySelect')"> Пожалуйста, выберите тест из списка </mat-error>
    </mat-form-field>
  </div>
  <div *ngIf="isTestSelected" class="dropdown-container">
    <mat-form-field>
      <mat-select placeholder="Варианты тестов" class="create-event-field w-select" formControlName="variantTestId">
        <mat-option *ngFor="let testVariant of listedTestVariants" [value]="testVariant.id">{{ testVariant.name }}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div *ngIf="isTestVariantSelected" class="dropdown-container">
    <mat-form-field>
      <mat-select placeholder="Регион" class="create-event-field w-select" formControlName="regions" multiple>
        <mat-option
          *ngFor="let region of listedRegionsForFullDiagnosticReport"
          [value]="region.data"
          [disabled]="form.get('regions').value?.length > 2 && !form.get('regions').value?.includes(region.data)"
          >{{ region.name }}</mat-option
        >
      </mat-select>
    </mat-form-field>
    <mat-error *ngIf="isTestVariantSelected && !listedRegionsForFullDiagnosticReport.length">
      Не найдено регионов для выбранного теста/варианта теста.
    </mat-error>
  </div>
  <div *ngIf="isTestVariantSelected" class="dropdown-container">
    <mat-form-field>
      <mat-select placeholder="Агрегат" class="create-event-field w-select" formControlName="aggregates" multiple>
        <mat-option
          *ngFor="let aggregateItem of listedAggregates"
          [value]="aggregateItem.data"
          [disabled]="
            form.get('aggregates').value?.includes(ALL_AGGREGATES_OPTION_VALUE) &&
            !form.get('aggregates').value?.includes(aggregateItem.data)
          "
          >{{ aggregateItem.name }}</mat-option
        >
      </mat-select>
    </mat-form-field>
    <mat-error *ngIf="isTestVariantSelected && !listedAggregates.length">
      Не найдено агрегатов для выбранного теста/варианта теста. Пожалуйста, выберите другой тест/варианат теста.</mat-error
    >
  </div>
  <div *ngIf="isTestVariantSelected" class="dropdown-container">
    <mat-form-field>
      <mat-select placeholder="Вид данных" class="create-event-field w-select" formControlName="dataType">
        <mat-option *ngFor="let dataTypeItem of listedDataTypes" [value]="dataTypeItem.data">{{ dataTypeItem.name }}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div *ngIf="isTestVariantSelected" class="dropdown-container">
    <mat-form-field>
      <mat-select placeholder="Баллы в результатах" class="create-event-field w-select" formControlName="resultsPoints">
        <mat-option
          *ngFor="let resultPointItem of listedResultsPoints"
          [value]="resultPointItem.data"
          [disabled]="form.get('dataType').value === 1"
          >{{ resultPointItem.name }}</mat-option
        >
      </mat-select>
    </mat-form-field>
  </div>
  <div *ngIf="isTestVariantSelected" class="dropdown-container d-flex">
    <mat-form-field>
      <mat-date-range-input
        [rangePicker]="picker"
        [max]="fullDiagnosticReportDateConfig.maxDateRange"
        [min]="fullDiagnosticReportDateConfig.minDateRange"
      >
        <input matInput matStartDate formControlName="dateFrom" placeholder="От" readonly />
        <input matInput matEndDate formControlName="dateTo" placeholder="До" readonly (dateChange)="dateChange($event)" />
      </mat-date-range-input>
      <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-date-range-picker #picker disabled="false"></mat-date-range-picker>
    </mat-form-field>
  </div>
  <div class="section-row">
    <a
      class="button-animate btn-activate"
      [class.disabled-btn]="isFullDiagnosticReportFormInvalid()"
      (click)="generateFullDiagnosticReport()"
    >
      <span class="btn-icon"></span>
      <span class="btn-text">Получить отчет</span></a
    >
  </div>
</section>
