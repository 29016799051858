import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { UserControlService } from 'app/shared/dashboard/backend-services/user-control.service';
import { ICompletedTestsCount } from 'app/shared/interfaces/icompletedtestscount.interface';
import { ISchoolClassExtended } from 'app/shared/interfaces/ischoolclassextended.interface';
import { CookieService } from 'ngx-cookie-service';
import { of, BehaviorSubject, Observable, throwError, TimeoutError } from 'rxjs';
import { catchError, map, switchMap, take, tap } from 'rxjs/operators';
import { HttpService } from 'app/core/services/http.service';
import { RequestCache } from 'app/core/services/request-cache.service';

@Injectable()
export class SharedService {
  public callRequest$ = new BehaviorSubject<boolean>(false);
  public responseCache = new Map();
  public userInfo$ = new BehaviorSubject<any>(null);

  constructor(
    private http: HttpService,
    private userControlService: UserControlService,
    private cookieService: CookieService,
    private cache: RequestCache,
    private router: Router,
  ) {}

  // обработка ошибок
  handleError(err: any): Observable<any> {
    if (err instanceof TimeoutError) {
      console.error(`Frontend returned timeout error: ${err['error']}`);
      return throwError(err['error']);
    }
    if (err.error instanceof Error) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', err.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(`Backend returned code ${err.status}, body was: ${err.error}`);
      const errorText = err.error ? (err.error.comment ? err.error.comment : err.error) : null;
    }
    throw throwError(err);
  }

  // получить школу ученика
  getSchool(): Observable<any> {
    return this.http.get('/saas/v1.0/saas/getschool').pipe(
      map(r => r),
      catchError((err: HttpErrorResponse) => {
        return this.handleError(err);
      }),
    );
  }

  getMunicipality(municipalityId: string): Observable<any> {
    return this.http.get('/api/v1.0/catalog/municipalities/one?municipalityId=' + municipalityId).pipe(
      map(r => r.municipality),
      catchError((err: HttpErrorResponse) => {
        return this.handleError(err);
      }),
    );
  }

  // получаем информацию о пользователе, его идентификатор и прочее
  requestUserInfo(): Observable<any> {
    return this.http.get('/saas/v1.0/saas/getuserinfo').pipe(
      map(r => r),
      tap(userInfo => this.userInfo$.next(userInfo)),
      catchError((err: HttpErrorResponse) => {
        return this.handleError(err);
      }),
    );
  }

  //todo везде, где обновляется профиль пользователя, нужно вручную запросить requestUserInfo()
  getUserInfoData(): Observable<any> {
    return this.userInfo$.asObservable().pipe(
      take(1),
      switchMap(userInfo => {
        if (userInfo) {
          return of(userInfo);
        } else {
          return this.requestUserInfo().pipe(
            switchMap(userInfo => {
              //нужно, чтобы можно было получать promise
              return this.userInfo$.asObservable().pipe(
                take(1),
                switchMap(userInfo => of(userInfo)),
              );
            }),
          );
        }
      }),
    );
  }

  public getSchoolPortalRoute(): Observable<any> {
    return this.http.get('/saas/v1.0/dnevnik/getauthurl').pipe(
      map(r => r),
      catchError((err: HttpErrorResponse) => {
        return this.handleError(err);
      }),
    );
  }

  getRegionTestInfo(regionId: string) {
    return this.http.get('/api/v1.0/player/regiontestinfo?regionId=' + regionId).pipe(map(resp => resp));
  }

  public logOut(): Observable<any> {
    this.cache.clear();
    return this.http.get('/saas/v1.0/saas/logout').pipe(
      tap(r => {
        this.cookieService.deleteAll();
        localStorage.clear();
        this.router.navigate(['/'], { state: { userLogout: true } });
      }),
      map(r => r),
      catchError((err: HttpErrorResponse) => {
        return this.handleError(err);
      }),
    );
  }

  getSessionList(userId: string): Observable<any> {
    return this.http.get('/api/v1.0/player/sessions/list?userId=' + userId).pipe(
      map(r => r),
      catchError((err: HttpErrorResponse) => {
        return this.handleError(err);
      }),
    );
  }

  //-----------school class methods-----------------------
  needCall(call: boolean) {
    this.callRequest$.next(call);
  }

  public getCompletedTestsCount(schoolId: string): Observable<any> {
    const callState: boolean = this.callRequest$.getValue();
    // пока нет методов во всех контроллерах
    // let apiControlName: string = this.userControlService.userControlDomain;
    const URL = `/saas/v1.0/saas/getcompletedtestscountbyclass?schoolId=` + schoolId;
    const testsFromCache = this.responseCache.get(URL);
    if (testsFromCache && !callState) {
      return of(testsFromCache);
    }
    try {
      const response = this.http.get(URL).pipe(map(response => response as ICompletedTestsCount[]));
      response.subscribe(completedTests => this.responseCache.set(URL, completedTests));
      return response;
    } catch (e) {
      throwError(e);
      return null;
    }
  }

  public getSchoolClassesExtended(schoolId): Observable<any> {
    const callState: boolean = this.callRequest$.getValue();
    const URL = '/saas/v1.0/saas/getschoolclasseswithcounts?schoolId=' + schoolId;
    const classesFromCache = this.responseCache.get(URL);
    if (classesFromCache && !callState) {
      return of(classesFromCache);
    }
    try {
      const response = this.http.get(URL).pipe(map(response => response as ISchoolClassExtended[]));
      response.subscribe(schoolClasses => this.responseCache.set(URL, schoolClasses));
      return response;
    } catch (e) {
      throwError(e);
      return null;
    }
  }

  public getTeachers(schoolId: string): Observable<any> {
    const callState: boolean = this.callRequest$.getValue();
    // let apiControlName: string = this.userControlService.userControlDomain;
    const URL = `/saas/v1.0/saas/getteachers?schoolId=` + schoolId;
    const teachersFromCache = this.responseCache.get(URL);
    if (teachersFromCache && !callState) {
      return of(teachersFromCache);
    }
    try {
      const response = this.http.get(URL).pipe(map(response => response));
      response.subscribe(teachers => this.responseCache.set(URL, teachers));
      return response;
    } catch (e) {
      throwError(e);
      return null;
    }
  }

  public getAggregatesByTestVariantId(screeningTestId: string, variantId: string): Observable<any> {
    return this.http.get('/api/v1.0/results/aggregates/getaggregatesbytestandvariant', { screeningTestId, variantId }).pipe(take(1));
  }
}
