<div class="prf-butterfly-control">
  <div (click)="highlightCol1Elements($event)" class="prf-butterfly-control-column1" #column1>
    <span
      *ngFor="let elementInColumn1 of elementsInColumn1 | keyvalue: originalOrder"
      [attr.id]="'column1:' + elementInColumn1.key"
      [class.prf-butterfly-control-column1-item_active]="elementInColumn1.value.isHighlighted"
      class="prf-butterfly-control-column1-item"
    >
      {{ elementInColumn1.value.name }}
      <span *ngIf="elementInColumn1.value.text" class="prf-butterfly-control-column1-item-text">{{ elementInColumn1.value.text }}</span>
    </span>
  </div>
  <div class="prf-butterfly-control-arrows">
    <div (click)="clickRightArrow()" class="prf-butterfly-control-arrows-arrow">
      <div class="prf-butterfly-control-arrows-arrow-right"></div>
    </div>
    <div (click)="clickLeftArrow()" class="prf-butterfly-control-arrows-arrow">
      <div class="prf-butterfly-control-arrows-arrow-left"></div>
    </div>
  </div>
  <div (click)="highlightCol2Elements($event)" class="prf-butterfly-control-column2" #column2>
    <span
      *ngFor="let elementInColumn2 of elementsInColumn2 | keyvalue: originalOrder"
      [attr.id]="'column2:' + elementInColumn2.key"
      [class.prf-butterfly-control-column1-item_active]="elementInColumn2.value.isHighlighted"
      class="prf-butterfly-control-column2-item"
    >
      {{ elementInColumn2.value.name }}
      <span *ngIf="elementInColumn2.value.text" class="prf-butterfly-control-column2-item-text">{{ elementInColumn2.value.text }}</span>
    </span>
  </div>
</div>
