import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Meta } from '@angular/platform-browser';
import { UtilsService } from 'app/shared/dashboard/backend-services/utils.service';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { IMediaFile, IRegion } from '@profilum-library';
import {
  ICourseMaterial,
} from 'app/pages/catalog/career-education-courses-page/interfaces/career-education-courses.interface';
import {
  CareerEducationCoursesService,
} from 'app/pages/catalog/career-education-courses-page/career-education-courses.service';
import { RegionsService } from '../../../../../control-panel/admin/regions/regions.service';
import { IListedItem } from '../../../../../control-panel/admin/system/system.interface';
import { DDType } from '../../../../../../shared/elements/dropdown-with-search/dropdown-with-search.component';
import { UnsubscribeComponent } from '@profilum-components/unsubscribe/unsubscribe.component';

@Component({
  selector: 'prf-edit-career-education-course',
  templateUrl: './edit-career-education-course.component.html',
  styleUrls: ['./edit-career-education-course.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditCareerEducationCourseComponent extends UnsubscribeComponent implements OnInit {
  public addCourseFrom: UntypedFormGroup;
  public submitted: boolean = false;
  public course: ICourseMaterial;
  public isLoading: boolean = true;
  public listedRegions: IListedItem[] = [];
  public isRegionInvalid: boolean = false;
  private courseId: string = '';
  protected readonly DDType = DDType;
  protected readonly DropdownClassVariants = [
    { name: 6, data: 6 },
    { name: 7, data: 7 },
    { name: 8, data: 8 },
    { name: 9, data: 9 },
    { name: 10, data: 10 },
    { name: 11, data: 11 },
  ];

  constructor(
    private meta: Meta,
    private formBuilder: UntypedFormBuilder,
    private careerEducationCoursesService: CareerEducationCoursesService,
    private utilsService: UtilsService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private translateService: TranslateService,
    private changeDetectorRef: ChangeDetectorRef,
    private regionsService: RegionsService,
  ) {
    super();
    this.meta.updateTag({ name: 'og:title', content: 'Редактирование курса' });
  }

  public get formControls(): Record<string, AbstractControl> {
    return this.addCourseFrom.controls;
  }

  public ngOnInit(): void {
    this.getRegions();
    this.activatedRoute.params
      .pipe(
        switchMap(params => {
          this.courseId = params['courseId'];

          if (!this.courseId) {
            this.error();
            return;
          }

          return this.careerEducationCoursesService.getCourseMaterial(this.courseId);
        }),
        tap(resp => {
          if (resp?.status !== 'Success') {
            throw new Error('Bad request');
          }
        }),
        takeUntil(this.unsubscribe),
      )
      .subscribe(
        course => {
          this.course = course.courseMaterial;
          this.isRegionInvalid = !!(!this.course.regionId && this.course.regionName);
          this.initForm();
          this.isLoading = false;
          this.changeDetectorRef.detectChanges();
        },
        () => {
          this.isLoading = false;
          this.error();
        },
      );
  }

  private getRegions(): void {
    this.regionsService
      .getAllRegions()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe({
        next: (response: IRegion[]) => {
          response.forEach(region =>
            this.listedRegions.push({
              name: region.name,
              data: region.id,
            }),
          );
        },
      });
  }

  public submit(): void {
    this.submitted = true;

    if (this.addCourseFrom.valid && this.course) {
      this.course.name = this.addCourseFrom.value.name;
      this.course.description = this.addCourseFrom.value.description;

      if (this.addCourseFrom.value.region?.data) {
        this.course.regionId = this.addCourseFrom.value.region.data;
      }

      if ((!this.course.regionId && this.course.regionName) || this.addCourseFrom.value.region?.data) {
        this.course.regionId = this.addCourseFrom.value.region.data;
      }

      if (this.addCourseFrom.value.grade?.data) {
        this.course.grade = this.addCourseFrom.value.grade.data;
      }

      this.careerEducationCoursesService
        .updateCourseMaterial({ courseMaterial: this.course })
        .pipe(take(1))
        .subscribe(this.success, this.error);
    }
  }

  private initForm(): void {
    this.addCourseFrom = this.formBuilder.group({
      name: new UntypedFormControl(this.course.name, [Validators.required]),
      description: new UntypedFormControl(this.course.description, [Validators.required]),
      region: new UntypedFormControl(this.course.regionId && this.course.regionName ? this.course.regionName : '', []),
      grade: new UntypedFormControl(this.course.grade, []),
    });

    // Временная мера
    // Если у пользователя есть regionName, но нет regionId - принуждать выбирать Регион (по умолчанию - не обязательное поле)
    if (!this.course.regionId && this.course.regionName) {
      this.addCourseFrom.get('region').addValidators(Validators.required);
    }
  }

  public trackByFn(index: number): number {
    return index;
  }

  public onChangeMedia(files: IMediaFile[]): void {
    this.course.files = files;
  }

  private success = (): void => {
    this.utilsService.openSnackBar('👌 Курс обновлён, вы будете перенаправлены на страницу данного мероприятия', 'success');

    this.timer = setTimeout(() => {
      this.router.navigate(['/career-education/course-details/' + this.courseId]);
    }, 2000);
  };

  private error = (): void => {
    this.getTranslation('SHARED.ERROR_MSG')
      .pipe(
        tap((translation: string) => {
          this.utilsService.openSnackBar(translation, 'error');
        }),
      )
      .subscribe(() => {
        this.timer = setTimeout(() => {
          this.router.navigate([`/admin/career-education`]);
        }, 2000);
      });
  };

  private getTranslation(key: string): Observable<any> {
    return this.translateService.get(key);
  }
}
