import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MainGuard } from '../core/guards/main.guard';
import { LoginComponent } from './login/login.component';
import { TokenauthComponent } from './tokenauth/tokenauth.component';

const routes: Routes = [
  {
    path: '',
    component: LoginComponent,
    canActivate: [MainGuard],
    data: { hideHeader: true, userLogout: false, hideMenu: true },
  },
  { path: 'login', component: LoginComponent },
  { path: 'tokenauth', component: TokenauthComponent },
  { path: 'vorobievi-gori/login', component: LoginComponent },
  { path: 'vorobievi-gori', redirectTo: 'vorobievi-gori/registration', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule],
})
export class LandingRoutingModule {}
