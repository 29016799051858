import { registerLocaleData } from '@angular/common';
import { HttpBackend, HttpClientModule } from '@angular/common/http';
import localeRu from '@angular/common/locales/ru';
import { LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { CoreModule } from 'app/core/core.module';
import { SharedModule } from 'app/shared/shared.module';
import { CookieService } from 'ngx-cookie-service';
import { AppComponent } from './app.component';
import { AnimationsService } from './core';
import { LandingModule } from './landing/landing.module';
import { PagesModule } from './pages/pages.module';
import { routeConfig } from './router.config';
import { AppsettingsModule, AppSettingsService } from './shared/services/appsettings.service';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { MultiTranslateHttpLoader } from 'ngx-translate-multi-http-loader';
import { ProfilumLibraryModule } from '@profilum-library';
import { provideCharts, withDefaultRegisterables } from 'ng2-charts';

registerLocaleData(localeRu);

export function HttpLoaderFactory(http: HttpBackend): MultiTranslateHttpLoader {
  return new MultiTranslateHttpLoader(http, [{ prefix: './assets/translations/core/', suffix: '.json' }]);
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserAnimationsModule,
    SharedModule,
    PagesModule,
    LandingModule,
    BrowserModule,
    FormsModule,
    HttpClientModule,
    CoreModule,
    ProfilumLibraryModule,
    RouterModule.forRoot(routeConfig, {}),
    NgCircleProgressModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpBackend],
      },
    }),
  ],
  providers: [
    provideCharts(withDefaultRegisterables()),
    CookieService,
    {
      provide: LOCALE_ID,
      useValue: 'ru-RU',
    },
    AnimationsService,
    AppSettingsService,
    AppsettingsModule.init(),
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
