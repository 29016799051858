<a *ngIf="isClickable; else notClickable" [routerLink]="['/career-education/course-details/' + course.id]" class="course-card w-block">
  <ng-template [ngTemplateOutlet]="courseCard"></ng-template>
</a>

<ng-template #notClickable>
  <div class="course-card">
    <ng-template [ngTemplateOutlet]="courseCard"></ng-template>
  </div>
</ng-template>

<ng-template #courseCard>
  <div class="course-card-content" [ngClass]="{ 'course-card_not-editable': !isEditable }">
    <h2 class="course-card-content__title course-card_truncation">{{ course.name }}</h2>
    <p class="course-card-content__description-about-class w-block">
      <span *ngIf="course.grade" class="w-block">Класс: {{ course.grade }}</span>
      <span *ngIf="course.regionName" class="w-block">Регион: {{ course.regionName }}</span>
    </p>
    <ng-template [ngIf]="isEditable" [ngIfElse]="description">
      <ng-template [ngTemplateOutlet]="info"></ng-template>
      <div class="course-card-content__details">
        <ng-template [ngTemplateOutlet]="description"></ng-template>
        <div class="course-card-content__actions">
          <a (click)="toggleCopyCoursePopup()" aria-label="Копирование курса" class="course-card-content__copy w-inline-block"></a>
          <a
            [routerLink]="['/admin/career-education/edit-course/' + course.id]"
            aria-label="Редактирование курсов"
            class="course-card-content__edit w-inline-block"
          ></a>
        </div>
      </div>
    </ng-template>
  </div>
</ng-template>

<ng-template #info>
  <span *ngIf="isEditable" class="course-card-content__info w-block"> {{ countOfBlocks }} разделов {{ countOfLessons }} уроков </span>
</ng-template>

<ng-template #description>
  <p
    *ngIf="course.description"
    [title]="course.description | plainText"
    [innerHTML]="course.description"
    class="course-card-content__description course-card_multiline-truncation"
  ></p>
</ng-template>

<div class="editors-modal" *ngIf="copyPopup" [ngClass]="copyPopup ? 'active-modal' : ''">
  <div class="editors-modal-wrapper">
    <div class="editors-modal-body">
      <div class="editors-modal-dialog">
        <div class="editors-modal-dialog-body copy-popup">
          <img class="copy-popup__icon" src="assets/images/icons/close-popup.svg" (click)="toggleCopyCoursePopup()" />
          <div class="copy-popup__title">Копирование курса</div>
          <form [formGroup]="form">
            <div class="create-form-column _1">
              <label class="create-form-label copy-popup__label" for="name">Название*</label>
            </div>
            <div class="create-form-column">
              <input
                type="text"
                class="create-event-field w-input copy-popup__input"
                [class.error-field]="submitted && f.name.errors"
                maxlength="256"
                name="name"
                placeholder="Название"
                id="name"
                formControlName="name"
              />
              <ng-container *ngIf="submitted && f.name.errors">
                <mat-error *ngIf="f.name.errors.required">
                  {{ 'SHARED.FORM.FILL_INPUT' | translate }}
                </mat-error>
              </ng-container>
            </div>
          </form>
          <button (click)="submit()" class="button green-btn w-button copy-popup__button">Сохранить</button>
        </div>
      </div>
    </div>
  </div>
</div>
