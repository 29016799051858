import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'prf-interests',
  templateUrl: './interests.component.html',
  styleUrls: ['./interests.component.scss'],
})
export class InterestsComponent implements OnInit {
  @Input() data;

  // Bar
  public barChartLabels: string[] = [];
  public barChartData: number[] = [];
  public barChartLegend: boolean = true;
  public barChartType: string = 'bar';
  public chartColors: any[] = [{ backgroundColor: ['#ffa1b5', '#86c7f3', '#ffe29a', '#93d9d9', '#c964a0', '#49147e'] }];
  public barChartOptions: any = {
    scaleShowVerticalLines: false,
    responsive: true,
    fontSize: 20,
    color: '#444',
    legend: {
      display: false,
    },
    tooltips: {
      enabled: false,
      callbacks: {
        label: tooltipItem => {
          return tooltipItem.yLabel;
        },
      },
    },
    elements: {
      point: {
        radius: 0,
      },
    },
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
            stepSize: 20,
            max: 100, // max value for the chart is 100
          },
        },
      ],
      pointLabels: {
        fontSize: 16,
        fontColor: '#444',
        fontFamily: 'ALSSchlangesans, Arial',
      },
    },
  };

  constructor() {}

  ngOnInit() {
    this.data.forEach(item => {
      this.barChartLabels.push(item.name);
      this.barChartData.push(item.percents);
    });
  }
}
