import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { take, takeUntil, tap } from 'rxjs/operators';
import {
  ICourseMaterial,
  ICourseMaterialsModel,
} from 'app/pages/catalog/career-education-courses-page/interfaces/career-education-courses.interface';
import {
  CareerEducationCoursesService,
} from 'app/pages/catalog/career-education-courses-page/career-education-courses.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { UtilsService } from 'app/shared/dashboard/backend-services/utils.service';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { UnsubscribeComponent } from '@profilum-components/unsubscribe/unsubscribe.component';

@Component({
  selector: 'prf-career-education-course-card',
  templateUrl: './career-education-course-card.component.html',
  styleUrls: ['./career-education-course-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CareerEducationCourseCardComponent extends UnsubscribeComponent implements OnInit {
  @Input() course: ICourseMaterial;
  @Input() isEditable: boolean = false;
  @Input() isClickable: boolean = false;
  public countOfLessons: number = 0;
  public countOfBlocks: number = 0;
  public popUpConfirming: boolean = false;
  public submitted: boolean = false;
  public copyPopup: boolean = false;
  public form: FormGroup;

  constructor(
    private careerEducationCoursesService: CareerEducationCoursesService,
    private changeDetectorRef: ChangeDetectorRef,
    private fb: FormBuilder,
    private utilsService: UtilsService,
    private router: Router,
    private translateService: TranslateService,
  ) {
    super();
  }

  public ngOnInit(): void {
    this.form = this.fb.group({
      name: new FormControl(this.course.name + ' (1)', [Validators.required]),
    });
    this.careerEducationCoursesService
      .getCountOfLessons(this.course.id)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(countOfLessons => {
        this.countOfLessons = countOfLessons?.count ?? 0;
        this.changeDetectorRef.detectChanges();
      });

    this.careerEducationCoursesService
      .getBlockNames(this.course.id)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(blocks => {
        this.countOfBlocks = blocks?.blockNames ? Object.keys(blocks.blockNames).length : 0;
        this.changeDetectorRef.detectChanges();
      });
  }

  public toggleCopyCoursePopup(): void {
    event.preventDefault();
    event.stopPropagation();
    this.copyPopup = !this.copyPopup;
  }

  get f() {
    return this.form.controls;
  }

  public submit(): void {
    this.submitted = true;
    if (this.form.valid) {
      this.toggleCopyCoursePopup();
      const course: ICourseMaterial = {
        name: '',
        description: '',
        lessonsMaterials: [],
      };
      course.name = this.form.value.name;
      course.description = this.course.description;
      course.lessonsMaterials = this.course.lessonsMaterials;
      this.careerEducationCoursesService.createCourseMaterial({ courseMaterial: course }).pipe(take(1)).subscribe(this.success, this.error);
    }
  }

  private success = (courseData: ICourseMaterialsModel): void => {
    this.utilsService.openSnackBar('👌 Курс добавлен, вы будете перенаправлены на страницу данного мероприятия', 'success');

    this.timer = setTimeout(_ => {
      this.router.navigate(['/career-education/course-details/' + courseData.courseMaterial.id]);
    }, 1000);
  };

  private error = (): void => {
    this.getTranslation('SHARED.ERROR_MSG')
      .pipe(
        tap((translation: string) => {
          this.utilsService.openSnackBar(translation, 'error');
        }),
        take(1),
      )
      .subscribe();
  };

  private getTranslation(key: string): Observable<any> {
    return this.translateService.get(key);
  }
}
