import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  inject,
  Input,
  OnChanges,
  SimpleChanges,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
  ViewRef,
} from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';

import {
  ISpiderChartConfig,
  ISpiderChartUnit,
  SpiderChartFiguresEnum,
  SpiderChartPositioningTypeEnum,
  SpiderChartThemeEnum,
} from './spider-chart-custom.model';
import { SPIDER_CHART_CONFIG } from './spider-chart-custom.config';
import { SpiderChartService } from './spider-chart.service';
import { SpiderChartLayoutComponent } from './spider-chart-layout/spider-chart-layout.component';
import { SpiderChartPolygonComponent } from './spider-chart-polygon/spider-chart-polygon.component';
import { SpiderChartValueBlockComponent } from './spider-chart-value-block/spider-chart-value-block.component';
import { SpiderChartDifferentBlockComponent } from './spider-chart-different-block/spider-chart-different-block.component';
import { RoundNumberPipe } from '@profilum-components/spider-chart-custom/round-number.pipe';

@Component({
  selector: 'prf-spider-chart-custom',
  standalone: true,
  imports: [
    CommonModule,
    SpiderChartLayoutComponent,
    RoundNumberPipe,
    NgOptimizedImage,
    SpiderChartPolygonComponent,
    SpiderChartValueBlockComponent,
    SpiderChartDifferentBlockComponent,
  ],
  templateUrl: './spider-chart-custom.component.html',
  styleUrls: ['./spider-chart-custom.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SpiderChartCustomComponent implements OnChanges, AfterViewInit {
  protected readonly SpiderChartThemeEnum = SpiderChartThemeEnum;
  public SPIDER_CHART_CONFIG: ISpiderChartConfig = SPIDER_CHART_CONFIG;
  public spiderSVGHeight: number = this.SPIDER_CHART_CONFIG.defaultSize / this.SPIDER_CHART_CONFIG.layout.heightModifier;
  public spiderSVGWidth: number = this.SPIDER_CHART_CONFIG.defaultSize / this.SPIDER_CHART_CONFIG.layout.widthModifier;
  public polygonPoints: string = '';
  public additionalPolygonPoints: string = '';
  public resultBlocksPosition: string[] = [];
  public differentIconsPosition: string[] = [];
  public data: ISpiderChartUnit[][];

  private spiderChartService: SpiderChartService = inject(SpiderChartService);
  private changeDetector: ChangeDetectorRef = inject(ChangeDetectorRef);

  @Input({ required: true }) public set inputSpiderChartData(changes: ISpiderChartUnit[][]) {
    if (changes.length) {
      this.data = changes;
      this.getRenderData();
    }
  }
  @Input() public theme: SpiderChartThemeEnum = SpiderChartThemeEnum.GREEN;
  @Input() public positioningType: SpiderChartPositioningTypeEnum = SpiderChartPositioningTypeEnum.DEFAULT;
  @Input() public isDoubleResultsVariant: boolean = false;
  @Input() public isAdditionalResultsFirst: boolean = false;
  @Input() public figureType: SpiderChartFiguresEnum = SpiderChartFiguresEnum.HEXAGON;

  @ViewChild('content', { read: ViewContainerRef }) public content: ViewContainerRef;
  @ViewChild('simpleResultsTemplate') public simpleResultsTemplate: TemplateRef<any>;
  @ViewChild('additionalResultsTemplate') public additionalResultsTemplate: TemplateRef<any>;
  @ViewChild('doubleResultsTemplate') public doubleResultsTemplate: TemplateRef<any>;

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.isDoubleResultsVariant || changes.isAdditionalResultsFirst) {
      this.checkNeededTemplate();
    }
  }

  public ngAfterViewInit(): void {
    this.checkNeededTemplate();
  }

  private changeViewContent(view: ViewRef): void {
    this.content?.clear();
    this.content?.insert(view);
  }

  private checkNeededTemplate(): void {
    if (this.isDoubleResultsVariant) {
      this.changeViewContent(this.doubleResultsTemplate?.createEmbeddedView(null));
    } else if (this.isAdditionalResultsFirst) {
      this.changeViewContent(this.additionalResultsTemplate?.createEmbeddedView(null));
    } else {
      this.changeViewContent(this.simpleResultsTemplate?.createEmbeddedView(null));
    }

    this.changeDetector.detectChanges();
  }

  public getRenderData(): void {
    this.polygonPoints = this.spiderChartService.getPolygonPoints(this.data[0], this.spiderSVGHeight, this.spiderSVGWidth, this.figureType);
    this.additionalPolygonPoints = this.spiderChartService.getPolygonPoints(
      this.data[1],
      this.spiderSVGHeight,
      this.spiderSVGWidth,
      this.figureType,
    );
    this.resultBlocksPosition = this.spiderChartService.getResultsBlocksPosition(
      this.data[0],
      this.spiderSVGHeight,
      this.spiderSVGWidth,
      this.figureType,
      this.positioningType,
    );
    this.differentIconsPosition = this.spiderChartService.getDifferentBlocksPosition(
      this.data,
      this.spiderSVGHeight,
      this.spiderSVGWidth,
      this.figureType,
    );
  }
}
