<div class="content-section">
  <div class="container">
    <div class="tabs">
      <div class="tabs-professions">
        <div [class.no-display]="tabName !== 'catalog'" class="tabs-content">
          <div class="tab-pane left">
            <prf-courses-catalog></prf-courses-catalog>
          </div>
        </div>

        <div [class.no-display]="tabName !== 'recommendations'" class="tabs-content">
          <div class="tab-pane left"></div>
        </div>
      </div>
    </div>
  </div>
</div>
