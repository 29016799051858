import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { SharedService } from 'app/shared/shared.service';
import { UtilsService } from 'app/shared/dashboard/backend-services/utils.service';

import { catchError, mergeMap, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { IExtendedPupil, ExtendedPupil } from '../../../interfaces/iextendedpupil.interface';
import { IFavoriteProfessionInterface } from '../../../interfaces/ifavoriteprofession.interface';
import { ISchoolClass } from '../../../interfaces/ischoolclass.interface';
import { ITeacher } from '../../../interfaces/iteacher.interface';
import { OverlayBusyService } from '../../../overlay-busy/overlay-busy.service';

import { forkJoin as observableForkJoin } from 'rxjs/internal/observable/forkJoin';
import { Observable, of, Subject, throwError } from 'rxjs';
import { AdminClassService } from './admin-class.service';
import { Output, EventEmitter } from '@angular/core';
import { UserRoles } from 'app/shared/enums/userroles.enum';
import { SchoolAdminPanelService } from 'app/shared/services/schooladmin-panel.service';

@Component({
  selector: 'prf-admin-class',
  templateUrl: './admin-class.component.html',
  styleUrls: ['./admin-class.component.scss'],
})
export class AdminClassComponent implements OnInit, OnDestroy {
  @Output() teacherEvent = new EventEmitter<ITeacher>();
  @Output() schoolClassEvent = new EventEmitter<ISchoolClass>();
  @Output() pupilsEvent = new EventEmitter<IExtendedPupil[]>();
  @Output() editClassEvent = new EventEmitter<boolean>();
  @Output() editPupilsEvent = new EventEmitter<boolean>();
  schoolClass: ISchoolClass;
  teacher: ITeacher;
  pupils: IExtendedPupil[] = [];
  selectedPupil: IExtendedPupil;
  favoriteProfessions: any = [];
  showPupilPage: boolean = false;
  dataFetched: boolean = false;
  isFixed: boolean = false;
  userRole: string = '';
  userRoles = UserRoles;
  userToDelete: any;
  showDeletePopUp: boolean = false;
  private ngUnsubscribe$: Subject<any> = new Subject();

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private meta: Meta,
    private overlayService: OverlayBusyService,
    private route: ActivatedRoute,
    private utilsService: UtilsService,
    private router: Router,
    private sharedService: SharedService,
    private adminClassService: AdminClassService,
    private schoolAdminPanelService: SchoolAdminPanelService,
  ) {
    this.meta.updateTag({ name: 'og:title', content: 'Школьный класс' });
    this.userRole = localStorage.getItem('userRole');
  }

  ngOnInit() {
    this.getSchoolClass()
      .pipe(
        mergeMap(sc => {
          if (sc) {
            return this.adminClassService.getPupilPage().pipe(
              mergeMap(data => {
                this.showPupilPage = data.showPupilPage;
                if (!this.showPupilPage) {
                  return this.getDashboardData();
                } else {
                  return of(null);
                }
              }),
            );
          } else {
            return of(null);
          }
        }),
        takeUntil(this.ngUnsubscribe$),
      )
      .subscribe(() => this.overlayService.hide());
  }

  public getSchoolClass(): Observable<any> {
    this.overlayService.show();
    const classID = this.route.snapshot.paramMap.get('classID');
    return this.adminClassService.getSchoolClass(classID).pipe(
      tap(schoolClass => {
        if (schoolClass === 'CANT_FIND') {
          let userRole = localStorage.getItem('userRole');
          switch (userRole) {
            case 'admin':
              let schoolId = this.schoolClass.schoolId;
              this.router.navigate([`/admin/schools/${schoolId}`]);
              break;
            case 'schooladmin':
              this.router.navigate(['/schooladmin/classes']);
              break;
            default:
              this.router.navigate(['/']);
              break;
          }

          return this.utilsService.openSnackBar('👎 Данный класс не был найден', 'error');
        } else {
          this.schoolClass = {
            className: schoolClass.className,
            id: schoolClass.id,
            letter: schoolClass.letter,
            number: schoolClass.number,
            pupilsCount: schoolClass.pupilsCount,
            schoolId: schoolClass.schoolId,
            teacherId: schoolClass.teacherId,
          };
          this.schoolClassEvent.emit(this.schoolClass);
          return this.schoolClass;
        }
      }),
      catchError(err => {
        this.utilsService.openSnackBar('👎 Ошибка на сервере, попробуйте позже', 'error');
        return throwError(err);
      }),
    );
  }

  getDashboardData(): Observable<any> {
    return observableForkJoin([
      // получаем учителя класса
      this.adminClassService.getTeacher(this.schoolClass.id, this.schoolClass.schoolId),
      // получаем расширенный список учеников
      this.adminClassService.getExtendedPupils(this.schoolClass.id),
      //получаем полный список учеников
      this.adminClassService.getPupils(this.schoolClass.id),
    ]).pipe(
      switchMap(([teacher, extendedPupils, pupils]) => {
        this.teacher = teacher;
        this.teacherEvent.emit(this.teacher);
        // совпадающие ученики
        let pupilsBase = extendedPupils.filter(exPupil => pupils.some(p => p.userId === exPupil.userId));

        //недавно добавленные ученики
        let userIds = pupilsBase.map(x => x.userId);
        let newPupils = pupils
          .filter(x => userIds.indexOf(x.userId) < 0)
          .map(user => {
            return new ExtendedPupil(user);
          });
        this.pupils = [...pupilsBase, ...newPupils];

        this.pupilsEvent.emit(this.pupils);
        return this.getFavoritesProfessions(this.pupils);
      }),
    );
  }

  showEditPupils() {
    this.editPupilsEvent.emit(true);
  }

  showEditClass() {
    this.editClassEvent.emit(true);
  }

  refreshPage(refresh) {
    if (refresh) {
      this.overlayService.show();
      return this.getSchoolClass()
        .pipe(takeUntil(this.ngUnsubscribe$))
        .subscribe(() => this.overlayService.hide());
    }
  }

  showElement(event: any) {
    event.target.nextSibling.style.display = 'block';
  }

  hideElement(event: any) {
    event.target.nextSibling.style.display = 'none';
  }

  showSelectedPupil(pupil: any) {
    this.selectedPupil = pupil;
    this.adminClassService.showPupilPage();
  }

  isTestOld(lastTestDate: Date): boolean {
    var dateCheck = new Date();
    var dateCompare = new Date(lastTestDate);
    dateCheck.setMonth(dateCheck.getMonth() - 3);
    if (dateCompare > dateCheck) {
      return false;
    }
    return true;
  }

  getFavoritesProfessions(pupils: any): Observable<any> {
    let studentsWithProfessions = pupils.filter(el => el.favoriteProfessions.length > 0);
    let professionsIds = studentsWithProfessions.map(el => el.favoriteProfessions.map(e => e.productId || e.id));
    professionsIds = professionsIds.reduce((a, b) => a.concat(b), []);
    let unique = [...new Set(professionsIds)];
    if (this.userRole === 'admin') {
      return this.adminClassService.getUserProfessionsInGoalsByIdsMongo(unique).pipe(
        tap(r => {
          this.favoriteProfessions = r;
          this.parseStudentsProfessions(this.pupils);
        }),
      );
    } else {
      return this.adminClassService.getUserProfessionsInGoalsByIdsElastic(unique).pipe(
        tap(r => {
          this.favoriteProfessions = r;
          this.parseStudentsProfessions(this.pupils);
        }),
      );
    }
  }

  private parseStudentsProfessions(pupils: any) {
    pupils.forEach((student, i) => {
      if (student.favoriteProfessions.length > 0) {
        let professionsIds = student.favoriteProfessions.map(e => e.productId || e.id);
        let unique = [...new Set(professionsIds)];
        let professions: IFavoriteProfessionInterface[] = [];
        unique.forEach(id => {
          this.favoriteProfessions.find(el => el.id === id) ? professions.push(this.favoriteProfessions.find(el => el.id === id)) : null;
        });
        this.pupils[i].favoriteProfessions = professions;
      }
    });
    this.dataFetched = true;
  }

  getImageUrl(user: any) {
    return user.imagePath ? user.imagePath : '/assets/images/dashboard/no-photo.svg';
  }

  getImagePath(job: any) {
    if (job.mainImagePath) {
      job.mainImagePath = job.mainImagePath.replace('/content/images/professions/default_icons/', '/assets/images/profession/');
    } else {
      job.mainImagePath = '/assets/images/dashboard/petard.png';
    }
    return job.mainImagePath;
  }

  ngOnDestroy() {
    this.adminClassService.closePupilPage();
    this.ngUnsubscribe$.next(null);
    this.ngUnsubscribe$.complete();
  }

  deleteStudent(user) {
    this.schoolAdminPanelService
      .removeUnregisteredPupil(user.userId)
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe(
        r => {
          this.utilsService.openSnackBar('👌 Ученик удален', 'success');
          this.pupils = this.pupils.filter(x => x.userId != user.userId);
        },
        catchError(err => {
          this.utilsService.openSnackBar('👎 Произошла ошибка', 'error');
          return throwError(err);
        }),
      );
  }

  resetPasswordToTeacher() {
    this.schoolAdminPanelService
      .resetPasswordToCode(this.teacher.email)
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe(
        r => {
          this.utilsService.openSnackBar('👌 Пароль изменен', 'success');
          this.showDeletePopUp = false;
        },
        catchError(err => {
          this.utilsService.openSnackBar('👎 Произошла ошибка', 'error');
          return throwError(err);
        }),
      );
  }

  closeResetPopUp() {
    this.showDeletePopUp = false;
  }

  showResetPopUp() {
    this.showDeletePopUp = true;
  }
}
