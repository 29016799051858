<a class="button" (click)="openModal()">Перевести учителя / Добавить класс</a>
<div *ngIf="reassignTeacherFlag">
  <div class="overlay">
    <div class="modal">
      <p (click)="closeModal()">
        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 35 35">
          <g fill-rule="evenodd" stroke-linecap="square">
            <path d="M9.192 9.192l18.385 18.385M27.577 9.192L9.192 27.577"></path>
          </g>
        </svg>
      </p>

      <form novalidate [formGroup]="form" (submit)="onSubmit()">
        <h3>{{ 'SHARED.REASSIGN_TEACHER' | translate }} {{ teacher.lastName }} {{ teacher.firstName }} {{ teacher.middleName }}</h3>

        <ng-container *ngIf="loaded; else loader">
          <div class="reassign-teacher__tabs-contaner">
            <a
              class="button reassign-teacher__tab"
              [class.disabled]="onlyAddNewClass"
              [class.reassign-teacher__tab_not-active]="onlyAddNewClass || !transferFromSchoolClassFlag"
              (click)="toggleReassignMode(true)"
            >
              Перевод
            </a>
            <a
              class="button reassign-teacher__tab"
              [class.reassign-teacher__tab_not-active]="transferFromSchoolClassFlag"
              (click)="toggleReassignMode(false)"
            >
              Добавление
            </a>
          </div>

          <ng-container *ngIf="transferFromSchoolClassFlag; else addNewClass">
            <p>Старый класс</p>
            <mat-select
              placeholder="Школа"
              formControlName="sourceSchool"
              name="sourceSchool"
              class="w-input"
              [class.error-field]="submitted && f.sourceSchool.errors"
            >
              <mat-option *ngFor="let school of allSchoolsByTeacher" [value]="school">
                {{ school.number }}
              </mat-option>
            </mat-select>

            <mat-select
              placeholder="Класс"
              formControlName="sourceSchoolClass"
              name="sourceSchoolClass"
              class="w-input"
              [class.error-field]="submitted && f.sourceSchoolClass.errors"
            >
              <mat-option *ngFor="let schoolClass of allSchoolClassesByTeacher" [value]="schoolClass">
                {{ schoolClass?.className }}
              </mat-option>
            </mat-select>

            <p>Новый класс</p>
            <mat-select
              placeholder="Школа"
              formControlName="targetSchool"
              name="targetSchool"
              class="w-input"
              [class.error-field]="submitted && f.targetSchool.errors"
            >
              <mat-option *ngFor="let school of allSchoolsByCity" [value]="school">
                {{ school.number }}
              </mat-option>
            </mat-select>

            <mat-select
              placeholder="Класс"
              formControlName="targetSchoolClass"
              name="targetSchoolClass"
              class="w-input"
              [class.error-field]="submitted && f.targetSchoolClass.errors"
            >
              <mat-option *ngFor="let schoolClass of targetSchoolClasses" [value]="schoolClass">
                {{ schoolClass?.className }}
              </mat-option>
            </mat-select>
          </ng-container>

          <ng-template #addNewClass>
            <p>Новый класс</p>
            <mat-select
              placeholder="Школа"
              formControlName="newSchool"
              name="newSchool"
              class="w-input"
              [class.error-field]="submitted && f.newSchool.errors"
            >
              <mat-option *ngFor="let school of allSchoolsByCity" [value]="school">
                {{ school.number }}
              </mat-option>
            </mat-select>

            <mat-select
              placeholder="Класс"
              formControlName="newSchoolClass"
              name="newSchoolClass"
              class="w-input"
              [class.error-field]="submitted && f.newSchoolClass.errors"
            >
              <mat-option *ngFor="let schoolClass of newSchoolClasses" [value]="schoolClass">
                {{ schoolClass?.className }}
              </mat-option>
            </mat-select>
          </ng-template>

          <button type="submit" class="button green-btn w-button">{{ getButtonText() }}</button>
        </ng-container>
      </form>

      <ng-template #loader>
        <prf-simple-loader></prf-simple-loader>
      </ng-template>
    </div>
  </div>
</div>
