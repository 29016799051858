import { ChangeDetectionStrategy, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Meta } from '@angular/platform-browser';
import { take, tap } from 'rxjs/operators';
import { UtilsService } from 'app/shared/dashboard/backend-services/utils.service';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import {
  ICourseMaterial,
  ICourseMaterialsModel,
} from 'app/pages/catalog/career-education-courses-page/interfaces/career-education-courses.interface';
import {
  CareerEducationCoursesService,
} from 'app/pages/catalog/career-education-courses-page/career-education-courses.service';
import { UnsubscribeComponent } from '@profilum-components/unsubscribe/unsubscribe.component';

@Component({
  selector: 'prf-add-career-education-course',
  templateUrl: './add-career-education-course.component.html',
  styleUrls: ['./add-career-education-course.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddCareerEducationCourseComponent extends UnsubscribeComponent implements OnInit {
  public addCourseFrom: UntypedFormGroup;
  public submitted: boolean = false;
  private readonly course: ICourseMaterial = {
    name: '',
    description: '',
    lessonsMaterials: [],
  };

  constructor(
    private meta: Meta,
    private formBuilder: UntypedFormBuilder,
    private careerEducationCoursesService: CareerEducationCoursesService,
    private utilsService: UtilsService,
    private router: Router,
    private translateService: TranslateService,
  ) {
    super();
    this.meta.updateTag({ name: 'og:title', content: 'Добавление курса' });
  }

  get formControls(): { [p: string]: AbstractControl } {
    return this.addCourseFrom.controls;
  }

  public ngOnInit(): void {
    this.initForm();
  }

  public submit(): void {
    this.submitted = true;

    if (this.addCourseFrom.valid) {
      this.course.name = this.addCourseFrom.value.name;
      this.course.description = this.addCourseFrom.value.description;

      this.careerEducationCoursesService
        .createCourseMaterial({ courseMaterial: this.course })
        .pipe(take(1))
        .subscribe(this.success, this.error);
    }
  }

  private initForm(): void {
    this.addCourseFrom = this.formBuilder.group({
      name: new UntypedFormControl(this.course.name, [Validators.required]),
      description: new UntypedFormControl(this.course.description, [Validators.required]),
    });
  }

  private success = (courseData: ICourseMaterialsModel): void => {
    this.utilsService.openSnackBar('👌 Курс добавлен, вы будете перенаправлены на страницу данного мероприятия', 'success');

    this.timer = setTimeout(_ => {
      this.router.navigate(['/career-education/course-details/' + courseData.courseMaterial.id]);
    }, 1000);
  };

  private error = (): void => {
    this.getTranslation('SHARED.ERROR_MSG')
      .pipe(
        tap((translation: string) => {
          this.utilsService.openSnackBar(translation, 'error');
        }),
        take(1),
      )
      .subscribe();
  };

  private getTranslation(key: string): Observable<any> {
    return this.translateService.get(key);
  }
}
