export const DataTypeList = [
  {
    name: 'Ответы',
    data: 1,
  },
  {
    name: 'Результаты',
    data: 2,
  },
  {
    name: 'Ответы + Результаты',
    data: 3,
  },
];

export const ResultsPointsList = [
  {
    name: 'Сырой балл',
    data: 1,
  },
  {
    name: 'Процент',
    data: 2,
  },
  {
    name: 'Сырой балл + Процент',
    data: 3,
  },
];

export const ALL_AGGREGATES_OPTION_VALUE = 'ALL';
