import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import { environment } from '../environments/environment';
import { routeAnimations } from './core';
import { AppSettingsService } from './shared/services/appsettings.service';

@Component({
  selector: 'prf-app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [routeAnimations],
})
export class AppComponent implements OnInit {
  constructor(private translate: TranslateService, private router: Router, private appSettingsService: AppSettingsService) {}

  public ngOnInit(): void {
    this.setLocale().subscribe(() => this.initApp());
  }

  public setLocale(): Observable<any> {
    let locale: string;
    if (environment.eng) {
      locale = 'en';
    } else {
      locale = 'ru';
    }
    if (location.href.includes('kz')) {
      locale = 'kz';
    }
    this.translate.setDefaultLang(locale);
    this.appSettingsService.setDefaultTranslation();
    return this.translate.use(locale);
  }

  private initApp() {
    let prevPath: string = window.location.href;
    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(() => {
      const newPath = window.location.href;
      prevPath = newPath;
    });
  }
}
