import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SharedService } from 'app/shared/shared.service';
import { EUserTags } from 'app/shared/enums/user-types.enum';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { ICookieInterface } from 'app/shared/interfaces/icookie';
import { HttpService } from 'app/core/services/http.service';

@Injectable()
export class MainGuard  {
  constructor(private router: Router, private http: HttpService, public route: ActivatedRoute, private sharedService: SharedService) {}

  canActivate(): Observable<boolean> {
    let userLogout: boolean = this.router.getCurrentNavigation().extras.state?.userLogout;

    if (userLogout) {
      return of(true);
    }

    return this.checkCookie().pipe(
      switchMap((cookieResponse: ICookieInterface) => {
        if (localStorage.getItem('tag') === EUserTags[EUserTags.VorobieviGori].toString()) {
          return of(false);
        }
        if (cookieResponse.status !== 'Success') {
          this.sharedService.logOut();
          return of(true);
        } else {
          const token = localStorage.getItem('userRole');

          if (token !== 'admin') return of(true);

          this.router.navigate(['/admin/schools']);
          return of(false);
        }
      }),
      catchError((err: HttpErrorResponse) => {
        // если получаем ошибку 401 Unauthorized, то попадаем на '/'
        // в этом случае нельзя выполнить /logout
        if (err.status >= 500 && err.status < 600) {
          this.sharedService.logOut();
        }
        return of(true);
      }),
    );
  }

  public checkCookie() {
    return this.http.get('/saas/v1.0/saas/checkcookie').pipe(map((r: ICookieInterface) => r));
  }
}
