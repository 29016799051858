import { Component, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { InternshipsService } from 'app/shared/dashboard/internships/internships.service';
import { AGE_DATA, COURSES_TYPES } from 'app/shared/global-constants/constants';
import { ISchool } from 'app/shared/interfaces/ischool.interface';
import { OverlayBusyService } from 'app/shared/overlay-busy/overlay-busy.service';
import * as moment from 'moment';
import { take, takeUntil } from 'rxjs/operators';
import { InternshipService } from 'app/shared/dashboard/internships/internship.service';
import { InternshipDetailsService } from './internship-details.service';
import { Internship } from 'app/shared/classes/internship.class';
import { InternshipFormat } from 'app/shared/enums/internship-format.enum';
import { Subject } from 'rxjs';
import { UserProfileOutletService } from 'app/pages/catalog/user-profile-page/user-profile-outlet/user-profile-outlet.service';

export const INTERNSHIP_FORMAT = [
  { value: InternshipFormat.OFFLINE, viewValue: 'Дистанционный' },
  { value: InternshipFormat.ONLINE, viewValue: 'Очный' },
  { value: InternshipFormat.MIXED, viewValue: 'Смешанный' },
];

@Component({
  selector: 'prf-internship-details',
  templateUrl: './internship-details.component.html',
  styleUrls: ['./internship-details.component.scss'],
})
export class InternshipDetailsComponent implements OnInit {
  subscription: any;
  internship: Internship;
  types: any = COURSES_TYPES;
  userId: string;

  hrid: string;
  title: string;
  mapHeader: string;
  userRole: string;
  adminLevel: string = '';
  color: string;
  complexity: string = '';
  format: string = '';
  dataFetched: boolean = false;
  popUpConfirming: boolean = false;
  mapsLoaded: boolean = false;

  moment: any;
  testCompleted: boolean = false;
  school: ISchool;

  private ngUnsubscribe$: Subject<any> = new Subject();

  constructor(
    private meta: Meta,
    private internshipDetailsService: InternshipDetailsService,
    private profileService: UserProfileOutletService,
    private internshipService: InternshipService,
    private route: ActivatedRoute,
    private internshipsService: InternshipsService,
    private overlayService: OverlayBusyService,
    private router: Router,
  ) {
    this.meta.updateTag({ name: 'og:title', content: 'Стажировка' });
    this.userRole = localStorage.getItem('userRole');
    if (this.userRole && this.userRole.includes('admin')) {
      this.adminLevel = localStorage.getItem('adminLevel');
    }
    this.userId = localStorage.getItem('userId');
    this.moment = moment;
    this.moment.locale('ru');
  }

  ngOnInit() {
    this.overlayService.show();
    const sessionStatus = localStorage.getItem('sessionStatus');
    this.testCompleted = sessionStatus === 'Success';
    this.subscription = this.route.params.subscribe(params => {
      this.hrid = params['hrid'];
      window.scrollTo(0, 0);
      this.loadCurrentInternship();
    });
    this.overlayService.hide();
  }

  backButtonUrl() {
    return '/admin/internships';
  }

  loadCurrentInternship() {
    this.internshipService
      .getInternshipByHRID(this.hrid)
      .pipe(take(1))
      .subscribe(async data => {
        this.internship = data;
        const format = INTERNSHIP_FORMAT.find(c => c.value === this.internship.format);
        this.format = format ? format.viewValue : '';
        this.dataFetched = true;
      });
  }

  deleteInternship(internshipID) {
    this.internshipsService
      .removeCurrentInternship(internshipID)
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe(r => {
        this.popUpConfirming = !this.popUpConfirming;
        return this.router.navigate(['/' + this.userRole + '/internships']);
      });
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
