import { filter, map, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { OverlayBusyService } from 'app/shared/overlay-busy/overlay-busy.service';
import { LessonsService } from './lessons.service';
import { ILesson } from 'app/shared/interfaces/ilesson';
import { Observable } from 'rxjs';
import {
  ICourseMaterial,
} from 'app/pages/catalog/career-education-courses-page/interfaces/career-education-courses.interface';
import { UtilsService } from 'app/shared/dashboard/backend-services/utils.service';
import { IField, IMappedLesson, ITest, IVariant } from 'app/pages/control-panel/admin/lessons/interfaces/interfaces';
import { IFilter } from 'app/shared/common-components/dropdown-with-filter/dropdown-with-filter.component';
import { Helper } from 'app/shared/common/helpers/helper';
import { ActivatedRoute, Router } from '@angular/router';
import { AdminPanelService } from '../admin-panel.service';
import { IProfession } from '../../../../shared/interfaces/iprofession.interface';
import { UnsubscribeComponent } from '@profilum-components/unsubscribe/unsubscribe.component';

@Component({
  selector: 'prf-lessons',
  templateUrl: './lessons.component.html',
  styleUrls: ['./lessons.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LessonsComponent extends UnsubscribeComponent implements OnInit {
  public mappedLessons: { [key: string]: IMappedLesson } = {};
  public popUpConfirming: boolean = false;
  public removedLessonId: string;
  public toggleLessonId: string;
  public courseMaterialsFilter: IFilter[];
  public selectedCourseId: string = null;
  public lessonsCountInfo: string = '';
  public lessonsWithoutCourse: ILesson[];
  private fragment: string;
  public lessons: ILesson[] = [];
  private cachedLessons: ILesson[];
  private courseMaterials: ICourseMaterial[];
  private courseMaterialIds: string[];

  constructor(
    private lessonsService: LessonsService,
    private overlayBusyService: OverlayBusyService,
    private changeDetectorRef: ChangeDetectorRef,
    private utilsService: UtilsService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private adminPanelService: AdminPanelService,
  ) {
    super();
  }

  public ngOnInit(): void {
    this.overlayBusyService.show();
    this.lessonsService
      .getAllLesson()
      .pipe(
        switchMap(lessons => {
          this.cachedLessons = lessons.sort((lesson1, lesson2) => lesson1.orderNumber - lesson2.orderNumber);

          return this.lessonsService.getCourseMaterials();
        }),
        filter((courseMaterials: ICourseMaterial[]) => !!courseMaterials?.length),
        tap((courseMaterials: ICourseMaterial[]) => {
          let lessons = [];
          let lessonsWithoutCourse = [];
          const courseMaterialsObject = this.getCourseMaterialsObject(this.cachedLessons);

          this.courseMaterials = courseMaterials.filter(el => Helper.isDefined(courseMaterialsObject[el.id]));
          this.courseMaterialIds = this.courseMaterials.map(el => el.id);
          this.courseMaterialsFilter = this.setCourseMaterialsFilter(this.courseMaterials);
          this.getMappedResults();
          if (this.courseMaterials.length !== courseMaterials.length) {
            this.cachedLessons.forEach(lesson => {
              if (this.courseMaterialIds.includes(lesson.courseMaterialId)) {
                lessons.push(lesson);
              } else {
                lessonsWithoutCourse.push(lesson);
              }
            });

            this.lessons = lessons;
          } else {
            this.lessons = this.cachedLessons;
          }
          this.lessonsWithoutCourse = lessonsWithoutCourse;
        }),
        takeUntil(this.unsubscribe),
      )
      .subscribe(
        () => {
          this.lessonsCountInfo = `Всего занятий: ${this.lessons.length}`;
          this.overlayBusyService.hide();
          this.changeDetectorRef.detectChanges();

          if (this.fragment) {
            const lesson = this.lessons.find(lesson => lesson.lessonMaterialId === this.fragment);

            if (!lesson) {
              const course = this.courseMaterials.find(courseMaterial =>
                courseMaterial.lessonsMaterials.some(lessonMaterial => lessonMaterial.id === this.fragment),
              );

              if (course) {
                this.lessonsService.dataForCreatingLesson = {
                  lessonMaterialId: this.fragment,
                  courseMaterialId: course.id,
                };
                this.router.navigate(['/admin/create-lesson']);
              }
            } else {
              this.toggle(lesson.id);
              lesson ? document.getElementById(this.toggleLessonId)?.scrollIntoView({ behavior: 'smooth' }) : null;
            }
          }
        },
        error => {
          console.error(error);
          this.overlayBusyService.hide();
        },
      );

    this.activatedRoute.fragment
      .pipe(
        filter(fragment => !!fragment),
        takeUntil(this.unsubscribe),
      )
      .subscribe(fragment => {
        this.fragment = fragment;
      });
  }

  public toggle(lessonId: string): void {
    if (this.toggleLessonId === lessonId) {
      return;
    }

    this.toggleLessonId = lessonId;

    const lesson = this.cachedLessons.find(lesson => lesson.id === lessonId);

    this.mappedLessons[lesson.id].test = this.setSelectedScreeningTestTask(lesson);
    this.mappedLessons[lesson.id].field = this.setSelectedChooseProfessionsFromFieldTask(lesson);
    this.mappedLessons[lesson.id].professions = this.setSelectedProfessions(lesson);
  }

  public selectedCourse(event: IFilter): void {
    this.selectedCourseId = event !== null ? (event.id as string) : null;
    this.setMappedResults();
  }

  public removeLesson(): void {
    this.lessonsService
      .deleteLesson(this.removedLessonId)
      .pipe(take(1))
      .subscribe(
        resp => {
          if (resp?.status === 'Success') {
            const index = this.lessons.findIndex(lesson => lesson.id === this.removedLessonId);
            if (index !== -1) {
              this.lessons.splice(index, 1);
              this.cachedLessons.splice(index, 1);
              this.setMappedResults();
            } else {
              if (this.lessonsWithoutCourse) {
                const removedIndex = this.lessonsWithoutCourse.findIndex(lesson => lesson.id === this.removedLessonId);
                this.lessonsWithoutCourse.splice(removedIndex, 1);
              }
            }
            this.utilsService.openSnackBar(`👌 Занятие успешно удалено`, 'success');
          } else {
            this.utilsService.openSnackBar('Ошибка удаления занятия!', 'error');
          }

          this.popUpConfirming = false;
          this.removedLessonId = '';
          this.changeDetectorRef.detectChanges();
        },
        () => {
          this.popUpConfirming = false;
          this.removedLessonId = '';
          this.utilsService.openSnackBar('Ошибка удаления занятия!', 'error');
          this.changeDetectorRef.detectChanges();
        },
      );
  }

  public trackByFn(index, item: ILesson): string {
    return item.id;
  }

  private setSelectedChooseProfessionsFromFieldTask(lesson: ILesson): Observable<IField>[] {
    const tasks = lesson.tasks;
    const observers: Observable<IField>[] = [];

    if (!tasks?.length) {
      return [];
    }

    tasks.forEach(task => {
      const { chooseProfessionsFromFieldTask } = task;

      if (chooseProfessionsFromFieldTask?.fieldId) {
        observers.push(this.lessonsService.getField(chooseProfessionsFromFieldTask.fieldId));
      }
    });

    return observers;
  }

  public setSelectedProfessions(lesson: ILesson): Observable<IProfession[]>[] {
    const tasks = lesson.tasks;
    const observers: Observable<IProfession[]>[] = [];

    if (!tasks?.length) {
      return observers;
    }

    tasks.forEach(task => {
      const { chooseProfessionsFromFieldTask } = task;

      if (chooseProfessionsFromFieldTask?.professionIds.length) {
        observers.push(
          this.adminPanelService.getElasticProfessionsByFilters({
            ids: chooseProfessionsFromFieldTask.professionIds,
          }),
        );
      }
    });

    return observers;
  }

  private setSelectedScreeningTestTask(lesson: ILesson): Observable<[ITest, IVariant]>[] {
    const tasks = lesson.tasks;
    const observers: Observable<[ITest, IVariant]>[] = [];

    if (!tasks?.length) {
      return [];
    }

    tasks.forEach(task => {
      const { screeningTestTask } = task;

      if (screeningTestTask && screeningTestTask.screeningTestId && screeningTestTask.variantId) {
        observers.push(
          this.lessonsService.getTest(screeningTestTask.screeningTestId).pipe(
            map(test => {
              const variant = test?.variants.find(variant => variant.id === screeningTestTask.variantId);

              return [test, variant];
            }),
          ),
        );
      }
    });

    return observers;
  }

  private getCourseMaterialsObject(lessons: ILesson[]): {
    [key: string]: ICourseMaterial;
  } {
    return lessons.reduce((prev, current) => {
      if (!Helper.isDefined(prev[current.courseMaterialId])) {
        prev[current.courseMaterialId] = current;
      }

      return prev;
    }, {});
  }

  private setCourseMaterialsFilter(courseMaterials: ICourseMaterial[]): IFilter[] {
    return courseMaterials.map(({ id, name }) => {
      return { id, name };
    });
  }

  private setMappedResults(): void {
    if (this.selectedCourseId === null) {
      this.lessons = this.cachedLessons;
      this.lessonsCountInfo = `Всего занятий: ${this.lessons.length}`;
      return;
    }

    const course: IFilter = this.courseMaterialsFilter[this.selectedCourseId];

    this.lessons = this.cachedLessons.filter(lesson => {
      return lesson.courseMaterialId === course.id;
    });
    this.lessonsCountInfo = `Всего занятий в курсе: ${this.lessons.length}`;
  }

  private getMappedResults(): void {
    this.cachedLessons.forEach(lesson => {
      const courseMaterial = this.courseMaterials?.find(({ id }) => id === lesson.courseMaterialId);
      const lessonMaterial = courseMaterial?.lessonsMaterials?.find(({ id }) => id === lesson.lessonMaterialId);

      this.mappedLessons[lesson.id] = {
        courseMaterial: courseMaterial ?? null,
        lesson,
        lessonMaterial: lessonMaterial ?? null,
        test: null,
        field: null,
      };
    });
  }
}
