import { Routes } from '@angular/router';
import { AuthGuard } from 'app/core/guards/auth.guard';
import { ProfessionCardsComponent } from 'app/pages/catalog/profession-page/profession-cards/profession-cards.component';
import { ChangePasswordComponent } from 'app/pages/catalog/settings-page/change-password/change-password.component';
import { RestorePasswordComponent } from 'app/pages/catalog/settings-page/restore-password/restore-password.component';
import { CourseDetailsComponent } from './pages/catalog/courses-page-without-filters/course-details/course-details.component';
import { CoursesPageComponent } from './pages/catalog/courses-page-without-filters/courses-page.component';
import { InternshipCardsComponent } from './pages/catalog/internship-page/internship-cards.component';
import { InternshipCatalogComponent } from './pages/catalog/internship-page/internship-catalog/internship-catalog.component';
import { InternshipDetailsComponent } from './pages/catalog/internship-page/internship-details/internship-details.component';
import { ProfessionDetailsComponent } from './pages/catalog/profession-page/profession-details/profession-details.component';
import { EventsPageComponent } from './pages/events-page/events-page.component';
import { PageNewsComponent } from './pages/news-page/page-news.component';
import { Page404Component } from './pages/page-404/page-404.component';
import { Page500Component } from './pages/page-500/page-500.component';
import { CoursesCatalogComponent } from './shared/dashboard/courses/courses-catalog/courses-catalog.component';
import { LogoutComponent } from './shared/dashboard/logout/logout.component';
import { CareerEducationCourseDetailsComponent } from 'app/pages/catalog/career-education-courses-page/career-education-course-details/career-education-course-details.component';

export const routeConfig: Routes = [
  { path: 'courses/:hrid', component: CourseDetailsComponent },
  {
    path: 'courses',
    component: CoursesPageComponent,
    children: [
      {
        path: '',
        component: CoursesCatalogComponent,
        outlet: 'courses-catalog',
        data: { isCourses: true },
      },
    ],
  },
  {
    path: 'career-education/course-details/:courseId',
    component: CareerEducationCourseDetailsComponent,
  },
  { path: 'events/:hrid', component: CourseDetailsComponent },
  { path: 'events', component: EventsPageComponent },
  { path: 'change_password', component: ChangePasswordComponent },
  { path: 'recovery_password', component: RestorePasswordComponent },
  { path: 'professions', component: ProfessionCardsComponent },
  { path: 'professions/:hrid', component: ProfessionDetailsComponent },
  { path: 'internships', component: InternshipCardsComponent },
  { path: 'internships/:hrid', component: InternshipDetailsComponent },
  {
    path: 'internships',
    component: InternshipCardsComponent,
    children: [
      {
        path: '',
        component: InternshipCatalogComponent,
        outlet: 'internship-catalog',
      },
    ],
  },
  {
    path: 'news',
    component: PageNewsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'news/:id',
    component: PageNewsComponent,
    canActivate: [AuthGuard],
  },
  { path: 'logout', component: LogoutComponent },
  { path: '500', component: Page500Component },
  { path: '404', component: Page404Component },
  { path: '**', redirectTo: '/404' },
];
