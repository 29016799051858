import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'prf-question-bar',
  templateUrl: './question-bar.component.html',
  styleUrls: ['./question-bar.component.scss'],
})
export class QuestionBarComponent implements OnInit {
  @Input() questions: any[];

  // Bar
  public barChartArray: any[] = [];
  public groups: any[] = [];
  public barChartLegend: boolean = true;
  public barChartType: string = 'horizontalBar';
  public chartColors: any[] = [{ backgroundColor: ['#ffa1b5', '#86c7f3', '#ffe29a', '#93d9d9', '#c964a0', '#49147e'] }];
  public barChartOptions: any = {
    scaleShowVerticalLines: false,
    responsive: true,
    fontSize: 20,
    color: '#444',
    legend: {
      display: false,
    },
    tooltips: {
      enabled: false,
      callbacks: {
        label: tooltipItem => {
          return tooltipItem.yLabel;
        },
      },
    },
    elements: {
      point: {
        radius: 0,
      },
    },
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
            stepSize: 2,
            max: [], // max value for the chart
          },
        },
      ],
      xAxes: [
        {
          ticks: {
            // callback: function (value) {
            //   return value.substr(0, 15);//truncate
            // },
          },
        },
      ],
      pointLabels: {
        fontSize: 16,
        fontColor: '#444',
        fontFamily: 'ALSSchlangesans, Arial',
      },
    },
  };

  constructor() {}

  ngOnInit() {
    this.parseQuestions();
  }

  // Parse data charts
  private parseQuestions() {
    this.questions.forEach((question, i) => {
      let criteria = ['count', 'answerName'];
      this.barChartArray = criteria.reduce((s, a) => {
        s.push({ index: i, name: a, data: [] });
        return s;
      }, []);
      this.barChartArray.forEach(v => {
        question.answers.forEach(c => {
          v.data.push(c[v.name]);
        });
      });
      this.groups.push({
        yAxesMax: Math.max(...this.barChartArray[0].data),
        questionName: question.questionName,
        data: this.barChartArray,
      });
      this.barChartOptions.scales.yAxes[0].ticks.max = this.groups[0].yAxesMax;
    });
  }
}
