import { Component, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CourseDetailsService } from 'app/pages/catalog/courses-page-without-filters/course-details/course-details.service';
import { CourseService } from 'app/pages/catalog/courses-page-without-filters/course.service';
import { COMPLEXITYS } from 'app/pages/catalog/courses-page/all-courses/active-courses/add-course/add-course.component';
import { MetroColors } from 'app/pages/catalog/courses-page/all-courses/active-courses/add-course/select-metro/metro-colors';
import { UtilsService } from 'app/shared/dashboard/backend-services/utils.service';
import { COURSES_TYPES } from 'app/shared/global-constants/constants';
import { OverlayBusyService } from 'app/shared/overlay-busy/overlay-busy.service';
import * as moment from 'moment';
import { Observable, Subject } from 'rxjs';
import { switchMap, takeUntil, tap } from 'rxjs/operators';

@Component({
  selector: 'prf-promotest-course-details',
  templateUrl: './promotest-course-details.component.html',
  styleUrls: ['./promotest-course-details.component.scss'],
})
export class PromotestCourseDetailsComponent implements OnInit {
  course: any;
  types: any = COURSES_TYPES;
  classesFormat: string;
  otherCourses: any = [];
  similarCourses: any = [];
  userId: string;

  hrid: string;
  title: string;
  mapHeader: string;
  userRole: string;
  adminLevel: string = '';
  color: string;
  isCourse: boolean;
  startDate: string;
  complexity: string = '';
  dataFetched: boolean = false;
  mapsLoaded: boolean = false;

  moment: any;
  metroColors: MetroColors = new MetroColors();

  fromString = '';
  beforeString = '';
  yearsString = '';
  private ngUnsubscribe$: Subject<any> = new Subject();

  constructor(
    private meta: Meta,
    private courseDetailsService: CourseDetailsService,
    private courseService: CourseService,
    private route: ActivatedRoute,
    private utilsService: UtilsService,
    private overlayService: OverlayBusyService,
    private translateService: TranslateService,
    private router: Router,
  ) {
    this.getTranslations(['SHARED.COURSE', 'SHARED.FROM', 'SHARED.BEFORE', 'COURCES.CATALOG.YEARS'])
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe(translations => {
        this.meta.updateTag({ name: 'og:title', content: translations['SHARED.COURSE'] });
        this.fromString = translations['SHARED.FROM'];
        this.beforeString = translations['SHARED.BEFORE'];
        this.yearsString = translations['COURCES.CATALOG.YEARS'];
      });
    this.userRole = localStorage.getItem('userRole');
    if (this.userRole.includes('admin')) {
      this.adminLevel = localStorage.getItem('adminLevel');
    }
    this.userId = localStorage.getItem('userId');
    this.moment = moment;
    this.moment.locale('ru');
  }

  ngOnInit() {
    this.overlayService.show();
    this.setTitles();
    this.route.params
      .pipe(
        switchMap(params => {
          this.hrid = params['hrid'];
          window.scrollTo(0, 0);
          return this.loadCurrentCourse();
        }),
        takeUntil(this.ngUnsubscribe$),
      )
      .subscribe(() => this.overlayService.hide());
  }

  loadCurrentCourse(): Observable<any> {
    return this.courseService.getClassByHRId(this.hrid).pipe(
      tap(data => {
        if (data.status == 'Not Found') {
          this.utilsService.openSnackBar(`Не удалось найти курс с hrid = ${this.hrid}`, 'error');
          this.router.navigate([this.backButtonUrl()]);
        }
        this.course = data.class;
        let type = this.types.find(el => el.value === this.course.classesFormat);
        this.classesFormat = type.viewValue;
        const complexity = COMPLEXITYS.find(c => c.value === this.course.complexity);
        this.complexity = complexity ? complexity.viewValue : '';
        this.color = type.color.length > 0 ? type.color : '';
        let startTime = this.moment(this.course.startDate).format('HH:mm');
        if (startTime == '00:00') {
          this.startDate = this.moment(this.course.startDate).format('D MMMM YYYY');
        } else {
          this.startDate = this.moment(this.course.startDate).format('D MMMM YYYY в HH:mm');
        }
      }),
    );
  }

  goToCourse() {
    const url = 'www.mos.ru/pgu/ru/application/dogm/077060701/#step_1';
    return this.router.navigate([]).then(result => {
      window.open('https://' + url, '_blank');
    });
  }

  getAges() {
    if (this.course.maxAgeAudience != 100 && this.course.minAgeAudience != 0) {
      return (
        this.fromString +
        ' ' +
        this.course.minAgeAudience +
        ' ' +
        this.beforeString +
        ' ' +
        this.course.maxAgeAudience +
        ' ' +
        this.yearsString
      );
    } else if (this.course.maxAgeAudience == 100) {
      return this.course.minAgeAudience + '+ ' + this.yearsString;
    } else if (this.course.minAgeAudience == 0) {
      return this.fromString + ' ' + this.course.maxAgeAudience + ' ' + this.yearsString;
    }
  }

  calculateRealPrice() {
    return this.courseDetailsService.calculateRealPrice(this.course);
  }

  setTitles() {
    this.getTranslations(['COURCES.CATALOG.COURCES', 'SHARED.EVENT_ON_MAP'])
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe(translations => {
        this.title = translations['COURCES.CATALOG.COURCES'];
        this.mapHeader = translations['SHARED.COURSE_ON_MAP'];
        this.isCourse = true;
      });
  }

  backButtonUrl() {
    return '/vorobievi-gori/partners-courses-all';
  }

  checkCourseEdit(course) {
    let isEditable = false;
    isEditable =
      this.userRole == 'admin' ||
      (this.userRole == 'schooladmin' &&
        course.regionId === localStorage.getItem('regionId') &&
        course.municipalityId === localStorage.getItem('municipalityId') &&
        course.institutionId === localStorage.getItem('schoolId'));

    return isEditable;
  }

  getTranslations(keys: string[]): Observable<any> {
    return this.translateService.get(keys);
  }

  ngOnDestroy() {
    this.ngUnsubscribe$.next(null);
    this.ngUnsubscribe$.complete();
  }
}
