import { Component, OnDestroy, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { PageHeaderService } from '../../../shared/dashboard/page-header/page-header.service';

@Component({
  selector: 'prf-career-education-courses',
  templateUrl: './career-education-courses.component.html',
  styleUrls: ['./career-education-courses.component.scss'],
})
export class CareerEducationCoursesComponent implements OnInit, OnDestroy {
  public tabLevel: number = 1;
  private title: string = 'Все курсы';

  constructor(private meta: Meta, private pageHeaderService: PageHeaderService) {
    this.meta.updateTag({ name: 'og:title', content: 'Страница курсов карьерной грамотности' });
  }

  public ngOnInit(): void {
    this.pageHeaderService.setTitle(this.title);
  }

  public ngOnDestroy(): void {
    this.pageHeaderService.setTitle(null);
  }
}
