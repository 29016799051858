import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import {
  CoursesFiltersService,
} from 'app/shared/dashboard/courses/courses-catalog/courses-filter/courses-filters.service';
import { ClassesFormatTypes } from 'app/shared/enums/courses-types.enum';
import { EmptyGuid } from 'app/shared/global-constants/constants';
import { IFilterClasses } from 'app/shared/interfaces/ifilterclasses.interface';
import { Observable, of, Subject } from 'rxjs';
import { catchError, mergeMap, switchMap, takeUntil, tap, timeout } from 'rxjs/operators';
import { OverlayBusyService } from '../../../../../../shared/overlay-busy/overlay-busy.service';
import { CoursesService } from '../../../courses.service';

const PAGE_SIZE: number = 6;
const TRIGGER_SCROLL_BOTTOM: number = 350;
const PAGE_LOAD: number = 12;

//используется админом, шк. админом, директором
@Component({
  selector: 'prf-events-courses',
  templateUrl: './events-courses.component.html',
  styleUrls: ['./events-courses.component.scss'],
})
export class EventsCoursesComponent implements OnInit {
  @Input() isCourses: boolean;

  @Input() viewValue;
  @Output() countCoursesEmitter = new EventEmitter<any>();
  fromTo: any = { currentPage: 0, pageSize: PAGE_LOAD };
  filters: IFilterClasses;
  courses: Array<any> = [];
  coursesCount: number;
  titleName: string;
  currentCourses: Array<any> = [];
  cardsToShow: number = PAGE_SIZE;
  userRole: string;
  url: string;
  public searchCourses: any[];

  requestError: boolean = false;
  loadStopper: boolean = false;
  isLoading: boolean = false;

  private ngUnsubscribe$: Subject<any> = new Subject();

  constructor(
    private filtersService: CoursesFiltersService,
    private overlayService: OverlayBusyService,
    private coursesService: CoursesService,
    private router: Router,
  ) {
    this.userRole = localStorage.getItem('userRole');
    router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.url = event.url;
      }
    });
  }

  ngOnInit() {
    this.overlayService.show();

    if (this.userRole === 'schooladmin') {
      // у шк. админа и директора выводятся только курсы
      this.filtersService.setCoursesFilter({
        classesFormat: [ClassesFormatTypes.ShortCourse, ClassesFormatTypes.LongCourse],
      });
    }

    this.filtersService
      .getCoursesFilters()
      .pipe(
        mergeMap(filters => {
          //check and reject null/undefined values
          let filtersVerified: IFilterClasses = {};
          Object.keys(filters).forEach(key => {
            filters[key] != null && filters[key] != undefined ? (filtersVerified[key] = filters[key]) : null;
          });
          this.filters = filtersVerified;

          // Блок для избегания ошибок с повторными запросами и фильтрами
          this.loadStopper = false;
          this.fromTo.currentPage = 0;
          this.currentCourses = [];
          return this.loadCourses(this.filters);
        }),
        takeUntil(this.ngUnsubscribe$),
      )
      .subscribe(() => {
        this.overlayService.hide();
      });
  }

  loadCourses(filtersObject): Observable<any> {
    let path = this.router.routerState.snapshot.url;
    let filters = Object.assign({}, filtersObject);
    if (this.filters && !this.filters.classesFormat) {
      if (path.includes('/events')) {
        this.titleName = 'мероприятий';
        filters.classesFormat = [
          ClassesFormatTypes.MasterClass,
          ClassesFormatTypes.Excursion,
          ClassesFormatTypes.Action,
          ClassesFormatTypes.Festival,
          ClassesFormatTypes.Meeting,
          ClassesFormatTypes.Competition,
          ClassesFormatTypes.Profproba,
          ClassesFormatTypes.OpenDay,
        ];
      }
      if (path.includes('/courses')) {
        this.titleName = 'курсов';
        filters.classesFormat = [ClassesFormatTypes.ShortCourse, ClassesFormatTypes.LongCourse];
      }
    }

    if (path === '/schooladmin/courses/mine-courses') {
      filters.InstitutionId = localStorage.getItem('schoolId');
    }
    this.filtersService.setRegionMunicipalityFilters(filters);

    if (!this.isLoading && !this.loadStopper) {
      this.isLoading = true;
      this.requestError = false;

      let coursesRequest$: Observable<any> = of(null);
      if (this.userRole === 'admin') {
        coursesRequest$ = this.coursesService.getClassesCount(filters).pipe(
          switchMap(data => {
            this.coursesCount = data.count;
            this.countCoursesEmitter.emit(data.count);

            return this.coursesService.getFilteredClasses(filters, this.fromTo).pipe(
              tap(data => {
                let courses = data;
                this.loadStopper = !!(courses.length == 0 || courses.length < PAGE_LOAD);

                if (this.url === '/admin/courses/mine-courses') {
                  // todo проверить, что правильно работает
                  courses = courses.filter(d => d.institutionId === EmptyGuid);
                }
                if (courses) {
                  for (let item in courses) {
                    if (courses) {
                      this.currentCourses.push(courses[item]);
                    }
                  }
                }
              }),
            );
          }),
          takeUntil(this.ngUnsubscribe$),
        );
      } else {
        coursesRequest$ = this.coursesService.getClassesElasticCount(filters).pipe(
          switchMap(data => {
            this.countCoursesEmitter.emit(data);

            if (!this.loadStopper) {
              return this.coursesService.getElasticFilteredClasses(filters, this.fromTo).pipe(
                tap(data => {
                  let courses = data;
                  this.loadStopper = !!(courses.length == 0 || courses.length < PAGE_LOAD);

                  if (courses) {
                    for (let item in courses) {
                      if (courses) {
                        this.currentCourses.push(courses[item]);
                      }
                    }
                  }
                }),
              );
            } else {
              return of(null);
            }
          }),
          takeUntil(this.ngUnsubscribe$),
        );
      }

      return coursesRequest$.pipe(
        timeout(10000),
        tap(r => {
          this.isLoading = false;
          this.requestError = false;
        }),
        catchError(err => {
          this.isLoading = false;
          this.requestError = true;
          return of(null);
        }),
        takeUntil(this.ngUnsubscribe$),
      );
    } else {
      return of(null);
    }
  }

  @HostListener('window:scroll', [])
  onScroll(): void {
    // условие на то, что это не список из поиска
    if (window.innerHeight + window.scrollY >= document.body.offsetHeight) {
      this.loadMore();
      this.cardsToShow += PAGE_SIZE;
    }
  }

  public loadMore() {
    this.fromTo.currentPage += PAGE_LOAD;
    this.loadCourses(this.filters).pipe(takeUntil(this.ngUnsubscribe$)).subscribe();
  }

  public hasCoursesToLoad() {
    if (this.coursesCount < PAGE_LOAD) return false;
    return this.fromTo.currentPage < this.coursesCount;
  }

  ngOnDestroy() {
    this.ngUnsubscribe$.next(null);
    this.ngUnsubscribe$.complete();
  }
}
