import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { SwiperDirective } from '@profilum-directives/swiper/swiper.directive';
import { SharedModule } from 'app/shared/shared.module';
import { BaseSliderComponent } from './base-slider/base-slider.component';
import { BooksSliderComponent } from './books-slider/books-slider.component';
import { JobSliderComponent } from './job-slider/job-slider.component';
import { JobSubscriptionComponent } from './job-subscription/job-subscription.component';
import { MovieSliderComponent } from './movie-slider/movie-slider.component';
import { PersonSliderComponent } from './person-slider/person-slider.component';
import { ProfessionCardsComponent } from './profession-cards/profession-cards.component';
import { ProfessionCatalogComponent } from './profession-cards/profession-catalog/profession-catalog.component';
import {
  ProfessionOrderComponent,
} from './profession-cards/profession-catalog/profession-order/profession-order.component';
import {
  ProfessionSearchComponent,
} from './profession-cards/profession-catalog/profession-search/profession-search.component';
import {
  ProfessionContentLoaderComponent,
} from './profession-cards/profession-content-loader/profession-content-loader.component';
import {
  ProfessionRecommendationsComponent,
} from './profession-cards/profession-recommendations/profession-recommendations.component';
import { ProfessionDetailsComponent } from './profession-details/profession-details.component';

@NgModule({
  imports: [BrowserModule, RouterModule, SharedModule, SwiperDirective],
  declarations: [
    JobSubscriptionComponent,
    BaseSliderComponent,
    MovieSliderComponent,
    JobSliderComponent,
    PersonSliderComponent,
    BooksSliderComponent,
    ProfessionDetailsComponent,
    ProfessionCardsComponent,
    ProfessionCatalogComponent,
    ProfessionSearchComponent,
    ProfessionOrderComponent,
    ProfessionRecommendationsComponent,
    ProfessionContentLoaderComponent,
  ],
  providers: [],
  bootstrap: [],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ProfessionsModule {}
