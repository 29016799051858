import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { SharedService } from 'app/shared/shared.service';
import { ICookieInterface } from 'app/shared/interfaces/icookie';
import { CookieService } from 'ngx-cookie-service';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { HttpService } from '../services/http.service';

@Injectable()
export class MosruGuard  {
  constructor(
    private router: Router,
    private http: HttpService,
    private cookieService: CookieService,
    private sharedService: SharedService,
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    let tag = localStorage.getItem('tag');
    return this.checkCookie().pipe(
      switchMap((response: ICookieInterface) => {
        if (response?.status !== 'Success' || tag !== 'MosRu') {
          this.sharedService.logOut().pipe(
            switchMap(r => {
              localStorage.setItem('lastRoute', state.url);
              this.router.navigate(['/mosrulogin']);
              return of(false);
            }),
          );
        } else {
          localStorage.removeItem('lastRoute');
          return of(true);
        }
      }),
    );
  }

  public checkCookie(): Observable<ICookieInterface> {
    return this.http.get('/saas/v1.0/saas/checkcookie').pipe(
      map(r => r),
      catchError((err: HttpErrorResponse) => {
        if (err.status >= 500 && err.status < 600) {
          this.sharedService.logOut();
        }
        return throwError(err);
      }),
    );
  }
}
