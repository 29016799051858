import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpService } from 'app/core/services/http.service';
import { ClassesFormatTypes } from 'app/shared/enums/courses-types.enum';
import { Stands } from 'app/shared/enums/stands.enums';
import { IFilterClasses } from 'app/shared/interfaces/ifilterclasses.interface';
import { Observable, throwError, TimeoutError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable()
export class ProgrammsService {
  constructor(private httpService: HttpService) {}

  // обработка ошибок
  handleError(err: any): Observable<any> {
    if (err instanceof TimeoutError) {
      console.error(`Frontend returned timeout error: ${err['error']}`);
      return throwError(err['error']);
    }
    if (err.error instanceof Error) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', err.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(`Backend returned code ${err.status}, body was: ${err.error}`);
      let errorText = err.error ? (err.error.comment ? err.error.comment : err.error) : null;
    }
    throw throwError(err);
  }

  getCoursesByCourseObject(count, course): Observable<any> {
    let filters: IFilterClasses = {};
    filters.courses = [course];
    filters.from = 0;
    filters.size = count;

    filters.classesFormat = [];
    filters.classesFormat.push(ClassesFormatTypes.ShortCourse, ClassesFormatTypes.LongCourse);

    return this.httpService.post('/api/v1.0/catalog/classes/elasticsearch/search/filters', filters).pipe(map(resp => resp));
  }

  getEventsByCourseObject(count, course): Observable<any> {
    let filters: IFilterClasses = {};
    filters.courses = [course];
    filters.from = 0;
    filters.size = count;

    filters.classesFormat = [];
    filters.classesFormat.push(
      ClassesFormatTypes.MasterClass,
      ClassesFormatTypes.Excursion,
      ClassesFormatTypes.Festival,
      ClassesFormatTypes.Action,
      ClassesFormatTypes.Meeting,
      ClassesFormatTypes.Competition,
      ClassesFormatTypes.Profproba,
      ClassesFormatTypes.OpenDay,
    );
    filters.municipalityId = localStorage.getItem('municipalityId');
    filters.stand = Stands.Talent;
    return this.httpService.post('/api/v1.0/catalog/classes/elasticsearch/search/filters', filters).pipe(map(resp => resp));
  }

  CoursesByIds(ids: Array<string>): Observable<any> {
    let filters: IFilterClasses = {};
    filters.ids = ids;

    let credentials = {
      filters: filters,
    };

    return this.httpService.post('/api/v1.0/catalog/classes/mongo/search/filters', credentials).pipe(
      map(r => r.classes),
      catchError((err: HttpErrorResponse) => {
        return this.handleError(err);
      }),
    );
  }

  getUserRecommendations(recommendationType: string, addresserRole: string): Observable<any> {
    return this.httpService
      .get(`/saas/v1.0/saas/getuserrecommendations?recommendationType=${recommendationType}&addresserRole=${addresserRole}`)
      .pipe(
        map(r => r),
        catchError((err: HttpErrorResponse) => {
          return this.handleError(err);
        }),
      );
  }
}
