<div class="w-tab-pane w--tab-active">
  <div class="tab-pane adm">
    <div class="admin-menu">
      <div class="admin-menu-col">
        <a class="admin-add-event w-button" [routerLink]="['/admin/career-education/create-course']" aria-label="Добавить новый курс">
          {{ 'SHARED.ADD' | translate }}
        </a>
      </div>
    </div>
    <router-outlet></router-outlet>
  </div>
</div>
