import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { IProfession } from '../../api-services';
import { ProfessionFilter } from "./professions.interface";


@Injectable({
  providedIn: 'root',
})
export class ProfessionsDataHandlerService {
  private _professions: BehaviorSubject<IProfession[]> = new BehaviorSubject<IProfession[]>(null);
  private _categoryList: BehaviorSubject<ProfessionFilter[]> = new BehaviorSubject<ProfessionFilter[]>(null);

  public getProfessions(): BehaviorSubject<IProfession[]> {
    return this._professions;
  }

  public setProfessions(professions: IProfession[]): void {
    this._professions.next(professions);
  }

  public getCategoryList(): BehaviorSubject<ProfessionFilter[]> {
    return this._categoryList;
  }

  public setCategoryList(categoryList: ProfessionFilter[]): void {
    this._categoryList.next(categoryList);
  }
}
