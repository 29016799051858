<section class="classes" *ngIf="mergingArrays; else welcome">
  <ng-container *ngIf="showDeletePopUp">
    <div class="popup-overlay overlay">
      <div class="popup-container">
        <div class="popup-title">Удаление класса: {{ classSelectedToDelete.number }} {{ classSelectedToDelete.letter }}</div>
        <div class="popup-content">
          <div class="content-text">
            <div class="popup-text _1">
              Вы уверены, что хотите удалить данный класс? После удаления доступ к результатам учеников в данном классе будет потерян.
            </div>
            <div class="info-text">
              <img src="./assets/images/icons/info-purple.svg" class="" />
              <div class="popup-text _2">Ученики могут выбрать новый класс при следующем входе в систему.</div>
            </div>
            <div class="buttons">
              <a class="button w-button popup-button _red" (click)="deleteSchoolClass(classSelectedToDelete.id)"> Удалить </a>
              <a class="button w-button popup-button _white" (click)="closeDeletePopUp()"> Отмена </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>

  <a class="button w-button" routerLink="/schooladmin/new-class">{{ 'SHARED.CREATE_NEW_CLASS' | translate }}</a>
  <div class="classes-section">
    <div class="content-row">
      <div class="classes-container">
        <div class="class-div">
          <div class="class-header _2" [class.fixed]="isFixed">
            <div class="class-row class-header-row-3">
              <div class="class-column _9"></div>
              <div class="class-column-class _4">
                <div>Класс</div>
              </div>
              <div class="teacher-class-header">
                <div>{{ 'CODE_REGISTRATION_KZ.TEACHER' | translate }}</div>
              </div>
              <div class="class-column _6">
                <div>Кол-во учеников</div>
                <div class="class-test-row">
                  <div class="class-test-column">
                    <div>Всего</div>
                  </div>
                  <div class="class-test-column">
                    <div>Прошли тест</div>
                  </div>
                </div>
              </div>
              <div class="class-column _11"></div>
            </div>
          </div>
          <div class="classes-list-block _2">
            <div class="students-list">
              <!--CLASS-->
              <ng-container *ngIf="!loadedClasses">
                <prf-content-loader></prf-content-loader>
              </ng-container>
              <ng-container *ngIf="loadedClasses">
                <div
                  class="class-list-item-2"
                  *ngFor="let mergingArray of mergingArrays; let i = index"
                  [ngClass]="mergingArrays.length === i ? 'no-underline' : ''"
                >
                  <div class="class-row-2 classes-row">
                    <!--Number-->
                    <div class="class-column _9">
                      <!-- <div class="class-header-number _2"></div> -->
                    </div>
                    <!--Class name-->
                    <div class="class-row-div">
                      <a class="classes-column-class classes-link" [routerLink]="['../class', mergingArray.id]">
                        <div class="class-column _2">
                          <div class="class-number-column">{{ mergingArray.number }} «{{ mergingArray.letter }}»</div>
                        </div>
                      </a>

                      <!--Teacher name-->
                      <a class="classes-column-teacher classes-link" [routerLink]="['../class', mergingArray.id]">
                        <div class="classes-column-teacher-item">
                          <div
                            class="class-photo w-inline-block"
                            [ngStyle]="{
                              'background-image': mergingArray && mergingArray.imagePath ? 'url(' + mergingArray.imagePath + ')' : ''
                            }"
                          ></div>
                          <div class="class-column-name">
                            <div>
                              {{ mergingArray.lastName }} {{ mergingArray.firstName }}
                              {{ mergingArray.middleName }}
                            </div>
                            <div *ngIf="mergingArray.lastName == null || mergingArray.firstName == null">Класс без учителя</div>
                            <div class="advice is-register" *ngIf="mergingArray?.isActivated">Регистрация пройдена</div>
                            <div class="student-online-status not-register" *ngIf="!mergingArray?.isActivated">Не зарегистрирован</div>
                          </div>
                        </div>
                      </a>

                      <div class="class-column _6">
                        <a class="class-test-row classes-link" [routerLink]="['../class', mergingArray.id]">
                          <div class="class-test-column test">
                            <div class="length-students">{{ mergingArray.totalPupilsCount }}</div>
                          </div>
                          <div class="class-test-column test">
                            <div class="length-students-complete">
                              {{ mergingArray.completedTestsCount }}
                            </div>
                          </div>
                        </a>
                      </div>

                      <div class="class-column _11">
                        <a class="delete-class w-inline-block" (click)="selectSchoolClassToDelete(mergingArray)"> </a>
                      </div>
                      <div class="class-line" [ngClass]="teachers.length === i ? 'no-underline' : ''"></div>
                    </div>
                  </div>
                </div>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<!--Welcome-->
<ng-template #welcome>
  <ng-container *ngIf="userRole === 'schooladmin' || 'teacher'">
    <prf-schooladmin-welcome></prf-schooladmin-welcome>
  </ng-container>
</ng-template>
