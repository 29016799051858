import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { UnsubscribeComponent } from '@profilum-components/unsubscribe/unsubscribe.component';
import {
  CareerEducationCoursesService,
} from 'app/pages/catalog/career-education-courses-page/career-education-courses.service';
import {
  ICourseMaterial,
} from 'app/pages/catalog/career-education-courses-page/interfaces/career-education-courses.interface';
import { map, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'prf-career-education-course',
  templateUrl: './career-education-course.component.html',
  styleUrls: ['./career-education-course.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CareerEducationCourseComponent extends UnsubscribeComponent implements OnInit {
  public isLoading: boolean = false;
  public requestError: boolean = false;
  public courses: ICourseMaterial[] = [];

  constructor(private careerEducationCoursesService: CareerEducationCoursesService, private changeDetectorRef: ChangeDetectorRef) {
    super();
  }

  public ngOnInit(): void {
    this.isLoading = true;
    this.careerEducationCoursesService
      .getAllCourseMaterials()
      .pipe(
        map(courses => courses?.courseMaterials ?? []),
        takeUntil(this.unsubscribe),
      )
      .subscribe(
        courses => {
          this.isLoading = false;
          this.requestError = false;
          this.courses = courses;
          this.changeDetectorRef.detectChanges();
        },
        () => {
          this.isLoading = false;
          this.requestError = true;
          this.changeDetectorRef.detectChanges();
        },
      );
  }

  public trackByFn(index, item): string {
    return item.id;
  }
}
