import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { WindowRef } from 'app/shared/services/windowRef.service';
import { switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { PageHeaderService } from './page-header.service';
import { Observable } from 'rxjs';
import { UserInfoClass } from 'app/shared/classes/userInfo.class';
import { SharedService } from 'app/shared/shared.service';
import { UserProfileOutletService } from 'app/pages/catalog/user-profile-page/user-profile-outlet/user-profile-outlet.service';
import { MenuService } from '../menus/admin-menu/menu.service';
import { IBackButton } from './page-header.interface';
import { UnsubscribeComponent } from '@profilum-components/unsubscribe/unsubscribe.component';

@Component({
  selector: 'prf-page-header',
  templateUrl: './page-header.component.html',
  styleUrls: ['./page-header.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class PageHeaderComponent extends UnsubscribeComponent implements OnInit {
  @ViewChild('headerMenu') headerMenu;
  @ViewChild('fileInput') fileInput: ElementRef;
  @ViewChild('notification') notification: ElementRef;
  @ViewChild('nav') nav: ElementRef;
  @Input() schoolTitle: string;
  public backButtonData$: Observable<IBackButton | null>;
  @Input() backButtonUrl: string = '';

  @Input() editButton: boolean = false;
  public title$: Observable<string | null>;
  @Input() buttonToTest: boolean = false;
  @Output() adminLevel = new EventEmitter<any>();
  userInfo: UserInfoClass;
  isAuthorized: any = false;
  active: boolean = false;
  window;
  error: string;
  userRole: string;
  dataFetched: boolean = false;
  regionId: string;
  favoriteProfessions: any[] = [];
  tag: string;

  isMOStend: boolean = false;

  constructor(
    private windowRef: WindowRef,
    private router: Router,
    private pageHeaderService: PageHeaderService,
    private profileService: UserProfileOutletService,
    private menuService: MenuService,
    private sharedService: SharedService,
  ) {
    super();
    this.isMOStend = location.origin.includes('mosreg');
    this.tag = localStorage.getItem('tag');
  }

  public ngOnInit(): void {
    this.window = this.windowRef.getNativeWindow();
    this.userRole = localStorage.getItem('userRole');
    this.title$ = this.pageHeaderService.getTitle();
    this.backButtonData$ = this.pageHeaderService.getBackButtonData();

    this.pageHeaderService.avatarUpdated$
      .pipe(
        takeUntil(this.unsubscribe),
        switchMap(() => this.updateUserInfo()),
      )
      .subscribe(() => (this.dataFetched = true));
  }

  updateUserInfo(): Observable<any> {
    return this.sharedService.getUserInfoData().pipe(
      take(1),
      tap(r => {
        this.userInfo = r;
      }),
    );
  }

  showMobileMenu() {
    this.menuService.showMobileMenu(true);
  }

  isOpenProfile(): boolean {
    return this.userRole == 'pupil' || this.userRole == 'parent';
  }

  checkNotificationAllow(): boolean {
    return this.isMOStend;
  }

  openMobileMenu() {
    this.pageHeaderService.toggleMobileMenu(true);
  }

  @HostListener('document:click', ['$event'])
  checkClick(event) {
    if (this.notification && !this.notification?.nativeElement.contains(event.target)) {
      this.nav.nativeElement.classList.remove('w--open');
    }
  }
}
