import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { IMunicipality } from 'app/shared/interfaces/imunicipality';
import { IRegion } from 'app/shared/interfaces/iregion';
import { Observable, of } from 'rxjs';
import { map, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { UtilsService } from 'app/shared/dashboard/backend-services/utils.service';
import { AdminPanelService } from 'app/pages/control-panel/admin/admin-panel.service';
import { RegionsService } from 'app/pages/control-panel/admin/regions/regions.service';
import { MunicipalitiesService } from 'app/pages/control-panel/admin/municipalities/municipalities.service';
import { IProfession } from 'app/shared/interfaces/iprofession.interface';
import { EmptyGuid } from 'app/shared/global-constants/constants';
import { ApiFieldsService } from '@profilum-library';
import { MatLegacySlideToggleChange as MatSlideToggleChange } from '@angular/material/legacy-slide-toggle';
import { ISpeciality } from '../../../../../catalog/specialties-page/specialties.interface';
import { UnsubscribeComponent } from '@profilum-components/unsubscribe/unsubscribe.component';

@Component({
  selector: 'prf-edit-profession',
  templateUrl: './edit-profession.component.html',
  styleUrls: ['./edit-profession.component.scss'],
})
export class EditProfessionComponent extends UnsubscribeComponent implements OnInit, OnDestroy {
  @Input() courses: any[];
  @Input() profilClasses: any[];
  @Input() fieldTypes: any[];
  @Input() isCopy: boolean;
  @Input() specialities: ISpeciality[];
  @Output() updateValue = new EventEmitter<any>();
  public error: boolean = false;
  public educationList = [];
  public demandRatings = [];
  public booleanVariants = [];
  public selectedOptions = [];
  public addedEducation = {
    level: 1,
    isPreferred: false,
    perentage: 0,
  };
  public addedPerson = {
    imagePath: '',
    name: '',
    description: '',
  };
  public professionFieldName: string;
  public professionFieldType: any;
  public selected: string[];
  public selectedSpecialities: string[];
  public profession: IProfession;
  public allRegions: IRegion[] = [];
  public currentRegion: IRegion = {
    id: '',
    hrid: '',
    name: '',
  };
  public municipalitiesByRegion: IMunicipality[] = [];
  public currentMunicipality: IMunicipality = {
    id: '',
    hrid: '',
    name: '',
    regionId: '',
  };
  public imagePreview: string | ArrayBuffer;
  public loadingUpload: boolean = false;
  public loadingDeleting: boolean = false;
  public fields: any[];
  private selectedPhoto: FileList;
  private selectedImage: FileList;
  private professionToHide: IProfession = null;

  @Input()
  set _profession(profession: IProfession) {
    if (profession) {
      this.profession = profession;
      this.selected = this.profession.courses.map(el => el.name);
      this.selectedSpecialities = this.profession.specialities.map(el => el.name);
    }
  }

  constructor(
    private adminPanelService: AdminPanelService,
    private router: Router,
    private regionsService: RegionsService,
    private municipalitiesService: MunicipalitiesService,
    private utilsService: UtilsService,
    private translateService: TranslateService,
    private apiFieldsService: ApiFieldsService,
  ) {
    super();
    this.getTranslations([
      'SHARED.EDU.SECONDARY',
      'SHARED.EDU.VOCATIONAL',
      'SHARED.EDU.HIGHER',
      'SHARED.EDUCATION.LOW',
      'SHARED.EDUCATION.MEDIUM',
      'SHARED.EDUCATION.HIGH',
      'BUTTONS.YES',
      'BUTTONS.NO',
    ])
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(translations => {
        this.educationList = [
          { level: 1, viewValue: translations['SHARED.EDU.SECONDARY'] },
          { level: 2, viewValue: translations['SHARED.EDU.VOCATIONAL'] },
          { level: 3, viewValue: translations['SHARED.EDU.HIGHER'] },
        ];
        this.demandRatings = [
          { level: 1, viewValue: translations['SHARED.EDUCATION.LOW'] },
          { level: 2, viewValue: translations['SHARED.EDUCATION.MEDIUM'] },
          { level: 3, viewValue: translations['SHARED.EDUCATION.HIGH'] },
        ];
        this.booleanVariants = [
          { value: true, viewValue: translations['BUTTONS.YES'] },
          { value: false, viewValue: translations['BUTTONS.NO'] },
        ];
      });
  }

  ngOnInit(): void {
    if (!this.profession.additionalTexts) {
      this.profession.additionalTexts = {
        actualCompaniesText: '',
        educationDescription: '',
      };
    }

    if (this.isCopy) {
      this.resetFieldsForCopy();
    }
    this.getRegions();

    if (this.profession.demandRating == 0) {
      this.profession.demandRating = null;
    }
  }

  private resetFieldsForCopy(): void {
    this.profession.id = this.utilsService.newGuid();
    this.profession.hrid = null;
    this.profession.regionId = null;
    this.currentRegion = null;
    this.profession.municipalityId = EmptyGuid;
  }

  getRegions() {
    this.regionsService
      .getAllRegions()
      .pipe(
        switchMap(r => {
          this.allRegions = r;
          let currentObs$: Observable<any>;
          if (this.profession.regionId && this.profession.regionId != EmptyGuid) {
            this.currentRegion = this.allRegions.find(x => x.id == this.profession.regionId);
            currentObs$ = this.municipalitiesService.getMunicipalitiesByRegion(this.currentRegion.id).pipe(
              tap(municipalities => {
                this.municipalitiesByRegion = municipalities;
                this.currentMunicipality = this.municipalitiesByRegion.find(
                  x => x.regionId == this.profession.regionId && x.id == this.profession.municipalityId,
                );
              }),
            );
          } else {
            currentObs$ = of(null);
          }
          return currentObs$;
        }),
        takeUntil(this.unsubscribe),
      )
      .subscribe();
  }

  public selectRegion(region): void {
    this.setRegion(region).pipe(takeUntil(this.unsubscribe)).subscribe();
  }

  private setRegion(region): Observable<any> {
    this.currentRegion = region;
    this.profession.regionId = region.id;
    this.clearCurrentMunicipality();
    return this.municipalitiesService.getMunicipalitiesByRegion(region.id).pipe(
      tap(r => {
        this.municipalitiesByRegion = r;
      }),
    );
  }

  public setMunicipality(municipality): void {
    if (municipality) {
      this.currentMunicipality = municipality;
      this.profession.municipalityId = municipality.id;
    }
    return;
  }

  public resetMunicipality(): void {
    this.profession.municipalityId = EmptyGuid;
  }

  private clearCurrentMunicipality(): void {
    this.currentMunicipality = {
      id: '',
      hrid: '',
      name: '',
      regionId: '',
    };
    return;
  }

  private clearTerritoryData(): void {
    this.currentRegion = {
      id: '',
      hrid: '',
      name: '',
    };
    this.municipalitiesByRegion = [];
    this.currentMunicipality = {
      id: '',
      hrid: '',
      name: '',
      regionId: '',
    };
    return;
  }

  /* Update profession */
  public onSubmit(form: NgForm) {
    if (form.status == 'VALID') {
      if (this.professionFieldName !== undefined) {
        this.profession.fields[0] = this.fields.find(el => el.name === this.professionFieldName);
      }

      if (this.selected && this.selected.length) {
        this.profession.courses = this.courses.filter(el => this.selected.includes(el.name));
      }

      if (this.selectedSpecialities?.length) {
        this.profession.specialities = this.specialities.filter(el => this.selectedSpecialities.includes(el.name));
      }

      if (this.professionFieldType) {
        this.profession.fieldType = this.professionFieldType;
      }

      if (this.isCopy) {
        this.adminPanelService
          .getProfessionByHrid(this.profession.hrid)
          .pipe(
            switchMap(profession => {
              let currentObs$: Observable<any>;
              if (profession) {
                this.utilsService.openSnackBar('👎 Профессия с таким Hrid уже существует', 'error');
                currentObs$ = of(null);
              } else {
                currentObs$ = this.adminPanelService.createProfessions(this.profession).pipe(
                  tap(r => {
                    if (r && r.status === 'Success') {
                      this.utilsService.openSnackBar('👌 Профессия добавлена', 'success');
                      setTimeout(_ => {
                        this.router.navigate(['/admin/professions']);
                      }, 2000);
                    } else {
                      this.utilsService.openSnackBar('👎 Произошла ошибка, попробуйте снова', 'error');
                      setTimeout(_ => {
                        this.router.navigate(['/admin/professions']);
                      }, 1000);
                    }
                  }),
                );
              }
              return currentObs$;
            }),
          )
          .subscribe();
      } else {
        this.adminPanelService
          .changeProfessions(this.profession)
          .pipe(takeUntil(this.unsubscribe))
          .subscribe(r => {
            if (r) {
              this.utilsService.openSnackBar('👌 Профессия обновлена', 'success');
              form.reset();
              this.clearTerritoryData();
              return this.updateValue.emit(true);
            }
          });
      }
    } else {
      this.getTranslations(['SHARED.ERROR_MSG'])
        .pipe(take(1))
        .subscribe(translations => this.utilsService.openSnackBar(translations['SHARED.ERROR_MSG'], 'error'));
    }
  }

  // установка imagePath для профессии по сфере
  popUpConfirmHidingProfession: any;
  public onModelChangeFieldNames(name): void {
    let imagePath = null;
    if (name) {
      imagePath = this.fields.find(field => field.name === name).icon || null;
    }
    this.profession.mainImagePath = imagePath;
  }

  public addMovie(movie: string): void {
    this.utilsService.addStringValueToCollection(this.profession.movies, movie);
  }

  public addSkill(skill: string): void {
    this.utilsService.addStringValueToCollection(this.profession.skills, skill);
  }

  public addResponsibility(responsibility: string): void {
    this.utilsService.addStringValueToCollection(this.profession.resposibilities, responsibility);
  }

  public addProductGroup(productGroup: string): void {
    this.utilsService.addStringValueToCollection(this.profession.productGroups, productGroup);
  }

  public addKnowledge(knowledge: string): void {
    this.utilsService.addStringValueToCollection(this.profession.knowledge, knowledge);
  }

  public addAbility(ability: string): void {
    this.utilsService.addStringValueToCollection(this.profession.abilities, ability);
  }

  public addFact(fact: string): void {
    this.utilsService.addStringValueToCollection(this.profession.facts, fact);
  }

  public addAdditionalEducation(additionalEducation: string): void {
    this.utilsService.addStringValueToCollection(this.profession.additionalEducations, additionalEducation);
  }

  public addEducation(): void {
    if (!this.profession.education) {
      this.profession.education = [
        {
          level: this.addedEducation.level,
          isPreferred: this.addedEducation.isPreferred,
          perentage: this.addedEducation.perentage,
        },
      ];
    } else {
      const indexEducation: number = this.profession.education.findIndex(d => d.level === this.addedEducation.level);
      if (indexEducation === -1) {
        const education = {
          level: this.addedEducation.level,
          isPreferred: this.addedEducation.isPreferred,
          perentage: this.addedEducation.perentage,
        };
        this.profession.education.push(education);
      }
    }
  }

  public addVideo(videoName: string, videoUrl: string): void {
    if (videoName && videoUrl) {
      if (!this.profession.videos) {
        this.profession.videos = [
          {
            name: videoName,
            url: videoUrl,
          },
        ];
      } else {
        const indexVideo: number = this.profession.videos.findIndex(d => d.name === videoName);
        if (indexVideo === -1) {
          const video = {
            name: videoName,
            url: videoUrl,
          };
          this.profession.videos.push(video);
        }
      }
    }
  }

  public addCompany(companyName: string, companyDescription: string, companyUrl: string, companyImageId: string): void {
    if (companyName && companyDescription) {
      if (!this.profession.companies) {
        this.profession.companies = [
          {
            name: companyName,
            description: companyDescription,
            url: companyUrl,
            imageId: companyImageId,
          },
        ];
      } else {
        const indexCompany: number = this.profession.companies.findIndex(d => d.name === companyName);
        if (indexCompany === -1) {
          const company = {
            name: companyName,
            description: companyDescription,
            url: companyUrl,
            imageId: companyImageId,
          };
          this.profession.companies.push(company);
        }
      }
    }
  }

  public addArticle(articleName: string, articleUrl: string): void {
    if (articleName && articleUrl) {
      if (!this.profession.articles) {
        this.profession.articles = [
          {
            name: articleName,
            url: articleUrl,
          },
        ];
      } else {
        const indexArticle: number = this.profession.articles.findIndex(d => d.name === articleName);
        if (indexArticle === -1) {
          const article = {
            name: articleName,
            url: articleUrl,
          };
          this.profession.articles.push(article);
        }
      }
    }
  }

  public addPerson(): void {
    if (this.addedPerson.name && this.addedPerson.description && this.addedPerson.imagePath) {
      if (!this.profession.persons) {
        this.profession.persons = [
          {
            name: this.addedPerson.name,
            description: this.addedPerson.description,
            imagePath: this.addedPerson.imagePath,
          },
        ];
      } else {
        const indexPerson: number = this.profession.persons.findIndex(d => d.name === this.addedPerson.name);
        if (indexPerson === -1) {
          const person = {
            name: this.addedPerson.name,
            description: this.addedPerson.description,
            imagePath: this.addedPerson.imagePath,
          };
          this.profession.persons.push(person);
          this.addedPerson.imagePath = '';
          this.addedPerson.description = '';
          this.addedPerson.name = '';
        }
      }
    } else if (!this.addedPerson.imagePath) {
      this.utilsService.openSnackBar('👎 Фото персоны не загружено', 'error');
    } else if (!this.addedPerson.name || !this.addedPerson.description) {
      this.utilsService.openSnackBar('👎 Необходимо заполнить все поля о персоне', 'error');
    }
  }

  public addSpecial(specialName: string, specialEducationLevel: number): void {
    if (specialName && specialEducationLevel) {
      if (!this.profession.specialities) {
        this.profession.specialities = [
          {
            name: specialName,
            educationLevel: specialEducationLevel,
          },
        ];
      } else {
        const indexSpecials: number = this.profession.specialities.findIndex(
          d => d.name === specialName && d.educationLevel === specialEducationLevel,
        );
        if (indexSpecials === -1) {
          const specials = {
            name: specialName,
            educationLevel: specialEducationLevel,
          };
          this.profession.specialities.push(specials);
        }
      }
    }
  }

  public removeMovies(): void {
    this.selectedOptions.forEach(item => {
      this.utilsService.deleteItemFromStringArray(this.profession.movies, item);
    });
    this.selectedOptions = [];
  }

  public removeSkills(): void {
    this.selectedOptions.forEach(item => {
      this.utilsService.deleteItemFromStringArray(this.profession.skills, item);
    });
    this.selectedOptions = [];
  }

  public removeResponsibilities(): void {
    this.selectedOptions.forEach(item => {
      this.utilsService.deleteItemFromStringArray(this.profession.resposibilities, item);
    });
    this.selectedOptions = [];
  }

  public removeProductGroups(): void {
    this.selectedOptions.forEach(item => {
      this.utilsService.deleteItemFromStringArray(this.profession.productGroups, item);
    });
    this.selectedOptions = [];
  }

  public removeKnowledges(): void {
    this.selectedOptions.forEach(item => {
      this.utilsService.deleteItemFromStringArray(this.profession.knowledge, item);
    });
    this.selectedOptions = [];
  }

  public removeAbilities(): void {
    this.selectedOptions.forEach(item => {
      this.utilsService.deleteItemFromStringArray(this.profession.abilities, item);
    });
    this.selectedOptions = [];
  }

  public removeAdditionalEducations(): void {
    this.selectedOptions.forEach(item => {
      this.utilsService.deleteItemFromStringArray(this.profession.additionalEducations, item);
    });
    this.selectedOptions = [];
  }

  public removeFacts(): void {
    this.selectedOptions.forEach(item => {
      this.utilsService.deleteItemFromStringArray(this.profession.facts, item);
    });
    this.selectedOptions = [];
  }

  public removeArticles(): void {
    this.selectedOptions.forEach(item => {
      this.utilsService.deleteItemFromCollectionByName(this.profession.articles, item);
    });
    this.selectedOptions = [];
  }

  public removeVideos(): void {
    this.selectedOptions.forEach(item => {
      this.utilsService.deleteItemFromCollectionByName(this.profession.videos, item);
    });
    this.selectedOptions = [];
  }

  public removeCompanies(): void {
    this.selectedOptions.forEach(item => {
      this.utilsService.deleteItemFromCollectionByName(this.profession.companies, item);
    });
    this.selectedOptions = [];
  }

  public removePersons(): void {
    this.selectedOptions.forEach(item => {
      this.utilsService.deleteItemFromCollectionByName(this.profession.persons, item);
    });
    this.selectedOptions = [];
  }

  public removeEducations(): void {
    this.selectedOptions.forEach(item => {
      const indexEducation: number = this.profession.education.findIndex(d => d.level === item.level);
      if (indexEducation > -1) {
        this.profession.education.splice(indexEducation, 1);
      }
    });
    this.selectedOptions = [];
  }

  public removeSpecials(): void {
    this.selectedOptions.forEach(item => {
      const indexSpecials: number = this.profession.specialities.findIndex(
        d => d.name === item.name && d.educationLevel === item.educationLevel,
      );
      if (indexSpecials > -1) {
        this.profession.specialities.splice(indexSpecials, 1);
      }
    });
    this.selectedOptions = [];
  }

  public updateCompanyField(event: any, companyField: string, companyIndex: number): void {
    this.profession.companies[companyIndex][companyField] = event.target.value;
  }

  public selectFile(event: Event): void {
    const input = event.target as HTMLInputElement;

    if (input?.files.item(0).type.match('image.*')) {
      this.selectedPhoto = input?.files;
    } else {
      alert('invalid format!');
    }
  }

  public uploadPhoto(): void {
    if (!this.selectedPhoto) {
      this.utilsService.openSnackBar('👎 Сначала выберите файл', 'error');
      return;
    }

    this.adminPanelService
      .uploadImage(this.selectedPhoto, this.profession.id)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(r => {
        this.addedPerson.imagePath = r;
      });
  }

  public uploadImage(event: Event): void {
    this.loadingUpload = true;
    const input = event.target as HTMLInputElement;
    const reader = new FileReader();

    if (input?.files.item(0).type.match('image.*')) {
      this.selectedImage = input?.files;
    } else {
      this.loadingUpload = false;
      return alert('invalid format!');
    }

    reader.addEventListener('load', () => {
      this.imagePreview = reader.result;
    });

    this.adminPanelService
      .uploadImage(this.selectedImage, this.profession.id)
      .pipe(
        map((imgPath: string) => {
          return { path: imgPath };
        }),
        switchMap(imgData => {
          this.profession.images = [imgData];
          return this.adminPanelService.addImagePathsInProfession(this.profession.id, imgData.path);
        }),
        takeUntil(this.unsubscribe),
      )
      .subscribe(
        () => {
          this.loadingUpload = false;
          reader.readAsDataURL(this.selectedImage[0]);
        },
        () => {
          this.loadingUpload = false;
          this.utilsService.openSnackBar('👎 Не получилось загрузить, попробуйте позже', 'error');
        },
      );
  }

  public deleteImage(): void {
    // todo метод для удаления изображения профессии, пока не работает, нужно реализовать
    this.loadingDeleting = true;

    this.adminPanelService
      .deleteImagePathsInProfession(this.profession.id, this.profession.images[0].id, this.profession.images[0].path)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        () => {
          this.loadingDeleting = false;
          this.imagePreview = null;
        },
        () => {
          this.loadingDeleting = false;
          this.utilsService.openSnackBar('👎 Не получилось удалить, попробуйте позже', 'error');
        },
      );
  }

  public getImagePath(): string | null {
    const imgPath = this.imagePreview ?? this.profession.images[this.profession.images.length - 1]?.path;

    return imgPath ? `url(${imgPath})` : '';
  }

  public openChangeProfessionVisibilityWindow(event: MatSlideToggleChange, profession: IProfession): void {
    this.popUpConfirmHidingProfession = !event.checked;

    if (!this.popUpConfirmHidingProfession) {
      this.setProfessionVisibility(profession, true);
    } else {
      this.professionToHide = profession;
    }
  }

  public hideProfession(): void {
    if (this.professionToHide) {
      this.setProfessionVisibility(this.professionToHide, false);
      this.professionToHide = null;
    }

    this.popUpConfirmHidingProfession = false;
  }

  public closePopUpConfirmHidingProfession(): void {
    this.popUpConfirmHidingProfession = !this.popUpConfirmHidingProfession;
    this.resetSlideToggle(null);
  }

  private setProfessionVisibility(profession: IProfession, isVisible: boolean): void {
    this.adminPanelService
      .setProfessionVisibility(profession.hrid, isVisible)
      .pipe(take(1))
      .subscribe({
        next: resp => {
          if (resp.status === 'Success') {
            profession.isVisible = isVisible;
            this.utilsService.openSnackBar(`Профессия успешно ${isVisible ? 'опубликована' : 'скрыта'}`, 'success');
          } else {
            this.utilsService.openSnackBar(`Ошибка ${resp.comment}`, 'error');
            this.resetSlideToggle(profession);
          }
        },
        error: () => {
          this.utilsService.openSnackBar('Ошибка редактирования профессии', 'error');
          this.resetSlideToggle(profession);
        },
      });
  }

  private resetSlideToggle(profession: IProfession): void {
    if (profession) {
      profession.isVisible = !profession.isVisible;
    }

    if (this.professionToHide) {
      this.professionToHide.isVisible = !this.professionToHide.isVisible;
    }
  }

  private getTranslations(keys: string[]): Observable<any> {
    return this.translateService.get(keys);
  }

  public selectedFieldType(): void {
    this.fields = null;
    this.apiFieldsService.getAllFields(this.professionFieldType).subscribe(response => {
      this.fields = response.fields;
    });
  }
}
