import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'prf-pupil-talant-card',
  templateUrl: './pupil-talant-card.component.html',
  styleUrls: ['./pupil-talant-card.component.scss'],
})
export class PupilTalantCardComponent implements OnInit {
  @Input() talant: any;

  constructor() {}

  ngOnInit(): void {
    this.setCardData(this.talant);
  }

  setCardData(talant) {
    if (talant?.name) {
      switch (talant.name) {
        case 'Исследователь':
          talant['shortDescription'] = 'TALANTS_DESC.RESEARCHER';
          talant.imageUrl = '/assets/images/talants/pupil_talants_cards/researcher.svg';
          break;
        case 'Лидер ':
          talant['shortDescription'] = 'TALANTS_DESC.LEDAER';
          talant.imageUrl = '/assets/images/talants/pupil_talants_cards/leader.svg';
          break;
        case 'Коммуникатор':
          talant['shortDescription'] = 'TALANTS_DESC.COMMUNICATOR';
          talant.imageUrl = '/assets/images/talants/pupil_talants_cards/communicator.svg';
          break;
        case 'Креатор':
          talant['shortDescription'] = 'TALANTS_DESC.CREATOR';
          talant.imageUrl = '/assets/images/talants/pupil_talants_cards/creator.svg';
          break;
        case 'Мастер':
          talant['shortDescription'] = 'TALANTS_DESC.MASTER';
          talant.imageUrl = '/assets/images/talants/pupil_talants_cards/master.svg';
          break;
        case 'Инженер':
          talant['shortDescription'] = 'TALANTS_DESC.ENGINEER';
          talant.imageUrl = '/assets/images/talants/pupil_talants_cards/engineer.svg';
          break;
        case 'Аналитик':
          talant['shortDescription'] = 'TALANTS_DESC.ANALYTIC';
          talant.imageUrl = '/assets/images/talants/pupil_talants_cards/analytic.svg';
          break;
      }
    }
  }
}
