<a class="professions-card w-inline-block" *ngIf="profession && profession.name" (click)="openProfession()">
  <!-- <prf-profession-by-municipality></prf-profession-by-municipality> -->
  <div class="prof-municipality" *ngIf="checkProfession(profession)">
    <img
      src="./assets/images/icons/fire-profession.svg"
      class="prof-municipality-image"
      (mouseleave)="popup.style.display = 'none'"
      (mouseover)="popup.style.display = 'block'"
    />
    <div class="info-popup recommendation w-hidden-small w-hidden-tiny" #popup style="display: none">
      <p>
        {{ 'SHARED.NEW_CARD_DESCRIPTION' | translate }}
      </p>
      <img src="./assets/images/icons/info-triangle.png" width="12" class="info-triangle" />
    </div>
  </div>
  <div class="professions-image"><img src="{{ profession.mainImagePath }}" alt="" /></div>
  <div class="prof-column">
    <div class="professions-card-vertical-column">
      <div>
        <div class="professions-heading-wrapper">
          <h3
            class="professions-h3"
            (mouseleave)="popup.style.display = 'none'"
            (mouseover)="profession.name.length > 30 && !isMobile ? (popup.style.display = 'block') : null"
          >
            {{ isMobile ? profession.name : cutText(profession.name, 30) }}
          </h3>
          <div class="info-popup recommendation profession w-hidden-small w-hidden-tiny" #popup style="display: none">
            <p>{{ profession.name }}</p>
            <img src="./assets/images/icons/info-triangle.png" width="12" class="info-triangle" />
          </div>
        </div>
      </div>

      <p class="professions-description">{{ profession.shortDescription }}</p>
    </div>
    <div class="professions-wage" *ngIf="showSalary">
      {{ profession.minSalary }}–{{ profession.maxSalary }}
      <span class="rub">{{ 'COMMON.MONEY_SYMBOL' | translateByDefault }}</span>
    </div>
  </div>
</a>
