<button type="button" (click)="addNewTask()">Add new Task</button>

<ng-container>
  <form [formGroup]="form" (change)="outTasks($event)">
    <ng-container formArrayName="tasks">
      <ng-container *ngFor="let task of form.get('tasks')['controls']; let i = index">
        <fieldset>
          <legend>
            <h3>task {{ i + 1 }}:</h3>
          </legend>
          <ng-container [formGroupName]="i">
            <button type="button" (click)="deleteControl(form.get('tasks'), i)">Delete task</button>
            <div *ngIf="form.value.id">
              <label>task id:</label>
              <input formControlName="id" readonly />
            </div>
            <div>
              <label>task type:</label>
              <select formControlName="type" [class.error-field]="submit && form.get('tasks').controls[i].controls.type.errors">
                <option *ngFor="let type of enumTaskType | enumToArray" [value]="type">
                  {{ enumTaskType[type] }}
                </option>
              </select>
            </div>
            <div>
              <label>task orderNumber:</label>
              <input
                type="number"
                formControlName="orderNumber"
                [class.error-field]="submit && form.get('tasks').controls[i].controls.orderNumber.errors"
              />
            </div>
            <br />
            <div>
              <label>task text:</label>
              <quill-editor formControlName="text" [class.error-field]="submit && form.get('tasks').controls[i].controls.text.errors">
              </quill-editor>
              <button (click)="onQuillContentChanged()">Save text</button>
            </div>
            <br />
            <div>
              <label>task imageUrl:</label>
              <input formControlName="imageUrl" [class.error-field]="submit && form.get('tasks').controls[i].controls.imageUrl.errors" />
              <div>
                <div>Load image</div>
                <input class="upload-image" type="file" (change)="selectFile($event, form.get('tasks')['controls'][i])" />
              </div>
            </div>
            <div>
              <label class="textarea-label">task videoUrl:</label>
              <input type="text" formControlName="videoUrl" />
            </div>
            <div>
              <label class="textarea-label">Answer rightAnswerComment:</label>
              <textarea
                formControlName="rightAnswerComment"
                [class.error-field]="submit && form.get('tasks').controls[i].controls.rightAnswerComment.errors"
              ></textarea>
              <mat-error *ngIf="form.get('tasks').controls[i].controls.rightAnswerComment.errors"> Заполните поле </mat-error>
            </div>
            <div>
              <label class="textarea-label">Answer wrongAnswerComment:</label>
              <textarea
                formControlName="wrongAnswerComment"
                [class.error-field]="submit && form.get('tasks').controls[i].controls.wrongAnswerComment.errors"
              ></textarea>
              <mat-error *ngIf="form.get('tasks').controls[i].controls.wrongAnswerComment.errors"> Заполните поле </mat-error>
            </div>
            <ng-container [ngSwitch]="task.value.type">
              <ng-container *ngSwitchCase="'0'" formGroupName="radioButtonTask">
                <div *ngIf="task.get('radioButtonTask.rightAnswerId')">
                  <label>task rightAnswerId:</label>
                  <input formControlName="rightAnswerId" />
                </div>
                <br />

                <div formArrayName="answers">
                  <div *ngFor="let answer of task.get('radioButtonTask.answers').controls; let j = index">
                    <fieldset>
                      <legend>
                        <h4>Answer {{ j + 1 }}</h4>
                      </legend>
                      <div [formGroupName]="j">
                        <button type="button" (click)="deleteControl(task.get('radioButtonTask.answers'), j)">Delete Answer</button>
                        <div *ngIf="answer.value.id">
                          <label>task id:</label>
                          <input formControlName="id" readonly />
                        </div>
                        <div>
                          <label>Answer orderNumber:</label>
                          <input
                            type="number"
                            formControlName="orderNumber"
                            [class.error-field]="
                              submit && form.get('tasks').controls[i].get('radioButtonTask.answers').controls[j].controls.orderNumber.errors
                            "
                          />
                        </div>
                        <div>
                          <label>Answer text:</label>
                          <input
                            formControlName="text"
                            [class.error-field]="
                              submit && form.get('tasks').controls[i].get('radioButtonTask.answers').controls[j].hasError('atLeastOne')
                            "
                          />
                        </div>
                        <div>
                          <label>Answer imageUrl:</label>
                          <input
                            formControlName="imageUrl"
                            [class.error-field]="
                              submit && form.get('tasks').controls[i].get('radioButtonTask.answers').controls[j].hasError('atLeastOne')
                            "
                          />
                          <div>
                            <div>Load image</div>
                            <input
                              class="upload-image"
                              type="file"
                              (change)="selectFile($event, form.get('tasks')['controls'][i].get('radioButtonTask.answers')['controls'][j])"
                            />
                          </div>
                        </div>
                      </div>
                    </fieldset>
                  </div>
                  <button type="button" (click)="addNewAnswer(task.get('radioButtonTask.answers'))">Add new Answer</button>
                </div>
              </ng-container>
              <ng-container *ngSwitchCase="'1'" formGroupName="checkBoxTask">
                <button *ngIf="isEdit" type="button" (click)="addArray(task.get('checkBoxTask').controls, 'rightAnswersIds')">
                  Add new Id
                </button>
                <div *ngIf="task.get('checkBoxTask.rightAnswersIds')">
                  <label>task rightAnswerId:</label>
                  <div formArrayName="rightAnswersIds">
                    <div *ngFor="let rightAnswerId of task.get('checkBoxTask.rightAnswersIds').controls; let j = index">
                      <input [formControlName]="j" />
                      <button type="button" (click)="deleteControl(task.get('checkBoxTask.rightAnswersIds'), j)">Del Id</button>
                    </div>
                  </div>
                </div>
                <br />

                <div formArrayName="answers">
                  <div *ngFor="let answer of task.get('checkBoxTask.answers').controls; let j = index">
                    <fieldset>
                      <legend>
                        <h4>Answer {{ j + 1 }}</h4>
                      </legend>
                      <div [formGroupName]="j">
                        <button type="button" (click)="deleteControl(task.get('checkBoxTask.answers'), j)">Delete Answer</button>
                        <div *ngIf="answer.value.id">
                          <label>Task id:</label>
                          <input formControlName="id" readonly />
                        </div>
                        <div>
                          <label>Answer orderNumber:</label>
                          <input
                            type="number"
                            formControlName="orderNumber"
                            [class.error-field]="
                              submit && form.get('tasks').controls[i].get('checkBoxTask.answers').controls[j].controls.orderNumber.errors
                            "
                          />
                        </div>
                        <div>
                          <label>Answer text:</label>
                          <input
                            formControlName="text"
                            [class.error-field]="
                              submit && form.get('tasks').controls[i].get('checkBoxTask.answers').controls[j].hasError('atLeastOne')
                            "
                          />
                        </div>
                        <div>
                          <label>Answer imageUrl:</label>
                          <input
                            formControlName="imageUrl"
                            [class.error-field]="
                              submit && form.get('tasks').controls[i].get('checkBoxTask.answers').controls[j].hasError('atLeastOne')
                            "
                          />
                          <div>
                            <div>Load image</div>
                            <input
                              class="upload-image"
                              type="file"
                              (change)="selectFile($event, form.get('tasks')['controls'][i].get('checkBoxTask.answers')['controls'][j])"
                            />
                          </div>
                        </div>
                      </div>
                    </fieldset>
                  </div>
                  <button type="button" (click)="addNewAnswer(task.get('checkBoxTask.answers'))">Add new Answer</button>
                </div>
              </ng-container>
              <ng-container *ngSwitchCase="'2'" formGroupName="matchingTask">
                <br />
                <div>Task rightBlockMatchingId:</div>
                <button *ngIf="isEdit" type="button" (click)="addObject(task.get('matchingTask').controls, 'rightBlockMatchingIds')">
                  Add new Id
                </button>
                <ng-container *ngIf="task.get('matchingTask.rightBlockMatchingIds')">
                  <div formGroupName="rightBlockMatchingIds">
                    <div
                      *ngFor="let rightBlockMatchingId of task.get('matchingTask.rightBlockMatchingIds').controls | keyvalue; let j = index"
                    >
                      rightBlockMatchingId answer Id <br />(input first blockA Id -> click outside -> than blockB Id -> click outside):
                      <input
                        [formControlName]="rightBlockMatchingId.key"
                        (change)="setObjectControlName(task.get('matchingTask.rightBlockMatchingIds'), rightBlockMatchingId)"
                      />
                      <ng-container *ngIf="!rightBlockMatchingId.key.includes('new_')">
                        blockA Id: {{ rightBlockMatchingId.key }}
                      </ng-container>
                      <button
                        type="button"
                        (click)="deleteObject(task.get('matchingTask.rightBlockMatchingIds'), rightBlockMatchingId.key)"
                      >
                        Del Id
                      </button>
                    </div>
                  </div>
                </ng-container>
                <ng-container *ngIf="task.get('matchingTask.rightBlockMatchingIds').value">
                  <pre>

                                        {{ task.get('matchingTask.rightBlockMatchingIds').value | json }}
                                    </pre
                  >
                </ng-container>

                <br />

                <br />
                <button *ngIf="isEdit" type="button" (click)="addObject(task.get('matchingTask').controls, 'rightAnswerComments')">
                  Add new comment for Right answer:
                </button>
                <ng-container *ngIf="task.get('matchingTask.rightAnswerComments')">
                  <div formGroupName="rightAnswerComments">
                    <div
                      *ngFor="let rightAnswerCommentId of task.get('matchingTask.rightAnswerComments').controls | keyvalue; let j = index"
                    >
                      rightAnswerComment <br />(input first answer Id -> click outside -> then answer comment -> click outside):
                      <input
                        [formControlName]="rightAnswerCommentId.key"
                        (change)="setObjectControlName(task.get('matchingTask.rightAnswerComments'), rightAnswerCommentId)"
                      />
                      <ng-container *ngIf="!rightAnswerCommentId.key.includes('new_')">
                        answer Id: {{ rightAnswerCommentId.key }}
                      </ng-container>
                      <button type="button" (click)="deleteObject(task.get('matchingTask.rightAnswerComments'), rightAnswerCommentId.key)">
                        Del Id
                      </button>
                    </div>
                  </div>
                </ng-container>

                <br />
                <button *ngIf="isEdit" type="button" (click)="addObject(task.get('matchingTask').controls, 'wrongAnswerComments')">
                  Add new comment for Wrong answer:
                </button>
                <ng-container *ngIf="task.get('matchingTask.wrongAnswerComments')">
                  <div formGroupName="wrongAnswerComments">
                    <div
                      *ngFor="let wrongAnswerCommentId of task.get('matchingTask.wrongAnswerComments').controls | keyvalue; let j = index"
                    >
                      wrongAnswerComment <br />(input first answer Id -> click outside -> then answer comment -> click outside):
                      <input
                        [formControlName]="wrongAnswerCommentId.key"
                        (change)="setObjectControlName(task.get('matchingTask.wrongAnswerComments'), wrongAnswerCommentId)"
                      />
                      <ng-container *ngIf="!wrongAnswerCommentId.key.includes('new_')">
                        answer Id: {{ wrongAnswerCommentId.key }}
                      </ng-container>
                      <button type="button" (click)="deleteObject(task.get('matchingTask.wrongAnswerComments'), wrongAnswerCommentId.key)">
                        Del Id
                      </button>
                    </div>
                  </div>
                </ng-container>

                <label>BlockA name:</label>
                <input
                  type="text"
                  formControlName="blockAName"
                  [class.error-field]="submit && form.get('tasks').controls[i].get('matchingTask.blockAName').errors"
                />
                <label>BlockB name:</label>
                <input
                  type="text"
                  formControlName="blockBName"
                  [class.error-field]="submit && form.get('tasks').controls[i].get('matchingTask.blockBName').errors"
                />
                <br />
                <ng-container formArrayName="blockA">
                  <ng-container *ngFor="let answer of task.get('matchingTask.blockA').controls; let j = index">
                    <fieldset>
                      <legend>
                        <h4>blockA {{ j + 1 }}</h4>
                      </legend>
                      <ng-container [formGroupName]="j">
                        <button type="button" (click)="deleteControl(task.get('matchingTask.blockA'), j)">Delete blockA</button>
                        <div *ngIf="answer.value.id">
                          <label>Task id:</label>
                          <input formControlName="id" readonly />
                        </div>
                        <div>
                          <label>Answer orderNumber:</label>
                          <input
                            type="number"
                            formControlName="orderNumber"
                            [class.error-field]="
                              submit && form.get('tasks').controls[i].get('matchingTask.blockA').controls[j].controls.orderNumber.errors
                            "
                          />
                        </div>
                        <div>
                          <label>Answer text:</label>
                          <input
                            formControlName="text"
                            [class.error-field]="
                              submit && form.get('tasks').controls[i].get('matchingTask.blockA').controls[j].hasError('atLeastOne')
                            "
                          />
                        </div>
                        <div>
                          <label>Answer imageUrl:</label>
                          <input
                            formControlName="imageUrl"
                            [class.error-field]="
                              submit && form.get('tasks').controls[i].get('matchingTask.blockA').controls[j].hasError('atLeastOne')
                            "
                          />
                          <div>
                            <div>Load image</div>
                            <input
                              class="upload-image"
                              type="file"
                              (change)="selectFile($event, form.get('tasks')['controls'][i].get('matchingTask.blockA')['controls'][j])"
                            />
                          </div>
                        </div>
                      </ng-container>
                    </fieldset>
                  </ng-container>
                  <button type="button" (click)="addNewAnswer(task.get('matchingTask.blockA'))">Add new blockA</button>
                </ng-container>
                <ng-container formArrayName="blockB">
                  <ng-container *ngFor="let answer of task.get('matchingTask.blockB').controls; let j = index">
                    <fieldset>
                      <legend>
                        <h4>blockB {{ j + 1 }}</h4>
                      </legend>
                      <ng-container [formGroupName]="j">
                        <button type="button" (click)="deleteControl(task.get('matchingTask.blockB'), j)">Delete blockB</button>
                        <div *ngIf="answer.value.id">
                          <label>Task id:</label>
                          <input formControlName="id" readonly />
                        </div>
                        <div>
                          <label>Answer orderNumber:</label>
                          <input
                            type="number"
                            formControlName="orderNumber"
                            [class.error-field]="
                              submit && form.get('tasks').controls[i].get('matchingTask.blockB').controls[j].controls.orderNumber.errors
                            "
                          />
                        </div>
                        <div>
                          <label>Answer text:</label>
                          <input
                            formControlName="text"
                            [class.error-field]="
                              submit && form.get('tasks').controls[i].get('matchingTask.blockB').controls[j].hasError('atLeastOne')
                            "
                          />
                        </div>
                        <div>
                          <label>Answer imageUrl:</label>
                          <input
                            formControlName="imageUrl"
                            [class.error-field]="
                              submit && form.get('tasks').controls[i].get('matchingTask.blockB').controls[j].hasError('atLeastOne')
                            "
                          />
                          <div>
                            <div>Load image</div>
                            <input
                              class="upload-image"
                              type="file"
                              (change)="selectFile($event, form.get('tasks')['controls'][i].get('matchingTask.blockB')['controls'][j])"
                            />
                          </div>
                        </div>
                      </ng-container>
                    </fieldset>
                  </ng-container>
                  <button type="button" (click)="addNewAnswer(task.get('matchingTask.blockB'))">Add new blockB</button>
                </ng-container>
              </ng-container>
              <ng-container *ngSwitchCase="'3'" formGroupName="partitioningTask">
                <br />
                <button *ngIf="isEdit" type="button" (click)="addArray(task.get('partitioningTask').controls, 'rightAnswersIdsForGroupA')">
                  Add new rightAnswersIdForGroupA
                </button>
                <div *ngIf="task.get('partitioningTask.rightAnswersIdsForGroupA')">
                  <label>Task rightAnswersIdsForGroupA:</label>
                  <div formArrayName="rightAnswersIdsForGroupA">
                    <div
                      *ngFor="let rightAnswersIdForGroupA of task.get('partitioningTask.rightAnswersIdsForGroupA').controls; let j = index"
                    >
                      <input [formControlName]="j" />
                      <button type="button" (click)="deleteControl(task.get('partitioningTask.rightAnswersIdsForGroupA'), j)">
                        Del Id
                      </button>
                    </div>
                  </div>
                </div>
                <br />
                <button *ngIf="isEdit" type="button" (click)="addArray(task.get('partitioningTask').controls, 'rightAnswersIdsForGroupB')">
                  Add new rightAnswersIdForGroupB
                </button>
                <div *ngIf="task.get('partitioningTask.rightAnswersIdsForGroupB')">
                  <label>Task rightAnswersIdsForGroupB:</label>
                  <div formArrayName="rightAnswersIdsForGroupB">
                    <div
                      *ngFor="let rightAnswersIdForGroupB of task.get('partitioningTask.rightAnswersIdsForGroupB').controls; let j = index"
                    >
                      <input [formControlName]="j" />
                      <button type="button" (click)="deleteControl(task.get('partitioningTask.rightAnswersIdsForGroupB'), j)">
                        Del Id
                      </button>
                    </div>
                  </div>
                </div>
                <br />

                <label>GroupAName name:</label>
                <input
                  type="text"
                  formControlName="groupAName"
                  [class.error-field]="submit && form.get('tasks').controls[i].get('partitioningTask.groupAName').errors"
                />
                <label>GroupBName name:</label>
                <input
                  type="text"
                  formControlName="groupBName"
                  [class.error-field]="submit && form.get('tasks').controls[i].get('partitioningTask.groupBName').errors"
                />
                <br />

                <br />
                <button *ngIf="isEdit" type="button" (click)="addObject(task.get('partitioningTask').controls, 'commentsForRightAnswer')">
                  Add new comment for Right answer:
                </button>
                <ng-container *ngIf="task.get('partitioningTask.commentsForRightAnswer')">
                  <div formGroupName="commentsForRightAnswer">
                    <div
                      *ngFor="
                        let commentForRightAnswerId of task.get('partitioningTask.commentsForRightAnswer').controls | keyvalue;
                        let j = index
                      "
                    >
                      commentsForRightAnswer <br />(input first answer Id -> click outside -> then answer comment -> click outside):
                      <input
                        [formControlName]="commentForRightAnswerId.key"
                        (change)="setObjectControlName(task.get('partitioningTask.commentsForRightAnswer'), commentForRightAnswerId)"
                      />
                      <ng-container *ngIf="!commentForRightAnswerId.key.includes('new_')">
                        answer Id: {{ commentForRightAnswerId.key }}
                      </ng-container>
                      <button
                        type="button"
                        (click)="deleteObject(task.get('partitioningTask.commentsForRightAnswer'), commentForRightAnswerId.key)"
                      >
                        Del Id
                      </button>
                    </div>
                  </div>
                </ng-container>

                <br />
                <button *ngIf="isEdit" type="button" (click)="addObject(task.get('partitioningTask').controls, 'commentsForWrongAnswer')">
                  Add new comment for Wrong answer:
                </button>
                <ng-container *ngIf="task.get('partitioningTask.commentsForWrongAnswer')">
                  <div formGroupName="commentsForWrongAnswer">
                    <div
                      *ngFor="
                        let commentForWrongAnswerId of task.get('partitioningTask.commentsForWrongAnswer').controls | keyvalue;
                        let j = index
                      "
                    >
                      commentsForWrongAnswer <br />(input first answer Id -> click outside -> then answer comment -> click outside):
                      <input
                        [formControlName]="commentForWrongAnswerId.key"
                        (change)="setObjectControlName(task.get('partitioningTask.commentsForWrongAnswer'), commentForWrongAnswerId)"
                      />
                      <ng-container *ngIf="!commentForWrongAnswerId.key.includes('new_')">
                        answer Id: {{ commentForWrongAnswerId.key }}
                      </ng-container>
                      <button
                        type="button"
                        (click)="deleteObject(task.get('partitioningTask.commentsForWrongAnswer'), commentsForWrongAnswer.key)"
                      >
                        Del Id
                      </button>
                    </div>
                  </div>
                </ng-container>

                <div formArrayName="answers">
                  <div *ngFor="let answer of task.get('partitioningTask.answers').controls; let j = index">
                    <fieldset>
                      <legend>
                        <h4>Answer {{ j + 1 }}</h4>
                      </legend>
                      <div [formGroupName]="j">
                        <button type="button" (click)="deleteControl(task.get('partitioningTask.answers'), j)">Delete Answer</button>
                        <div *ngIf="answer.value.id">
                          <label>Task id:</label>
                          <input formControlName="id" readonly />
                        </div>
                        <div>
                          <label>Answer orderNumber:</label>
                          <input
                            type="number"
                            formControlName="orderNumber"
                            [class.error-field]="
                              submit &&
                              form.get('tasks').controls[i].get('partitioningTask.answers').controls[j].controls.orderNumber.errors
                            "
                          />
                        </div>
                        <div>
                          <label>Answer text:</label>
                          <input
                            formControlName="text"
                            [class.error-field]="
                              submit && form.get('tasks').controls[i].get('partitioningTask.answers').controls[j].hasError('atLeastOne')
                            "
                          />
                        </div>
                        <div>
                          <label>Answer imageUrl:</label>
                          <input
                            formControlName="imageUrl"
                            [class.error-field]="
                              submit && form.get('tasks').controls[i].get('partitioningTask.answers').controls[j].hasError('atLeastOne')
                            "
                          />
                          <div>
                            <div>Load image</div>
                            <input
                              class="upload-image"
                              type="file"
                              (change)="selectFile($event, form.get('tasks')['controls'][i].get('partitioningTask.answers')['controls'][j])"
                            />
                          </div>
                        </div>
                      </div>
                    </fieldset>
                  </div>
                  <button type="button" (click)="addNewAnswer(task.get('partitioningTask.answers'))">Add new Answer</button>
                </div>
              </ng-container>
              <ng-container *ngSwitchCase="'4'" formGroupName="multiMatchingTask">
                <ng-container formArrayName="sections">
                  <button type="button" (click)="addNewMatching(task.get('multiMatchingTask.sections'))">Add new Section</button>
                  <ng-container *ngFor="let section of task.get('multiMatchingTask.sections').controls; let j = index">
                    <ng-container [formGroupName]="j">
                      <fieldset>
                        <legend>
                          <h3>Section {{ j }}</h3>
                          <button type="button" (click)="deleteControl(task.get('multiMatchingTask.sections'), j)">Del Matching</button>
                        </legend>

                        <br />
                        <div>Task rightBlockMatchingId:</div>
                        <button *ngIf="isEdit" type="button" (click)="addObject(section.controls, 'rightBlockMatchingIds')">
                          Add new Id
                        </button>
                        <ng-container *ngIf="section.get('rightBlockMatchingIds')">
                          <div formGroupName="rightBlockMatchingIds">
                            <div
                              *ngFor="let rightBlockMatchingId of section.get('rightBlockMatchingIds').controls | keyvalue; let j = index"
                            >
                              rightBlockMatchingId answer Id <br />(input first blockA Id -> click outside -> then blockB Id -> click
                              outside):
                              <input
                                [formControlName]="rightBlockMatchingId.key"
                                (change)="setObjectControlName(section.get('rightBlockMatchingIds'), rightBlockMatchingId)"
                              />
                              <ng-container *ngIf="!rightBlockMatchingId.key.includes('new_')">
                                blockA Id: {{ rightBlockMatchingId.key }}
                              </ng-container>
                              <button type="button" (click)="deleteObject(section.get('rightBlockMatchingIds'), rightBlockMatchingId.key)">
                                Del Id
                              </button>
                            </div>
                          </div>
                        </ng-container>
                        <ng-container *ngIf="section.get('matchingTask.rightBlockMatchingIds').value">
                          <pre>

                                                        {{ section.get('rightBlockMatchingIds').value | json }}
                                                    </pre
                          >
                        </ng-container>
                        <br />

                        <br />
                        <button *ngIf="isEdit" type="button" (click)="addObject(section.controls, 'rightAnswerComments')">
                          Add new comment for Right answer:
                        </button>
                        <ng-container *ngIf="section.get('rightAnswerComments')">
                          <div formGroupName="rightAnswerComments">
                            <div *ngFor="let rightAnswerCommentId of section.get('rightAnswerComments').controls | keyvalue; let j = index">
                              rightAnswerComment <br />(input first answer Id -> click outside -> then answer comment -> click outside):
                              <input
                                [formControlName]="rightAnswerCommentId.key"
                                (change)="setObjectControlName(section.get('rightAnswerComments'), rightAnswerCommentId)"
                              />
                              <ng-container *ngIf="!rightAnswerCommentId.key.includes('new_')">
                                answer Id: {{ rightAnswerCommentId.key }}
                              </ng-container>
                              <button type="button" (click)="deleteObject(section.get('rightAnswerComments'), rightAnswerCommentId.key)">
                                Del Id
                              </button>
                            </div>
                          </div>
                        </ng-container>

                        <br />
                        <button *ngIf="isEdit" type="button" (click)="addObject(section.controls, 'wrongAnswerComments')">
                          Add new comment for Wrong answer:
                        </button>
                        <ng-container *ngIf="section.get('wrongAnswerComments')">
                          <div formGroupName="wrongAnswerComments">
                            <div *ngFor="let wrongAnswerCommentId of section.get('wrongAnswerComments').controls | keyvalue; let j = index">
                              wrongAnswerComment <br />(input first answer Id -> click outside -> then answer comment -> click outside):
                              <input
                                [formControlName]="wrongAnswerCommentId.key"
                                (change)="setObjectControlName(section.get('wrongAnswerComments'), wrongAnswerCommentId)"
                              />
                              <ng-container *ngIf="!wrongAnswerCommentId.key.includes('new_')">
                                answer Id: {{ wrongAnswerCommentId.key }}
                              </ng-container>
                              <button type="button" (click)="deleteObject(section.get('rightAnswerComments'), wrongAnswerCommentId.key)">
                                Del Id
                              </button>
                            </div>
                          </div>
                        </ng-container>

                        <button *ngIf="isEdit" type="button" (click)="addObject(section.controls, 'rightBlockMatchingIds')">
                          Add new Id
                        </button>
                        <ng-container *ngIf="section.get('rightBlockMatchingIds')">
                          <label>Task rightBlockMatchingId:</label>
                          <div formGroupName="rightBlockMatchingIds">
                            <div
                              *ngFor="let rightBlockMatchingId of section.get('rightBlockMatchingIds').controls | keyvalue; let j = index"
                            >
                              <input
                                [formControlName]="rightBlockMatchingId.key"
                                (change)="putObject(section.get('rightBlockMatchingIds'), rightBlockMatchingId)"
                              />
                              <button type="button" (click)="deleteControl(section.get('rightBlockMatchingIds'), j)">Del Id</button>
                            </div>
                          </div>
                        </ng-container>
                        <br />

                        <label>BlockA name:</label>
                        <input
                          type="text"
                          formControlName="blockAName"
                          [class.error-field]="
                            submit && form.get('tasks').controls[i].get('multiMatchingTask.sections').controls[j].get('blockAName').errors
                          "
                        />
                        <label>BlockB name:</label>
                        <input
                          type="text"
                          formControlName="blockBName"
                          [class.error-field]="
                            submit && form.get('tasks').controls[i].get('multiMatchingTask.sections').controls[j].get('blockBName').errors
                          "
                        />
                        <br />

                        <ng-container formArrayName="blockA">
                          <ng-container *ngFor="let s of section.get('blockA').controls; let k = index">
                            <fieldset>
                              <legend>
                                <h4>blockA {{ k + 1 }}</h4>
                                <button type="button" (click)="deleteControl(section.get('blockA'), k)">Delete blockA</button>
                              </legend>
                              <ng-container [formGroupName]="k">
                                <div *ngIf="s.value.id">
                                  <label>Task id:</label>
                                  <input formControlName="id" readonly />
                                </div>
                                <div>
                                  <label>Answer orderNumber:</label>
                                  <input
                                    type="number"
                                    formControlName="orderNumber"
                                    [class.error-field]="
                                      submit &&
                                      form.get('tasks').controls[i].get('multiMatchingTask.sections').controls[j].get('blockA').controls[k]
                                        .controls.orderNumber.errors
                                    "
                                  />
                                </div>
                                <div>
                                  <label>Answer text:</label>
                                  <input
                                    formControlName="text"
                                    [class.error-field]="
                                      submit &&
                                      form
                                        .get('tasks')
                                        .controls[i].get('multiMatchingTask.sections')
                                        .controls[j].get('blockA')
                                        .controls[k].hasError('atLeastOne')
                                    "
                                  />
                                </div>
                                <div>
                                  <label>Answer imageUrl:</label>
                                  <input
                                    formControlName="imageUrl"
                                    [class.error-field]="
                                      submit &&
                                      form
                                        .get('tasks')
                                        .controls[i].get('multiMatchingTask.sections')
                                        .controls[j].get('blockA')
                                        .controls[k].hasError('atLeastOne')
                                    "
                                  />
                                  <div>
                                    <div>Load image</div>
                                    <input
                                      class="upload-image"
                                      type="file"
                                      (change)="
                                        selectFile(
                                          $event,
                                          form.get('tasks')['controls'][i].get('multiMatchingTask.sections')['controls'][j].get('blockA')[
                                            'controls'
                                          ][k]
                                        )
                                      "
                                    />
                                  </div>
                                </div>
                              </ng-container>
                            </fieldset>
                          </ng-container>
                          <button type="button" (click)="addNewAnswer(section.get('blockA'))">Add new blockA</button>
                        </ng-container>
                        <ng-container formArrayName="blockB">
                          <ng-container *ngFor="let s of section.get('blockB').controls; let k = index">
                            <fieldset>
                              <legend>
                                <h4>blockB {{ k + 1 }}</h4>
                                <button type="button" (click)="deleteControl(section.get('blockB'), k)">Delete blockB</button>
                              </legend>
                              <ng-container [formGroupName]="k">
                                <div *ngIf="s.value.id">
                                  <label>Task id:</label>
                                  <input formControlName="id" readonly />
                                </div>
                                <div>
                                  <label>Answer orderNumber:</label>
                                  <input
                                    type="number"
                                    formControlName="orderNumber"
                                    [class.error-field]="
                                      submit &&
                                      form.get('tasks').controls[i].get('multiMatchingTask.sections').controls[j].get('blockB').controls[k]
                                        .controls.orderNumber.errors
                                    "
                                  />
                                </div>
                                <div>
                                  <label>Answer text:</label>
                                  <input
                                    formControlName="text"
                                    [class.error-field]="
                                      submit &&
                                      form
                                        .get('tasks')
                                        .controls[i].get('multiMatchingTask.sections')
                                        .controls[j].get('blockB')
                                        .controls[k].hasError('atLeastOne')
                                    "
                                  />
                                </div>
                                <div>
                                  <label>Answer imageUrl:</label>
                                  <input
                                    formControlName="imageUrl"
                                    [class.error-field]="
                                      submit &&
                                      form
                                        .get('tasks')
                                        .controls[i].get('multiMatchingTask.sections')
                                        .controls[j].get('blockB')
                                        .controls[k].hasError('atLeastOne')
                                    "
                                  />
                                  <div>
                                    <div>Load image</div>
                                    <input
                                      class="upload-image"
                                      type="file"
                                      (change)="
                                        selectFile(
                                          $event,
                                          form.get('tasks')['controls'][i].get('multiMatchingTask.sections')['controls'][j].get('blockB')[
                                            'controls'
                                          ][k]
                                        )
                                      "
                                    />
                                  </div>
                                </div>
                              </ng-container>
                            </fieldset>
                          </ng-container>
                          <button type="button" (click)="addNewAnswer(section.get('blockB'))">Add new blockB</button>
                        </ng-container>
                      </fieldset>
                    </ng-container>
                  </ng-container>
                </ng-container>
              </ng-container>
              <ng-container *ngSwitchCase="'5'" formGroupName="blockingTask">
                <br />
                <label>Offline event URL:</label>
                <input
                  type="text"
                  formControlName="offlineEventUrl"
                  [class.error-field]="submit && form.get('tasks').controls[i].get('blockingTask.offlineEventUrl').errors"
                />
              </ng-container>
            </ng-container>
          </ng-container>
        </fieldset>
      </ng-container>
    </ng-container>
  </form>
</ng-container>
<pre>{{ form.value | json }}</pre>
