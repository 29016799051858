import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButterflyControlComponent } from './butterfly-control.component';

@NgModule({
  declarations: [ButterflyControlComponent],
  imports: [CommonModule],
  exports: [ButterflyControlComponent],
})
export class ButterflyControlModule {}
