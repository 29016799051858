import { Component, ElementRef, ViewChild } from '@angular/core';
import { ProjectVariables } from '../../../../../core/project.variables';
import { ResultsService } from '../../../../../shared/services/results.service';

import { ProfessionsService } from '../../../../../shared/dashboard/professions/professions.service';
import { mergeMap, switchMap, takeUntil, tap } from 'rxjs/operators';
import { Observable, of, Subject } from 'rxjs';
import { Swiper } from 'swiper';
import { SwiperOptions } from 'swiper/types/swiper-options';

export const PAGE_SIZE: number = 3;

@Component({
  selector: 'prf-profession-recommendations',
  templateUrl: './profession-recommendations.component.html',
  styleUrls: ['./profession-recommendations.component.scss'],
})
export class ProfessionRecommendationsComponent {
  public loaded: boolean = false;
  public userId: string = '';
  public professionMenu: string = 'testResults';
  public professionIdList: Array<string> = [];
  public resultProfessionIdList: Array<string> = [];
  public parentProfessionIdList: Array<string> = [];
  public professionListBySource: Array<any> = [];
  public pageCurrent: number = 0;
  public showMenuPopupResult: boolean = false;
  public showMenuPopupParent: boolean = false;
  public swiperConfig: SwiperOptions = {
    slidesPerView: PAGE_SIZE,
    slidesPerGroup: PAGE_SIZE,
    speed: 500,
  };

  @ViewChild('swiperComponent') swiperComponent?: ElementRef;
  public swiper?: Swiper;
  private ngUnsubscribe$ = new Subject<any>();

  constructor(
    private professionsService: ProfessionsService,
    private resultsService: ResultsService,
    private projectVariables: ProjectVariables,
  ) {
    this.userId = localStorage.getItem('userId');
    this.getRecommended();
  }

  public setProfessionMenu(value: string) {
    let isSetVal: boolean = false;
    if (value === this.professionMenu) {
      return;
    }

    if (value === 'testResults') {
      this.professionIdList = this.resultProfessionIdList;
      isSetVal = true;
    }

    if (value === 'fromParents' && this.parentProfessionIdList.length > 0) {
      this.professionIdList = this.parentProfessionIdList;
      isSetVal = true;
    }

    if (isSetVal) {
      this.professionListBySource = [];
      this.professionMenu = value;
      this.pageCurrent = 0;
      this.swiper.update();
      // this.swiperComponent.directiveRef.update();
      this.getProfessions().pipe(takeUntil(this.ngUnsubscribe$)).subscribe();
    }
  }

  public nextPage() {
    if (this.isEnabledNext()) {
      this.pageCurrent++;
      this.swiper.slideNext();
      // this.swiperComponent.directiveRef.nextSlide();
      this.getProfessions().pipe(takeUntil(this.ngUnsubscribe$)).subscribe();
    }
  }

  public prevPage() {
    if (this.isEnabledPrev()) {
      this.pageCurrent--;
      this.swiper.slidePrev();
      // this.swiperComponent.directiveRef.prevSlide();
    }
  }

  public isEnabledNext() {
    return this.pageCurrent + 1 < this.professionIdList.length / PAGE_SIZE;
  }

  public isEnabledPrev() {
    return this.pageCurrent > 0;
  }

  private getProfessions(): Observable<any> {
    const resultByPage = this.professionIdList.slice(
      this.professionListBySource.length,
      this.professionListBySource.length + PAGE_SIZE * 2,
    );

    if (resultByPage.length === 0) {
      return;
    }

    return this.professionsService.getProfessionsByIds(resultByPage).pipe(
      tap(_profList => {
        _profList
          .filter(_pl1 => !this.professionListBySource.some(_pl2 => _pl2.id === _pl1.id))
          .forEach(_prof => {
            this.professionListBySource.push(_prof);
          });
      }),
    );
  }

  private getRecommended() {
    this.resultsService
      .getLastSession(this.userId)
      .pipe(
        switchMap(lastSession => {
          const lastSessionId = lastSession.status == 'Success' ? lastSession.sessionId : '';

          let resultsObservable$: Observable<any>;
          if (lastSessionId) {
            resultsObservable$ = this.professionsService.getResults(lastSessionId).pipe(
              switchMap(_resp => {
                let currentObs$: Observable<any>;
                if (_resp && _resp.results && _resp.results.length > 0) {
                  this.resultProfessionIdList = _resp.results
                    .filter(item => item.objectType === 'Profession')
                    .sort((a, b) => (a.results[0]['transformedValue'] > b.results[0]['transformedValue'] ? -1 : 1))
                    .map(item => item.id);

                  this.professionIdList = this.resultProfessionIdList;
                  currentObs$ = this.getProfessions();
                } else {
                  currentObs$ = of(null);
                }
                return currentObs$;
              }),
            );
          } else {
            resultsObservable$ = of(null);
          }

          return resultsObservable$.pipe(
            mergeMap(() => {
              this.loaded = true;
              return this.professionsService.getUserRecommendations('Profession', 'Parent').pipe(
                tap(parentRecommendations => {
                  this.parentProfessionIdList = parentRecommendations;
                }),
              );
            }),
          );
        }),
      )
      .subscribe();
  }
}
