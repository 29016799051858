import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { HttpService } from 'app/core/services/http.service';
import { Subject, BehaviorSubject, Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { RequestCache } from '../../../core/services/request-cache.service';
import { Helper } from '../../common/helpers/helper';
import { IBackButton } from './page-header.interface';

@Injectable()
export class PageHeaderService {
  private showProfile = new BehaviorSubject({ showProfile: false });
  private showMobileMenu = new BehaviorSubject({ showMobileMenu: false });
  private title$: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(null);
  private backButtonData$: BehaviorSubject<IBackButton> = new BehaviorSubject<IBackButton | null>(null);

  public avatarUpdated$ = new BehaviorSubject(null);

  previousUrl: string;
  currentUrl: string;

  parentMessage$: Subject<any> = new Subject();

  constructor(private http: HttpService, private router: Router, private cache: RequestCache) {
    this.currentUrl = this.router.url;
    router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.previousUrl = this.currentUrl;
        this.currentUrl = event.url;
      }
    });
  }

  public mosruLogOut() {
    return this.http.get('/saas/v1.0/mosru/getlogouturl').pipe(map(r => r));
  }

  uploadImage(image: File, id: string): Observable<any> {
    let formData: FormData = new FormData();
    formData.append(image[0].name, image[0]);
    return this.http.postImage('/saas/v1.0/utils/upload/' + id, formData).pipe(
      map(r => r),
      catchError(Helper.handleError),
    );
  }

  getNotifications(): Observable<any> {
    return this.http.get('/saas/v1.0/saas/getnotifications').pipe(
      map(r => r),
      catchError(Helper.handleError),
    );
  }

  markNotification(notifications: any): Observable<any> {
    return this.http.post('/saas/v1.0/saas/marknotification', notifications).pipe(
      map(r => r),
      catchError(Helper.handleError),
    );
  }

  openProfile() {
    this.showProfile.next({ showProfile: true });
  }

  closeProfile() {
    this.showProfile.next({ showProfile: false });
  }

  getProfileState(): Observable<any> {
    return this.showProfile.asObservable();
  }

  public getPreviousUrl() {
    return this.previousUrl;
  }

  toggleMobileMenu(bool: boolean) {
    this.showMobileMenu.next({ showMobileMenu: bool });
  }

  getMobileMenuState(): Observable<any> {
    return this.showMobileMenu.asObservable();
  }

  getSchoolByID(schoolID): Observable<any> {
    return this.http.get('/api/v1.0/catalog/schools/onebyid?schoolId=' + schoolID).pipe(
      map(r => r),
      catchError(Helper.handleError),
    );
  }

  getCity(cityId): Observable<any> {
    return this.http.get('/api/v1.0/catalog/cities/one?id=' + cityId).pipe(
      map(r => r.city),
      catchError(Helper.handleError),
    );
  }

  sendParentMessage(value) {
    this.parentMessage$.next(value);
  }

  public getTitle(): Observable<string | null> {
    return this.title$.asObservable();
  }

  public setTitle(value: string | null): void {
    this.title$.next(value);
  }

  public getBackButtonData(): Observable<IBackButton | null> {
    return this.backButtonData$.asObservable();
  }

  public setBackButtonData(backButtonUrl: string | null, backButtonName: string | null): void {
    let buttonData: IBackButton | null;
    if (backButtonName !== null && backButtonUrl !== null) {
      buttonData = {
        backButtonName,
        backButtonUrl,
      };
    } else {
      buttonData = null;
    }
    this.backButtonData$.next(buttonData);
  }
}
