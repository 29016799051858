<a class="btn-prf-outline" (click)="togglePopupForm()">{{ 'SHARED.ADD_SCHOOL' | translate }}</a>
<div class="add-new-director__form" *ngIf="dialogOpen">
  <div class="overlay">
    <div class="modal">
      <p (click)="togglePopupForm()">
        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 35 35">
          <g fill-rule="evenodd" stroke-linecap="square">
            <path d="M9.192 9.192l18.385 18.385M27.577 9.192L9.192 27.577"></path>
          </g>
        </svg>
      </p>
      <form novalidate #f="ngForm" (ngSubmit)="onSubmit(f)">
        <p>Добавление школы</p>
        <mat-form-field>
          <mat-select
            name="region"
            [(ngModel)]="currentRegion"
            [disabled]="currentRegion && adminLevel != adminAccessLevel.GLOBAL"
            placeholder="Выберите регион"
            required
          >
            <mat-option *ngFor="let region of allRegions" [value]="region" (click)="selectRegion(region)">
              {{ region.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field *ngIf="currentRegion && currentRegion.id">
          <mat-select
            placeholder="Выберите муниципалитет"
            [(ngModel)]="currentMunicipality"
            [disabled]="currentMunicipality && adminLevel == adminAccessLevel.MUNICIPALITY"
            name="municipality"
            required
          >
            <mat-option
              *ngFor="let municipality of municipalitiesByRegion"
              [value]="municipality"
              (click)="selectMunicipality(municipality)"
            >
              {{ municipality.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field *ngIf="currentMunicipality && currentMunicipality.id">
          <mat-select placeholder="Выберите город" [(ngModel)]="currentCity" name="city" required>
            <mat-option *ngFor="let city of citiesByMunicipality" [value]="city">
              {{ city.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field>
          <input
            matInput
            type="text"
            name="name"
            [(ngModel)]="school.number"
            placeholder="{{ 'SHARED.NAME_SCHOOL' | translate }}"
            required
          />
        </mat-form-field>

        <mat-form-field>
          <input matInput type="text" name="address" [(ngModel)]="school.address" placeholder="Адрес" required />
        </mat-form-field>

        <mat-form-field>
          <mat-select name="type" [(ngModel)]="school.type" placeholder="Тип" required>
            <mat-option *ngFor="let type of types" [value]="type">
              {{ type }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field>
          <input matInput type="text" name="schoolOther" [(ngModel)]="school.other" placeholder="Другое" />
        </mat-form-field>

        <button type="submit" class="button green-btn w-button">
          {{ 'SHARED.ADD' | translate }}
        </button>
      </form>
    </div>
  </div>
</div>
