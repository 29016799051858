import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import {
  IResponseGetChatBotById,
  IResponseGetChatBots
} from "./api-chatbots.interface";
import {HttpService} from "../http";
import {ChatbotsCollection} from "./api-chatbots.collections";




@Injectable({
  providedIn: 'root',
})
export class ChatBotsService {

  private readonly httpService = inject(HttpService);

  public getChatBotById(id: string): Observable<IResponseGetChatBotById> {
    return this.httpService.get<IResponseGetChatBotById>(ChatbotsCollection.GetChatBotById, { id }).pipe(take(1));
  }

  public getChatBotsByRegionId(regionId: string): Observable<IResponseGetChatBots> {
    return this.httpService.get<IResponseGetChatBots>(ChatbotsCollection.GetChatBotsByRegion, { regionId }).pipe(take(1));
  }
}
