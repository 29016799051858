import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { SharedService } from 'app/shared/shared.service';
import { UtilsService } from 'app/shared/dashboard/backend-services/utils.service';
import { forkJoin as observableForkJoin, Subject, throwError } from 'rxjs';
import { catchError, switchMap, takeUntil, tap } from 'rxjs/operators';
import { UserInfoClass } from 'app/shared/classes/userInfo.class';
import { ICompletedTestsCount } from 'app/shared/interfaces/icompletedtestscount.interface';
import { ISchoolClass } from 'app/shared/interfaces/ischoolclass.interface';
import { OverlayBusyService } from 'app/shared/overlay-busy/overlay-busy.service';

import { SchoolAdminPanelService } from 'app/shared/services/schooladmin-panel.service';

@Component({
  selector: 'prf-admin-classes',
  templateUrl: './admin-classes.component.html',
  styleUrls: ['./admin-classes.component.scss'],
})
export class AdminClassesComponent implements OnInit, OnDestroy {
  @Output() showClass = new EventEmitter();
  userRole: string = '';
  schoolClasses: ISchoolClass[] = [];
  teachers: Array<any> = [];
  mergingArrays: Array<any> = [];
  classesCompletedTestsCount: Array<ICompletedTestsCount> = [];
  isFixed: boolean = false;
  public loadedClasses: boolean = false;
  private ngUnsubscribe$: Subject<any> = new Subject();
  showDeletePopUp: boolean = false;
  classSelectedToDelete: any = null;

  constructor(
    private meta: Meta,
    private schoolAdminPanelService: SchoolAdminPanelService,
    private overlayService: OverlayBusyService,
    private sharedService: SharedService,
    private route: ActivatedRoute,
    private utilsService: UtilsService,
  ) {
    this.meta.updateTag({ name: 'og:title', content: 'Класс школьного администратора' });
    this.userRole = localStorage.getItem('userRole');
  }

  ngOnInit() {
    this.overlayService.show();
    this.sharedService
      .getUserInfoData()
      .pipe(
        switchMap((user: UserInfoClass) => {
          // при открытии компонента из Админа, берем id школы из параметров урла
          if (this.route.snapshot.params['schoolId']) {
            user.schoolId = this.route.snapshot.params['schoolId'];
          }

          return observableForkJoin([
            this.sharedService.getCompletedTestsCount(user.schoolId), // количество пройденных тестов в каждом классе
            this.sharedService.getSchoolClassesExtended(user.schoolId), // список классов
            this.sharedService.getTeachers(user.schoolId), // учителей класса
          ]).pipe(
            tap(([completedTestsCount, schoolClassesExtended, teachers]) => {
              this.classesCompletedTestsCount = completedTestsCount;
              this.schoolClasses = schoolClassesExtended;
              this.teachers = teachers;

              // объединяем массивы в один для удобства
              this.mergingArrays = this.schoolClasses.map(a => {
                let objectTwo = this.teachers.find(b => a.id === b.schoolClassId);
                if (objectTwo) {
                  Object.assign(a, objectTwo);
                }
                return a;
              });

              this.mergingArrays.sort((classA, classB) => {
                // сортируем по классу
                if (classA.number < classB.number) return -1;
                if (classA.number > classB.number) return 1;
                // сортируем по букве
                if (classA.letter < classB.letter) return -1;
                if (classA.letter > classB.letter) return 1;
              });

              // дописываем количество завершенных тестов по каждому классу
              this.mergingArrays.forEach(item => {
                let schoolClassTestsCount = this.classesCompletedTestsCount.find(c => c.schoolClassId === item.id);
                item.completedTestsCount = schoolClassTestsCount != null ? schoolClassTestsCount.count : 0;
              });

              this.loadedClasses = true;
            }),
            catchError(err => {
              this.utilsService.openSnackBar('👎 Ошибка на сервере, попробуйте позже', 'error');
              return throwError(err);
            }),
          );
        }),
        takeUntil(this.ngUnsubscribe$),
      )
      .subscribe(() => this.overlayService.hide());
  }

  selectSchoolClassToDelete(schoolClass) {
    this.showDeletePopUp = true;
    this.classSelectedToDelete = schoolClass;
  }

  closeDeletePopUp() {
    this.showDeletePopUp = false;
    this.classSelectedToDelete = null;
  }

  deleteSchoolClass(schoolClassId: string) {
    // res возвращает true или false
    this.schoolAdminPanelService
      .removeSchoolClass(schoolClassId)
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe(res => {
        this.mergingArrays = this.mergingArrays.filter(p => p.id !== schoolClassId);
        this.utilsService.openSnackBar('👌 Класс удален', 'success');
        setTimeout(() => (this.showDeletePopUp = false), 500);
      });
  }

  ngOnDestroy() {
    this.ngUnsubscribe$.next(null);
    this.ngUnsubscribe$.complete();
  }
}
