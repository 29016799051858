<div #container class="filter-div cat">
  <div class="professions-dropdown w-dropdown" style="border-color: rgba(53, 53, 53, 0.1)">
    <div
      (click)="isOpen = !isOpen"
      [class.active]="selectedCategoryItem && selectedCategoryItem.name"
      class="professions-dropdown-toggle cat w-dropdown-toggle"
    >
      <div class="professions-dd-icon-2 w-icon-dropdown-toggle"></div>
      <div>
        <span *ngIf="!selectedCategoryItem.name">{{ 'SHARED.INDUSTRY' | translate }}</span>
        <span *ngIf="selectedCategoryItem.name">
          {{ selectedCategoryItem.name }}
        </span>
      </div>
    </div>

    <nav [class.w--open]="isOpen" class="dd-prof-filter-list w-dropdown-list">
      <div class="dd-prof-filter-div _2">
        <div class="filter-list-row">
          <div class="filter-list-heading">
            <span *ngIf="!selectedCategoryItem.name">{{ 'SHARED.INDUSTRY' | translate }}</span>
            <span *ngIf="selectedCategoryItem.name">{{ selectedCategoryItem.name }}</span>
          </div>
          <a *ngIf="selectedCategoryItem.name" (click)="onClickClear()" class="clear-categories show">
            {{ 'SHARED.CLEAR_BUTTON' | translate }}
          </a>
        </div>

        <div class="form-block w-form">
          <form id="email-form" name="email-form" data-name="Email Form">
            <div class="search-block">
              <input
                [(ngModel)]="filterName"
                class="search-categories w-input"
                maxlength="256"
                name="Search-2"
                data-name="Search 2"
                placeholder="{{ 'SHARED.SEARCH' | translate }}"
                id="Search-2"
                required=""
              />
              <a *ngIf="filterName.length > 0" (click)="clearFilterName($event)" class="search-clear show w-inline-block"></a>
            </div>
          </form>

          <div class="w-form-done">
            <div>Thank you! Your submission has been received!</div>
          </div>

          <div class="w-form-fail">
            <div>Oops! Something went wrong while submitting the form.</div>
          </div>
        </div>

        <div class="scroll-block">
          <div class="prof-categories-links">
            <a
              *ngFor="let item of categoryList | professionFilter: filterName"
              [class.w--current]="item.selected"
              (click)="onSelectItem(item)"
              class="prof-categories-link w-inline-block"
            >
              <div class="category-text-link">{{ item.name }}</div>
              <!--<div class="category-num">{{item.count}}</div>-->
            </a>
          </div>
        </div>
      </div>
    </nav>
  </div>
</div>
