import { inject, Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { v4 as uuid } from 'uuid';
import { IStorageHelper, StorageHelper } from '../../helpers';
import { ApiAuthService, ApiAuthUrls, IData, IUserAction } from '../../api-services';
import { Storages } from '../web-storage';
import { IYmParams, YandexMetricsService, YmItems } from '../yandex-metrics';
import { IBaseActionInfo } from './user-actions.interface';
import { UaParamsByYmItem, YmParamsByYmItems } from './user-actions.collections';
import { UserDataHandlerService } from '../../utils-services';

@Injectable({
  providedIn: 'root',
})
export class UserActionsService {
  private storageHelper: IStorageHelper;
  private initializationTime: Map<YmItems, number> = new Map<YmItems, number>();

  private readonly yandexMetrics: YandexMetricsService = inject(YandexMetricsService);
  private readonly apiAuthService: ApiAuthService = inject(ApiAuthService);

  constructor(@Inject(DOCUMENT) private document: Document, private userDataHandlerService: UserDataHandlerService) {
    this.storageHelper = new StorageHelper(Storages.Local);
  }

  public log(event: YmItems, journalParams?: IData, metricParams?: IData): void {
    const action: IBaseActionInfo = this.baseActionInfo();
    const uaParams: IData | IUserAction = Object.assign(
      {
        id: action.id,
        userId: action.userId,
        timestamp: action.time,
      },
      UaParamsByYmItem.get(event),
    );

    if (journalParams) {
      Object.entries(journalParams).forEach((param: [string, string]) => {
        const [key, value]: [string, string] = param;

        uaParams[key] = value;
      });
    }

    if (this.initializationTime.has(event)) {
      uaParams.duration = this.measureDuration(event);
    }

    const ymParams: IData | IYmParams = Object.assign(
      {
        action_id: action.id,
        user_id: action.userId,
        event_time: action.time,
        event_page: action.url,
      },
      YmParamsByYmItems.get(event),
    );

    if (metricParams) {
      Object.entries(metricParams).forEach((param: [string, string]) => {
        const [key, value]: [string, string] = param;

        ymParams[key] = value;
      });
    }

    this.yandexMetrics.reachGoal(event, ymParams as IYmParams);
    this.apiAuthService.logUserActions(uaParams as IUserAction).subscribe();
  }

  public setInitializationTime(events: YmItems[]): void {
    const currentTime: number = Date.now();
    events.forEach((event: YmItems) => this.initializationTime.set(event, currentTime));
  }

  private measureDuration(eventLabel: YmItems): string {
    const duration: number = Date.now() - this.initializationTime.get(eventLabel);

    return new Date(duration).toLocaleTimeString('ru-RU', { timeZone: 'UTC' }) + '.' + String(new Date(duration).getMilliseconds());
  }

  private baseActionInfo(): IBaseActionInfo {
    return {
      id: uuid(),
      userId: this.userDataHandlerService.getUserInfo()?.userId,
      time: new Date().toISOString(),
      url: this.document.URL,
    };
  }
}
