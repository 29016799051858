import { Component, Input } from '@angular/core';

import { CompareModalComponent } from '../compare-modal/compare-modal.component';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';

export const MAX_DIFFERENT: number = 15;

@Component({
  selector: 'prf-interests-progress-bar',
  templateUrl: './interests-progress-bar.component.html',
  styleUrls: ['./interests-progress-bar.component.scss'],
})
export class InterestsProgressBarComponent {
  @Input()
  set data(val: Array<any>) {
    this._data = val;
    this.prepareData();
  }
  @Input() showText: boolean = true;

  get data(): Array<any> {
    return this._data;
  }

  @Input() isParent = null;

  public hasChildResult: boolean = false;
  public hasParentResult: boolean = false;

  private _data: Array<any> = [];

  constructor(private dialog: MatDialog) {}

  openDialog(skillData) {
    if (skillData.canCompare) {
      // Выводить окно только если есть возможность сравнивать
      let dialogRef = this.dialog.open(CompareModalComponent);
      dialogRef.componentInstance.data = skillData;
      dialogRef.componentInstance.isParent = this.isParent;
    }
  }

  private prepareData() {
    this.hasChildResult = false;
    this.hasParentResult = false;

    this._data = this._data.sort((a, b) => b.parentEstimate - a.parentEstimate);
    // Вычислить расхождения если они есть
    this._data.forEach(item => {
      item.isEqual = Math.abs(item.childEstimate - item.parentEstimate) < 1;
      item.totalEstimate = item.childEstimate ? Math.floor((item.parentEstimate + item.childEstimate) / 2) : item.parentEstimate;
      item.canCompare = item.childEstimate && item.parentEstimate !== item.childEstimate;
      item.problem = this._data.length > 1 && Math.abs(item.childEstimate - item.parentEstimate) > MAX_DIFFERENT;

      if (!this.hasChildResult) {
        this.hasChildResult = item.childEstimate > 0;
      }

      if (!this.hasParentResult) {
        this.hasParentResult = item.parentEstimate > 0;
      }
    });
  }
}
