import { Component } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { AppSettingsService } from 'app/shared/services/appsettings.service';
import { filter } from 'rxjs/operators';
import { routeAnimations } from '../core';

@Component({
  selector: 'prf-pages',
  templateUrl: './pages.component.html',
  styleUrls: ['./pages.component.scss'],
  animations: [routeAnimations],
})
export class PagesComponent {
  public hideHeader: boolean = false;
  public hideMenu: boolean = false;
  public activeLocation: string = 'base';

  constructor(private router: Router, private route: ActivatedRoute, private appSettings: AppSettingsService) {
    this.activeLocation = this.appSettings.currentLocation;
    router.events.pipe(filter(e => e instanceof NavigationEnd)).forEach(() => {
      this.hideHeader = route.root.firstChild.snapshot.data['hideHeader'];
      this.hideMenu = route.root.firstChild.snapshot.data['hideMenu'];
    });
  }
}
