<section class="courses">
  <div class="admin-section">
    <!-- <div class="tabs w-tabs">
      <div class="tabs-menu w-tab-menu">
        <a
          (click)="tabLevel = 1"
          [class.w--current]="tabLevel === 1"
          [routerLink]="['./all-institutions']"
          class="tab-link adm w-inline-block w-tab-link"
        >
          <div>Все</div>
          <img src="./assets/images/courses/angle.png" width="20" class="angle" />
        </a>
      </div>
      <div class="tabs-content w-tab-content">
        <router-outlet></router-outlet>
      </div>
    </div> -->

    <router-outlet></router-outlet>
  </div>
</section>
