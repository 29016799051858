<ng-container *ngIf="type === DDType.FULL">
  <div class="profile-data-row" [class.pointer-disabled]="_isDisabled" (click)="toggleInput($event)">
    <div>
      <label class="login-label">{{ label }}<span *ngIf="!isOptional">*</span></label>
      <div class="div-space"></div>
    </div>
    <div class="dropdown-inline cat">
      <ng-container [ngTemplateOutlet]="dropdown"></ng-container>
    </div>
  </div>
</ng-container>
<ng-container *ngIf="type === DDType.SHORT">
  <ng-container [ngTemplateOutlet]="dropdown"></ng-container>
</ng-container>

<!---->
<!---->
<!---->

<ng-template #dropdown>
  <div class="dropdown-inline-container w-dropdown">
    <div
      #dropdownContent
      class="dd-input-field w-dropdown-content"
      [class.pointer-disabled]="_isDisabled"
      [class.opened]="isFind"
      (click)="toggleInput($event)"
    >
      <input
        autocomplete="off"
        prfNoAutocomplete
        type="text"
        class="login-field w-input"
        [placeholder]="type === DDType.SHORT ? label : ''"
        [class.active]="control.value"
        [class.error-field]="isError"
        [formControl]="control"
      />
    </div>
    <nav class="dd-prof-filter-list w-dropdown-list" [class.w--open]="isFind" [class.opened]="isFind">
      <div class="dd-prof-filter-div" [style.height]="optionsWrapperHeight">
        <div class="filter-list-row">
          <div class="filter-list-heading">{{ label }}</div>
        </div>
        <div class="prof-categories-links">
          <a
            class="prof-categories-link w-inline-block"
            *ngFor="let option of filteredOptions | async"
            [class.dd-inline-current]="option.name === control.value"
            (click)="control.setValue(option.name); writeValue(option); toggleInput($event)"
          >
            <div class="category-text-link">{{ option.name }}</div>
          </a>
        </div>
      </div>
    </nav>
  </div>
</ng-template>
