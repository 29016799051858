export const enum ApiProgramSpoVpoUrls {
  GetOneProgramsSpoVpoElastic = '/api/v1.0/catalog/programsspovpo/elastic/one',
  GetAllProgramsSpoVpoElastic = '/api/v1.0/catalog/programsspovpo/elastic/all',
  CreateProgramsSpoVpoElastic = '/api/v1.0/catalog/programsspovpo/elastic/create',
  UpdateProgramsSpoVpoElastic = '/api/v1.0/catalog/programsspovpo/elastic/update',
  DeleteProgramsSpoVpoElastic = '/api/v1.0/catalog/programsspovpo/elastic/delete',
  ReloadProgramsSpoVpoElasticIndex = '/api/v1.0/catalog/programsspovpo/reloadprogramsspovpoelasticindex',
  GetOneProgramsSpoVpoMongo = '/api/v1.0/catalog/programsspovpo/mongo/one',
  GetAllProgramsSpoVpoMongo = '/api/v1.0/catalog/programsspovpo/mongo/all',
  CreateProgramsSpoVpoMongo = '/api/v1.0/catalog/programsspovpo/mongo/create',
  UpdateProgramsSpoVpoMongo = '/api/v1.0/catalog/programsspovpo/mongo/update',
  DeleteProgramsSpoVpoMongo = '/api/v1.0/catalog/programsspovpo/mongo/delete',
  UploadSpoVpoFromExel = '/api/v1.0/catalog/programsspovpo/uploadspovpofromexcel',
  GetProgramsSpoVpoByRegionMongo = '/api/v1.0/catalog/programsspovpo/getprogramsspovpobyregionid?regionId=',
  GetProgramSpoVpoByQueryMongo = '/api/v1.0/catalog/programsspovpo/mongo/search/query',
}

export enum EducationalLevels {
  SO = 1,
  SPO = 2,
  VO = 3,
}
