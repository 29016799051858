<ng-container *ngIf="!isLoading && requestError; else defaultPageTemplate">
  <ng-container *ngTemplateOutlet="requestErrorTemplate"></ng-container>
</ng-container>

<ng-template #defaultPageTemplate>
  <ng-container *ngIf="(!currentCourses || (currentCourses && currentCourses.length == 0)) && isLoading; else elasticCoursesPage">
    <!-- плейсхолдер при подгрузке курсов -->
    <div class="loader-container">
      <prf-content-loader></prf-content-loader>
    </div>
  </ng-container>
</ng-template>

<ng-template #elasticCoursesPage>
  <div class="catalog-events-row">
    <ng-container *ngIf="currentCourses && currentCourses.length > 0; else emptyCourses">
      <div class="events-column" [class.short-view]="viewValue" *ngFor="let course of currentCourses">
        <prf-event-course-card [course]="course" [viewValue]="viewValue"></prf-event-course-card>
      </div>
    </ng-container>
  </div>
</ng-template>

<ng-template #requestErrorTemplate>
  <div class="results-empty-block">
    <div>
      <img src="./assets/images/menu/grey/Test.svg" width="80" class="no-profession-icon" />
    </div>
    <div class="event-empty-block-text">Неполадки на сервере, скоро починим</div>
  </div>
</ng-template>

<ng-template #emptyCourses>
  <div class="event-empty-block">
    <div>
      <img src="./assets/images/menu/grey/Courses.svg" width="80" class="no-profession-icon" />
    </div>
    <div class="event-empty-block-text">{{ 'SHARED.FOR_YOU_REQUEST_NOT_COURSES' | translate }}</div>
  </div>
</ng-template>
