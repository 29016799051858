import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { atLeastOne } from 'app/shared/form-validators/at-least-one';
import { ITask } from 'app/shared/interfaces/itask';

export enum TaskType {
  RadioButton,
  CheckBox,
  Match,
  Partition,
  MultiMatch,
  BlockingTask,
}

@Component({
  selector: 'prf-tasks',
  templateUrl: './tasks.component.html',
  styleUrls: ['./tasks.component.scss'],
})
export class TasksComponent implements OnInit {
  @Input()
  set task(data: UntypedFormGroup) {
    if (data.value && data.value.length > 0) {
      this.initTasks();
      this.setTasks(data.value);
    }
  }

  @Input()
  set reset(val) {
    if (val) {
      this.initTasks();
    }
  }

  @Input()
  set submitted(submitted: boolean) {
    if (submitted) {
      this.submit = submitted;
    }
  }

  @Output() changeTask = new EventEmitter();

  public codePattern = /^([a-z0-9]{6})/;

  public submit: boolean;

  public enumTaskType = TaskType;
  public form: UntypedFormGroup;
  public isEdit: boolean = false;

  public uploadLogoError = '';
  public imageUrl: string = '';
  public noLogoSrc: string = '/assets/images/shared/no-logo.svg';

  public ngUnsubscribe: Subject<any> = new Subject();

  constructor(private fb: UntypedFormBuilder) {
    this.initTasks();
  }

  ngOnInit() {}

  private initTasks(): void {
    this.form = this.fb.group({
      tasks: this.fb.array([]),
    });
  }

  private getTasks(x: ITask): UntypedFormGroup {
    let isEdit = x.id ? true : false;
    this.isEdit = isEdit;
    let group = this.fb.group({
      type: x.type,
      orderNumber: x.orderNumber,
      text: x.text,
      imageUrl: x.imageUrl,
      videoUrl: x.videoUrl,
      rightAnswerComment: x.rightAnswerComment,
      wrongAnswerComment: x.wrongAnswerComment,
      radioButtonTask: this.setRadioButtonTask(x.radioButtonTask, isEdit),
      checkBoxTask: this.setCheckBoxTask(x.checkBoxTask, isEdit),
      matchingTask: this.setMatchingTask(x.matchingTask, isEdit),
      partitioningTask: this.setPartitioningTask(x.partitioningTask, isEdit),
      multiMatchingTask: this.setMultiMatchingTask(x.multiMatchingTask, isEdit),
      blockingTask: this.setBlockingTask(x.blockingTask, isEdit),
    });
    if (isEdit) {
      group.setControl('id', this.fb.control(x.id));
    }
    return group;
  }

  private setTasks(data: ITask[]): void {
    let control = <UntypedFormArray>this.form.controls.tasks;
    data.forEach(x => {
      control.push(this.getTasks(x));
    });
  }

  public addNewTask(): void {
    let control = <UntypedFormArray>this.form.controls.tasks;
    control.push(
      this.fb.group({
        type: this.fb.control(this.enumTaskType.RadioButton, Validators.required),
        orderNumber: this.fb.control(1, [Validators.required, Validators.min(1)]),
        text: this.fb.control(''),
        imageUrl: this.fb.control(''),
        videoUrl: this.fb.control(''),
        rightAnswerComment: this.fb.control('', Validators.required),
        wrongAnswerComment: this.fb.control('', Validators.required),
        radioButtonTask: this.fb.group({
          answers: this.fb.array([]),
          rightAnswerId: this.fb.control(''),
        }),
        checkBoxTask: this.fb.group({
          answers: this.fb.array([]),
          rightAnswerId: this.fb.control(''),
        }),
        matchingTask: this.fb.group({
          blockA: this.fb.array([]),
          blockB: this.fb.array([]),
          blockAName: this.fb.control(''),
          blockBName: this.fb.control(''),
        }),
        partitioningTask: this.fb.group({
          answers: this.fb.array([]),
          groupAName: this.fb.control(''),
          groupBName: this.fb.control(''),
        }),
        multiMatchingTask: this.fb.group({
          sections: this.fb.array([]),
        }),
        blockingTask: this.fb.group({
          offlineEventUrl: this.fb.control(''),
        }),
      }),
    );
  }
  public outTasks(event?): void {
    let isUploadImage = event ? event.target.className.includes('upload-image') : false;
    if (this.form.valid && isUploadImage === false) {
      this.changeTask.emit(this.form.controls.tasks);
    }
  }

  private getAnswers(y): UntypedFormGroup {
    let isEdit = y.id ? true : false;
    let group = this.fb.group({});
    if (!isEdit) {
      group = this.fb.group({
        orderNumber: y.orderNumber,
        text: y.text,
        imageUrl: y.imageUrl,
      });
    } else {
      group = this.fb.group({
        id: y.id,
        orderNumber: y.orderNumber,
        text: y.text,
        imageUrl: y.imageUrl,
      });
    }
    return group;
  }
  private setAnswers(array): UntypedFormArray {
    let result_array = new UntypedFormArray([]);
    if (array && array.length > 0) {
      array.forEach(y => {
        result_array.push(this.getAnswers(y));
      });
    }
    return result_array;
  }
  public addNewAnswer(control): void {
    control.push(
      this.fb.group(
        {
          orderNumber: this.fb.control(1, [Validators.required, Validators.min(1)]),
          text: this.fb.control(''),
          imageUrl: this.fb.control(''),
        },
        { validator: atLeastOne(Validators.required, ['text', 'imageUrl']) },
      ),
    );
  }

  public deleteControl(control, index) {
    control = <UntypedFormArray>control;
    control.removeAt(index);
    this.outTasks();
  }
  public deleteObject(control, key) {
    control = <UntypedFormGroup>control;
    control.removeControl(key);
    this.outTasks();
  }

  private setArray(array, control): UntypedFormArray {
    let result_array = new UntypedFormArray([]);
    if (array[control]) {
      array[control].forEach(y => {
        result_array.push(this.fb.control(y));
      });
    }
    return result_array;
  }
  public addArray(array, control): void {
    if (array[control] && array[control]['controls']) {
      array[control].push(this.fb.control('', Validators.required));
    } else {
      array[control] = this.fb.array([]);
      array[control].push(this.fb.control('', Validators.required));
    }
  }

  private setObject(object): UntypedFormGroup {
    let result_object = new UntypedFormGroup({});
    if (object && Object.keys(object).length > 0) {
      for (const [key, control] of Object.entries(object)) {
        let b = this.fb.control(control, Validators.required);
        result_object.addControl(key, b);
      }
    }
    return result_object;
  }

  public addObject(object, control): void {
    // формирование дефолтного значения
    // Если добавляем новый Id в {}
    // Его имя будет: new_[number]
    let count_prs = obj => {
      let count = 0;
      for (let prs in obj) {
        if (obj.hasOwnProperty(prs)) {
          count++;
        }
      }
      return count;
    };

    if (object[control] && object[control]['controls']) {
      //let c = (this.fb.control('', Validators.required));
      let c = this.fb.control('');
      object[control].addControl('new_' + count_prs(object[control].value), c);
    } else {
      object[control] = this.fb.group({});
      // let c = (this.fb.control('', Validators.required));
      let c = this.fb.control('');
      object[control].addControl('new_' + count_prs(object[control].value), c);
    }
  }

  public setObjectControlName(object, control) {
    if (object && control.value) {
      if (control.key.includes('new_')) {
        let _obj = object.controls[control.key];
        this.deleteObject(object, control.key);

        object.addControl(control.value.value, new UntypedFormControl(''));
      }
    }
  }

  public putObject(object, control): void {
    if (object && control.value) {
      let _obj = object.controls[control.value];

      this.deleteObject(object, control.key);

      object.addControl(control.value.value, _obj);
    }
  }

  public addNewMatching(control) {
    let new_control = this.fb.group({
      blockA: this.fb.array([]),
      blockB: this.fb.array([]),
      blockAName: this.fb.control('', Validators.required),
      blockBName: this.fb.control('', Validators.required),
    });
    control.push(new_control);
  }

  private setRadioButtonTask(data, isEdit?): UntypedFormGroup {
    let group = this.fb.group({
      answers: this.setAnswers(data.answers),
    });
    if (isEdit) {
      group.setControl('rightAnswerId', this.fb.control(data.rightAnswerId));
    }
    return group;
  }
  private setCheckBoxTask(data, isEdit?): UntypedFormGroup {
    let group = this.fb.group({
      answers: this.setAnswers(data.answers),
    });
    if (isEdit) {
      group.setControl('rightAnswersIds', this.setArray(data, 'rightAnswersIds'));
    }
    return group;
  }
  private setMatchingTask(data, isEdit?): UntypedFormGroup {
    let group = this.fb.group({
      blockA: this.setAnswers(data.blockA),
      blockB: this.setAnswers(data.blockB),
      blockAName: this.fb.control(data.blockAName),
      blockBName: this.fb.control(data.blockBName),
    });
    if (isEdit) {
      group.setControl('rightBlockMatchingIds', this.setObject(data.rightBlockMatchingIds));
      group.setControl('rightAnswerComments', this.setObject(data.rightAnswerComments));
      group.setControl('wrongAnswerComments', this.setObject(data.wrongAnswerComments));
    }
    return group;
  }
  private setPartitioningTask(data, isEdit?): UntypedFormGroup {
    let group = this.fb.group({
      answers: this.setAnswers(data.answers),
      groupAName: this.fb.control(data.groupAName),
      groupBName: this.fb.control(data.groupBName),
    });
    if (isEdit) {
      group.setControl('rightAnswersIdsForGroupA', this.setArray(data, 'rightAnswersIdsForGroupA'));
      group.setControl('rightAnswersIdsForGroupB', this.setArray(data, 'rightAnswersIdsForGroupB'));
      group.setControl('commentsForRightAnswer', this.setObject(data.commentsForRightAnswer));
      group.setControl('commentsForWrongAnswer', this.setObject(data.commentsForWrongAnswer));
    }
    return group;
  }
  private setMultiMatchingTask(data, isEdit?): UntypedFormGroup {
    let group = this.fb.group({
      sections: this.fb.array([]),
    });
    if (data.sections.length > 0) {
      let sections = this.fb.array([]);
      data.sections.forEach(section => {
        sections.push(this.setMatchingTask(section, isEdit));
      });
      group = this.fb.group({
        sections: sections,
      });
    }
    return group;
  }
  private setBlockingTask(data, isEdit?): UntypedFormGroup {
    let group = this.fb.group({
      rightCode: this.fb.control(data.rightCode),
      offlineEventUrl: this.fb.control(data.offlineEventUrl),
    });
    return group;
  }

  get tasks(): UntypedFormArray {
    return <UntypedFormArray>this.form.get('tasks');
  }

  public selectFile(event, control) {
    if (event) {
      if (!event.target.files.item(0).type.match('image.*')) {
        this.uploadLogoError = 'Неверный формат файла.';
        return alert(this.uploadLogoError);
      }

      // this.store
      //   .select(fromAuth.getTokenKey)
      //   .pipe(takeUntil(this.ngUnsubscribe))
      //   .subscribe(x => {
      //     let filePayload = {
      //       image: event.target.files,
      //       tokenKey: x,
      //     };
      //     this.managerService
      //       .loadLogo(filePayload)
      //       .pipe(take(1))
      //       .pipe(takeUntil(this.ngUnsubscribe))
      //       .subscribe(r => {
      //         if (r.documentPath && r.documentPath !== '') {
      //           this.imageUrl = r.documentPath;
      //           control.controls.imageUrl.setValue(this.imageUrl);
      //           this.outTasks();
      //         }
      //       });
      //   });
    }
  }

  public onQuillContentChanged() {
    this.outTasks();
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(null);
    this.ngUnsubscribe.complete();
  }
}
