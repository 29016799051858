<div class="territory-filters" [formGroup]="form">
  <mat-form-field>
    <mat-select prfBlurOnChange placeholder="Регион" formControlName="region" name="allRegions">
      <mat-option *ngFor="let region of allRegions; trackBy: trackByFn" [value]="region" (click)="selectRegion(region)">
        {{ region.name }}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field>
    <mat-select
      prfBlurOnChange
      placeholder="Муниципалитет"
      formControlName="municipality"
      [disabled]="!formControls.region.value"
      name="municipality"
    >
      <mat-option
        *ngFor="let municipality of municipalitiesByRegion; trackBy: trackByFn"
        [value]="municipality"
        (click)="selectMunicipality(municipality)"
      >
        {{ municipality.name }}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field>
    <mat-select prfBlurOnChange placeholder="Город" formControlName="city" [disabled]="!formControls.municipality.value" name="cities">
      <mat-option *ngFor="let city of citiesByMunicipality; trackBy: trackByFn" [value]="city" (click)="selectCity(city)">
        {{ city.name }}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field>
    <mat-select prfBlurOnChange placeholder="Школа" formControlName="school" [disabled]="!formControls.city.value" name="schools">
      <mat-option
        *ngFor="let school of schoolsFiltered; trackBy: trackByFn"
        [value]="school"
        (click)="selectSchool(school)"
        data-school-select-option
      >
        {{ school.number }}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field>
    <mat-select
      prfBlurOnChange
      placeholder="Номер класса"
      formControlName="classNumber"
      [disabled]="!formControls.city.value"
      name="classNumbers"
    >
      <mat-option
        *ngFor="let classNumber of classNumbers; trackBy: trackByFn"
        [value]="classNumber"
        (click)="selectClassNumber(classNumber)"
      >
        {{ classNumber }}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field>
    <mat-select prfBlurOnChange placeholder="Класс" formControlName="class" [disabled]="!formControls.school.value" name="classes">
      <mat-option *ngFor="let class of allClasses; trackBy: trackByFn" [value]="class" (click)="selectClass(class)">
        {{ class.className }}
      </mat-option>
    </mat-select>
  </mat-form-field>
</div>
