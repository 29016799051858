<ng-container *ngIf="dataFetched">
  <prf-add-user [inputInstitutions]="institutions" [inputRegions]="allRegions" [inputCities]="allCities" [inputSchools]="allSchools">
  </prf-add-user>
  <prf-edit-user *ngIf="editUserFlag" (editUserFlag)="editFlag()" [inputOldUserData]="selectedUser" [inputUserRole]="selectedRole">
  </prf-edit-user>

  <div class="userSearch" [formGroup]="form">
    <div class="personalInfo filtersColumn">
      <mat-form-field>
        <mat-select
          prfBlurOnChange
          placeholder="Роль"
          formControlName="role"
          name="role"
          [class.error-field]="submitted && f.role.errors"
          required
        >
          <mat-option *ngFor="let role of userRoles; trackBy: trackByFn" [value]="role">
            {{ role.name }}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="f.role.errors?.required && submitted"> Обязательное поле </mat-error>
      </mat-form-field>

      <mat-form-field>
        <input matInput type="text" class="w-input" name="lastName" placeholder="Фамилия" formControlName="lastName" />
      </mat-form-field>
      <mat-form-field>
        <input
          matInput
          type="text"
          class="w-input"
          name="firstName"
          placeholder="Имя "
          formControlName="firstName"
          (focusin)="showInfo = true"
        />
        <ng-container *ngIf="showInfo">
          <span class="info-row">Поиск по имени доступен при указании фамилии</span>
        </ng-container>
      </mat-form-field>

      <mat-form-field>
        <input matInput type="text" class="w-input" name="email" placeholder="Email" formControlName="email" />
      </mat-form-field>

      <mat-form-field>
        <input matInput type="text" class="w-input" name="phone" placeholder="Phone" formControlName="phone" />
      </mat-form-field>

      <mat-form-field>
        <input matInput type="text" class="w-input" name="code" placeholder="Регистрационный код" formControlName="code" />
      </mat-form-field>
    </div>

    <div class="territoryInfo filtersColumn">
      <mat-form-field>
        <mat-select prfBlurOnChange placeholder="Регион" formControlName="region" name="allRegions">
          <mat-option *ngFor="let region of allRegions; trackBy: trackByFn" [value]="region" (click)="selectRegion(region)">
            {{ region.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field>
        <mat-select
          prfBlurOnChange
          placeholder="Муниципалитет"
          formControlName="municipality"
          [disabled]="!f.region.value"
          name="municipality"
        >
          <mat-option
            *ngFor="let municipality of municipalitiesByRegion; trackBy: trackByFn"
            [value]="municipality"
            (click)="selectMunicipality(municipality)"
          >
            {{ municipality.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field>
        <mat-select prfBlurOnChange placeholder="Город" formControlName="city" [disabled]="!f.municipality.value" name="cities">
          <mat-option *ngFor="let city of citiesByMunicipality; trackBy: trackByFn" [value]="city" (click)="setCity(city)">
            {{ city.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field>
        <mat-select prfBlurOnChange placeholder="Школа" formControlName="school" [disabled]="!f.city.value" name="schools">
          <mat-option *ngFor="let school of schoolsFiltered; trackBy: trackByFn" [value]="school" data-school-select-option>
            {{ school.number }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>

  <button type="button" class="admin-add-event w-button" (click)="findUsers()">Найти</button>
  <button type="button" class="admin-add-event w-button reset-button" (click)="resetFilters()">Сбросить фильтры</button>

  <section class="users-count" *ngIf="users">Количество найденных пользователей: {{ users.length }}</section>
  <section class="users-list" *ngIf="users && users.length">
    <h2>{{ 'SHARED.USERS' | translate }}</h2>
    <div class="example-action-buttons">
      <button type="button" mat-button (click)="accordion.openAll()">Раскрыть все</button>
      <button type="button" mat-button (click)="accordion.closeAll()">Свернуть все</button>
    </div>
    <div class="table-container">
      <div class="table-wrapper">
        <div class="table">
          <div class="row table-header">
            <div [style.width.px]="40" class="cell">№</div>
            <div [style.width.px]="190" class="cell">Логин</div>
            <div [style.width.px]="130" class="cell">{{ 'SHARED.FORM.FAMILY' | translate }}</div>
            <div [style.width.px]="100" class="cell">{{ 'SHARED.FORM.NAME' | translate }}</div>
            <div [style.width.px]="150" class="cell">
              {{ 'SHARED.FORM.MIDDLENAME' | translate }}
            </div>
            <div [style.width.px]="110" class="cell">Рег.код</div>
            <div [style.width.px]="130" class="cell">Редактировать</div>
            <div [style.width.px]="100" class="cell">Сброс пароля</div>
            <div [style.width.px]="100" class="cell">Тег</div>
          </div>

          <mat-accordion multi>
            <mat-expansion-panel *ngFor="let user of users; let count = index; trackBy: trackByFn" hideToggle>
              <mat-expansion-panel-header class="row">
                <div [style.width.px]="40" class="cell" data-title="count">{{ count + 1 }}</div>
                <div [style.width.px]="190" class="cell" data-title="login">{{ user.phoneNumber || user.email }}</div>
                <div [style.width.px]="130" class="cell" data-title="lastName">
                  {{ user.lastName }}
                </div>
                <div [style.width.px]="100" class="cell" data-title="firstName">
                  {{ user.firstName }}
                </div>
                <div [style.width.px]="150" class="cell" data-title="middleName">
                  {{ user.middleName }}
                </div>
                <div [style.width.px]="110" class="cell" data-title="registrationCode">
                  {{ user.registrationCode }}
                </div>
                <div [style.width.px]="130" class="cell" data-title="SETTINGS">
                  <div class="settings-wrapper">
                    <button type="button" (click)="showEditUser(user, f.role.value)" class="user-edit-btn w-inline-block">
                      Редактировать
                    </button>
                    <button type="button" class="delete-user w-inline-block" (click)="selectDeletedUser(user, f.role.value)">
                      Удалить
                    </button>
                  </div>
                </div>
                <div [style.width.px]="100" class="cell" data-title="ResetPassword">
                  <button
                    type="button"
                    *ngIf="user.isActivated"
                    mat-stroked-button
                    (click)="openResetPopUp(user)"
                    class="reset-password"
                  >
                    Сброс
                  </button>
                </div>
                <div [style.width.px]="100" class="cell" data-title="Tag">{{ user.tag }}</div>
              </mat-expansion-panel-header>
              <div class="desc-block">
                <h3 class="desc-block__title">Дополнительная информация</h3>
                <div class="desc-block__row">
                  <div class="desc-block__label">Регистрационный код:</div>
                  <div class="desc-block__state" data-title="registrationCode">
                    {{ user.registrationCode ? user.registrationCode : '-' }}
                  </div>
                </div>
                <div class="desc-block__row">
                  <div class="desc-block__label">Номер телефона:</div>
                  <div class="desc-block__state" data-title="registrationCode">
                    {{ user.phoneNumber ? user.phoneNumber : '-' }}
                  </div>
                </div>
                <div class="desc-block__row">
                  <div class="desc-block__label">Активирован:</div>
                  <div class="desc-block__state" data-title="isActivated">
                    <span *ngIf="user.isActivated === true" class="done-icon"></span>
                    <span *ngIf="user.isActivated === false" class="not-icon"></span>
                  </div>
                </div>
                <div class="desc-block__row">
                  <div class="desc-block__label">Тестирован:</div>
                  <div class="desc-block__state" data-title="isTested">
                    <span *ngIf="user.isTested === true" class="done-icon"></span>
                    <span *ngIf="user.isTested === false" class="not-icon"></span>
                  </div>
                </div>
                <div class="desc-block__row">
                  <div class="desc-block__label">Школа:</div>
                  <div class="desc-block__state" data-title="schoolName">
                    {{ user.schoolId ? schoolsByIds.get(user.schoolId) : 'Не определенна' }}
                  </div>
                </div>
                <div class="desc-block__row">
                  <div class="desc-block__label">Класс:</div>
                  <div class="desc-block__state" data-title="className">
                    {{ user.className ? user.className : 'Не определен' }}
                  </div>
                </div>
                <prf-reassign-teacher
                  *ngIf="isTeacher(user.role)"
                  class="desc-block__row"
                  [teacher]="user"
                  [allSchoolsByCity]="schoolsFiltered"
                  (updateTable)="onUpdateTable()"
                >
                </prf-reassign-teacher>
              </div>
            </mat-expansion-panel>
          </mat-accordion>
        </div>
      </div>
    </div>
  </section>

  <ng-container *ngIf="showDeletePopUp">
    <div class="popup-overlay overlay">
      <div class="popup-container">
        <div class="popup-title">Удаление пользователя:</div>
        <div class="popup-content">
          <div class="content-text">
            <div class="popup-text _1">
              {{ deletedUser.lastName }} {{ deletedUser.firstName }} {{ deletedUser.middleName }}
              {{ deletedUser.registrationCode }}
            </div>
            <div class="popup-text _1">
              Вы уверены, что хотите удалить данного пользователя? После удаления доступ к данным будет потерян.
            </div>

            <div class="buttons">
              <button type="button" class="button w-button popup-button _red" (click)="totalDeleteUser(deletedUser.userId)">Удалить</button>
              <button type="button" class="button w-button popup-button _white" (click)="closeDeletePopUp()">Отмена</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="showResetPasswordPopUp">
    <div class="popup-overlay overlay">
      <div class="popup-container">
        <div class="popup-title">Генерация ссылки для сброса пароля:</div>
        <div class="popup-content">
          <div class="content-text">
            <div class="popup-text _1">Выберете через что пользователь авторизовался:</div>
            <form>
              <div class="popup-input-wrapper">
                <div class="popup-input" [class.disabled-field]="!selectedUser.email">
                  <input
                    (click)="selectResetType('email')"
                    [disabled]="!selectedUser.email"
                    [checked]="selectedUser.email && !selectedUser.phoneNumber"
                    type="radio"
                    id="choiceEmail"
                    name="contact"
                    value="email"
                  />
                  <label for="choiceEmail">Email</label>
                </div>

                <div class="popup-input" [class.disabled-field]="!selectedUser.phoneNumber">
                  <input
                    (click)="selectResetType('phone')"
                    [disabled]="!selectedUser.phoneNumber"
                    [checked]="selectedUser.phoneNumber && !selectedUser.email"
                    type="radio"
                    id="choicePhone"
                    name="contact"
                    value="phone"
                  />
                  <label for="choicePhone">Phone</label>
                </div>
              </div>
            </form>
            <div class="buttons">
              <button
                type="button"
                class="button w-button popup-button _red"
                [class.disabled]="!isActiveButton"
                (click)="generatePasswordResetLink()"
              >
                Сбросить
              </button>
              <button type="button" class="button w-button popup-button _white" (click)="closeResetPasswordPopUp()">Отмена</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</ng-container>
