<ng-template [ngIf]="!isLoading && requestError" [ngIfElse]="defaultPageTemplate">
  <ng-template [ngTemplateOutlet]="requestErrorTemplate"></ng-template>
</ng-template>

<ng-template #defaultPageTemplate>
  <ng-template [ngIf]="isLoading" [ngIfElse]="coursesPage">
    <!-- плейсхолдер при подгрузке курсов -->
    <div class="loader-container">
      <prf-content-loader></prf-content-loader>
    </div>
  </ng-template>
</ng-template>

<ng-template #coursesPage>
  <div class="catalog-course-row">
    <ng-template [ngIf]="courses && courses.length" [ngIfElse]="emptyCourses"> </ng-template>
    <div *ngFor="let course of courses; trackBy: trackByFn" class="course-column">
      <prf-career-education-course-card [course]="course" [isEditable]="true" [isClickable]="true"></prf-career-education-course-card>
    </div>
  </div>
</ng-template>

<ng-template #requestErrorTemplate>
  <div class="results-empty-block">
    <div>
      <img src="./src/assets/images/menu/grey/Test.svg" width="80" class="no-profession-icon" />
    </div>
    <div class="event-empty-block-text">Неполадки на сервере, скоро починим</div>
  </div>
</ng-template>

<ng-template #emptyCourses>
  <div class="event-empty-block">
    <div>
      <img src="./src/assets/images/menu/grey/Courses.svg" width="80" class="no-profession-icon" />
    </div>
    <div class="event-empty-block-text">{{ 'SHARED.FOR_YOU_REQUEST_NOT_COURSES' | translate }}</div>
  </div>
</ng-template>
