<label for="top-list" class="label topList-label">
  <div class="chart-title" *ngIf="!noTitle">
    {{ 'CHARTS.TOP_PROFESSIONS.FULL' | translate }} ({{ 'CHARTS.TOP_PROFESSIONS.TOP' | translate }}-15)

    <mat-icon
      *ngIf="topListTooltip && topListTooltip.length"
      svgIcon="info"
      class="cursor-arrow"
      [tp]="tooltip"
      [tpPlacement]="'right'"
    ></mat-icon>
  </div>
  <mat-card id="top-list">
    <section class="actual">
      <div class="list_header">
        <span class="header_title">{{ 'CHARTS.TOP_PROFESSIONS.RELEVANT_PROFESSIONS' | translate }}</span>
        <div class="buttons">
          <button (click)="isSpo = false" [class.active]="!isSpo">
            {{ 'CHARTS.VPO.SHORT' | translate }}
          </button>
          <button (click)="isSpo = true" [class.active]="isSpo">
            {{ 'CHARTS.SPO.SHORT' | translate }}
          </button>
        </div>
      </div>
      <div class="content">
        <ng-container *ngIf="isSpo; else vpo">
          <ul *ngIf="demanded.spo && demanded.spo.length > 0; else empty">
            <li
              *ngFor="let item of demanded.spo | slice: 0 : 15; let i = index"
              class="cursor-arrow"
              tooltip-class="hint-content__small"
              show-delay="100"
              hide-delay="0"
              animation-duration="150"
            >
              <div class="rect">{{ i + 1 }}</div>
              <div class="text">
                {{ item.name }}
              </div>
            </li>
          </ul>
        </ng-container>
        <ng-template #vpo>
          <ul *ngIf="demanded.vpo && demanded.vpo.length > 0; else empty">
            <li
              *ngFor="let item of demanded.vpo | slice: 0 : 15; let i = index"
              class="cursor-arrow"
              tooltip-class="hint-content__small"
              show-delay="100"
              hide-delay="0"
              animation-duration="150"
            >
              <div class="rect">{{ i + 1 }}</div>
              <div class="text">
                {{ item.name }}
              </div>
            </li>
          </ul>
        </ng-template>
      </div>
    </section>
    <section class="not_popular">
      <div class="list_header">
        <span>{{ 'CHARTS.TOP_PROFESSIONS.NOT_POPULAR_PROF_PUPILS' | translate }}</span>
      </div>
      <div class="content">
        <ul *ngIf="not_popular && not_popular.length > 0; else empty">
          <li
            *ngFor="let item of not_popular | slice: -15; let i = index"
            class="cursor-arrow"
            tooltip-class="hint-content__small"
            show-delay="100"
            hide-delay="0"
            animation-duration="150"
          >
            <div class="rect">{{ i + 1 }}</div>
            <div class="text">
              {{ item.name }}
            </div>
          </li>
        </ul>
      </div>
    </section>
    <section class="popular">
      <div class="list_header">
        <span>{{ 'CHARTS.TOP_PROFESSIONS.POPULAR_PROF_PUPILS' | translate }}</span>
      </div>
      <div class="content">
        <ul *ngIf="popular && popular.length > 0; else empty">
          <li
            *ngFor="let item of popular | slice: 0 : 15; let i = index"
            class="cursor-arrow"
            tooltip-class="hint-content__small"
            show-delay="100"
            hide-delay="0"
            animation-duration="150"
          >
            <div class="rect">{{ i + 1 }}</div>
            {{ item.name }}
          </li>
        </ul>
      </div>
    </section>
  </mat-card>
</label>

<ng-template #empty>
  <div class="empty">{{ 'ALERTS.DATA_NOT_ANOUGH' | translate }}</div>
</ng-template>

<ng-template #tooltip>
  <prf-tooltip>{{ topListTooltip }}</prf-tooltip>
</ng-template>
