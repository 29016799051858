<a class="btn-prf-outline" [routerLink]="['/admin/create-lesson']">Создать занятие</a>

<div *ngIf="mappedLessons">
  <h2>Список занятий</h2>
  <div class="lessons-info">
    <span class="lessons-count-info">
      {{ lessonsCountInfo }}
    </span>
    <prf-dropdown-with-filter
      [items]="courseMaterialsFilter"
      (selectedItemEvent)="selectedCourse($event)"
      [placeholder]="'Курс карьерной грамотности'"
    ></prf-dropdown-with-filter>
  </div>
  <div *ngFor="let lesson of lessons; trackBy: trackByFn" [id]="lesson.id" class="lesson">
    <div (click)="toggle(lesson.id)" class="lesson-card">
      <div>
        <h3 class="lesson-card__title lesson-card_truncation">
          {{ lesson.name }}
        </h3>
        <span>{{ mappedLessons[lesson.id].courseMaterial.name }}</span>
      </div>
      <div class="lesson-card__arrow" [ngClass]="{ 'lesson-card__arrow_active': toggleLessonId === lesson.id }"></div>
    </div>
    <div class="lessons" [ngClass]="{ lessons_active: toggleLessonId === lesson.id }">
      <div class="lessons__header">
        <span class="lessons__header__title">Детали занятия:</span>
        <span class="lessons__tools">
          <a [routerLink]="'/admin/edit-lesson/' + lesson.id" class="edit-btn w-inline-block" aria-label="Отредактировать урок"></a>
          <a
            class="delete-event w-inline-block"
            (click)="popUpConfirming = !popUpConfirming; removedLessonId = lesson.id"
            aria-label="Удалить занятие"
          ></a>
        </span>
      </div>
      <div class="lessons__body">
        <span class="lessons__body__title"> <strong>Название:</strong> {{ lesson.name }} </span>
        <span> <strong>HRID:</strong> {{ lesson.hrid }} </span>
        <span *ngIf="lesson.text"> <strong>Описание:</strong> <span [innerHTML]="lesson.text"></span> </span>
        <span *ngIf="lesson.imageUrl">
          <strong>Ссылка на изображение:</strong>
          <a [href]="lesson.imageUrl" target="_blank" aria-label="Ссылка на изображение"> Открыть </a>
        </span>
        <span *ngIf="lesson.videoUrl">
          <strong>Ссылка на видео:</strong>
          <a [href]="lesson.videoUrl" target="_blank" aria-label="Ссылка на видео"> Открыть </a>
        </span>
        <span> <strong>Порядковый номер:</strong> {{ lesson.orderNumber }} </span>
        <span>
          <strong>Тип задания: </strong>
          <ng-container [ngSwitch]="lesson.tasks.length">
            <span *ngSwitchCase="0">Без заданий</span>
            <span *ngSwitchCase="1">Одно задание</span>
            <span *ngSwitchCase="2">Два задания</span>
          </ng-container>
        </span>
        <ng-template [ngIf]="mappedLessons[lesson.id].courseMaterial && mappedLessons[lesson.id].lessonMaterial">
          <span><strong>Курс карьерной грамотности:</strong></span>
          <span class="left-indent"> <strong>Курс:</strong> {{ mappedLessons[lesson.id].courseMaterial.name }} </span>
          <span class="left-indent"> <strong>Занятие для учителя:</strong> {{ mappedLessons[lesson.id].lessonMaterial.name }} </span>
        </ng-template>
        <ng-container *ngFor="let test$ of mappedLessons[lesson.id].test">
          <ng-container *ngIf="test$ | async as test">
            <span><strong>Задание на прохождение диагностики:</strong></span>
            <span *ngIf="test[0]" class="left-indent"> <strong>Тест:</strong> {{ test[0].name }} </span>
            <span *ngIf="test[1]" class="left-indent"> <strong>Вариант теста:</strong> {{ test[1].name }} </span>
          </ng-container>
        </ng-container>
        <ng-container *ngFor="let field$ of mappedLessons[lesson.id].field; let index = index">
          <ng-container *ngIf="field$ | async as field">
            <span><strong>Задание на выбор профессии:</strong></span>
            <span class="left-indent"><strong>Отрасль:</strong> {{ field.name }}</span>
            <ng-container *ngIf="mappedLessons[lesson.id].professions[index] | async as professions">
              <ng-template [ngIf]="professions?.length">
                <span><strong>Профессии:</strong></span>
                <span *ngFor="let profession of professions" class="left-indent">{{ profession.name }}</span>
              </ng-template>
            </ng-container>
          </ng-container>
        </ng-container>
      </div>
    </div>
  </div>
  <ng-template [ngIf]="popUpConfirming">
    <div class="perform-task-lb lb show">
      <div class="lb-bg"></div>
      <div class="lb-block task-lb">
        <a class="close-lb w-inline-block" (click)="popUpConfirming = !popUpConfirming">
          <img src="./src/assets/images/icons/close-lb.svg" />
        </a>
        <div class="tasl-lb-row">
          <div class="task-lb-column _2">
            <h4 class="task-lb-heading-result">Удаление занятия</h4>
            <div class="task-result-text">Вы уверены, что хотите удалить занятие?</div>
            <a class="button attach-file-btn w-button" (click)="removeLesson()"> {{ 'BUTTONS.DELETE' | translate }}</a>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
</div>
<div *ngIf="lessonsWithoutCourse && lessonsWithoutCourse?.length">
  <h2>Занятия без курса</h2>
  <div class="lessons-info">
    <span class="lessons-count-info"> Всего занятий: {{ lessonsWithoutCourse.length }} </span>
  </div>
  <div *ngFor="let lesson of lessonsWithoutCourse" class="lesson">
    <div (click)="toggle(lesson.id)" class="lesson-card">
      <div>
        <h3 class="lesson-card__title lesson-card_truncation">
          {{ lesson.name }}
        </h3>
      </div>
      <div class="lesson-card__arrow" [ngClass]="{ 'lesson-card__arrow_active': toggleLessonId === lesson.id }"></div>
    </div>
    <div class="lessons" [ngClass]="{ lessons_active: toggleLessonId === lesson.id }">
      <div class="lessons__header">
        <span class="lessons__header__title">Детали занятия:</span>
        <span class="lessons__tools">
          <a
            class="delete-event w-inline-block"
            (click)="popUpConfirming = !popUpConfirming; removedLessonId = lesson.id"
            aria-label="Удалить занятие"
          ></a>
        </span>
      </div>
      <div class="lessons__body">
        <span class="lessons__body__title"> <strong>Название:</strong> {{ lesson.name }} </span>
        <span> <strong>HRID:</strong> {{ lesson.hrid }} </span>
        <span *ngIf="lesson.text"> <strong>Описание:</strong> <span [innerHTML]="lesson.text"></span> </span>
        <span *ngIf="lesson.imageUrl">
          <strong>Ссылка на изображение:</strong>
          <a [href]="lesson.imageUrl" target="_blank" aria-label="Ссылка на изображение"> Открыть </a>
        </span>
        <span *ngIf="lesson.videoUrl">
          <strong>Ссылка на видео:</strong>
          <a [href]="lesson.videoUrl" target="_blank" aria-label="Ссылка на видео"> Открыть </a>
        </span>
        <span> <strong>Порядковый номер:</strong> {{ lesson.orderNumber }} </span>
      </div>
    </div>
  </div>
</div>
