import { Component, OnDestroy, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { OverlayBusyService } from 'app/shared/overlay-busy/overlay-busy.service';
import { ICity } from '../../../../shared/interfaces/icity';
import { IMunicipality } from '../../../../shared/interfaces/imunicipality';
import { IRegion } from '../../../../shared/interfaces/iregion';
import { MunicipalitiesService } from '../municipalities/municipalities.service';
import { RegionsService } from '../regions/regions.service';
import { CitiesService } from './cities.service';
import { forkJoin as observableForkJoin } from 'rxjs/internal/observable/forkJoin';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'prf-cities',
  templateUrl: './cities.component.html',
  styleUrls: ['./cities.component.scss'],
})
export class CitiesComponent implements OnInit, OnDestroy {
  public regions: IRegion[];
  public municipalities: IMunicipality[];
  public cities: ICity[];

  private ngUnsubscribe$: Subject<any> = new Subject();
  constructor(
    private meta: Meta,
    private citiesService: CitiesService,
    private municipalitiesService: MunicipalitiesService,
    private regionsService: RegionsService,
    private overlayService: OverlayBusyService,
  ) {
    this.meta.updateTag({ name: 'og:title', content: 'Города' });
  }

  ngOnInit() {
    this.overlayService.show();
    // TODO добавить фильтры по региону и муниципалитету

    observableForkJoin([this.regionsService.getAllRegions(), this.citiesService.getAllCities()])
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe(([regions, cities]) => {
        this.regions = regions.sort((r1, r2) => r1.name.localeCompare(r2.name));

        this.cities = cities.sort((r1, r2) => r1.name.localeCompare(r2.name));
        this.overlayService.hide();
      });
  }

  public cityAdded(city: ICity): void {
    if (city) {
      this.cities.push(city);
    }
  }

  public cityUpdated(city: ICity): void {
    if (this.isValidCity(city)) {
      const changedCity = this.cities.find(i => i.id === city.id);
      const index = this.cities.indexOf(changedCity);
      if (index > -1) {
        this.cities[index] = Object.assign(this.cities[index], city);
      }
    }
  }

  public cityDeleted(cityId: string): void {
    this.cities = this.cities.filter(i => i.id != cityId);
  }

  private isValidCity(city: ICity): boolean {
    return city && city.id && city.hrid && city.name && city.municipalityId ? true : false;
  }

  ngOnDestroy() {
    this.ngUnsubscribe$.next(null);
    this.ngUnsubscribe$.complete();
  }
}
