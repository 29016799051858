import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IRegion } from '../../interfaces/iregion';
import { IMunicipality } from '../../interfaces/imunicipality';
import { ICity } from '../../interfaces/icity';
import { ISchool } from '../../interfaces/ischool.interface';
import { switchMap, takeUntil, tap } from 'rxjs/operators';
import { combineLatest, Observable, of } from 'rxjs';
import { EmptyGuid } from '../../global-constants/constants';
import { MunicipalitiesService } from '../../../pages/control-panel/admin/municipalities/municipalities.service';
import { CitiesService } from '../../../pages/control-panel/admin/cities/cities.service';
import { AbstractControl, FormGroup } from '@angular/forms';
import { AdminPanelService } from '../../../pages/control-panel/admin/admin-panel.service';
import { RegionsService } from '../../../pages/control-panel/admin/regions/regions.service';
import { TerritoryFiltersService } from '../../services/territory-filters.service';
import { UnsubscribeComponent } from '@profilum-components/unsubscribe/unsubscribe.component';

@Component({
  selector: 'prf-territory-filters',
  templateUrl: './territory-filters.component.html',
  styleUrls: ['./territory-filters.component.scss'],
})
export class TerritoryFiltersComponent extends UnsubscribeComponent implements OnInit {
  @Input() public regionId: string;
  @Input() public municipalityId: string;
  @Input() public cityName: string;
  @Input() public schoolId: string;
  @Output() public classChanged = new EventEmitter();

  public form: FormGroup;
  public allRegions: IRegion[] = [];
  public municipalitiesByRegion: IMunicipality[] = [];
  public allCities: ICity[];
  public citiesByMunicipality: ICity[] = [];
  public allSchools: ISchool[] = [];
  public schoolsFiltered: ISchool[] = [];
  public allClasses: any[] = [];
  public classNumbers: number[] = [5, 6, 7, 8, 9, 10, 11];

  private initialSetUp: boolean = true;

  constructor(
    private territoryFiltersService: TerritoryFiltersService,
    private adminPanelService: AdminPanelService,
    private regionsService: RegionsService,
    private municipalitiesService: MunicipalitiesService,
    private citiesService: CitiesService,
  ) {
    super();
  }

  public ngOnInit(): void {
    this.form = this.territoryFiltersService.form;

    combineLatest([
      this.territoryFiltersService.allRegions$,
      this.territoryFiltersService.allCities$,
      this.territoryFiltersService.allSchools$,
      this.territoryFiltersService.allClassesBySchool$,
    ])
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(([regions, cities, schools, classes]) => {
        this.allCities = cities;
        this.allSchools = schools;
        this.allRegions = regions;
        this.allClasses = classes;

        this.setForm();
      });
  }

  public get formControls(): Record<string, AbstractControl> {
    return this.form.controls;
  }

  public trackByFn(index: number, item: any): any {
    return item;
  }

  public selectRegion(region: IRegion): void {
    this.setRegion(region).pipe(takeUntil(this.unsubscribe)).subscribe();
  }

  public selectMunicipality(municipality: IMunicipality): void {
    this.setMunicipality(municipality).pipe(takeUntil(this.unsubscribe)).subscribe();
  }

  public selectCity(city: ICity): void {
    this.setCity(city).pipe(takeUntil(this.unsubscribe)).subscribe();
  }

  public selectSchool(school: ISchool): void {
    if (school) {
      this.formControls.school.setValue(school);
      this.formControls.classNumber.setValue(null);
      this.formControls.class.setValue(null);
    }
  }

  public selectClassNumber(classNumber: number): void {
    if (classNumber) {
      this.formControls.classNumber.setValue(classNumber);

      this.territoryFiltersService.updateClasses();
    }
  }

  public selectClass(schoolClass: any): void {
    if (schoolClass) {
      this.formControls.class.setValue(schoolClass);
      this.classChanged.emit(schoolClass);
    }
  }

  private setRegion(region: IRegion): Observable<IMunicipality[]> {
    this.formControls.municipality.setValue(null);
    this.formControls.city.setValue(null);
    this.formControls.school.setValue(null);

    this.formControls.region.setValue(region);
    this.formControls.region.disable();
    return this.getMunicipalities(region);
  }

  private setMunicipality(municipality: IMunicipality): Observable<any> {
    this.formControls.city.setValue(null);
    this.formControls.school.setValue(null);
    this.formControls.municipality.setValue(municipality);

    return this.getCities(municipality);
  }

  private setCity(city: ICity): Observable<any> {
    if (city) {
      this.formControls.city.setValue(city);
      this.formControls.school.setValue(null);

      return this.getSchoolsByCity(city);
    }
    return;
  }

  private getMunicipalities(region: IRegion): Observable<IMunicipality[]> {
    return this.municipalitiesService.getMunicipalitiesByRegion(region.id).pipe(
      tap((r: IMunicipality[]) => {
        this.municipalitiesByRegion = r.filter((r: IMunicipality) => r.id != EmptyGuid);
      }),
    );
  }

  private getCities(municipality: IMunicipality): Observable<ICity[]> {
    return this.citiesService.getAllCitiesByMunicipality(municipality.id).pipe(
      tap((cities: ICity[]) => {
        this.citiesByMunicipality = cities;
      }),
      takeUntil(this.unsubscribe),
    );
  }

  private getSchoolsByCity(city: ICity): Observable<any> {
    this.schoolsFiltered = [];
    if (city) {
      const citiesByMunicipality: ICity[] = this.allCities.filter((el: ICity) => el.id === city.id);
      this.schoolsFiltered = this.allSchools
        .filter((school: ISchool) => {
          const schoolCity: ICity = citiesByMunicipality.find((el: ICity) => el.id === school.cityId);
          return citiesByMunicipality.indexOf(schoolCity) > -1;
        })
        .sort((a, b) => (a.number > b.number ? 1 : -1));
      return of(null);
    }
  }

  private setForm(): void {
    if (this.initialSetUp && this.regionId) {
      const oldRegion = this.allRegions.find(region => region.id === this.regionId);

      this.formControls.region.setValue(oldRegion);
      this.setRegion(oldRegion)
        .pipe(
          switchMap(() => {
            if (this.municipalityId) {
              const oldMunicipality = this.municipalitiesByRegion.find(municipality => municipality.id === this.municipalityId);

              return this.setMunicipality(oldMunicipality);
            } else {
              return of(null);
            }
          }),
          switchMap(() => {
            if (this.cityName) {
              const oldCity = this.citiesByMunicipality.find(city => city.name === this.cityName);

              return this.setCity(oldCity);
            } else {
              return of(null);
            }
          }),
          takeUntil(this.unsubscribe),
        )
        .subscribe(() => {
          if (this.schoolId) {
            const oldSchool = this.schoolsFiltered.find(school => school.id === this.schoolId);

            this.selectSchool(oldSchool);
          }

          this.initialSetUp = false;
        });
    }
  }
}
