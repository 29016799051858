import { NgModule } from '@angular/core';
import { CatalogRoutingModule } from './catalog-routing.module';
import { CoursesModule } from './courses-page/courses.module';
import { InternshipsModule } from './internship-page/internships.module';
import { ProfessionsModule } from './profession-page/professions.module';
import { SettingsModule } from './settings-page/settings.module';
import { UniversitiesModule } from './universities-page/universities.module';
import { UserProfileModule } from './user-profile-page/user-profile.module';
import {
  CareerEducationCoursesModule,
} from 'app/pages/catalog/career-education-courses-page/career-education-courses.module';

@NgModule({
  declarations: [],
  providers: [],
  imports: [
    CatalogRoutingModule,
    CoursesModule,
    InternshipsModule,
    ProfessionsModule,
    UserProfileModule,
    SettingsModule,
    UniversitiesModule,
    CareerEducationCoursesModule,
  ],
})
export class CatalogModule {}
