import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import {
  CareerEducationCoursesService,
} from 'app/pages/catalog/career-education-courses-page/career-education-courses.service';
import {
  ILessonMaterial,
  ILessons,
} from 'app/pages/catalog/career-education-courses-page/interfaces/career-education-courses.interface';
import { ActivatedRoute, Router } from '@angular/router';
import { UtilsService } from 'app/shared/dashboard/backend-services/utils.service';
import { TranslateService } from '@ngx-translate/core';
import { take, takeUntil, tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { UnsubscribeComponent } from '@profilum-components/unsubscribe/unsubscribe.component';

@Component({
  selector: 'prf-career-education-lesson-card',
  templateUrl: './career-education-lesson-card.component.html',
  styleUrls: ['./career-education-lesson-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CareerEducationLessonCardComponent extends UnsubscribeComponent implements OnInit {
  public showLessons: boolean = false;
  public popUpConfirming: boolean = false;
  public cardInfo: string;
  public title: string;
  public lessons: ILessonMaterial[];
  public courseId: string;
  public removedLessonId: string = '';
  @Input() lessonsData: [string, ILessons];
  @Output() removeSection: EventEmitter<string> = new EventEmitter();

  constructor(
    private careerEducationCoursesService: CareerEducationCoursesService,
    private activatedRoute: ActivatedRoute,
    private utilsService: UtilsService,
    private translateService: TranslateService,
    private router: Router,
    private changeDetectorRef: ChangeDetectorRef,
  ) {
    super();
  }

  public ngOnInit(): void {
    this.activatedRoute.params.pipe(takeUntil(this.unsubscribe)).subscribe(params => {
      this.courseId = params['courseId'];

      if (!this.courseId) {
        this.error();
        return;
      }

      if (this.lessonsData) {
        const { minNumber, maxNumber } = this.lessonsData[1];

        this.setCardInfo(minNumber, maxNumber);
        this.title = this.lessonsData[0];
        this.lessons = this.lessonsData[1].lessons;
        this.sortLessons();
      }
    });
  }

  public toggle(): void {
    this.showLessons = !this.showLessons;
  }

  public removeLesson(): void {
    this.careerEducationCoursesService
      .deleteLesson(this.courseId, this.removedLessonId)
      .pipe(take(1))
      .subscribe(
        resp => {
          if (resp?.status === 'Success') {
            const index = this.lessons.findIndex(lesson => lesson.id === this.removedLessonId);
            const { minNumber, maxNumber } = this.lessonsData[1];
            const { min, max } = CareerEducationCoursesService.updateMinMaxAfterRemoving(minNumber, maxNumber, this.lessons[index].number);

            this.lessonsData[1].minNumber = min;
            this.lessonsData[1].maxNumber = max;

            this.lessons.splice(index, 1);
            this.setCardInfo(min, max);

            if (!this.lessons.length) {
              this.removeSection.emit(this.lessonsData[0]);
            }

            this.utilsService.openSnackBar(`👌 Урок успешно удален`, 'success');
          } else {
            this.utilsService.openSnackBar('Ошибка удаления урока!', 'error');
          }

          this.popUpConfirming = false;
          this.removedLessonId = '';
          this.changeDetectorRef.detectChanges();
        },
        () => {
          this.popUpConfirming = false;
          this.removedLessonId = '';
          this.utilsService.openSnackBar('Ошибка удаления урока!', 'error');
          this.changeDetectorRef.detectChanges();
        },
      );
  }

  public updatedLesson(newLesson: ILessonMaterial): void {
    const index = this.lessons.findIndex(lesson => lesson.id === newLesson.id);
    const number = this.lessons[index].number;
    const { minNumber, maxNumber } = this.lessonsData[1];
    const { min, max } = CareerEducationCoursesService.updateMinMaxAfterUpdating(minNumber, maxNumber, number, newLesson.number);

    this.lessons[index] = newLesson;
    this.lessonsData[1].minNumber = min;
    this.lessonsData[1].maxNumber = max;
    this.setCardInfo(min, max);

    if (number !== newLesson.number) {
      this.sortLessons();
    }

    this.changeDetectorRef.detectChanges();
  }

  private error = (): void => {
    this.getTranslation('SHARED.ERROR_MSG')
      .pipe(
        tap((translation: string) => {
          this.utilsService.openSnackBar(translation, 'error');
        }),
      )
      .subscribe(() => {
        this.timer = setTimeout(_ => {
          this.router.navigate([`/admin/career-education`]);
        }, 2000);
      });
  };

  private getTranslation(key: string): Observable<any> {
    return this.translateService.get(key);
  }

  private setCardInfo(min: number, max: number): void {
    this.cardInfo = min === max ? `Урок ${min}` : `Уроки ${min}-${max}`;
  }

  private sortLessons(): void {
    this.lessons.sort((lesson1, lesson2) => lesson1.number - lesson2.number);
  }
}
