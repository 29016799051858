import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AdminModule } from './admin/admin.module';
import { ControlPanelComponent } from './control-panel.component';

@NgModule({
  declarations: [ControlPanelComponent],
  imports: [CommonModule, AdminModule],
  exports: [AdminModule],
})
export class ControlPanelModule {}
