import { animate, query, sequence, stagger, style, transition, trigger } from '@angular/animations';
import { AnimationsService } from './animations.service';

export const ROUTE_ANIMATIONS_ELEMENTS = 'route-animations-elements';

const isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
const isSafari = /^((?!chrome|android).)*safari/i.test(window.navigator.userAgent);

function isUnusualBrowser(): boolean {
  return isIEOrEdge || isSafari;
}

const STEPS_ALL: any[] = [
  /* При срабатывании trigger задаем промежуточные стили */
  query(':enter > *', style({ opacity: 0, position: 'fixed' }), {
    optional: true,
  }),
  query(':enter .' + ROUTE_ANIMATIONS_ELEMENTS, style({ opacity: 0 }), {
    optional: true,
  }),
  /* Основная анимация всплытия */
  sequence([
    query(
      ':leave > *',
      [
        style({ transform: 'translateY(0%)', opacity: 1 }),
        animate('0.2s ease-in-out', style({ transform: 'translateY(-3%)', opacity: 0 })),
        style({ position: 'fixed' }),
      ],
      { optional: true },
    ),
    query(
      ':enter > *',
      [
        style({
          transform: 'translateY(-3%)',
          opacity: 0,
          position: 'static',
        }),
        animate('0.5s ease-in-out', style({ transform: 'translateY(0%)', opacity: 1 })),
      ],
      { optional: true },
    ),
  ]),
  query(
    ':enter .' + ROUTE_ANIMATIONS_ELEMENTS,
    stagger(75, [
      style({ transform: 'translateY(10%)', opacity: 0 }),
      animate('0.5s ease-in-out', style({ transform: 'translateY(0%)', opacity: 1 })),
    ]),
    { optional: true },
  ),
];
const STEPS_NONE = [];
const STEPS_PAGE = [STEPS_ALL[0], STEPS_ALL[2]];
const STEPS_ELEMENTS = [STEPS_ALL[1], STEPS_ALL[3]];

/* В сервисе передается какую анимацию мы используем */
export const routeAnimations = trigger('routeAnimations', [
  transition(isRouteAnimationsAll, STEPS_ALL),
  transition(isRouteAnimationsNone, STEPS_NONE),
  transition(isRouteAnimationsPage, STEPS_PAGE),
  transition(isRouteAnimationsElements, STEPS_ELEMENTS),
]);

export function isRouteAnimationsAll() {
  if (window.innerWidth > 768 && !isUnusualBrowser()) {
    return AnimationsService.isRouteAnimationsType('ALL');
  }
}

export function isRouteAnimationsNone() {
  if (window.innerWidth > 768 && !isUnusualBrowser()) {
    return AnimationsService.isRouteAnimationsType('NONE');
  }
}

export function isRouteAnimationsPage() {
  if (window.innerWidth > 768 && !isUnusualBrowser()) {
    return AnimationsService.isRouteAnimationsType('PAGE');
  }
}

export function isRouteAnimationsElements() {
  if (window.innerWidth > 768 && !isUnusualBrowser()) {
    return AnimationsService.isRouteAnimationsType('ELEMENTS');
  }
}
