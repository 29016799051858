import { Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatAccordion } from '@angular/material/expansion';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest, Observable } from 'rxjs';
import { switchMap, takeUntil, tap } from 'rxjs/operators';
import { UtilsService } from 'app/shared/dashboard/backend-services/utils.service';
import { ICity } from 'app/shared/interfaces/icity';
import { IMunicipality } from 'app/shared/interfaces/imunicipality';
import { IRegion } from 'app/shared/interfaces/iregion';
import { ISchool } from 'app/shared/interfaces/ischool.interface';
import { IFindUsersFilter } from 'app/shared/interfaces/ifindusersfilter.interface';
import { OverlayBusyService } from 'app/shared/overlay-busy/overlay-busy.service';
import { EmptyGuid } from 'app/shared/global-constants/constants';
import { AdminPanelService } from '../admin-panel.service';
import { CitiesService } from '../cities/cities.service';
import { MunicipalitiesService } from '../municipalities/municipalities.service';
import { RegionsService } from '../regions/regions.service';
import { IGenerateResetPasswordLinkResponse, IUserInfo, IUserRole } from './users.interface';
import { IInstitution } from 'app/shared/interfaces/IInstitution.interface';
import { USER_ROLES } from './constants/user-roles';
import { ClipboardService } from 'ngx-clipboard';
import { PageHeaderService } from '../../../../shared/dashboard/page-header/page-header.service';
import { TerritoryFiltersService } from '../../../../shared/services/territory-filters.service';
import { UnsubscribeComponent } from '@profilum-components/unsubscribe/unsubscribe.component';

@Component({
  selector: 'prf-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss'],
})
export class UsersComponent extends UnsubscribeComponent implements OnInit, OnDestroy {
  @ViewChild('regionDrop') public readonly regionDrop: ElementRef;
  @ViewChild('regionField') public readonly regionField: ElementRef;
  @ViewChild('municipalityDrop') public readonly municipalityDrop: ElementRef;
  @ViewChild('municipalityField') public readonly municipalityField: ElementRef;
  @ViewChild('cityDrop') public readonly cityDrop: ElementRef;
  @ViewChild('cityField') public readonly cityField: ElementRef;
  @ViewChild(MatAccordion) accordion: MatAccordion;

  public allSchools: ISchool[] = [];
  public schoolsByIds = new Map<string, string>();
  public institutions: IInstitution[] = [];
  public error: boolean = false;
  public selectedRole: string = 'schooladmin';
  public dataFetched: boolean = false;
  public editUserFlag: boolean = false;
  public allRegions: IRegion[] = [];
  public municipalitiesByRegion: IMunicipality[] = [];
  public allCities: ICity[];
  public citiesByMunicipality: ICity[] = [];
  public schoolsFiltered: ISchool[] = [];
  public showDeletePopUp: boolean = false;
  public showResetPasswordPopUp: boolean = false;
  public selectedUser: IUserInfo;
  public deletedUser: IUserInfo;
  public form: UntypedFormGroup;
  public showInfo: boolean = false;
  public submitted: boolean = false;
  public users: IUserInfo[];
  public userRoles: IUserRole[] = USER_ROLES;
  public resetType: string;
  public isActiveButton: boolean = false;

  private currentUsername: string = '';
  private userRegionId: string = '';
  private userMunicipalityId: string = '';

  @HostListener('click', ['$event'])
  private checkClick(event: Event): void {
    if (this.regionDrop?.nativeElement.classList.contains('w--open')) {
      if (!this.regionDrop.nativeElement.contains(event.target) && !this.regionField.nativeElement.contains(event.target)) {
        this.regionDrop.nativeElement.classList.remove('w--open');
      }
    }

    if (this.municipalityDrop?.nativeElement.classList.contains('w--open')) {
      if (!this.municipalityDrop.nativeElement.contains(event.target) && !this.municipalityField.nativeElement.contains(event.target)) {
        this.municipalityDrop.nativeElement.classList.remove('w--open');
      }
    }

    if (this.cityDrop?.nativeElement.classList.contains('w--open')) {
      if (!this.cityDrop.nativeElement.contains(event.target) && !this.cityField.nativeElement.contains(event.target)) {
        this.cityDrop.nativeElement.classList.remove('w--open');
      }
    }
  }

  constructor(
    private meta: Meta,
    private adminPanelService: AdminPanelService,
    private regionsService: RegionsService,
    private municipalitiesService: MunicipalitiesService,
    private citiesService: CitiesService,
    private overlayService: OverlayBusyService,
    private fb: UntypedFormBuilder,
    private utilsService: UtilsService,
    private translateService: TranslateService,
    private clipboardService: ClipboardService,
    private pageHeaderService: PageHeaderService,
    private territoryFiltersService: TerritoryFiltersService,
  ) {
    super();
    this.meta.updateTag({ name: 'og:title', content: 'Пользователи' });
  }

  public ngOnInit(): void {
    this.dataFetched = false;
    this.userRegionId = localStorage.getItem('regionId');
    this.userMunicipalityId = localStorage.getItem('municipalityId');
    this.overlayService.show();
    this.pageHeaderService.setTitle('Пользователи');

    this.form = this.fb.group({
      region: new UntypedFormControl(null, []),
      municipality: new UntypedFormControl(null, []),
      city: new UntypedFormControl(null, []),
      school: new UntypedFormControl(null, []),
      role: new UntypedFormControl(null, Validators.required),
      lastName: new UntypedFormControl(null, []),
      firstName: new UntypedFormControl(null, []),
      email: new UntypedFormControl(null, []),
      phone: new UntypedFormControl(null, []),
      code: new UntypedFormControl(null, []),
    });

    combineLatest([
      this.territoryFiltersService.allCities$,
      this.territoryFiltersService.allSchools$,
      this.territoryFiltersService.allRegions$,
      this.territoryFiltersService.schoolsByIds$,
      this.adminPanelService.getInstitutionsAll(),
    ])
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(([cities, allSchools, regions, schoolsById, institutions]) => {
        this.allCities = cities;
        this.allSchools = allSchools;
        this.allRegions = regions;
        this.schoolsByIds = schoolsById;
        this.institutions = institutions;
        this.overlayService.hide();
        this.dataFetched = true;
      });
  }

  get f() {
    return this.form.controls;
  }

  public findUsers(): void {
    this.submitted = true;
    if (this.form.valid) {
      let filters: IFindUsersFilter = {
        role: this.f.role.value.value,
        lastName: this.f.lastName.value ? this.f.lastName.value : null,
        firstName: this.f.firstName.value ? this.f.firstName.value : null,
        code: this.f.code.value ? this.f.code.value : null,
        email: this.f.email.value ? this.f.email.value : null,
        phone: this.f.phone.value ? this.f.phone.value : null,
        regionId: this.f.region.value ? this.f.region.value.id : null,
        municipalityId: this.f.municipality.value ? this.f.municipality.value.id : null,
        city: this.f.city.value ? this.f.city.value.name : null,
        schoolId: this.f.school.value ? this.f.school.value.id : null,
      };

      this.users = [];

      this.adminPanelService
        .findUsersByFilters(filters)
        .pipe(
          tap(usersResponse => {
            if (usersResponse.status == 'Success') {
              if (usersResponse.users.length) {
                this.setUsers(usersResponse.users);
              }
            } else if (usersResponse.status == 'Failed') {
              if (usersResponse.comment == 'Not enough filters' || 'Not supported filters') {
                this.utilsService.openSnackBar('Недостаточно фильтров для данной роли', 'error');
              } else {
                this.utilsService.openSnackBar('👎 Ошибка на сервере, попробуйте позже', 'error');
              }
            }
          }),
          takeUntil(this.unsubscribe),
        )
        .subscribe();
    } else {
      this.f.role.markAsTouched();
    }
  }

  public resetFilters(): void {
    this.form.reset();
    this.users = [];
  }

  public selectRegion(region: IRegion): void {
    this.setRegion(region).pipe(takeUntil(this.unsubscribe)).subscribe();
  }

  public selectMunicipality(municipality: IMunicipality): void {
    this.f.city.setValue(null);
    this.f.school.setValue(null);
    this.f.municipality.setValue(municipality);

    this.citiesService
      .getAllCitiesByMunicipality(municipality.id)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((cities: ICity[]) => {
        this.citiesByMunicipality = cities;
      });
  }

  public setCity(city: ICity): void {
    if (city) {
      this.f.city.setValue(city);
      this.getSchoolsByCity(city);
    }
    return;
  }

  public editFlag(): void {
    this.editUserFlag = !this.editUserFlag;
  }

  public showEditUser(user: IUserInfo, role: string): void {
    this.editUserFlag = !this.editUserFlag;
    this.selectedUser = user;
    this.selectedRole = role;
  }

  public selectDeletedUser(user: IUserInfo, role: string): void {
    this.deletedUser = user;
    this.selectedRole = role;
    this.showDeletePopUp = true;
  }

  public totalDeleteUser(userId: string): void {
    this.adminPanelService.totalDeleteUser(userId);
    this.adminPanelService
      .totalDeleteUser(userId)
      .pipe(
        switchMap((response: any) => {
          if (response.status == 'Success') {
            return this.getTranslations(['SHARED.USER_DELETE_MSG']).pipe(
              tap(translations => {
                this.utilsService.openSnackBar(translations['SHARED.USER_DELETE_MSG'], 'success');
                this.showDeletePopUp = false;
              }),
            );
          } else {
            return this.getTranslations(['SHARED.ERROR_DELETE_MSG']).pipe(
              tap(translations => this.utilsService.openSnackBar(translations['SHARED.ERROR_DELETE_MSG'], 'error')),
            );
          }
        }),
        takeUntil(this.unsubscribe),
      )
      .subscribe();
  }

  public closeDeletePopUp(): void {
    this.showDeletePopUp = false;
  }

  public isTeacher(role: string): boolean {
    return role === 'teacher';
  }

  public openResetPopUp(user: IUserInfo): void {
    this.selectedUser = user;
    this.showResetPasswordPopUp = true;

    const hasEmail: boolean = !!user.email;
    const hasPhoneNumber: boolean = !!user.phoneNumber;
    if (hasEmail && !hasPhoneNumber) {
      this.resetType = 'email';
      this.isActiveButton = true;
    }

    if (!hasEmail && hasPhoneNumber) {
      this.resetType = 'phone';
      this.isActiveButton = true;
    }
  }

  public generatePasswordResetLink(): void {
    const userName: string = this.resetType === 'email' ? this.selectedUser.email : this.selectedUser.phoneNumber;
    this.adminPanelService
      .generatePasswordResetLink(userName)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe({
        next: (response: IGenerateResetPasswordLinkResponse) => {
          if (response?.passwordResetLink) {
            this.utilsService.openSnackBar('👌 Ссылка для восстановления пароля скопирована в буфер обмена', 'success');
            this.clipboardService.copy(response.passwordResetLink);
          }
          if (response?.status === 'Not Found') {
            this.utilsService.openSnackBar(`👎 Не удалось найти юзера по ${userName}`, 'error');
          }
          this.showResetPasswordPopUp = false;
          this.currentUsername = null;
        },
        error: err => {
          this.utilsService.openSnackBar('👎 Ошибка на сервере, попробуйте позже', 'error');
          console.error(err);
          this.showResetPasswordPopUp = false;
        },
      });
  }

  public closeResetPasswordPopUp(): void {
    this.showResetPasswordPopUp = false;
    this.currentUsername = null;
  }

  public onUpdateTable(): void {
    this.findUsers();
  }

  public selectResetType(type: string): void {
    this.resetType = type;
    this.isActiveButton = true;
  }

  public trackByFn(index: number, item: any): any {
    return item;
  }

  private setRegion(region: IRegion): Observable<IMunicipality[]> {
    this.f.municipality.setValue(null);
    this.f.city.setValue(null);
    this.f.school.setValue(null);

    this.f.region.setValue(region);
    return this.getMunicipalities(region);
  }

  private getSchoolsByCity(city: ICity): void {
    this.schoolsFiltered = [];
    if (city) {
      let citiesByMunicipality: ICity[] = this.allCities.filter((el: ICity) => el.id === city.id);
      this.schoolsFiltered = this.allSchools
        .filter((school: ISchool) => {
          let schoolCity: ICity = citiesByMunicipality.find((el: ICity) => el.id === school.cityId);
          return citiesByMunicipality.indexOf(schoolCity) > -1;
        })
        .sort((a, b) => (a.number > b.number ? 1 : -1));
    }
  }

  private getMunicipalities(region: IRegion): Observable<IMunicipality[]> {
    return this.municipalitiesService.getMunicipalitiesByRegion(region.id).pipe(
      tap((r: IMunicipality[]) => {
        this.municipalitiesByRegion = r.filter((r: IMunicipality) => r.id != EmptyGuid);
      }),
    );
  }

  private getTranslations(keys: string[]): Observable<any> {
    return this.translateService.get(keys);
  }

  private setUsers(searchResults): void {
    searchResults.sort((a, b) => {
      if (a.email < b.email) {
        return -1;
      }
      if (a.email > b.email) {
        return 1;
      }
      return 0;
    });
    this.users.push(searchResults[0]);
    this.users[0].allSchoolIds = [this.users[0].schoolId];
    this.users[0].allSchoolClassIds = [this.users[0].schoolClassId];

    this.users = searchResults.reduce((prevVal, curVal) => {
      const index = prevVal.findIndex(user => user.registrationCode === curVal.registrationCode);
      const currentUser = { ...curVal };

      if (index !== -1) {
        prevVal[index].className += `, ${currentUser.className}`;
        prevVal[index].allSchoolClassIds.push(currentUser.schoolClassId);

        if (!prevVal[index].allSchoolIds.includes(currentUser.schoolId)) {
          prevVal[index].allSchoolIds.push(currentUser.schoolId);
        }
      } else {
        currentUser.allSchoolIds = [currentUser.schoolId];
        currentUser.allSchoolClassIds = [currentUser.schoolClassId];
        prevVal.push(currentUser);
      }

      return prevVal;
    }, []);
  }

  public ngOnDestroy(): void {
    super.ngOnDestroy();
    this.pageHeaderService.setTitle(null);
  }
}
