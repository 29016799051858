<div class="card create">
  <div class="slide-toggle-container">
    <mat-slide-toggle [color]="'primary'" [checked]="true" (change)="changeProfessionVisibility($event)">
      Видимость профессии
    </mat-slide-toggle>
  </div>
  <form novalidate #f="ngForm" (ngSubmit)="onSubmit(f)" class="create-form-profession">
    <mat-form-field>
      <label for="name"></label>
      <input matInput placeholder="Название профессии" id="name" [(ngModel)]="profession.name" name="name" #className="ngModel" required />
      <mat-error *ngIf="className.errors?.required && className.touched">
        {{ 'SHARED.REQUIRED_CAPTION' | translate }}
      </mat-error>
      <mat-error *ngIf="className.errors?.minlength && className.touched">
        {{ 'REGISTRATION_PUPIL_KZ.FORM_ALERT.5_CHARACTERS' | translate }}
      </mat-error>
    </mat-form-field>

    <mat-form-field>
      <label for="hrid"></label>
      <input matInput placeholder="Hrid" id="hrid" [(ngModel)]="profession.hrid" name="hrid" #classHrid="ngModel" minlength="3" required />
      <mat-error *ngIf="classHrid.errors?.required && classHrid.touched"> Требуется hrid </mat-error>
      <mat-error *ngIf="classHrid.errors?.minlength && classHrid.touched">
        {{ 'REGISTRATION_PUPIL_KZ.FORM_ALERT.3_CHARACTERS' | translate }}
      </mat-error>
    </mat-form-field>

    <mat-form-field>
      <label for="genitive"></label>
      <input
        matInput
        placeholder="Название в родительном падеже"
        id="genitive"
        [(ngModel)]="profession.genitive"
        name="genitive"
        #classGenitive="ngModel"
        minlength="3"
      />
      <mat-error *ngIf="classGenitive.errors?.minlength && classGenitive.touched">
        {{ 'REGISTRATION_PUPIL_KZ.FORM_ALERT.3_CHARACTERS' | translate }}
      </mat-error>
    </mat-form-field>

    <mat-form-field>
      <label for="dative"></label>
      <input
        matInput
        placeholder="Название в творительном падеже"
        id="dative"
        [(ngModel)]="profession.dative"
        name="dative"
        #classDative="ngModel"
        minlength="3"
      />
      <mat-error *ngIf="classDative.errors?.minlength && classDative.touched">
        {{ 'REGISTRATION_PUPIL_KZ.FORM_ALERT.3_CHARACTERS' | translate }}
      </mat-error>
    </mat-form-field>

    <mat-form-field>
      <label for="descripion"></label>
      <textarea
        matInput
        id="descripion"
        placeholder="Длинное описание"
        class="form-control"
        [(ngModel)]="profession.longDescription"
        name="description"
        #classDescription="ngModel"
        minlength="3"
        required
      >
      </textarea>
      <mat-error *ngIf="classDescription.errors?.required && classDescription.touched"> Требуется описание </mat-error>
      <mat-error *ngIf="classDescription.errors?.minlength && classDescription.touched">
        {{ 'REGISTRATION_PUPIL_KZ.FORM_ALERT.3_CHARACTERS' | translate }}
      </mat-error>
    </mat-form-field>

    <mat-form-field>
      <label for="mediumDescription"></label>
      <textarea
        matInput
        id="mediumDescription"
        placeholder="Среднее описание"
        class="form-control"
        [(ngModel)]="profession.mediumDescription"
        name="description"
        #classMediumDescription="ngModel"
        minlength="3"
        required
      >
      </textarea>
      <mat-error *ngIf="classMediumDescription.errors?.required && classMediumDescription.touched"> Требуется описание </mat-error>
      <mat-error *ngIf="classMediumDescription.errors?.minlength && classMediumDescription.touched">
        {{ 'REGISTRATION_PUPIL_KZ.FORM_ALERT.3_CHARACTERS' | translate }}
      </mat-error>
    </mat-form-field>

    <mat-form-field>
      <label for="shortDescription"></label>
      <textarea
        id="shortDescription"
        placeholder="Краткое описание"
        matInput
        [(ngModel)]="profession.shortDescription"
        name="shortDescription"
        #classShortDescription="ngModel"
        minlength="3"
        required
      >
      </textarea>
      <mat-error *ngIf="classShortDescription.errors?.required && classShortDescription.touched"> Требуется описание </mat-error>
      <mat-error *ngIf="classShortDescription.errors?.minlength && classShortDescription.touched">
        {{ 'REGISTRATION_PUPIL_KZ.FORM_ALERT.3_CHARACTERS' | translate }}
      </mat-error>
    </mat-form-field>

    <mat-form-field>
      <mat-select name="region" [(ngModel)]="currentRegion" placeholder="Выберите регион" required>
        <mat-option *ngFor="let region of allRegions" [value]="region" (click)="selectRegion(region)">
          {{ region.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field *ngIf="currentRegion?.id">
      <mat-select placeholder="Выберите муниципалитет" [(ngModel)]="currentMunicipality" name="municipality">
        <mat-option (click)="resetMunicipality()" value="Не выбран"> Не выбран</mat-option>
        <mat-option *ngFor="let municipality of municipalitiesByRegion" [value]="municipality" (click)="setMunicipality(municipality)">
          {{ municipality.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field>
      <label for="minSalary"></label>
      <input
        placeholder="Минимальная зарплата"
        matInput
        pattern="^$|^(\d{1,6}|[1][0][0][0][0][0][0])?"
        id="minSalary"
        [(ngModel)]="profession.minSalary"
        name="minSalary"
        required
        #classMinSalary="ngModel"
      />
      <mat-error *ngIf="classMinSalary.errors?.required && classMinSalary.touched"> Требуется описание </mat-error>
      <mat-error *ngIf="classMinSalary.errors?.pattern && classMinSalary.touched">
        Значение должно быть в диапазоне от 0 до 1000000
      </mat-error>
    </mat-form-field>

    <mat-form-field>
      <label for="maxSalary"></label>
      <input
        placeholder="Максимальная зарплата"
        matInput
        pattern="^$|^(\d{1,6}|[1][0][0][0][0][0][0])?"
        id="maxSalary"
        [(ngModel)]="profession.maxSalary"
        name="maxSalary"
        required
        #classMaxSalary="ngModel"
      />
      <mat-error *ngIf="classMaxSalary.errors?.required && classMaxSalary.touched"> Требуется описание </mat-error>
      <mat-error *ngIf="classMaxSalary.errors?.pattern && classMaxSalary.touched">
        Значение должно быть в диапазоне от 0 до 1000000
      </mat-error>
    </mat-form-field>

    <mat-form-field>
      <label for="averageSalary"></label>
      <input
        placeholder="Средняя зарплата"
        matInput
        pattern="^$|^(\d{1,6}|[1][0][0][0][0][0][0])?"
        id="averageSalary"
        [(ngModel)]="profession.averageSalary"
        name="averageSalary"
        required
        #classAverageSalary="ngModel"
      />
      <mat-error *ngIf="classAverageSalary.errors?.required && classAverageSalary.touched"> Требуется описание </mat-error>
      <mat-error *ngIf="classAverageSalary.errors?.pattern && classAverageSalary.touched">
        Значение должно быть в диапазоне от 0 до 1000000
      </mat-error>
    </mat-form-field>

    <mat-form-field>
      <mat-select placeholder="Востребованность" [(ngModel)]="profession.demandRating" name="profession.demandRating" required>
        <mat-option *ngFor="let demandRating of demandRatings" [value]="demandRating.level">
          {{ demandRating.viewValue }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <div>
      <div class="multiple-input-container">
        <mat-form-field>
          <mat-select placeholder="Предпочтительный уровень" [(ngModel)]="addedEducation.isPreferred" name="isPreferred">
            <mat-option *ngFor="let booleanVariant of booleanVariants" [value]="booleanVariant.value">
              {{ booleanVariant.viewValue }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field>
          <input
            matInput
            pattern="^$|^(\d{1,2}|[1][0][0])?"
            id="perentage"
            name="perentage"
            placeholder="Процент предпочтения(число)"
            #perentage="ngModel"
            [(ngModel)]="addedEducation.perentage"
          />
          <mat-error *ngIf="perentage.errors?.required && perentage.touched"> Требуется описание </mat-error>
          <mat-error *ngIf="perentage.errors?.pattern && perentage.touched"> Значение должно быть в диапазоне от 0 до 100 </mat-error>
        </mat-form-field>
        <mat-form-field>
          <mat-select placeholder="Образовательный уровень" [(ngModel)]="addedEducation.level" name="addedEducation.level">
            <mat-option *ngFor="let education of educationList" [value]="education.level">
              {{ education.viewValue }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <a mat-raised-button (click)="addEducation()">{{ 'SHARED.ADD' | translate }}</a>
      </div>
      <mat-selection-list #education [(ngModel)]="selectedOptions" name="educations">
        <mat-list-option *ngFor="let education of profession.education" [value]="education">
          {{ education.isPreferred ? 'Да' : 'Нет' }}
          {{ education.perentage }}
          {{
            education.level === 1
              ? ('SHARED.EDU.SECONDARY' | translate)
              : education.level === 2
              ? ('SHARED.EDU.VOCATIONAL' | translate)
              : ('SHARED.EDU.HIGHER' | translate)
          }}
        </mat-list-option>
      </mat-selection-list>
      <a *ngIf="education.selectedOptions.selected.length > 0" mat-raised-button (click)="removeEducations()"> Удалить выбранные</a>
    </div>

    <mat-form-field>
      <mat-select
        placeholder="Типы отраслей"
        [(ngModel)]="professionFieldType"
        name="fieldTypes"
        (ngModelChange)="selectedFieldType()"
        required
      >
        <mat-option *ngFor="let fieldType of fieldTypes" [value]="fieldType.type">
          {{ fieldType.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field>
      <mat-select
        placeholder="Сферы профессии"
        [(ngModel)]="professionFieldName"
        name="fieldNames"
        (ngModelChange)="onModelChangeFieldNames($event)"
        required
      >
        <mat-option *ngFor="let field of fields" [value]="field.name">
          {{ field.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field>
      <input
        matInput
        id="fieldBlock"
        name="fieldBlock"
        [(ngModel)]="profession.fieldBlock"
        placeholder="Блок отрасли, к которой относится профессия"
        #fieldBlock
      />
    </mat-form-field>

    <mat-form-field>
      <mat-select name="courses" [(ngModel)]="profession.courses" placeholder="Курсы для профессии" multiple>
        <mat-option *ngFor="let course of courses" [value]="course.name">
          {{ course.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <div>
      <div class="multiple-input-container">
        <mat-form-field>
          <label for="knowledge"></label>
          <input
            matInput
            id="knowledge"
            placeholder="Знания"
            #knowledge
            (keyup.enter)="addKnowledge(knowledge.value)"
            (blur)="addKnowledge(knowledge.value); knowledge.value = ''"
          />
        </mat-form-field>
        <a mat-raised-button (click)="addKnowledge(knowledge.value)">{{ 'SHARED.ADD' | translate }}</a>
      </div>
      <mat-selection-list #knowledged [(ngModel)]="selectedOptions" name="knowledge">
        <mat-list-option *ngFor="let knowledge of profession.knowledge" [value]="knowledge">
          {{ knowledge }}
        </mat-list-option>
      </mat-selection-list>
      <a *ngIf="knowledged.selectedOptions.selected.length > 0" mat-raised-button (click)="removeKnowledges()"> Удалить выбранные </a>
    </div>

    <mat-form-field>
      <mat-select name="addedProfilClasses" [(ngModel)]="profession.profilClasses" placeholder="Профильные классы" multiple>
        <mat-option *ngFor="let profilClass of profilClasses" [value]="profilClass.name">
          {{ profilClass.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <div>
      <div class="multiple-input-container">
        <mat-form-field>
          <label for="skills"></label>
          <input
            matInput
            id="skills"
            placeholder="Умения"
            #skill
            (keyup.enter)="addSkill(skill.value)"
            (blur)="addSkill(skill.value); skill.value = ''"
          />
        </mat-form-field>
        <a mat-raised-button (click)="addSkill(skill.value)">{{ 'SHARED.ADD' | translate }}</a>
      </div>
      <mat-selection-list #skilled [(ngModel)]="selectedOptions" name="skill">
        <mat-list-option *ngFor="let skill of profession.skills" [value]="skill">
          {{ skill }}
        </mat-list-option>
      </mat-selection-list>
      <a *ngIf="skilled.selectedOptions.selected.length > 0" mat-raised-button (click)="removeSkills()"> Удалить выбранные </a>
    </div>

    <div>
      <div class="multiple-input-container">
        <mat-form-field>
          <label for="responsibilities"></label>
          <input
            matInput
            id="responsibilities"
            placeholder="Обязанности"
            #responsibilities
            (keyup.enter)="addResponsibility(responsibilities.value)"
            (blur)="addResponsibility(responsibilities.value); responsibilities.value = ''"
          />
        </mat-form-field>
        <a mat-raised-button (click)="addResponsibility(responsibilities.value)">{{ 'SHARED.ADD' | translate }}</a>
      </div>
      <mat-selection-list #responsibility [(ngModel)]="selectedOptions" name="responsibility">
        <mat-list-option *ngFor="let responsibility of profession.resposibilities" [value]="responsibility">
          {{ responsibility }}
        </mat-list-option>
      </mat-selection-list>
      <a *ngIf="responsibility.selectedOptions.selected.length > 0" mat-raised-button (click)="removeResponsibilities()">
        Удалить выбранные
      </a>
    </div>

    <div>
      <div class="multiple-input-container">
        <mat-form-field>
          <label for="productGroups"></label>
          <input
            matInput
            id="productGroups"
            placeholder="Продуктовые группы"
            #productGroups
            (keyup.enter)="addProductGroup(productGroups.value)"
            (blur)="addProductGroup(productGroups.value); productGroups.value = ''"
          />
        </mat-form-field>
        <a mat-raised-button (click)="addProductGroup(productGroups.value)">{{ 'SHARED.ADD' | translate }}</a>
      </div>
      <mat-selection-list #productGrouped [(ngModel)]="selectedOptions" name="productGroup">
        <mat-list-option *ngFor="let productGroup of profession.productGroups" [value]="productGroup">
          {{ productGroup }}
        </mat-list-option>
      </mat-selection-list>
      <a *ngIf="productGrouped.selectedOptions.selected.length > 0" mat-raised-button (click)="removeProductGroups()">
        Удалить выбранные
      </a>
    </div>

    <div>
      <div class="multiple-input-container">
        <mat-form-field>
          <label for="movies"></label>
          <input
            matInput
            id="movies"
            placeholder="Фильмы"
            #movie
            (keyup.enter)="addMovie(movie.value)"
            (blur)="addMovie(movie.value); movie.value = ''"
          />
        </mat-form-field>
        <a mat-raised-button (click)="addMovie(movie.value)">{{ 'SHARED.ADD' | translate }}</a>
      </div>
      <mat-selection-list #movied [(ngModel)]="selectedOptions" name="movie">
        <mat-list-option *ngFor="let movie of profession.movies" [value]="movie">
          {{ movie }}
        </mat-list-option>
      </mat-selection-list>
      <a *ngIf="movied.selectedOptions.selected.length > 0" mat-raised-button (click)="removeMovies()"> Удалить выбранные </a>
    </div>

    <div>
      <div class="multiple-input-container">
        <mat-form-field>
          <label for="abilities"></label>
          <input
            matInput
            id="abilities"
            placeholder="Способности"
            #abilitie
            (keyup.enter)="addAbility(abilitie.value)"
            (blur)="addAbility(abilitie.value); abilitie.value = ''"
          />
        </mat-form-field>
        <a mat-raised-button (click)="addAbility(abilitie.value)">{{ 'SHARED.ADD' | translate }}</a>
      </div>
      <mat-selection-list #abilitied [(ngModel)]="selectedOptions" name="abilitie">
        <mat-list-option *ngFor="let abilitie of profession.abilities" [value]="abilitie">
          {{ abilitie }}
        </mat-list-option>
      </mat-selection-list>
      <a *ngIf="abilitied.selectedOptions.selected.length > 0" mat-raised-button (click)="removeAbilities()"> Удалить выбранные </a>
    </div>

    <div>
      <div class="multiple-input-container">
        <mat-form-field>
          <label for="facts"></label>
          <textarea matInput id="facts" placeholder="Факты о профессии" #facts> </textarea>
        </mat-form-field>
        <a mat-raised-button (click)="addFact(facts.value)">{{ 'SHARED.ADD' | translate }}</a>
      </div>
      <mat-selection-list #facted [(ngModel)]="selectedOptions" name="fact">
        <mat-list-option *ngFor="let fact of profession.facts" [value]="fact">
          {{ fact }}
        </mat-list-option>
      </mat-selection-list>
      <a *ngIf="facted.selectedOptions.selected.length > 0" mat-raised-button (click)="removeFacts()"> Удалить выбранные </a>
    </div>

    <div>
      <div class="multiple-input-container">
        <mat-form-field>
          <input matInput id="articlesName" placeholder="Название статьи" #articleName />
        </mat-form-field>
        <mat-form-field>
          <input matInput id="articlesUrl" placeholder="Ссылка на статью" #articleUrl />
        </mat-form-field>
        <a mat-raised-button (click)="addArticle(articleName.value, articleUrl.value)">{{ 'SHARED.ADD' | translate }}</a>
      </div>
      <mat-selection-list #articled [(ngModel)]="selectedOptions" name="article">
        <mat-list-option *ngFor="let article of profession.articles" [value]="article">
          {{ article.name }} {{ article.url }}
        </mat-list-option>
      </mat-selection-list>
      <a *ngIf="articled.selectedOptions.selected.length > 0" mat-raised-button (click)="removeArticles()">Удалить выбранные</a>
    </div>

    <div>
      <div class="multiple-input-container">
        <mat-form-field>
          <label for="additionalEducations"></label>
          <input
            matInput
            id="additionalEducations"
            placeholder="{{ 'SHARED.ADDITIONAL_EDUCATION' | translate }}"
            #additionalEducation
            (keyup.enter)="addAdditionalEducation(additionalEducation.value)"
            (blur)="addAdditionalEducation(additionalEducation.value); additionalEducation.value = ''"
          />
        </mat-form-field>
        <a mat-raised-button (click)="addAdditionalEducation(additionalEducation.value)">{{ 'SHARED.ADD' | translate }}</a>
      </div>
      <mat-selection-list #addictEdu [(ngModel)]="selectedOptions" name="additionalEducation">
        <mat-list-option *ngFor="let additionalEducation of profession.additionalEducations" [value]="additionalEducation">
          {{ additionalEducation }}
        </mat-list-option>
      </mat-selection-list>
      <a *ngIf="addictEdu.selectedOptions.selected.length > 0" mat-raised-button (click)="removeAdditionalEducations()">
        Удалить выбранные
      </a>
    </div>

    <div>
      <div class="multiple-input-container">
        <mat-form-field>
          <input matInput id="videosName" placeholder="Название видео" #videoName />
        </mat-form-field>
        <mat-form-field>
          <input matInput id="videosUrl" placeholder="Ссылка на видео" #videoUrl />
        </mat-form-field>
        <a mat-raised-button (click)="addVideo(videoName.value, videoUrl.value)">{{ 'SHARED.ADD' | translate }}</a>
      </div>
      <mat-selection-list #videoed [(ngModel)]="selectedOptions" name="video">
        <mat-list-option *ngFor="let video of profession.videos" [value]="video"> {{ video.name }}, {{ video.url }} </mat-list-option>
      </mat-selection-list>
      <a *ngIf="videoed.selectedOptions.selected.length > 0" mat-raised-button (click)="removeVideos()"> Удалить выбранные</a>
    </div>

    <div>
      <div class="multiple-input-container">
        <mat-form-field>
          <input matInput id="companyName" placeholder="Название компании" #companyName />
        </mat-form-field>
        <mat-form-field>
          <input matInput id="companyDescription" placeholder="Описание компании" #companyDescription />
        </mat-form-field>
        <mat-form-field>
          <input matInput id="companyUrl" placeholder="Ссылка на компанию" #companyUrl />
        </mat-form-field>
        <mat-form-field>
          <input matInput id="companyImageId" placeholder="Идентификатор картинки" #companyImageId />
        </mat-form-field>
        <a mat-raised-button (click)="addCompany(companyName.value, companyDescription.value, companyUrl.value, companyImageId.value)">{{
          'SHARED.ADD' | translate
        }}</a>
      </div>
      <mat-selection-list #companyed [(ngModel)]="selectedOptions" name="company">
        <mat-list-option *ngFor="let company of profession.companies" [value]="company">
          {{ company.name }}, {{ company.description }} {{ company.url }} {{ company.imageId }}
        </mat-list-option>
      </mat-selection-list>
      <a *ngIf="companyed.selectedOptions.selected.length > 0" mat-raised-button (click)="removeCompanies()"> Удалить выбранные</a>
    </div>

    <div>
      <div>
        <mat-form-field>
          <label for="additionalTextsActualCompaniesText"></label>
          <textarea
            placeholder="Дополнительный текст (Актуальные данные компании)"
            matInput
            id="additionalTextsActualCompaniesText"
            [(ngModel)]="profession.additionalTexts.actualCompaniesText"
            name="additionalTextsActualCompaniesText"
            #classAdditionalTextsActualCompaniesText="ngModel"
          >
          </textarea>
        </mat-form-field>
        <mat-form-field>
          <label for="additionalTextsEducationDescription"></label>
          <textarea
            placeholder="Дополнительный текст (Описание образования)"
            matInput
            id="additionalTextsEducationDescription"
            [(ngModel)]="profession.additionalTexts.educationDescription"
            name="additionalTexts"
            #classAdditionalTextsEducationDescription="ngModel"
          >
          </textarea>
        </mat-form-field>
      </div>
    </div>

    <div>
      <div class="multiple-input-container">
        <mat-form-field>
          <input matInput id="specialsName" placeholder="Название специальности" #specialsName />
        </mat-form-field>
        <mat-select placeholder="Образовательные уровни" id="specialsEducation" #specialsEducation>
          <mat-option *ngFor="let education of educationList" [value]="education.level">
            {{ education.viewValue }}
          </mat-option>
        </mat-select>
        <a mat-raised-button (click)="addSpecial(specialsName.value, specialsEducation.value)">
          {{ 'SHARED.ADD' | translate }}
        </a>
      </div>
      <mat-selection-list #special [(ngModel)]="selectedOptions" name="specialities">
        <mat-list-option *ngFor="let specialities of profession.specialities" [value]="specialities">
          {{ specialities.name }}
          {{
          specialities.educationLevel === 1
              ? ('SHARED.EDU.SECONDARY' | translate)
              : specialities.educationLevel === 2
              ? ('SHARED.EDU.VOCATIONAL' | translate)
              : ('SHARED.EDU.HIGHER' | translate)
          }}
        </mat-list-option>
      </mat-selection-list>
      <a *ngIf="special.selectedOptions.selected.length > 0" mat-raised-button (click)="removeSpecials()"> Удалить выбранные</a>
    </div>

    <div></div>

    <div>
      <div class="multiple-input-container">
        <div>
          <label for="photo">Фото персоны</label>
          <input id="photo" type="file" (change)="selectFile($event)" />
        </div>
        <mat-form-field>
          <input
            matInput
            id="personName"
            name="personName"
            [(ngModel)]="addedPerson.name"
            placeholder="{{ 'SHARED.PERSON_NAME' | translate }}"
            #personName
          />
        </mat-form-field>
      </div>
      <mat-form-field>
        <textarea
          matInput
          id="personDescription"
          name="personDescription"
          [(ngModel)]="addedPerson.description"
          placeholder="Описание персоны"
          #personDescription
        >
        </textarea>
      </mat-form-field>
      <div class="profession__group">
        <a mat-raised-button (click)="addPerson()">{{ 'SHARED.ADD' | translate }}</a>
      </div>
      <mat-selection-list #persons [(ngModel)]="selectedOptions" name="persons">
        <mat-list-option class="current__persons" *ngFor="let person of profession.persons" [value]="person">
          <img class="persons-img" width="50" height="50" src="{{ person.imagePath }}" />
          <p>{{ person.name }}</p>
          <p>{{ person.description }}</p>
        </mat-list-option>
      </mat-selection-list>
      <a *ngIf="persons.selectedOptions.selected.length > 0" mat-raised-button (click)="removePersons()"> Удалить выбранные </a>
    </div>

    <mat-form-field>
      <label for="professionTitle"></label>
      <input
        matInput
        placeholder="Заголовок для SEO"
        id="professionTitle"
        [(ngModel)]="profession.title"
        name="professionTitle"
        #classProfessionTitle="ngModel"
      />
    </mat-form-field>

    <mat-form-field>
      <label for="professionMetaDescription"></label>
      <textarea
        placeholder="Description для SEO"
        matInput
        id="professionMetaDescription"
        [(ngModel)]="profession.metaDescription"
        name="professionMetaDescription"
        #classProfessionMetaDescription="ngModel"
      >
      </textarea>
    </mat-form-field>

    <mat-form-field>
      <label for="professionMetaKeywords"></label>
      <textarea
        placeholder="Ключевые слова для SEO (слова нужно писать через запятую)"
        matInput
        id="professionMetaKeywords"
        [(ngModel)]="profession.metaKeywords"
        name="professionMetaKeywords"
        #classProfessionMetaKeywords="ngModel"
      >
      </textarea>
    </mat-form-field>
    <div class="multiple-input-container">
      <div>
        <label for="image">Изображение профессии</label>
        <input id="image" type="file" (change)="selectFile($event)" />
      </div>
      <a mat-raised-button (click)="addProfessionImage()">{{ 'SHARED.ADD' | translate }}</a>
    </div>
    <button type="submit" class="admin-add-event w-button">Сохранить</button>
  </form>
</div>
<prf-page-toast *ngIf="error"></prf-page-toast>
