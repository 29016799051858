<form class="reports-page" [formGroup]="form">
  <div class="section-title">
    Матрица ответов
    <button
      type="button"
      class="button welcome-button-portfolio white-button"
      [disabled]="answersMatrixPrepearing"
      (click)="resetFilters()"
    >
      <span class="btn-text">Сбросить фильтры</span>
    </button>
  </div>
  <div class="dropdown-container">
    <prf-dropdown-with-search
      formControlName="test"
      [label]="'SHARED.TESTS' | translate"
      [options]="listedTests"
      [disabledInput]="answersMatrixPrepearing"
    ></prf-dropdown-with-search>
  </div>
  <div class="dropdown-container">
    <prf-dropdown-multi
      formControlName="variant"
      [label]="'SHARED.VARIANTS' | translate"
      [placeholder]="'SHARED.CHOOSE_VARIANT' | translate"
      [options]="listedTestVariants"
      [disabledInput]="answersMatrixPrepearing"
      [resetOptions]="isResetFilters"
    ></prf-dropdown-multi>
  </div>
  <div class="dropdown-container">
    <prf-dropdown-with-search
      formControlName="region"
      [label]="'SHARED.REGIONS' | translate"
      [options]="listedRegions"
      [disabledInput]="answersMatrixPrepearing"
      [isOptional]="true"
    ></prf-dropdown-with-search>
  </div>
  <div class="dropdown-container">
    <prf-dropdown-with-search
      formControlName="municipality"
      [label]="'SHARED.MUNICIPALITIES' | translate"
      [options]="listedMunicipalities"
      [disabledInput]="isMunicipalityDisabled || answersMatrixPrepearing"
      [isOptional]="true"
    ></prf-dropdown-with-search>
  </div>
  <div class="create-event-row">
    <label class="section-text" for="regiondateFrom">Начальная дата диапазона:</label>
    <input
      matInput
      [matDatepicker]="regionPickerStart"
      formControlName="dateFrom"
      class="create-event-field date w-input"
      maxlength="256"
      placeholder="Date"
      name="dateFrom"
      data-name="dateFrom"
      id="regiondateFrom"
    />
    <mat-datepicker-toggle matSuffix [for]="regionPickerStart"></mat-datepicker-toggle>
    <mat-datepicker #regionPickerStart></mat-datepicker>
  </div>

  <div class="create-event-row">
    <label class="section-text" for="regiondateTo">Конечная дата диапазона <br />(если не указано, то текущее число):</label>
    <input
      matInput
      [matDatepicker]="regionPickerEnd"
      formControlName="dateTo"
      class="create-event-field date w-input"
      maxlength="256"
      placeholder="Date"
      name="dateTo"
      data-name="dateTo"
      id="regiondateTo"
    />
    <mat-datepicker-toggle matSuffix [for]="regionPickerEnd"></mat-datepicker-toggle>
    <mat-datepicker #regionPickerEnd></mat-datepicker>
  </div>

  <div class="section-row">
    <button
      type="button"
      class="button-animate btn-activate"
      [class.disabled]="!form.valid"
      [class.btn-waiting]="answersMatrixPrepearing"
      (click)="generateAnswersReportExcel()"
    >
      <span class="btn-icon"></span>
      <span class="btn-text" data-wait="Подождите" data-after="Получен">Получить</span>
    </button>
  </div>
</form>
