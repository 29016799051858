<div class="event-page-section create" *ngIf="university">
  <div class="edit-event-row">
    <div class="edit-event-column">
      <h1 class="event-h1 edit">{{ title }}</h1>
    </div>
    <div class="edit-event-column _2">
      <a class="delete-event w-inline-block" (click)="popUpConfirming = !popUpConfirming"></a>
    </div>
  </div>
  <div class="create-event-form w-form">
    <form [formGroup]="form">
      <h2 class="event-h2">Основная информация</h2>
      <div class="create-form-row">
        <div class="create-form-column _1">
          <label class="create-form-label" for="name">Название*</label>
        </div>
        <div class="create-form-column">
          <input
            type="text"
            class="create-event-field w-input"
            maxlength="256"
            name="name"
            data-name="name"
            [class.error-field]="submitted && f.name.errors"
            placeholder="Название"
            id="name"
            formControlName="name"
          />
          <ng-container *ngIf="submitted && f.name.errors">
            <mat-error *ngIf="f.name.errors.required">
              {{ 'SHARED.FORM.FILL_INPUT' | translate }}
            </mat-error>
          </ng-container>
        </div>
      </div>

      <div class="create-form-row">
        <div class="create-form-column _1">
          <label class="create-form-label" for="name">Специальности</label>
        </div>
        <div class="create-form-column">
          <mat-select formControlName="universitySpecialities" multiple>
            <mat-option *ngFor="let spec of specialities" [value]="spec">{{ spec.name }}</mat-option>
          </mat-select>
        </div>
      </div>

      <div class="edit-event-buttons-row">
        <input type="submit" (click)="submit()" value="{{ 'SHARED.SAVE_CHANGES' | translate }}" class="button green-btn w-button" />
        <a class="white-button edit-event w-button" routerLink="/admin/universities">
          {{ 'SHARED.EXIT_WITHOUT_SAVE' | translate }}
        </a>
      </div>
    </form>
    <div class="w-form-done">
      <div>Thank you! Your submission has been received!</div>
    </div>
    <div class="w-form-fail">
      <div>Oops! Something went wrong while submitting the form.</div>
    </div>
  </div>
</div>

<ng-container *ngIf="popUpConfirming">
  <div class="perform-task-lb lb show">
    <div class="lb-bg"></div>
    <div class="lb-block task-lb">
      <a class="close-lb w-inline-block" (click)="popUpConfirming = !popUpConfirming">
        <img src="./assets/images/icons/close-lb.svg" />
      </a>
      <div class="tasl-lb-row">
        <div class="task-lb-column _2">
          <h4 class="task-lb-heading-result">Удаление ВУЗа</h4>
          <div class="task-result-text">Вы уверены, что хотите удалить ВУЗ?</div>
          <a class="button attach-file-btn w-button" (click)="deleteUniversity(university.id)">{{ 'BUTTONS.DELETE' | translate }}</a>
        </div>
      </div>
    </div>
  </div>
</ng-container>
