<ng-template [ngIf]="courseId">
  <div class="ce-lesson">
    <div (click)="toggle()" class="ce-lesson-card">
      <div class="ce-lesson-card__details">
        <h3 *ngIf="title" class="ce-lesson-card__title ce-lesson-card_truncation">{{ title }}</h3>
        <span *ngIf="cardInfo" class="ce-lesson-card__info">{{ cardInfo }}</span>
      </div>
      <div class="ce-lesson-card__arrow" [ngClass]="{ 'ce-lesson-card__arrow_active': showLessons }"></div>
    </div>

    <div class="ce-lessons" [ngClass]="{ 'ce-lessons_active': showLessons }">
      <div class="ce-lessons__header">
        <span *ngIf="cardInfo" class="ce-lessons__header__title">{{ cardInfo }}</span>
        <div class="ce-lessons__header__materials">
          <span>конспекты</span>
          <span>материалы</span>
          <span>наличие прикрепленных заданий</span>
          <span>редактирование</span>
        </div>
      </div>
      <div *ngFor="let lesson of lessons" class="ce-lessons__body">
        <span class="ce-lessons__body__number">{{ lesson.number }}</span>
        <span class="ce-lessons__body__title">{{ lesson.name }}</span>
        <div class="ce-lessons__body__materials">
          <span>
            <a [href]="lesson.conspectUrl" aria-label="Открыть конспекты для урока" [class.ce-lessons__link_disabled]="!lesson.conspectUrl">
              открыть
            </a>
          </span>
          <span>
            <a [href]="lesson.yandexDiskMaterialsUrl" aria-label="Открыть материалы для урока"> открыть </a>
          </span>
          <a [routerLink]="'/admin/lessons'" [fragment]="lesson.id" [innerText]="lesson.hasLinkedTaskForPupil ? 'да' : 'нет'"></a>
          <span class="ce-lessons__tools">
            <prf-career-education-edit-lesson [lesson]="lesson" (updateLesson)="updatedLesson($event)"></prf-career-education-edit-lesson>
            <a
              class="delete-btn w-inline-block"
              (click)="popUpConfirming = !popUpConfirming; removedLessonId = lesson.id"
              aria-label="Удалить урок"
            ></a>
          </span>
        </div>
      </div>
    </div>
  </div>

  <ng-template [ngIf]="popUpConfirming">
    <div class="perform-task-lb lb show">
      <div class="lb-bg"></div>
      <div class="lb-block task-lb">
        <a class="close-lb w-inline-block" (click)="popUpConfirming = !popUpConfirming">
          <img src="./src/assets/images/icons/close-lb.svg" />
        </a>
        <div class="tasl-lb-row">
          <div class="task-lb-column _2">
            <h4 class="task-lb-heading-result">Удаление урока</h4>
            <div class="task-result-text">Вы уверены, что хотите удалить урок?</div>
            <a class="button attach-file-btn w-button" (click)="removeLesson()">{{ 'BUTTONS.DELETE' | translate }}</a>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
</ng-template>
