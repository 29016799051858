import { Injectable } from '@angular/core';
import { catchError, filter, map, switchMap, take, tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { UserDataHandlerService } from '../user-data-handler';
import { HttpErrorResponse } from '@angular/common/http';
import {
  ApiAuthService,
  ApiCoursesMaterialsService,
  ApiUsersService,
  IGetSchoolClassResponse,
  IIsEmailFreeResponse,
  ResultsTestService,
} from '../../api-services';
import { StorageKeys, UserActionsService, WebStorageService, YmItems } from '../../logic-services';
import { IUserProfileInfo } from '../../core';
import { AppSettingsService } from '../app-settings';
import { getKevinSoftDomainForEurochem } from '../../../../../profilum-configurations/tools/tools';

@Injectable({
  providedIn: 'root',
})
export class AuthHandlerService {
  private userInfo: IUserProfileInfo;
  public isLoading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private showGreetingOnboarding: boolean = false;

  private loginRoutes: object = {
    admin: '/admin/schools',
    adminDO: '/adminDO/courses/all-courses',
    employer: '/employer/courses/all-courses',
    schooladmin: '/schooladmin/classes',
    director: '/director',
    teacher: '/teacher',
    pupil: '/pupil',
    cityadmin: '/cityadmin',
    parent: '/parent',
  };

  constructor(
    private apiAuthService: ApiAuthService,
    private webStorageService: WebStorageService,
    private router: Router,
    private userDataHandlerService: UserDataHandlerService,
    private apiUsersService: ApiUsersService,
    private apiCoursesMaterialsService: ApiCoursesMaterialsService,
    private userActionsService: UserActionsService,
    private resultTest: ResultsTestService,
  ) {}

  // public register() {
  //   // this.apiAuthService.login()
  // }

  public login(login: string, password: string, rememberMe?: boolean): void {
    this.apiAuthService
      .login(login, password, rememberMe)
      .pipe(
        tap(response => {
          if (!response.succeeded) {
            // this.passFailed = true;
            this.webStorageService.set(StorageKeys.LoginFailed, true);
            return throwError(null);
          } else if (response.tag === 'b2c') {
            // редирект на b2c
            document.location.href = AppSettingsService.settings.b2cRedirectUrl;
          } else {
            this.navigateToKevinSoftDomain();
            this.showGreetingOnboarding = this.webStorageService.get(StorageKeys.ShowGreetingOnboarding);
            this.webStorageService.clearAll();
            this.setUserProfile(response);
          }
        }),
        switchMap(response => {
          if (response.succeeded) {
            console.log(response);
            this.userDataHandlerService.updateUserInfo();
            this.webStorageService.set(StorageKeys.ShowSuspensionMessage, true);
            if (this.userInfo.role !== 'pupil') {
              this.navigateByUserRole();
              return of(null);
            } else {
              return this.apiUsersService.getCurrentStepPupils().pipe(
                filter(el => !!el),
                switchMap(welcomeStep => {
                  this.webStorageService.set(StorageKeys.WelcomeStep, welcomeStep);
                  return this.apiAuthService.getLastSession(response.userId);
                }),
                tap((session: any) => {
                  const sessionId = session.status == 'Success' || 'Uncompleted test' ? session.sessionId : undefined;
                  console.log(session);
                  console.log(sessionId);
                  if (sessionId) {
                    this.webStorageService.set(StorageKeys.TestSessionId, sessionId);
                    this.webStorageService.set(StorageKeys.SessionStatus, session.status);
                    this.navigateToPupilLk();
                  } else if (session.status == 'Untested user') {
                    this.router.navigate(['/test-na-professiyu']);
                  }
                }),
              );
            }
          } else {
            return of(null);
          }
        }),
      )
      .subscribe();
  }

  private navigateToKevinSoftDomain() {
    const currentUrl = new URL(window.location.href);
    const returnUrl = currentUrl.searchParams.get('returnUrl');

    if (returnUrl) {
      try {
        const kevinSoftDomain = getKevinSoftDomainForEurochem();
        const kevinSoftUrl = new URL(returnUrl);

        if (`${kevinSoftUrl.protocol}//${kevinSoftUrl.hostname}` === kevinSoftDomain) {
          window.location.href = returnUrl;
        }
      } catch (error) {
        console.error('error on redirect to returnUrl');
      }
    }
  }

  private navigateByUserRole(): void {
    this.router.navigate([this.loginRoutes[this.userInfo.role]]);
  }

  public navigateToPupilLk() {
    const schoolClassId: string = this.webStorageService.get(StorageKeys.SchoolClassId);
    const schoolClassId$ = schoolClassId
      ? of(schoolClassId)
      : this.apiUsersService.getSchoolClassPupils().pipe(
          map((schoolClass: IGetSchoolClassResponse) => {
            console.log(schoolClass);
            this.webStorageService.set(StorageKeys.TeacherId, schoolClass.schoolClasses[0]?.teacherId ?? null);

            return schoolClass.schoolClasses[0]?.id ?? null;
          }),
        );

    // хардкод при регистрации нового юзера
    // this.webStorageService.set(StorageKeys.TestSessionId, '00000000-0000-0000-0000-000000000000');
    // this.webStorageService.set(StorageKeys.SessionStatus, 'Untested user');

    return schoolClassId$
      .pipe(
        switchMap((classId: string) => {
          console.log(classId);
          this.webStorageService.set(StorageKeys.SchoolClassId, classId);

          return this.apiCoursesMaterialsService.getCourseByClass(classId);
        }),
        switchMap((coursesIds: any) => {
          const assignedCourseExists = !!coursesIds.courseIds.length;

          this.webStorageService.set(StorageKeys.AssignedCourseExists, assignedCourseExists.toString());
          this.webStorageService.set(StorageKeys.CoursesIds, coursesIds[0]);
          const isKruzhkiForm = location.origin.includes('kruzhki');
          if (assignedCourseExists && !isKruzhkiForm && this.showGreetingOnboarding) {
            this.userActionsService.log(YmItems.Pu_Reg_CreatePas_ClickEnterButton);
            this.router.navigate(['/pupil-welcome']);
            return of(null);
          } else if (isKruzhkiForm) {
            this.webStorageService.set(StorageKeys.IsRedirectAfterRegistrationOnKruzhki, true);
          }
          this.resultTest.setRegionTestInfo();
          return of(this.router.navigate(['/pupil']));
        }),
        catchError(() => {
          this.webStorageService.set(StorageKeys.AssignedCourseExists, false);

          return of(true);
        }),
      )
      .toPromise();
  }

  public setUserProfile(userProfileInfo: any): void {
    if (!userProfileInfo) {
      throw new Error('Login result is not defined');
    }
    this.userInfo = userProfileInfo;
    this.webStorageService.set(StorageKeys.UserProfileInfo, userProfileInfo);
  }

  public checkPhoneAvailability(phone: string): Observable<any> {
    return this.apiAuthService.checkPhoneAvailability(phone).pipe(take(1));
  }

  public checkEmailAvailability(email: string): Observable<IIsEmailFreeResponse> {
    return this.apiAuthService.checkEmailAvailability(email).pipe(take(1));
  }

  public checkCookie(): void {
    this.apiAuthService
      .checkCookie()
      .pipe(
        take(1),
        tap(
          (response: any) => {
            if (response?.status.includes('Unregistered')) {
              return;
            }
            if (response?.status !== 'Success') {
              this.logout();
            }
          },
          (err: HttpErrorResponse) => {
            if (err.status >= 500 && err.status < 600) {
              this.logout();
            }
          },
        ),
      )
      .subscribe();
  }

  public logout(): void {
    this.apiAuthService
      .logout()
      .pipe(take(1))
      .subscribe(() => {
        this.webStorageService.clearAll();
        location.reload();
        this.router.navigate([`/`]);
      });
  }
}
