import { Injectable } from '@angular/core';
import { HttpService } from 'app/core/services/http.service';
import { IRegion } from 'app/shared/interfaces/iregion';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { Helper } from 'app/shared/common/helpers/helper';
import { ILinkedCourseRegion, IRegionCourses, IRegionCourseStatus } from './regions.interface';
import { IRegionsResponse } from '../admin.interface';

@Injectable()
export class RegionsService {
  constructor(private http: HttpService) {}

  public getAllRegions(): Observable<IRegion[]> {
    return this.http.get('/api/v1.0/catalog/regions/all').pipe(
      map((r: IRegionsResponse) => r.regions),
      catchError((err: HttpErrorResponse) => Helper.handleError(err)),
    );
  }

  getRegion(regionId): Observable<any> {
    return this.http.get('/api/v1.0/catalog/regions/one?regionId=' + regionId).pipe(
      map(r => r.region),
      catchError((err: HttpErrorResponse) => Helper.handleError(err)),
    );
  }

  updateRegion(region: IRegion): Observable<any> {
    return this.http.put('/api/v1.0/catalog/regions', region).pipe(
      map(r => r),
      catchError((err: HttpErrorResponse) => Helper.handleError(err)),
    );
  }

  deleteRegion(regionId): Observable<any> {
    return this.http.delete('/api/v1.0/catalog/regions?regionId=' + regionId).pipe(
      map(r => r),
      catchError((err: HttpErrorResponse) => Helper.handleError(err)),
    );
  }

  createRegion(regionObj): Observable<any> {
    return this.http.post('/api/v1.0/catalog/regions', regionObj).pipe(
      map(r => r),
      catchError((err: HttpErrorResponse) => Helper.handleError(err)),
    );
  }

  getRegionCourse(regionId: string, courseId: string): Observable<ILinkedCourseRegion> {
    return this.http.get(`/api/v1.0/access/regioncourses/getregioncourse?regionId=${regionId}&courseId=${courseId}`).pipe(
      map(r => r),
      catchError((err: HttpErrorResponse) => Helper.handleError(err)),
    );
  }

  getRegionCoursesByRegionId(regionId: string): Observable<IRegionCourses[]> {
    return this.http.get(`/api/v1.0/access/regioncourses/getregioncoursesbyregionid?regionId=${regionId}`).pipe(
      map(r => r?.regionCourses ?? []),
      catchError((err: HttpErrorResponse) => Helper.handleError(err)),
    );
  }

  deleteRegionCourse(regionId: string, courseId: string): Observable<IRegionCourseStatus> {
    return this.http.delete(`/api/v1.0/access/regioncourses/deleteregioncourse?regionId=${regionId}&courseId=${courseId}`).pipe(
      map(r => r),
      catchError((err: HttpErrorResponse) => Helper.handleError(err)),
    );
  }

  createRegionCourse(regionCurse: IRegionCourses): Observable<IRegionCourseStatus> {
    return this.http.post(`/api/v1.0/access/regioncourses/createregioncourse`, regionCurse).pipe(
      map(r => r),
      catchError((err: HttpErrorResponse) => Helper.handleError(err)),
    );
  }
}
