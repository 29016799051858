import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { KeyValue } from '@angular/common';

export interface IButterflyControlElement {
  id: string;
  name: string;
  isSaved: boolean;
  text?: string;
  isHighlighted?: boolean;
  regionId?: string;
}

@Component({
  selector: 'prf-butterfly-control',
  templateUrl: './butterfly-control.component.html',
  styleUrls: ['./butterfly-control.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ButterflyControlComponent implements AfterViewInit {
  @Output() chosenElements: EventEmitter<IButterflyControlElement[]> = new EventEmitter();
  @ViewChild('column1') column1: ElementRef;
  @ViewChild('column2') column2: ElementRef;
  public allMappedElements: Map<string, IButterflyControlElement> = new Map();
  public elementsInColumn1: Map<string, IButterflyControlElement> = new Map();
  public elementsInColumn2: Map<string, IButterflyControlElement> = new Map();
  private lastClickedElementsInColumn1: Map<string, IButterflyControlElement> = new Map();
  private lastClickedElementsInColumn2: Map<string, IButterflyControlElement> = new Map();
  private column1HtmlElement: HTMLElement;
  private column2HtmlElement: HTMLElement;
  @Input() set allElements(allElements: IButterflyControlElement[]) {
    if (allElements?.length) {
      this.allMappedElements.clear();
      this.elementsInColumn2.clear();
      this.elementsInColumn1.clear();

      allElements.forEach(element => {
        this.allMappedElements.set(element.id, { ...element });
        element.isSaved
          ? this.elementsInColumn2.set(element.id, this.allMappedElements.get(element.id))
          : this.elementsInColumn1.set(element.id, this.allMappedElements.get(element.id));
      });
    }
  }

  public ngAfterViewInit(): void {
    this.column1HtmlElement = this.column1.nativeElement;
    this.column2HtmlElement = this.column2.nativeElement;
  }

  public highlightCol1Elements(e: Event): void {
    this.highlightElements(e, true);
  }

  public highlightCol2Elements(e: Event): void {
    this.highlightElements(e, false);
  }

  public clickRightArrow(): void {
    this.moveElements(false);
    this.chosenElements.emit(Array.from(this.elementsInColumn2.values()));

    if (this.column2HtmlElement) {
      setTimeout(() => {
        this.column2HtmlElement.scrollTop = this.column2HtmlElement.scrollHeight;
      }, 0);
    }
  }

  public clickLeftArrow(): void {
    this.moveElements(true);
    this.chosenElements.emit(Array.from(this.elementsInColumn2.values()));

    if (this.column1HtmlElement) {
      setTimeout(() => {
        this.column1HtmlElement.scrollTop = this.column1HtmlElement.scrollHeight;
      }, 0);
    }
  }

  public originalOrder = (a: KeyValue<string, IButterflyControlElement>, b: KeyValue<string, IButterflyControlElement>): number => {
    return 0;
  };

  private moveElements(toColumn1: boolean): void {
    const fromElements = toColumn1 ? this.elementsInColumn2 : this.elementsInColumn1;
    const toElements = toColumn1 ? this.elementsInColumn1 : this.elementsInColumn2;
    const fromLastClickedElements = toColumn1 ? this.lastClickedElementsInColumn2 : this.lastClickedElementsInColumn1;
    const toLastClickedElements = toColumn1 ? this.lastClickedElementsInColumn1 : this.lastClickedElementsInColumn2;
    const elementsArray = Array.from(fromElements.values()).filter(element => element.isHighlighted);

    if (!elementsArray.length) {
      return;
    }

    toElements.forEach(element => (element.isHighlighted = false));

    elementsArray.forEach(element => {
      fromElements.delete(element.id);
      toElements.set(element.id, element);
    });

    toLastClickedElements.clear();
    fromLastClickedElements.forEach(lastClickedElement => {
      toLastClickedElements.set(lastClickedElement.id, lastClickedElement);
    });
  }

  private highlightElements(event: Event, isColumn1: boolean): void {
    const idStr = (event.target as HTMLElement).id || (event.target as HTMLElement).parentElement.id;
    const id = idStr?.split(':')[1];
    const elements = isColumn1 ? this.elementsInColumn1 : this.elementsInColumn2;

    if (id && elements.size > 0) {
      switch (true) {
        case (event as KeyboardEvent).shiftKey:
          this.pressShiftKey(id, isColumn1);
          break;
        case (event as KeyboardEvent).ctrlKey:
          this.pressCtrlKey(id, isColumn1);
          break;
        default:
          this.pressMouseLeftButton(id, isColumn1);
          break;
      }
    }
  }

  private pressMouseLeftButton(id: string, isColumn1: boolean): void {
    const elements = isColumn1 ? this.elementsInColumn1 : this.elementsInColumn2;
    const lastClickedElements = isColumn1 ? this.lastClickedElementsInColumn1 : this.lastClickedElementsInColumn2;
    const element = elements.get(id);

    elements.forEach(element => (element.isHighlighted = false));
    lastClickedElements.clear();
    lastClickedElements.set(id, element);
    element.isHighlighted = true;
  }

  private pressCtrlKey(id: string, isColumn1: boolean): void {
    const elements = isColumn1 ? this.elementsInColumn1 : this.elementsInColumn2;
    const lastClickedElements = isColumn1 ? this.lastClickedElementsInColumn1 : this.lastClickedElementsInColumn2;
    const element = elements.get(id);

    lastClickedElements.has(id) ? lastClickedElements.delete(id) : lastClickedElements.set(id, element);
    element.isHighlighted = !element.isHighlighted;
    lastClickedElements.set(id, element);
  }

  private pressShiftKey(id: string, isColumn1: boolean): void {
    const lastClickedElements = isColumn1 ? this.lastClickedElementsInColumn1 : this.lastClickedElementsInColumn2;
    const elements = isColumn1 ? this.elementsInColumn1 : this.elementsInColumn2;
    const elementsArray = Array.from(elements.values());
    const element = elements.get(id);
    const lastClickedElementsArray = Array.from(lastClickedElements.values());
    const lastClickedElement = lastClickedElementsArray[lastClickedElementsArray.length - 1];

    let lastClickedElementIndex;
    let elementIndex;

    for (let i = 0; i < elementsArray.length; i++) {
      lastClickedElementIndex = elementsArray[i].id === lastClickedElement.id ? i : lastClickedElementIndex;
      elementIndex = elementsArray[i].id === id ? i : elementIndex;

      if (lastClickedElementIndex >= 0 && elementIndex >= 0) {
        break;
      }
    }

    if (!(lastClickedElementIndex >= 0) || lastClickedElementIndex === elementIndex) {
      element.isHighlighted = true;
      lastClickedElements.clear();
      lastClickedElements.set(element.id, element);
      return;
    }

    const rangeOfHighlightedElements =
      lastClickedElementIndex < elementIndex
        ? elementsArray.slice(lastClickedElementIndex, elementIndex + 1)
        : elementsArray.slice(elementIndex, lastClickedElementIndex);

    rangeOfHighlightedElements.forEach((element, index) => {
      elements.get(element.id).isHighlighted = true;
    });
  }
}
