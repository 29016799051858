import { ChangeDetectionStrategy, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Meta } from '@angular/platform-browser';

import { Router } from '@angular/router';
import { ROUTE_ANIMATIONS_ELEMENTS, routeAnimations } from 'app/core';
import { AppSettingsService } from 'app/shared/services/appsettings.service';
import { Swiper } from 'swiper';
import { SwiperOptions } from 'swiper/types/swiper-options';

export const PAGE_SIZE: number = 1;

@Component({
  selector: 'prf-landing-base',
  templateUrl: './landing-base.component.html',
  styleUrls: ['./landing-base.component.scss'],
  animations: [routeAnimations],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LandingBaseComponent implements OnInit {
  routeAnimationsElements = ROUTE_ANIMATIONS_ELEMENTS;
  public swiperConfig: SwiperOptions = {
    slidesPerView: PAGE_SIZE,
    slidesPerGroup: PAGE_SIZE,
    speed: 500,
  };
  public hideButtonPlay: boolean = true;
  public type: string = 'component';
  public swiperIndex: number = 0;
  public showPopUp: boolean = false;
  // @ViewChild(SwiperDirective) directiveRef?: SwiperDirective;
  @ViewChild('swiperComponent') swiperComponent?: ElementRef;
  public swiper?: Swiper;
  @ViewChild('videoPlayer') videoplayer: any;
  logo: boolean = false;

  public nowDate = new Date();

  constructor(private meta: Meta, private router: Router) {
    this.meta.addTag({ name: 'og:title', content: 'Профилум' });
  }

  ngOnInit() {}

  public swiperIndexChange(index: number): void {
    this.swiperIndex = index;
  }

  public toggleOverlayControls(goto): void {
    // if (this.type === 'directive') {
    // this.directiveRef.setIndex(goto);
    // this.swiper.slideTo(goto);
    // } else if (this.type === 'component' && this.swiperComponent && this.swiperComponent.directiveRef) {
    //   this.swiperComponent.directiveRef.setIndex(goto);
    // }
    this.swiper.slideTo(goto);
    // this.swiperComponent.directiveRef.setIndex(goto);
  }

  toggleVideo() {
    if (this.hideButtonPlay) {
      this.videoplayer.nativeElement.play();
    } else {
      this.videoplayer.nativeElement.pause();
    }
    this.hideButtonPlay = !this.hideButtonPlay;
  }

  getDocsRoute(): string {
    return AppSettingsService.settings.docsPath.docsPathDefault;
  }
}
