import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ResultsService } from 'app/shared/services/results.service';
import { SharedModule } from 'app/shared/shared.module';
import { DashboardModule } from 'app/shared/dashboard/dashboard.module';
import { CommonModule } from '@angular/common';
import { provideCharts, withDefaultRegisterables } from 'ng2-charts';

@NgModule({
  declarations: [],
  imports: [CommonModule, RouterModule, SharedModule, DashboardModule],
  providers: [
    provideCharts(withDefaultRegisterables()),
    ResultsService
  ],
})
export class ResultsModule {}
