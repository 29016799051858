<div class="user-data-form-block w-form">
  <form id="wf-form-User-Data" name="wf-form-User-Data" data-name="User Data" [formGroup]="form" autocomplete="off">
    <div class="change-name-div">
      <div class="change-name-text" *ngIf="userRole === 'pupil'">
        Чтобы изменить ФИО обратитесь к своему классному руководителю или родителю
      </div>
    </div>

    <div class="profile-data-row">
      <div>
        <label for="lastName" class="user-data-label">{{ 'SHARED.FORM.FAMILY' | translate }}:</label>
        <div class="div-space"></div>
      </div>
      <div class="profile-field-div">
        <input
          type="text"
          class="user-data-field w-input"
          [class.error-field]="submitted && f.lastName.errors"
          name="lastName"
          data-name="lastName"
          id="lastName"
          formControlName="lastName"
          autocomplete="off"
        />
      </div>
    </div>

    <div class="profile-data-row">
      <div>
        <label for="firstName" class="user-data-label">{{ 'SHARED.FORM.NAME' | translate }}:</label>
        <div class="div-space"></div>
      </div>
      <div class="profile-field-div">
        <input
          type="text"
          class="user-data-field w-input"
          [class.error-field]="submitted && f.firstName.errors"
          name="firstName"
          data-name="firstName"
          id="firstName"
          formControlName="firstName"
          autocomplete="off"
        />
      </div>
    </div>

    <!-- <div class="profile-data-row">
      <div>
        <label for="middleName" class="user-data-label"
          >{{ 'SHARED.FORM.MIDDLENAME' | translate }}:</label
        >
        <div class="div-space"></div>
      </div>
      <div class="profile-field-div">
        <input
          type="text"
          autocomplete="off"
          class="user-data-field w-input"
          [class.error-field]="submitted && f.middleName.errors"
          name="middleName"
          data-name="middleName"
          id="middleName"
          formControlName="middleName"
        />
      </div>
    </div> -->

    <div class="profile-data-row">
      <div>
        <label for="Phone" class="user-data-label">{{ 'SHARED.FORM.PHONE' | translate }}:</label>
        <div class="div-space"></div>
      </div>
      <div class="profile-field-div">
        <input
          type="text"
          class="user-data-field w-input"
          [class.error-field]="submitted && f.phone.errors"
          name="Phone"
          data-name="Phone"
          id="Phone"
          autocomplete="off"
          formControlName="phone"
          [textMask]="{ mask: phoneMask }"
        />
        <ng-container *ngIf="submitted && f.phone.errors">
          <mat-error *ngIf="f.phone.errors.pattern"> Номер телефона введен неверно </mat-error>
        </ng-container>
      </div>
    </div>
    <div class="div-space-2"></div>
    <div class="profile-data-row">
      <div>
        <label for="Email" class="user-data-label">Email:</label>
        <div class="div-space"></div>
      </div>
      <div class="profile-field-div">
        <input
          type="text"
          class="user-data-field w-input"
          [class.error-field]="submitted && f.email.errors"
          name="Email"
          data-name="Email"
          formControlName="email"
          id="Email"
          autocomplete="off"
        />
        <!-- <ng-container *ngIf="submitted && f.email.errors"> -->
        <ng-container *ngIf="submitted">
          <mat-error *ngIf="f.email.errors && f.email.errors.required"> Электронная почта не можеты быть пустой </mat-error>
          <mat-error *ngIf="f.email.errors && f.email.errors.pattern && f.email.dirty && !f.email.errors.required">
            Пожалуйста, введите корректный адрес электронной почты
          </mat-error>
          <mat-error *ngIf="submitted && duplicateUserName">
            {{ 'REGISTRATION_PUPIL_KZ.FORM_ALERT.EMAIL_EXIST' | translate }}
          </mat-error>
        </ng-container>
      </div>
    </div>

    <div class="div-space-2"></div>

    <div class="profile-data-row">
      <div>
        <label for="date" class="user-data-label">Дата рождения:</label>
        <div class="div-space"></div>
      </div>
      <div class="profile-field-div">
        <input
          type="text"
          class="user-data-field w-input"
          [class.error-field]="submitted && f.date.errors"
          [textMask]="{ mask: dateMask }"
          name="date"
          placeholder="Дата не определена"
          data-name="date"
          id="date"
          formControlName="date"
          autocomplete="off"
        />
        <ng-container>
          <mat-error *ngIf="submitted && f.date.errors">
            {{ 'REGISTRATION_PUPIL_KZ.FORM_ALERT.CHECK_DATA' | translate }}
          </mat-error>
        </ng-container>
      </div>
    </div>

    <div class="profile-data-row gender-choice">
      <div class="gender-choice-row">
        <label class="login-label">Пол:</label>
        <div class="div-space"></div>
      </div>
      <prf-gender-toggle formControlName="gender"></prf-gender-toggle>
    </div>

    <div class="div-space-2"></div>
    <div class="profile-data-row">
      <div class="div-space-3"></div>
      <div class="edit-profile-data-buttons">
        <a (click)="submitChanges()" class="button save-profile-changes-button w-button">Сохранить</a>
        <a class="white-button w-button" (click)="cancelEdit(); changeIndex(animIndexToChild, true)">Отменить</a>
      </div>
    </div>
  </form>
</div>
