<section class="system">
  <div class="admin-section">
    <div class="tabs w-tabs">
      <div class="tabs-menu w-tab-menu">
        <a
          (click)="tabLevel = 1"
          [class.w--current]="tabLevel === 1"
          [routerLink]="['./reports']"
          class="tab-link adm w-inline-block w-tab-link"
        >
          <div>Отчеты</div>
          <ng-container *ngIf="tabLevel === 1">
            <img src="./assets/images/courses/angle.png" width="20" class="angle" />
          </ng-container>
        </a>
        <a
          (click)="tabLevel = 2"
          [class.w--current]="tabLevel === 2"
          [routerLink]="['./statistics']"
          class="tab-link adm w-inline-block w-tab-link"
        >
          <div>Статистика</div>
          <ng-container *ngIf="tabLevel === 2">
            <img src="./assets/images/courses/angle.png" width="20" class="angle" />
            <img src="./assets/images/courses/angle2.png" width="20" class="angle _2" />
          </ng-container>
        </a>
        <a
          (click)="tabLevel = 3"
          [class.w--current]="tabLevel === 3"
          [routerLink]="['./dashboard']"
          class="tab-link adm w-inline-block w-tab-link"
        >
          <div>Дашборд</div>
          <ng-container *ngIf="tabLevel === 3">
            <img src="./assets/images/courses/angle2.png" width="20" class="angle _2" />
          </ng-container>
        </a>
        <a
          (click)="tabLevel = 4"
          [class.w--current]="tabLevel === 4"
          [routerLink]="['./tests-dashboard']"
          class="tab-link adm w-inline-block w-tab-link"
        >
          <div>Статусы тестов</div>
          <ng-container *ngIf="tabLevel === 4">
            <img src="./assets/images/courses/angle2.png" width="20" class="angle _2" />
          </ng-container>
        </a>

        <a
          (click)="tabLevel = 5"
          [class.w--current]="tabLevel === 5"
          [routerLink]="['./tests-matrix']"
          class="tab-link adm w-inline-block w-tab-link"
        >
          <div>Матрица ответов</div>
          <ng-container *ngIf="tabLevel === 5">
            <img src="./assets/images/courses/angle2.png" width="20" class="angle _2" />
          </ng-container>
        </a>
      </div>

      <div class="tabs-content w-tab-content">
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
</section>
