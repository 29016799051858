import { Component } from '@angular/core';
import { Meta } from '@angular/platform-browser';

@Component({
  selector: 'prf-events-page',
  templateUrl: './events-page.component.html',
  styleUrls: ['./events-page.component.scss'],
})
export class EventsPageComponent {
  public tabName: string = 'catalog';

  constructor(private meta: Meta) {
    this.meta.updateTag({ name: 'og:title', content: 'Мероприятия' });
  }
}
