import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TestsDashboardComponent } from 'app/shared/common-components/tests-dashboard/tests-dashboard.component';

const routes = [
  {
    path: '',
    component: TestsDashboardComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class TestsDashboardRoutingModule {}
