import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import {
  CareerEducationCoursesService,
} from 'app/pages/catalog/career-education-courses-page/career-education-courses.service';
import { map, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { UtilsService } from 'app/shared/dashboard/backend-services/utils.service';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import {
  ICourseMaterial,
  IGetCourseMaterial,
} from 'app/pages/catalog/career-education-courses-page/interfaces/career-education-courses.interface';
import { Meta } from '@angular/platform-browser';
import { PageHeaderService } from '../../../../shared/dashboard/page-header/page-header.service';
import { UnsubscribeComponent } from '@profilum-components/unsubscribe/unsubscribe.component';

@Component({
  selector: 'prf-career-education-course-details',
  templateUrl: './career-education-course-details.component.html',
  styleUrls: ['./career-education-course-details.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CareerEducationCourseDetailsComponent extends UnsubscribeComponent implements OnInit, OnDestroy {
  public course: ICourseMaterial;
  public isLoading: boolean = true;
  public popUpConfirming: boolean = false;
  public adminLevel: string;
  private title: string = 'Курс карьерной грамотности';
  private courseId: string;
  private readonly userRole: string;
  private readonly backButtonUrl: string = '/admin/career-education';
  private readonly backButtonName: string = 'Все курсы';

  constructor(
    private careerEducationCoursesService: CareerEducationCoursesService,
    private utilsService: UtilsService,
    private router: Router,
    private translateService: TranslateService,
    private meta: Meta,
    private activatedRoute: ActivatedRoute,
    private changeDetectorRef: ChangeDetectorRef,
    private pageHeaderService: PageHeaderService,
  ) {
    super();
    this.userRole = window.localStorage.getItem('userRole');
    if (this.userRole.includes('admin')) {
      this.adminLevel = window.localStorage.getItem('adminLevel');
    }
    this.meta.updateTag({ name: 'og:title', content: 'Курс карьерной грамотности' });
  }

  public ngOnInit(): void {
    this.activatedRoute.params
      .pipe(
        switchMap(params => {
          this.courseId = params['courseId'];

          if (!this.courseId) {
            this.error();
            return;
          }

          return this.careerEducationCoursesService.getCourseMaterial(this.courseId);
        }),
        tap(resp => {
          if (resp?.status !== 'Success') {
            throw new Error('Bad request');
          }
        }),
        map(resp => this.careerEducationCoursesService.parseLessons(resp)),
        takeUntil(this.unsubscribe),
      )
      .subscribe(
        course => {
          this.course = course.courseMaterial;
          this.isLoading = false;
          this.changeDetectorRef.detectChanges();
        },
        _ => {
          this.isLoading = false;
          this.error();
        },
      );

    this.pageHeaderService.setBackButtonData(this.backButtonUrl, this.backButtonName);
    this.pageHeaderService.setTitle(this.title);
  }

  public updateCourse(course: IGetCourseMaterial): void {
    this.course = course.courseMaterial;
    this.changeDetectorRef.detectChanges();
  }

  public removeCourse(): void {
    this.careerEducationCoursesService
      .deleteCourseMaterial(this.course.id)
      .pipe(take(1))
      .subscribe(
        resp => {
          if (resp?.status === 'Success') {
            this.utilsService.openSnackBar(`👌 Курс успешно удален`, 'success');
            this.closePopup();
          } else {
            this.utilsService.openSnackBar('Ошибка удаления курса', 'error');
            this.closePopup();
          }
        },
        () => {
          this.closePopup();
          this.utilsService.openSnackBar('Ошибка удаления курса', 'error');
        },
      );
  }

  public removeSection(sectionName: string): void {
    const index = this.course.parsedLessons.findIndex(lesson => lesson[0] === sectionName);

    this.course.parsedLessons.splice(index, 1);
    this.changeDetectorRef.detectChanges();
  }

  private error = (): void => {
    this.getTranslation('SHARED.ERROR_MSG')
      .pipe(
        tap((translation: string) => {
          this.utilsService.openSnackBar(translation, 'error');
        }),
      )
      .subscribe(() => {
        this.timer = setTimeout(_ => {
          this.router.navigate([`/admin/career-education`]);
        }, 2000);
      });
  };

  private getTranslation(key: string): Observable<any> {
    return this.translateService.get(key);
  }

  private closePopup(): void {
    this.popUpConfirming = false;
    this.router.navigate([`/admin/career-education`]);
  }

  public ngOnDestroy(): void {
    super.ngOnDestroy();
    this.pageHeaderService.setTitle(null);
    this.pageHeaderService.setBackButtonData(null, null);
  }
}
