import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgForm } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { UtilsService } from 'app/shared/dashboard/backend-services/utils.service';
import { AdminAccessLevel } from 'app/shared/enums/admins.enums';

import { ICity } from 'app/shared/interfaces/icity';
import { IMunicipality } from 'app/shared/interfaces/imunicipality';
import { IRegion } from 'app/shared/interfaces/iregion';
import { Observable, of, Subject } from 'rxjs';
import { switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { ISchool } from '../../../../../shared/interfaces/ischool.interface';
import { AdminPanelService } from '../../admin-panel.service';
import { CitiesService } from '../../cities/cities.service';
import { MunicipalitiesService } from '../../municipalities/municipalities.service';
import { RegionsService } from '../../regions/regions.service';

@Component({
  selector: 'prf-add-school',
  templateUrl: './add-school.component.html',
  styleUrls: ['./add-school.component.scss'],
})
export class AddSchoolComponent implements OnInit {
  @Output() updateValue = new EventEmitter<string>();

  @Input() set inputRegions(regions) {
    this.allRegions = regions;
    this.loadData().pipe(takeUntil(this.ngUnsubscribe$)).subscribe();
  }

  adminLevel: any;
  adminAccessLevel: any = AdminAccessLevel;
  dialogOpen: boolean = false;
  error: boolean = false;

  school: ISchool = {
    id: undefined,
    number: '',
    city: '',
    cityId: '',
    address: '',
    type: '',
    other: '',
    imageUrl: '',
    externalObjectId: '',
    externalServiceName: '',
    isDeleted: false,
    createdDate: '',
  };

  public allRegions: IRegion[];
  public currentRegion: IRegion = {
    id: '',
    hrid: '',
    name: '',
  };

  public municipalitiesByRegion: IMunicipality[] = [];
  public currentMunicipality: IMunicipality = {
    id: '',
    hrid: '',
    name: '',
    regionId: '',
  };

  public citiesByMunicipality: ICity[] = [];
  public currentCity: ICity = {
    id: '',
    hrid: '',
    name: '',
    region: '',
    regionId: '',
    municipality: '',
    municipalityId: '',
  };

  types: string[] = ['Государственная школа', 'Частная школа'];

  private ngUnsubscribe$: Subject<any> = new Subject();

  constructor(
    private adminPanelService: AdminPanelService,
    private regionsService: RegionsService,
    private municipalitiesService: MunicipalitiesService,
    private citiesService: CitiesService,
    private utilsService: UtilsService,
    private translateService: TranslateService,
  ) {}

  ngOnInit() {}

  loadData() {
    this.adminLevel = localStorage.getItem('adminLevel');
    let userRegionId = localStorage.getItem('regionId');
    let userMunicipalityId = localStorage.getItem('municipalityId');
    let userRegion: any;
    let userMunicipality: any;
    let currentObservable$: Observable<any> = of(null);

    switch (this.adminLevel) {
      case AdminAccessLevel.GLOBAL:
        break;
      case AdminAccessLevel.REGION:
        {
          userRegion = this.allRegions.find(region => region.id === userRegionId);
          currentObservable$ = this.setRegion(userRegion);
        }
        break;
      case AdminAccessLevel.MUNICIPALITY:
        {
          userRegion = this.allRegions.find(region => region.id === userRegionId);

          currentObservable$ = this.setRegion(userRegion).pipe(
            switchMap(r => {
              userMunicipality = this.municipalitiesByRegion.find(r => r.regionId == userRegionId && r.id === userMunicipalityId);
              return this.setMunicipality(userMunicipality);
            }),
          );
        }
        break;
    }
    return currentObservable$;
  }

  selectRegion(region) {
    this.setRegion(region).pipe(takeUntil(this.ngUnsubscribe$)).subscribe();
  }

  setRegion(region): Observable<any> {
    this.currentRegion = region;
    if (region) {
      this.clearCurrentMunicipality();
      this.clearCurrentCity();
      return this.municipalitiesService.getMunicipalitiesByRegion(region.id).pipe(
        tap(r => {
          this.municipalitiesByRegion = r;
        }),
      );
    } else {
      return of(null);
    }
  }

  selectMunicipality(municipality) {
    this.setMunicipality(municipality).pipe(takeUntil(this.ngUnsubscribe$)).subscribe();
  }

  setMunicipality(municipality): Observable<any> {
    if (municipality) {
      this.currentMunicipality = municipality;
      this.clearCurrentCity();
      return this.citiesService.getAllCitiesByMunicipality(municipality.id).pipe(
        tap(r => {
          this.citiesByMunicipality = r;
        }),
      );
    } else {
      return of(null);
    }
  }

  clearCurrentMunicipality() {
    this.currentMunicipality = {
      id: '',
      hrid: '',
      name: '',
      regionId: '',
    };
    return;
  }

  clearCurrentCity() {
    this.currentCity = {
      id: '',
      hrid: '',
      name: '',
      region: '',
      regionId: '',
      municipality: '',
      municipalityId: '',
    };
    return;
  }

  clearTerritoryData() {
    this.currentRegion = {
      id: '',
      hrid: '',
      name: '',
    };
    this.municipalitiesByRegion = [];
    this.currentMunicipality = {
      id: '',
      hrid: '',
      name: '',
      regionId: '',
    };
    this.citiesByMunicipality = [];
    this.currentCity = {
      id: '',
      name: '',
      hrid: '',
      region: '',
      regionId: '',
      municipality: '',
      municipalityId: '',
    };
    return;
  }

  // добавление школы
  onSubmit(form: NgForm) {
    if (form.valid && this.currentCity && this.isAllRequiredFieldsFilled(this.school)) {
      let schoolData = {
        number: this.school.number,
        city: this.currentCity.name,
        cityId: this.currentCity.id,
        address: this.school.address,
        type: this.school.type,
        other: this.school.other,
      };
      this.adminPanelService
        .setSchool(schoolData)
        .pipe(takeUntil(this.ngUnsubscribe$))
        .subscribe(schoolId => {
          if (schoolId) {
            this.togglePopupForm();
            form.reset();
            this.utilsService.openSnackBar('👌 Школа добавлена', 'success');
            this.clearTerritoryData();
            this.updateValue.emit(schoolId);
          } else {
            this.getTranslation('SHARED.ERROR_MSG')
              .pipe(take(1))
              .subscribe(translation => this.utilsService.openSnackBar(translation, 'error'));
          }
        },
          err => {
            return this.utilsService.openSnackBar('👎 Произошла ошибка, попробуйте позже', 'error');
          });
    } else {
      this.getTranslation('SHARED.ERROR_MSG')
        .pipe(take(1))
        .subscribe(translation => this.utilsService.openSnackBar(translation, 'error'));
    }
  }

  getTranslation(key: string): Observable<any> {
    return this.translateService.get(key);
  }

  togglePopupForm() {
    this.dialogOpen = !this.dialogOpen;
  }

  ngOnDestroy() {
    this.ngUnsubscribe$.next(null);
    this.ngUnsubscribe$.complete();
  }

  private isAllRequiredFieldsFilled(school: ISchool): boolean {
    return school.address && school.number && school.type ? true : false;
  }
}
