import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DropdownWithFilterComponent } from 'app/shared/common-components/dropdown-with-filter/dropdown-with-filter.component';
import { FormsModule } from '@angular/forms';
import { SharedModule } from 'app/shared/shared.module';

@NgModule({
  declarations: [DropdownWithFilterComponent],
  imports: [CommonModule, FormsModule, SharedModule],
  exports: [DropdownWithFilterComponent],
})
export class DropdownWithFilterModule {}
