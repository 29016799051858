import { Component, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { switchMap, takeUntil } from 'rxjs/operators';

import { OverlayBusyService } from 'app/shared/overlay-busy/overlay-busy.service';
import { LoginService } from 'app/landing/login/login.service';
import { SharedService } from 'app/shared/shared.service';
import { of, Subject } from 'rxjs';

@Component({
  selector: 'prf-tokenauth',
  templateUrl: './tokenauth.component.html',
  styleUrls: ['./tokenauth.component.scss'],
})
export class TokenauthComponent implements OnInit {
  queryParams: any;
  accessToken: any;
  tokenFailed: boolean = false;
  showMessage: boolean = false;
  alertMessage: string = '';

  buttonWaiting: boolean = false;
  buttonActivated: boolean = false;
  buttonActivate: boolean = false;

  private ngUnsubscribe$: Subject<any> = new Subject();

  constructor(
    private meta: Meta,
    private router: Router,
    private route: ActivatedRoute,
    private loginService: LoginService,
    private sharedService: SharedService,
    private overlayService: OverlayBusyService,
  ) {}

  ngOnInit() {
    this.overlayService.show();
    this.queryParams = this.route.snapshot.queryParams;
    this.accessToken = this.queryParams['accessToken'];

    if (this.accessToken && this.accessToken != '') {
      this.loginService
        .tokenLogin(this.accessToken)
        .pipe(
          switchMap((loginResult: any) => {
            // логика идентичная компоненту login
            if (loginResult.succeeded === false) {
              this.tokenFailed = true;
              this.showMessage = true;
              this.alertMessage = 'Токен неверный';
              return of(null);
            } else {
              localStorage.clear();

              localStorage.setItem('userRole', loginResult.role);
              localStorage.setItem('userId', loginResult.userId);
              localStorage.setItem('tag', loginResult.tag);
              localStorage.setItem('isAuthorized', 'true');

              return this.sharedService.getUserInfoData().pipe(
                switchMap(ui => {
                  localStorage.setItem('imagePath', ui.imagePath);
                  localStorage.setItem('firstName', ui.firstName);
                  localStorage.setItem('lastName', ui.lastName);
                  localStorage.setItem('schoolId', ui.schoolId);
                  localStorage.setItem('userGender', ui.gender);
                  localStorage.setItem('companyId', ui.companyId);
                  localStorage.setItem('position', ui.position);

                  return this.router.navigate(['/admin/schools']);
                }),
              );
            }
          }),
          takeUntil(this.ngUnsubscribe$),
        )
        .subscribe(() => this.removeWaiting());
    } else {
      this.showMessage = true;
      this.alertMessage = 'Нет токена в URL';
    }
    this.overlayService.hide();
  }

  animateLogin() {
    this.buttonActivate = true;
    this.buttonWaiting = true;
  }

  removeWaiting() {
    this.buttonWaiting = false;
    this.buttonActivated = true;
    this.overlayService.hide();
  }

  failWaiting() {
    this.buttonWaiting = false;
    this.buttonActivate = false;
    this.overlayService.hide();
  }

  ngOnDestroy() {
    this.ngUnsubscribe$.next(null);
    this.ngUnsubscribe$.complete();
  }
}
