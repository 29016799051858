import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { MaterialModule } from 'app/shared/modules/material.module';
import { SharedModule } from 'app/shared/shared.module';
import { CommonModule } from '@angular/common';
import {
  CareerEducationCoursesComponent,
} from 'app/pages/catalog/career-education-courses-page/career-education-courses.component';
import {
  CareerEducationCoursesService,
} from 'app/pages/catalog/career-education-courses-page/career-education-courses.service';
import {
  AllCareerEducationCoursesComponent,
} from 'app/pages/catalog/career-education-courses-page/all-career-education-courses/all-career-education-courses.component';
import {
  CareerEducationCourseComponent,
} from 'app/pages/catalog/career-education-courses-page/all-career-education-courses/active-career-education-courses/career-education-course.component';
import {
  CareerEducationCourseDetailsComponent,
} from 'app/pages/catalog/career-education-courses-page/career-education-course-details/career-education-course-details.component';
import {
  CareerEducationLessonCardComponent,
} from 'app/pages/catalog/career-education-courses-page/career-education-course-details/career-education-lesson-card/career-education-lesson-card.component';
import {
  AddCareerEducationCourseComponent,
} from 'app/pages/catalog/career-education-courses-page/all-career-education-courses/active-career-education-courses/add-career-education-course/add-career-education-course.component';
import {
  CareerEducationCourseCardComponent,
} from 'app/pages/catalog/career-education-courses-page/all-career-education-courses/active-career-education-courses/career-education-course-card/career-education-course-card.component';
import {
  EditCareerEducationCourseComponent,
} from 'app/pages/catalog/career-education-courses-page/all-career-education-courses/active-career-education-courses/edit-career-education-course/edit-career-education-course.component';
import {
  CareerEducationAddLessonComponent,
} from 'app/pages/catalog/career-education-courses-page/career-education-course-details/career-education-add-lesson/career-education-add-lesson.component';
import {
  CareerEducationEditLessonComponent,
} from './career-education-course-details/career-education-edit-lesson/career-education-edit-lesson.component';
import { PlainTextPipe } from '../../../shared/pipes/plain-text.pipe';
import { AttachMediaComponent } from 'app/shared/common-components/attach-media-control/attach-media.component';

@NgModule({
  imports: [CommonModule, BrowserModule, RouterModule, SharedModule, MaterialModule, PlainTextPipe, AttachMediaComponent],
  declarations: [
    CareerEducationCoursesComponent,
    AddCareerEducationCourseComponent,
    CareerEducationCourseCardComponent,
    EditCareerEducationCourseComponent,
    AllCareerEducationCoursesComponent,
    CareerEducationCourseComponent,
    CareerEducationCourseDetailsComponent,
    CareerEducationLessonCardComponent,
    CareerEducationAddLessonComponent,
    CareerEducationEditLessonComponent,
  ],
  providers: [CareerEducationCoursesService],
  bootstrap: [],
})
export class CareerEducationCoursesModule {}
