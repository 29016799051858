<div class="admin-header-event-page" *ngIf="university">
  <div class="admin-header-col"></div>
  <div class="edit-event-column event-page-edit">
    <div class="edit-event-col-2">
      <a class="delete-event w-inline-block" (click)="popUpConfirming = !popUpConfirming"></a>
    </div>
    <div class="edit-event-col">
      <a [routerLink]="['/admin/edit-university', university.id]" class="button edit-event-page-btn w-button">{{
        'SHARED.EDIT_BUTTON' | translate
      }}</a>
    </div>
  </div>
</div>

<div class="event-page-section" *ngIf="university">
  <div class="event-page-header event-gradient-bg-2 purple">
    <div
      class="event-image-bg"
      [ngStyle]="{
        'background-image': university.mainImagePath ? 'url(' + university.mainImagePath + ')' : ''
      }"
    ></div>
    <div class="event-header-column"></div>
    <div class="event-header-column _2">
      <div class="event-page-h1-div">
        <h1 class="event-page-h1">{{ university.name }}</h1>
      </div>
    </div>
  </div>
  <div class="event-page-content">
    <div class="event-page-description-row">
      <div class="event-page-description-column">
        <!-- <h2 class="event-page-h2">{{ 'COURCES.PAGE.DESCRIPTION' | translate }}</h2> -->

        <div class="event-page-descr-row">
          <div *ngIf="university.universitySpecialities && university.universitySpecialities.length > 0" class="event-page-descr-column">
            <h4 class="event-page-h4">Специальности</h4>
            <ul class="event-description-list">
              <li *ngFor="let spec of university.universitySpecialities">
                <div class="event-description-title">{{ spec.id }}</div>
              </li>
            </ul>
          </div>
        </div>

        <!-- <p class="event-page-description-text" innerHtml="{{ university.description }}"></p> -->
      </div>
      <div class="event-page-description-column _2">
        <div class="event-page-address">
          <!-- <div class="event-address event-pg">{{ university.address }}</div> -->
        </div>
      </div>
    </div>
  </div>
</div>

<ng-container *ngIf="popUpConfirming">
  <div class="perform-task-lb lb show">
    <div class="lb-bg"></div>
    <div class="lb-block task-lb">
      <a class="close-lb w-inline-block" (click)="popUpConfirming = !popUpConfirming">
        <img src="./assets/images/icons/close-lb.svg" />
      </a>
      <div class="tasl-lb-row">
        <div class="task-lb-column _2">
          <h4 class="task-lb-heading-result">Удаление ВУЗа</h4>
          <div class="task-result-text">Вы уверены, что хотите удалить ВУЗ?</div>
          <a class="button attach-file-btn w-button" (click)="deleteUniversity(university.id)">{{ 'BUTTONS.DELETE' | translate }}</a>
        </div>
      </div>
    </div>
  </div>
</ng-container>
