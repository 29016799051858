<div class="dropdown-multi">
  <mat-label class="login-label">{{ label }}</mat-label>
  <mat-form-field class="chip-list" appearance="fill">
    <mat-chip-grid #chipGrid aria-label="placeholder">
      <mat-chip-row *ngFor="let selected of selectedOptions; trackBy: trackByFn" (removed)="remove(selected.data)">
        {{ selected.name }}
        <button type="button" matChipRemove [attr.aria-label]="'remove ' + selected.name">
          <mat-icon svgIcon="icon_close"></mat-icon>
        </button>
      </mat-chip-row>
    </mat-chip-grid>
    <input #variantInput [matChipInputFor]="chipGrid" [matAutocomplete]="auto" [matChipInputSeparatorKeyCodes]="separatorKeysCodes" />
    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
      <mat-option *ngFor="let option of filteredOptions | async; trackBy: trackByFn" [value]="option" [disabled]="isDisabled">
        {{ option.name }}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
</div>
