import { Component, Inject, OnInit } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { IExtendedPupil } from 'app/shared/interfaces/iextendedpupil.interface';
import { ISchoolClass } from 'app/shared/interfaces/ischoolclass.interface';
import { ITeacher } from 'app/shared/interfaces/iteacher.interface';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';
import { take } from 'rxjs/operators';
import { AdminClassService } from 'app/shared/dashboard/admin-classes/admin-class/admin-class.service';

@Component({
  selector: 'prf-admin-class-template',
  templateUrl: './admin-class-template.component.html',
  styleUrls: ['./admin-class-template.component.scss'],
})
export class AdminClassTemplateComponent implements OnInit {
  schoolClass: ISchoolClass;
  teacher: ITeacher;
  pupils: IExtendedPupil[] = [];
  schoolId: string = '';

  private ngUnsubscribe$: Subject<any> = new Subject();

  constructor(@Inject(DOCUMENT) private document: Document, private router: Router, private adminClassService: AdminClassService) {}

  ngOnInit() {}

  setTeacher(event) {
    this.teacher = event;
  }
  setSchoolClass(event) {
    this.schoolClass = event;
    this.schoolId = this.schoolClass.schoolId;
  }
  setPupils(event) {
    this.pupils = event;
  }

  routingBack() {
    this.adminClassService
      .getPupilPage()
      .pipe(take(1))
      .subscribe(pupilPage => {
        if (pupilPage.showPupilPage) {
          this.adminClassService.closePupilPage();
        } else {
          if (this.schoolId) {
            this.router.navigate([`/admin/schools/${this.schoolId}`]);
          } else {
            this.router.navigate(['/admin/school']);
          }
        }
      });
  }
}
