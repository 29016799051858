import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Meta } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AdminPanelService } from 'app/pages/control-panel/admin/admin-panel.service';
import { MunicipalitiesService } from 'app/pages/control-panel/admin/municipalities/municipalities.service';
import { RegionsService } from 'app/pages/control-panel/admin/regions/regions.service';
import {
  COMPLEXITYS,
  COURSES_TYPES,
  DELIVERY,
} from 'app/pages/catalog/courses-page/all-courses/active-courses/add-course/add-course.component';
import { UtilsService } from 'app/shared/dashboard/backend-services/utils.service';
import { IInternship } from 'app/shared/interfaces/iinternships.interface';
import { IMunicipality } from 'app/shared/interfaces/imunicipality';
import { IRegion } from 'app/shared/interfaces/iregion';
import { OverlayBusyService } from 'app/shared/overlay-busy/overlay-busy.service';
import * as moment from 'moment';
import { InternshipsService } from '../../../internships.service';
import { Internship } from 'app/shared/classes/internship.class';
import { INTERNSHIP_FORMAT } from '../internship/internship-details/internship-details.component';
import { switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { Observable, of, Subject } from 'rxjs';
import { EmptyGuid } from 'app/shared/global-constants/constants';

@Component({
  selector: 'prf-edit-internship',
  templateUrl: './edit-internship.component.html',
  styleUrls: ['./edit-internship.component.scss'],
})
export class EditInternshipComponent implements OnInit {
  subscription: any;
  hrid: string;
  internship: Internship;
  startDate: string;
  popUpConfirming: boolean = false;
  addtoArchive: boolean = false;
  public showInfoPopup: boolean = false;
  public form: UntypedFormGroup;
  public submitted: boolean;
  public userRole: string;
  public internshipID: string;
  public title: string = 'Редактирование';
  public isCopy: boolean = false;
  public checkhrid: boolean;
  public oldHrid: string;
  createdDate: string;
  updatedDate: string;
  public timeMask = [/[0-9]/, /\d/, ':', /\d/, /\d/];
  public selectedTalents: any;
  public selectedComplexity: any;
  public isRegular: boolean;
  public complexitys = COMPLEXITYS;
  public classesFormats = COURSES_TYPES;
  public selectedFormat: any;
  public delivery = DELIVERY;
  public selectedDelivery: any;
  public selectedFiles: File;
  public image;
  public moment: any = moment;
  public format: any;
  public internshipFormats = INTERNSHIP_FORMAT;
  public saveToActive: boolean;

  //объекты территориальной привязки
  public allRegions: IRegion[] = [];
  public selectedRegion: IRegion = {
    id: '',
    hrid: '',
    name: '',
  };
  public municipalitiesByRegion: IMunicipality[] = [];
  public selectedMunicipality: IMunicipality = {
    id: EmptyGuid,
    hrid: '',
    name: '',
    regionId: '',
  };

  private ngUnsubscribe$: Subject<any> = new Subject();

  constructor(
    private meta: Meta,
    public route: ActivatedRoute,
    private overlayService: OverlayBusyService,
    private internshipsService: InternshipsService,
    private fb: UntypedFormBuilder,
    private router: Router,
    private utilsService: UtilsService,
    private regionsService: RegionsService,
    private municipalitiesService: MunicipalitiesService,
    private adminPanelService: AdminPanelService,
    private translateService: TranslateService,
  ) {
    this.meta.updateTag({ name: 'og:title', content: 'Редактирование стажировки' });
    this.userRole = localStorage.getItem('userRole');
    if (route.snapshot.routeConfig.path == 'copy-internship/:hrid') {
      this.isCopy = true;
      this.title = 'Копия стажировки';
    }
  }

  ngOnInit() {
    this.overlayService.show();
    this.subscription = this.route.params
      .pipe(
        switchMap(params => {
          this.hrid = params['hrid'];
          return this.internshipsService.getInternshipByHrid(this.hrid).pipe(
            tap((r: { comment: string; internship: Internship; senderService: string; status: string }) => {
              this.internship = r.internship;

              this.oldHrid = this.internship.hrid;
              this.startDate = this.moment(this.internship.startDate).format('D MMMM YYYY');
              this.createdDate = this.moment(this.internship.createdDate).format('D MMMM YYYY в HH:mm');
              this.updatedDate = this.moment(this.internship.updatedDate).format('D MMMM YYYY в HH:mm');
              this.internshipID = this.internship.id;
              this.saveToActive = this.internship.isActive;
              this.form = this.fb.group({
                id: new UntypedFormControl(this.internship.id, []),
                createdDate: new UntypedFormControl(this.internship.createdDate, []),
                updatedDate: new UntypedFormControl(this.internship.updatedDate, []),
                name: new UntypedFormControl(this.internship.name, [Validators.required]),
                departamentName: new UntypedFormControl(this.internship.departamentName, [Validators.required]),
                companyId: new UntypedFormControl(this.internship.companyId),
                description: new UntypedFormControl(this.internship.description, [Validators.required]),
                hrid: new UntypedFormControl(this.internship.hrid, [Validators.required]),
                candidates: new UntypedFormControl(this.internship.candidates, [Validators.required]),
                duties: new UntypedFormControl(this.internship.duties, [Validators.required]),
                totalPlaces: new UntypedFormControl(this.internship.totalPlaces, []),
                specialities: new UntypedFormControl(this.internship.specialities, []),
                talents: new UntypedFormControl(this.internship.talents, []),
                courses: new UntypedFormControl(this.internship.courses, []),
                startDate: new UntypedFormControl(this.internship.startDate, [Validators.required]),
                closeDate: new UntypedFormControl(this.internship.closeDate, [Validators.required]),
                address: new UntypedFormControl(this.internship.address, []),
                link: new UntypedFormControl(this.internship.link, []),
                deadline: new UntypedFormControl(this.internship.deadline, [Validators.required]),
                mainImagePath: new UntypedFormControl(this.internship.mainImagePath, []),
                format: new UntypedFormControl(this.internship.format, [Validators.required]),
                blockerExercises: new UntypedFormControl(this.internship.blockerExercises, []),
                signUpList: new UntypedFormControl(this.internship.signUpList),
                isActive: new UntypedFormControl(this.internship.isActive, []),
              });

              this.checkDataFromInternship();
              this.overlayService.hide();
            }),
          );
        }),
        takeUntil(this.ngUnsubscribe$),
      )
      .subscribe();
  }

  ngOnDestroy() {
    this.ngUnsubscribe$.next(null);
    this.ngUnsubscribe$.complete();
  }

  get f() {
    return this.form.controls;
  }

  checkDataFromInternship() {
    // if (this.internship.complexity) {
    //   let complexitys = this.complexitys.filter(el => el.value === this.internship.complexity);
    //   this.selectedComplexity = complexitys[0];
    // }
    // if (this.internship.deliveryMode) {
    //   let deliveryMode = this.delivery.filter(el => el.value === this.internship.deliveryMode);
    //   this.selectedDelivery = deliveryMode[0];
    // }
    this.format = INTERNSHIP_FORMAT.find(c => c.value === this.internship.format);
  }

  setInternshipFormat(obj) {
    if (obj) {
      this.form.value.format = obj.value;
      this.format = obj;
    }
  }

  checkInternshipFormatHeader() {
    if (this.format) {
      let format = INTERNSHIP_FORMAT.filter(el => el.value === this.format.value);
      return format[0].viewValue;
    } else {
      return 'Формат стажировки';
    }
  }

  uploadLogoInternship(event) {
    if (event.target.files.item(0).type.match('image.*')) {
      this.selectedFiles = event.target.files;
      this.adminPanelService
        .uploadImage(this.selectedFiles, this.internship.id)
        .pipe(take(1))
        .subscribe(r => {
          this.form.value.mainImagePath = r;
        });
    } else {
      this.utilsService.openSnackBar('👎 Некорректный формат файла', 'error');
    }
  }

  parseDate(dateString) {
    if (dateString) {
      return new Date(dateString);
    } else {
      return null;
    }
  }

  dataThemes(data) {
    this.form.value.courses = data;
  }

  dataTalents(data) {
    this.form.value.talents = data;
  }

  checkHridAllow(): Observable<any> {
    if (this.router.url.includes('/edit-internship/')) {
      if (this.oldHrid != this.form.value.hrid) {
        return this.internshipsService.checkHrid(this.form.value.hrid).pipe(tap(r => (this.checkhrid = r)));
      } else {
        this.checkhrid = true;
        return of(null);
      }
    } else if (this.router.url.includes('/copy-internship/')) {
      return this.internshipsService.checkHrid(this.form.value.hrid).pipe(tap(r => (this.checkhrid = r)));
    } else {
      return of(null);
    }
  }

  submit() {
    this.submitted = true;
    this.checkHridAllow().pipe(
      switchMap(r => {
        if (this.form.valid && this.checkhrid) {
          // let date = moment(this.form.value.startDate).format('YYYY-MM-DD');
          // if (date == undefined || date == 'Invalid date') {
          //   date = null;
          //   this.isRegular = true;
          // }
          // if (this.selectedDelivery) {
          //   this.form.value.deliveryMode = this.selectedDelivery.value;
          // }
          // if (this.isCopy) {
          //   this.form.value.id = this.utilsService.newGuid();
          // }

          let date = moment(new Date()).format('YYYY-MM-DD');

          this.internship = {
            id: this.form.value.id,
            hrid: this.form.value.hrid,
            address: this.form.value.address,
            description: this.form.value.description,
            departamentName: this.form.value.departamentName,
            companyId: this.form.value.companyId,
            candidates: this.form.value.candidates,
            duties: this.form.value.duties,
            name: this.form.value.name,
            totalPlaces: this.form.value.totalPlaces,
            link: this.form.value.link,
            mainImagePath: this.form.value.mainImagePath,
            specialities: this.form.value.specialities,
            talents: this.form.value.talents,
            courses: this.form.value.courses,
            blockerExercises: this.form.value.blockerExercises,
            signUpList: this.form.value.signUpList,
            deadline: this.form.value.deadline,
            startDate: this.form.value.startDate,
            closeDate: this.form.value.closeDate,
            createdDate: this.form.value.createdDate,
            updatedDate: date,
            format: this.form.value.format,
            isActive: this.saveToActive,
          };
          let currentObservable$: Observable<any> = of(null);
          if (this.isCopy) {
            currentObservable$ = this.internshipsService.createInternshipsDO(this.internship);
          } else {
            currentObservable$ = this.internshipsService.changeInternshipsDO(this.internshipID, this.internship);
          }
          return currentObservable$.pipe(
            tap(r => {
              this.utilsService.openSnackBar('👌 Стажировка обновлена, вы будете перенаправлены на страницу данной стажировки', 'success');
              return setTimeout(_ => {
                this.router.navigate(['/' + this.userRole + '/internships/' + this.form.value.hrid]);
              }, 2000);
            }),
          );
        } else {
          this.getTranslation('SHARED.ERROR_MSG')
            .pipe(take(1))
            .subscribe(translation => this.utilsService.openSnackBar(translation, 'error'));
        }
      }),
    );
  }

  deleteInternship(internshipID) {
    this.internshipsService
      .removeCurrentInternship(internshipID)
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe(r => {
        this.popUpConfirming = !this.popUpConfirming;
        return this.router.navigate(['/' + this.userRole + '/internships']);
      });
  }

  //todo: неиспользуемые методы, удалить потом

  // async setRegion(regionId) {
  //   this.regionsService.getRegion(regionId).then(r => {
  //     this.selectedRegion = r;
  //     this.form.get('region').setValue(this.selectedRegion);
  //     this.municipalitiesService
  //       .getMunicipalitiesByRegion(this.selectedRegion.id)
  //       .then(r => (this.municipalitiesByRegion = r));
  //     this.internship.municipalityId != EmptyGuid
  //       ? this.municipalitiesService.getMunicipality(this.internship.municipalityId).then(r => {
  //           this.form.get('municipality').setValue(r);
  //         })
  //       : '';
  //   });
  // }

  getRegions() {
    this.regionsService
      .getAllRegions()
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe(r => {
        this.allRegions = r.filter(region => region.id != EmptyGuid);
      });
  }

  selectRegion(region) {
    if (region) {
      if (region != this.selectedRegion) {
        this.clearMunicipality();
      }
      this.selectedRegion = region;
      this.municipalitiesService
        .getMunicipalitiesByRegion(region.id)
        .pipe(takeUntil(this.ngUnsubscribe$))
        .subscribe(r => (this.municipalitiesByRegion = r));
    }
  }

  public clearMunicipality() {
    this.form.get('municipality').setValue(this.selectedMunicipality);
    return;
  }

  archive(internship) {
    internship.isArchived = true;
    this.internshipsService
      .changeInternshipsDO(internship.id, internship)
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe(r => {
        this.utilsService.openSnackBar('👌 Стажировка добавлена в архив', 'success');
        return setTimeout(_ => {
          this.router.navigate(['/' + this.userRole + '/internships']);
        }, 2000);
      });
  }

  setComplexity(obj) {
    if (obj) {
      this.form.value.complexity = obj.value;
      this.selectedComplexity = obj;
    }
  }

  checkComplexityHeader() {
    if (this.selectedComplexity) {
      let town = this.complexitys.filter(el => el.value === this.selectedComplexity.value);
      return town[0].viewValue;
    } else {
      return 'Сложность стажировки';
    }
  }

  setDelivery(obj) {
    if (obj) {
      this.selectedDelivery = obj;
    }
  }

  checkDeliveryHeader() {
    if (this.selectedDelivery) {
      let town = this.delivery.filter(el => el.value === this.selectedDelivery.value);
      return town[0].viewValue;
    } else {
      return 'Формат стажировки';
    }
  }

  public compareItems(item1: any, item2: any) {
    return item1 && item2 && item1.id === item2.id;
  }

  getTranslation(key: string): Observable<any> {
    return this.translateService.get(key);
  }
}
