import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { UtilsService } from 'app/shared/dashboard/backend-services/utils.service';

import { IMunicipality } from 'app/shared/interfaces/imunicipality';
import { IRegion } from 'app/shared/interfaces/iregion';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { IMunicipalityView } from '../../../../../shared/interfaces/imunicipalityview.interface';
import { MunicipalitiesService } from '../municipalities.service';
import {NgForm} from "@angular/forms";

@Component({
  selector: 'prf-add-municipality',
  templateUrl: './add-municipality.component.html',
  styleUrls: ['./add-municipality.component.scss'],
})
export class AddMunicipalityComponent implements OnDestroy {
  @Input() regions: IRegion[];
  @Output() municipalityAdded = new EventEmitter<IMunicipalityView>();

  public region: IRegion = {
    id: '',
    hrid: '',
    name: '',
  };

  public municipality: IMunicipality = {
    name: '',
    hrid: '',
    regionId: '',
  };

  public dialogOpen: boolean = false;

  private ngUnsubscribe: Subject<any> = new Subject();

  constructor(private municipalitiesService: MunicipalitiesService, private utilsService: UtilsService) {}


  public onSubmit(form: NgForm) {
    if (form.valid && this.isValidMunicipality(this.municipality, this.region)) {
      const municipalityView: IMunicipalityView = {
        id: null,
        hrid: this.municipality.hrid,
        name: this.municipality.name,
        regionId: this.region.id,
        regionName: this.region.name,
      };
      this.municipality.regionId = this.region.id;
      this.municipalitiesService
        .createMunicipality(this.municipality)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(municipalityId => {
          this.togglePopupForm();
          form.reset();
          this.utilsService.openSnackBar('👌 Муниципалитет добавлен', 'success');
          municipalityView.id = municipalityId;
          this.municipalityAdded.emit(municipalityView);
        }),
        err => {
          return this.utilsService.openSnackBar('👎 Произошла ошибка, попробуйте позже', 'error');
        };
    } else {
      return;
    }
  }

  private isValidMunicipality(municipality: IMunicipality, region: IRegion) {
    return municipality.name && municipality.hrid && region.id && region.name ? true : false;
  }

  togglePopupForm() {
    this.dialogOpen = !this.dialogOpen;
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next(null);
    this.ngUnsubscribe.complete();
  }
}
