import { Injectable } from '@angular/core';
import { BehaviorSubject, forkJoin, Observable, of } from 'rxjs';
import { take } from 'rxjs/operators';
import { ISchool } from '../interfaces/ischool.interface';
import { IRegion } from '../interfaces/iregion';
import { EmptyGuid } from '../global-constants/constants';
import { AdminPanelService } from '../../pages/control-panel/admin/admin-panel.service';
import { RegionsService } from '../../pages/control-panel/admin/regions/regions.service';
import { ICity } from '../interfaces/icity';
import { AbstractControl, FormBuilder, FormGroup, FormControl } from '@angular/forms';

@Injectable({ providedIn: 'root' })
export class TerritoryFiltersService {
  private _allRegions = new BehaviorSubject<IRegion[]>([]);
  private _allCities = new BehaviorSubject<ICity[]>([]);
  private _allSchools = new BehaviorSubject<ISchool[]>([]);
  private _allClassesBySchool = new BehaviorSubject([]);
  private _schoolsByIds = new Map<string, string>();
  private readonly _form: FormGroup;

  constructor(private adminPanelService: AdminPanelService, private regionsService: RegionsService, private formBuilder: FormBuilder) {
    this._form = this.formBuilder.group({
      region: new FormControl(null, []),
      municipality: new FormControl(null, []),
      city: new FormControl(null, []),
      school: new FormControl(null, []),
      classNumber: new FormControl(null, []),
      class: new FormControl(null, []),
    });

    forkJoin([this.adminPanelService.getAllCities(), this.adminPanelService.getCatalogSchools(), this.regionsService.getAllRegions()])
      .pipe(take(1))
      .subscribe(([cities, allSchools, regions]) => {
        this._allCities.next(cities);
        this._allSchools.next(allSchools);
        allSchools.forEach((school: ISchool) => {
          this._schoolsByIds.set(school.id, school.number);
        });
        this._allRegions.next(regions.filter((r: IRegion) => r.id != EmptyGuid).sort((a, b) => (a.name > b.name ? 1 : -1)));
      });
  }

  public get allRegions$(): Observable<IRegion[]> {
    return this._allRegions.asObservable();
  }

  public get allCities$(): Observable<ICity[]> {
    return this._allCities.asObservable();
  }

  public get allSchools$(): Observable<ISchool[]> {
    return this._allSchools.asObservable();
  }

  public get allClassesBySchool$(): Observable<any> {
    return this._allClassesBySchool.asObservable();
  }

  public get schoolsByIds$(): Observable<Map<string, string>> {
    return of(this._schoolsByIds);
  }

  public get form(): FormGroup {
    return this._form;
  }

  public get formControls(): Record<string, AbstractControl> {
    return this._form.controls;
  }

  public updateClasses(): void {
    const filters = {
      regionId: this.formControls.region.value.id,
      munitipalityId: this.formControls.municipality.value.id,
      cityId: this.formControls.city.value.id,
      schoolName: this.formControls.school.value.number,
      classNumber: this.formControls.classNumber.value,
    };

    this.adminPanelService
      .findSchoolClasses(filters)
      .pipe(take(1))
      .subscribe(response => this._allClassesBySchool.next(response.classes));
  }
}
