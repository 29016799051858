import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { ActiveCoursesComponent } from './all-courses/active-courses/active-courses.component';
import { AddCourseComponent } from './all-courses/active-courses/add-course/add-course.component';
import { SelectMetroComponent } from './all-courses/active-courses/add-course/select-metro/select-metro.component';
import { SelectTalentsComponent } from './all-courses/active-courses/add-course/select-talents/select-talents.component';
import { SelectThemesComponent } from './all-courses/active-courses/add-course/select-themes/select-themes.component';
import { EditCourseComponent } from './all-courses/active-courses/edit-course/edit-course.component';
import { EventCourseCardComponent } from './all-courses/active-courses/events-courses/event-course-card/event-course-card.component';
import { EventsCoursesComponent } from './all-courses/active-courses/events-courses/events-courses.component';
import { CoursesFilterDateComponent } from './all-courses/active-courses/filters-courses/courses-filter-date/courses-filter-date.component';
import { CoursesFilterFormatComponent } from './all-courses/active-courses/filters-courses/courses-filter-format/courses-filter-format.component';
import { CoursesFilterGroupComponent } from './all-courses/active-courses/filters-courses/courses-filter-group/courses-filter-group.component';
import { CoursesFilterTerritoryComponent } from './all-courses/active-courses/filters-courses/courses-filter-territory/courses-filter-territory.component';
import { CoursesFilterTypesComponent } from './all-courses/active-courses/filters-courses/courses-filter-types/courses-filter-types.component';
import { FiltersCoursesComponent } from './all-courses/active-courses/filters-courses/filters-courses.component';
import { SearchCoursesComponent } from './all-courses/active-courses/search-courses/search-courses.component';
import { AllCoursesComponent } from './all-courses/all-courses.component';
import { CoursesComponent } from './courses.component';
import { CoursesService } from './courses.service';
import { MineCoursesComponent } from './mine-courses/mine-courses.component';
import { ArchiveCoursesComponent } from 'app/pages/catalog/courses-page/all-courses/archive-courses/archive-courses.component';
import { EventsCoursesArchiveComponent } from 'app/pages/catalog/courses-page/all-courses/archive-courses/events-courses-archive/events-courses-archive.component';
import { SharedModule } from 'app/shared/shared.module';
import { CoursesPageComponent } from '../courses-page-without-filters/courses-page.component';
import { CourseDetailsComponent } from '../courses-page-without-filters/course-details/course-details.component';
import { CourseService } from '../courses-page-without-filters/course.service';
import { CoursesListService } from '../courses-page-without-filters/courses-list.service';
import { CourseDetailsService } from '../courses-page-without-filters/course-details/course-details.service';
import { MaterialModule } from 'app/shared/modules/material.module';

import { CoursesFilterInstitutionComponent } from './all-courses/active-courses/filters-courses/courses-filter-institution/courses-filter-institution.component';
import { ElasticCoursesComponent } from './elastic-courses/elastic-courses.component';
import { ElasticEventsCoursesComponent } from './elastic-courses/elastic-active-courses/elastic-events-courses/elastic-events-courses.component';
import { ElasticActiveCoursesComponent } from './elastic-courses/elastic-active-courses/elastic-active-courses.component';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { TextMaskModule } from '@matheo/text-mask';

@NgModule({
  imports: [BrowserModule, RouterModule, SharedModule, MaterialModule, TextMaskModule],
  declarations: [
    //courses-page-without-filters
    //(старые?) компоненты для страницы курсов, которая открывается по роуту /courses
    CoursesPageComponent,
    CourseDetailsComponent,

    //courses-page
    //нормальные компоненты для страницы курсов, которая открывается по роуту /{userRole}/courses
    SearchCoursesComponent,
    CoursesComponent,
    EventsCoursesComponent,
    FiltersCoursesComponent,
    AllCoursesComponent,
    MineCoursesComponent,
    AddCourseComponent,
    SelectThemesComponent,
    SelectTalentsComponent,
    SelectMetroComponent,
    EventCourseCardComponent,
    EditCourseComponent,
    CoursesFilterTypesComponent,
    CoursesFilterGroupComponent,
    CoursesFilterDateComponent,
    CoursesFilterInstitutionComponent,
    ActiveCoursesComponent,
    ArchiveCoursesComponent,
    EventsCoursesArchiveComponent,
    CoursesFilterTerritoryComponent,
    CoursesFilterFormatComponent,
    ElasticCoursesComponent,
    ElasticEventsCoursesComponent,
    ElasticActiveCoursesComponent,
  ],
  providers: [
    CoursesService,

    //courses-page-without-filters
    CourseService,
    CoursesListService,
    CourseDetailsService,
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
  ],
  bootstrap: [],
})
export class CoursesModule {}
