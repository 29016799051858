import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: 'mat-select[prfBlurOnChange]',
})
export class BlurOnChangeDirective {
  constructor(private select: ElementRef) {}

  @HostListener('selectionChange', ['$event'])
  public onSelectionChange(): void {
    setTimeout(() => {
      this.select.nativeElement.blur();
    }, 0);
  }
}
