<div class="header">
  <a class="mobile-menu-button" (click)="showMobileMenu()">
    <div class="mobile-menu-icon-div">
      <div class="menu-line"></div>
      <div class="menu-line"></div>
    </div>
  </a>

  <div class="h1-div">
    <div class="class-head-row">
      <ng-container *ngIf="dataFetched">
        <div class="class-head-column title-column">
          <h1 class="heading" *ngIf="title$ | async as title" [class.stud-page-heading]="backButtonUrl">
            <span>{{ title }}</span>
          </h1>

          <ng-container *ngIf="backButtonData$ | async as buttonData">
            <a [routerLink]="buttonData.backButtonUrl" class="button white-button stud-page-back-btn w-button">
              {{ buttonData.backButtonName }}
            </a>
          </ng-container>
        </div>

        <div class="class-head-column button-column" *ngIf="buttonToTest">
          <div>
            <a class="button achievements-button w-button" routerLink="/test-na-professiyu">Пройти тест еще раз</a>
          </div>
        </div>
      </ng-container>
    </div>
  </div>

  <div class="header-menu">
    <div class="notice">
      <div *ngIf="checkNotificationAllow()" class="notice-column" #notification>
        <div class="notice-dropdown w-dropdown" (click)="nav.classList.toggle('w--open')">
          <div class="notice-dropdown-toggle notification w-dropdown-toggle"></div>

          <nav class="notice-list w-dropdown-list" #nav>
            <div class="notice-list-container">
              <img src="./assets/images/icons/triangle.png" width="28" class="triangle" />
              <div class="notices-block">
                <div class="notices-div-block">
                  <div class="notices-scroll">
                    <div class="notice-title">{{ 'SHARED.NOTIFICATIONS' | translate }}</div>
                    <div class="old-notices">
                      <div class="notice-block">
                        <div>
                          <p class="notice-text">
                            {{ 'SHARED.NOW_NOTIFICATIONS_IS_NOT' | translate }}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="notice-gradient"></div>
                </div>
              </div>
            </div>
          </nav>
        </div>
      </div>
    </div>
    <a class="user-dropdown w-inline-block" routerLink="{{ isOpenProfile() ? '/profile' : '/' + userRole + '/profile' }}">
      <div class="user-dropdown-toggle">
        <div
          class="user-photo"
          [ngStyle]="{
            'background-image':
              userInfo && userInfo.imagePath ? 'url(' + userInfo.imagePath + ')' : 'url(/assets/images/dashboard/no-photo.svg)'
          }"
        ></div>
        <div class="user-name">
          {{ userInfo && userInfo.firstName ? userInfo.firstName : 'Мой профиль' }}
        </div>
      </div>
    </a>
  </div>
</div>
