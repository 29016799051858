import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { UnsubscribeComponent } from '@profilum-components/unsubscribe/unsubscribe.component';
import {
  ICourseMaterial,
} from 'app/pages/catalog/career-education-courses-page/interfaces/career-education-courses.interface';
import { ISchoolClass } from 'app/shared/interfaces/ischoolclass.interface';

@Component({
  selector: 'prf-school-class',
  templateUrl: './school-class.component.html',
  styleUrls: ['./school-class.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SchoolClassComponent extends UnsubscribeComponent {
  @Input() public courseMaterials: ICourseMaterial[];
  @Input() set setSchoolClass([schoolClass, courseId]) {
    const assignedCourse = this.courseMaterials?.find(course => course.id === courseId);

    this.schoolClass = schoolClass;
    this.setCourse(assignedCourse);
  }

  @Output() public assignCourseForClass: EventEmitter<string[]> = new EventEmitter();
  @Output() public updateCourseForClass: EventEmitter<string[]> = new EventEmitter();
  @Output() public deleteCourseForClass: EventEmitter<string> = new EventEmitter();

  @ViewChild('courseFilter') public readonly courseFilter: ElementRef;
  @ViewChild('courseDrop') public readonly courseDrop: ElementRef;
  @ViewChild('courseField') public readonly courseField: ElementRef;

  @HostListener('document:click', ['$event.target'])
  checkClick(target) {
    if (this.courseFilter && !this.courseFilter?.nativeElement.contains(target)) {
      this.courseDrop.nativeElement.classList.remove('w--open');
      this.courseField.nativeElement.classList.remove('opened');
    }
  }

  public courseForm: UntypedFormGroup = new UntypedFormGroup({
    course: new UntypedFormControl(),
  });
  public schoolClass: ISchoolClass;
  public currentCourse: ICourseMaterial;
  public selectedCourseId: string;

  private emptyCourse: ICourseMaterial = {
    name: 'Диагностика',
    description: '',
    lessonsMaterials: [],
  };

  constructor(private ref: ChangeDetectorRef) {
    super();
  }

  public setCourse(course: ICourseMaterial): void {
    let courseName = '';
    let courseId = '';
    this.currentCourse = this.emptyCourse;

    if (course) {
      this.currentCourse = course;
      courseName = course.name;
      courseId = course.id;
    }

    this.courseForm.controls.course.setValue(courseName ? courseName : 'Диагностика');
    this.selectedCourseId = courseId;
    this.ref.markForCheck();
  }

  public patchCourse(course: ICourseMaterial): void {
    // Диагностика -> курс
    if (!this.selectedCourseId && course) {
      this.assignCourseForClass.emit([this.schoolClass.id, course.id]);
    }
    // Курс -> курс
    else if (this.selectedCourseId && course && this.selectedCourseId !== course?.id) {
      this.updateCourseForClass.emit([this.schoolClass.id, course.id]);
    }
    // Курс -> диагностика
    else if (this.selectedCourseId && !course) {
      this.deleteCourseForClass.emit(this.schoolClass.id);
    }

    this.setCourse(course);
  }

  public checkCourseHeader() {
    if (this.currentCourse) {
      return this.courseMaterials.find(el => el === this.currentCourse);
    }
  }
}
