<form *ngIf="_loaded; else loader" [formGroup]="_form" autocomplete="off">
  <div class="profile-data-wrapper">
    <div class="profile-data-row">
      <div>
        <label for="number" class="login-label">{{ 'SHARED.NAME_SCHOOL' | translate }}:<sup>*</sup></label>
        <div class="div-space"></div>
      </div>
      <div class="profile-field-div">
        <input type="text" autocomplete="new-number" class="login-field w-input" name="number" id="number" formControlName="number" />
      </div>
    </div>

    <div class="profile-data-row">
      <div>
        <label for="region" class="login-label">{{ 'SHARED.REGION' | translate }}:</label>
        <div class="div-space"></div>
      </div>
      <div class="dropdown-inline cat" #regionFilter>
        <div class="dropdown-inline-container w-dropdown">
          <div
            class="profile-field-div"
            #regionField
            (click)="regionDrop.classList.toggle('w--open'); regionField.classList.toggle('opened')"
          >
            <input
              autocomplete="autocomplete-region"
              type="text"
              class="login-field w-input"
              name="region"
              id="region"
              formControlName="region"
              [class.active]="currentRegion"
              (focusout)="fieldFocusOutRegion($event)"
            />
          </div>
          <nav class="dd-prof-filter-list w-dropdown-list" #regionDrop>
            <div class="dd-prof-filter-div">
              <div class="filter-list-row">
                <div class="filter-list-heading">{{ 'SHARED.REGION' | translate }}</div>
              </div>
              <div class="prof-categories-links">
                <a
                  class="prof-categories-link w-inline-block"
                  *ngFor="let region of allRegions"
                  [class.dd-inline-current]="checkRegionHeader() === region"
                  (click)="selectRegion(region); regionDrop.classList.remove('w--open'); regionField.classList.remove('opened')"
                >
                  <div class="category-text-link">{{ region.name }}</div>
                </a>
              </div>
            </div>
          </nav>
        </div>
      </div>
    </div>

    <div class="profile-data-row">
      <div>
        <label for="municipality" class="login-label">Муниципалитет:</label>
        <div class="div-space"></div>
      </div>
      <div class="dropdown-inline cat" #municipalityFilter>
        <div class="dropdown-inline-container w-dropdown">
          <div
            class="profile-field-div"
            #municipalityField
            (click)="municipalityDrop.classList.toggle('w--open'); municipalityField.classList.toggle('opened')"
          >
            <input
              autocomplete="autocomplete-municipality"
              type="text"
              class="login-field w-input"
              name="municipality"
              id="municipality"
              formControlName="municipality"
              [class.active]="currentMunicipality"
              (focusout)="fieldFocusOutMunicipality($event)"
            />
          </div>
          <nav class="dd-prof-filter-list w-dropdown-list" #municipalityDrop>
            <div class="dd-prof-filter-div">
              <div class="filter-list-row">
                <div class="filter-list-heading">Муниципалитет</div>
              </div>
              <div class="prof-categories-links">
                <a
                  class="prof-categories-link w-inline-block"
                  *ngFor="let municipality of municipalitiesByRegion"
                  [class.dd-inline-current]="checkMunicipalityHeader() === municipality"
                  (click)="
                    selectMunicipality(municipality);
                    municipalityDrop.classList.remove('w--open');
                    municipalityField.classList.remove('opened')
                  "
                >
                  <div class="category-text-link">{{ municipality.name }}</div>
                </a>
              </div>
            </div>
          </nav>
        </div>
      </div>
    </div>

    <div class="profile-data-row">
      <div>
        <label for="city" class="login-label">Город:<sup>*</sup></label>
        <div class="div-space"></div>
      </div>
      <div class="dropdown-inline cat" #cityFilter>
        <div class="dropdown-inline-container w-dropdown">
          <div class="profile-field-div" #cityField (click)="cityDrop.classList.toggle('w--open'); cityField.classList.toggle('opened')">
            <input
              autocomplete="off"
              type="text"
              class="login-field w-input"
              name="city"
              id="city"
              formControlName="city"
              [class.active]="selectedCity"
              (focusout)="fieldFocusOutCity($event)"
            />
          </div>
          <nav class="dd-prof-filter-list w-dropdown-list" #cityDrop>
            <div class="dd-prof-filter-div">
              <div class="filter-list-row">
                <div class="filter-list-heading">Город</div>
              </div>
              <div class="prof-categories-links">
                <a
                  class="prof-categories-link w-inline-block"
                  *ngFor="let city of citiesByMunicipality"
                  [class.dd-inline-current]="checkCityHeader() === city"
                  (click)="setCity(city); cityDrop.classList.remove('w--open'); cityField.classList.remove('opened')"
                >
                  <div class="category-text-link">{{ city.name }}</div>
                </a>
              </div>
            </div>
          </nav>
        </div>
      </div>
    </div>

    <div class="profile-data-row">
      <div>
        <label for="address" class="login-label">Адрес:<sup>*</sup></label>
        <div class="div-space"></div>
      </div>
      <div class="profile-field-div">
        <input type="text" autocomplete="new-address" class="login-field w-input" name="address" id="address" formControlName="address" />
      </div>
    </div>

    <div class="profile-data-row">
      <div>
        <label for="type" class="login-label">Тип школы:<sup>*</sup></label>
        <div class="div-space"></div>
      </div>
      <div class="dropdown-inline cat" #typeFilter>
        <div class="dropdown-inline-container w-dropdown">
          <div class="profile-field-div" #typeField (click)="typeDrop.classList.toggle('w--open'); typeField.classList.toggle('opened')">
            <input
              autocomplete="autocomplete-type"
              type="text"
              class="login-field w-input"
              name="type"
              id="type"
              formControlName="type"
              [class.active]="selectedType"
              (focus)="fieldFocusType($event)"
              (focusout)="fieldFocusOutType($event)"
            />
          </div>
          <nav class="dd-prof-filter-list w-dropdown-list" #typeDrop>
            <div class="dd-prof-filter-div">
              <div class="filter-list-row">
                <div class="filter-list-heading">Тип школы</div>
              </div>
              <div class="prof-categories-links">
                <a
                  class="prof-categories-link w-inline-block"
                  *ngFor="let type of _types"
                  [class.dd-inline-current]="checkTypeHeader() === type"
                  (click)="setType(type); typeDrop.classList.remove('w--open'); typeField.classList.remove('opened')"
                >
                  <div class="category-text-link">{{ type }}</div>
                </a>
              </div>
            </div>
          </nav>
        </div>
      </div>
    </div>

    <div class="profile-data-row">
      <div>
        <label for="other" class="login-label">Другое:</label>
        <div class="div-space"></div>
      </div>
      <div class="profile-field-div">
        <input type="text" autocomplete="new-other" class="login-field w-input" name="other" id="other" formControlName="other" />
      </div>
    </div>

    <div class="profile-data-row">
      <div>
        <label for="urlMskObr" class="login-label">Электронный адрес:</label>
        <div class="div-space"></div>
      </div>
      <div class="profile-field-div">
        <input
          type="text"
          autocomplete="autocomplete-urlMskObr"
          class="login-field w-input"
          name="urlMskObr"
          id="urlMskObr"
          formControlName="urlMskObr"
        />
      </div>
    </div>

    <ng-container *ngIf="schoolClasses.length">
      <div class="school-classes-header">Классы ({{ schoolClasses.length }})</div>
      <ng-container *ngFor="let class of schoolClasses">
        <div class="profile-data-row">
          <prf-school-class
            [courseMaterials]="courseMaterials"
            [setSchoolClass]="class"
            (assignCourseForClass)="onAssignCourseForClass($event)"
            (updateCourseForClass)="onUpdateCourseForClass($event)"
            (deleteCourseForClass)="onDeleteCourseForClass($event)"
          >
          </prf-school-class>
        </div>
      </ng-container>
    </ng-container>

    <div class="button-container">
      <a
        (click)="animateEdit()"
        [ngClass]="{ disabled: !isAccessAllowed() }"
        [class.btn-waiting]="buttonWaiting"
        [class.btn-activated]="buttonActivated"
        [class.btn-activate]="!buttonActivate"
        class="button-animate btn-activate"
        id="btnActivation"
      >
        <span class="btn-icon"></span>
        <span class="btn-text" data-wait="Подождите" data-after="Сохранено">Сохранить</span>
      </a>
      <a class="button white-button school-edit-button w-button" (click)="closeEditing.emit(false)">Отменить</a>
    </div>
  </div>
</form>

<ng-template #loader>
  <prf-simple-loader></prf-simple-loader>
</ng-template>
