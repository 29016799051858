import { Component, HostListener, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PageHeaderService } from 'app/shared/dashboard/page-header/page-header.service';
import { MenuService } from './menu.service';

@Component({
  selector: 'prf-admin-menu',
  templateUrl: './admin-menu.component.html',
  styleUrls: ['./admin-menu.component.scss'],
})
export class AdminMenuComponent implements OnInit {
  @Input() home: string = 'Школы';
  @Input() homeURL: string = 'schools';
  @Input() hide: boolean = false;
  @Input() innerRoute: boolean = false;
  @Input() internshipActive: boolean = false;
  @Input() professionActive: boolean = false;
  @Input() adminLevel: string;
  public href: string;
  public isShowMobileMenu: boolean = false;
  public imagePath: string = '';
  public firstName: string = '';
  public adminRole: string = '';
  public isMOStend: boolean = false;
  // Переменная временно скрывает стажировки
  public readonly isInternshipsReady: boolean = false;

  constructor(public router: Router, private menuService: MenuService, private headerService: PageHeaderService) {
    this.menuService.showMobileMenu$.subscribe((show: boolean) => {
      this.isShowMobileMenu = show;
    });
    this.imagePath = localStorage.getItem('imagePath');
    this.firstName = localStorage.getItem('firstName');
    this.adminRole = localStorage.getItem('userRole');
    this.isMOStend = location.origin.includes('mosreg');
  }

  public ngOnInit(): void {
    this.href = this.router.url;
  }

  public get routeActive(): boolean {
    return this.router.isActive('/schooladmin', true);
  }

  public openSubmenu(event: Event): void {
    let element: Element = (<HTMLElement>event.target).nextElementSibling;
    element.classList.toggle('opened');
  }

  public selectMain(): Promise<boolean> {
    this.hideProfile();
    this.hideMobileMenu();

    return this.router.navigate(['/admin']);
  }

  @HostListener('window:scroll', [])
  private onScroll(): void {
    if (this.isShowMobileMenu) {
      this.isShowMobileMenu = false;
    }
  }

  @HostListener('window:resize', [])
  private onResize(): void {
    if (this.isShowMobileMenu) {
      this.isShowMobileMenu = false;
    }
  }

  @HostListener('window:click', ['$event.target'])
  private closeOpenMenu(target: HTMLElement): void {
    let openedMenuList: NodeListOf<Element> = document.querySelectorAll('.submenu-wrapper.opened');

    if (openedMenuList.length) {
      openedMenuList.forEach((el: Element) => {
        if (el.previousElementSibling !== target) {
          el.classList.remove('opened');
        }
      });
    }
  }

  public hideMobileMenu(): void {
    this.menuService.showMobileMenu(false);
  }

  public hideProfile(): void {
    this.headerService.closeProfile();
  }

  public selectProfile(): void {
    this.hideProfile();
    this.hideMobileMenu();
    this.router.navigate(['/admin/profile']);
  }
}
