<ng-template [ngIf]="courseId">
  <a class="btn-prf-outline" (click)="toggleModal()" aria-label="Добавить урок">Добавить урок</a>
  <div class="add-new-lesson__form" *ngIf="addLessonFlag">
    <div class="overlay">
      <div class="modal">
        <p (click)="toggleModal()">
          <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 35 35">
            <g fill-rule="evenodd" stroke-linecap="square">
              <path d="M9.192 9.192l18.385 18.385M27.577 9.192L9.192 27.577"></path>
            </g>
          </svg>
        </p>
        <form novalidate #f="ngForm" (ngSubmit)="onSubmit(f)">
          <p>Добавление урока</p>
          <mat-form-field>
            <input matInput type="text" name="name" [(ngModel)]="lesson.name" placeholder="Название урока" required />
          </mat-form-field>
          <mat-form-field>
            <input matInput type="number" name="lessonNumber" [(ngModel)]="lesson.number" placeholder="Номер урока" required />
          </mat-form-field>
          <mat-form-field>
            <input matInput type="text" name="blockName" [(ngModel)]="lesson.blockName" placeholder="Раздел урока" required />
          </mat-form-field>
          <mat-form-field>
            <input matInput type="text" name="conspectUrl" [(ngModel)]="lesson.conspectUrl" placeholder="Ссылка на конспекты урока" />
          </mat-form-field>
          <mat-form-field>
            <input
              matInput
              type="text"
              name="yandexDiskMaterialsUrl"
              [(ngModel)]="lesson.yandexDiskMaterialsUrl"
              placeholder="Ссылка на материалы урока"
              required
            />
          </mat-form-field>
          <mat-checkbox class="checkbox" name="isBlocked" [(ngModel)]="blockingInfo.value">Задание заблокировано?</mat-checkbox>
          <mat-form-field *ngIf="blockingInfo.value">
            <input matInput type="text" name="blockingDescription" [(ngModel)]="blockingInfo.description" placeholder="Когда откроется?" />
          </mat-form-field>
          <button type="submit" class="button green-btn w-button">
            {{ 'SHARED.ADD' | translate }}
          </button>
        </form>
      </div>
    </div>
  </div>
</ng-template>
