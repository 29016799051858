import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { NavigationEnd, Router } from '@angular/router';
import { SharedService } from 'app/shared/shared.service';

import { CourseDetailsService } from './course-details/course-details.service';
import { PageHeaderService } from '../../../shared/dashboard/page-header/page-header.service';

@Component({
  selector: 'prf-courses-page',
  templateUrl: './courses-page.component.html',
  styleUrls: ['./courses-page.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CoursesPageComponent implements OnInit, OnDestroy {
  public userRole: string = '';
  public tabName: string = 'catalog';

  constructor(
    private meta: Meta,
    private courseDetailsService: CourseDetailsService,
    private sharedService: SharedService,
    private router: Router,
    private pageHeaderService: PageHeaderService,
  ) {
    this.meta.updateTag({ name: 'og:title', content: 'Курсы' });
    this.userRole = localStorage.getItem('userRole');
    this.router.events.subscribe(e => {
      if (e instanceof NavigationEnd) {
        if (e.url === '/courses') {
          switch (this.userRole) {
            case 'pupil':
            case 'parent':
            case 'tutor':
              break;
            default:
              this.router.navigate([`${this.userRole}/courses`]);
              break;
          }
        }
      }
    });
  }

  public ngOnInit(): void {
    this.pageHeaderService.setTitle('Курсы');
  }

  public ngOnDestroy(): void {
    this.pageHeaderService.setTitle(null);
  }
}
