<div class="content-section">
  <div class="container">
    <div class="admin-header-event-page" *ngIf="course">
      <div class="admin-header-col">
        <div class="event-status-text ev-page" *ngIf="course.startDate">
          {{ 'SHARED.CREATED_DUE_DATE' | translate: { createdDate: startDate } }}
        </div>
      </div>
      <div class="edit-event-column event-page-edit">
        <div class="edit-event-col-2">
          <a [routerLink]="['/' + userRole + '/copy-course', course.hrid]" class="edit-event-button w-inline-block">
            <div class="edit-event-button-row">
              <div class="edit-icon-div">
                <img src="./assets/images/icons/files-copy-interface-symbol.svg" class="icon-initial-state" />
              </div>
              <div>{{ 'SHARED.CREATE_COPY' | translate }}</div>
            </div>
          </a>
          <a (click)="addtoArchive = !addtoArchive" class="edit-event-button w-inline-block">
            <div class="edit-event-button-row">
              <div class="edit-icon-div">
                <img src="./assets/images/icons/archive-black-box_1.svg" class="icon-initial-state" />
              </div>
              <div>{{ 'SHARED.ADD_TO_ARCHIVE' | translate }}</div>
            </div>
          </a>
          <a class="delete-event w-inline-block" (click)="popUpConfirming = !popUpConfirming"></a>
        </div>
        <div class="edit-event-col">
          <a [routerLink]="['/' + userRole + '/edit-course', course.hrid]" class="button edit-event-page-btn w-button">{{
            'SHARED.EDIT_BUTTON' | translate
          }}</a>
        </div>
      </div>
    </div>

    <div class="event-page-section" *ngIf="course">
      <div class="event-page-header event-gradient-bg-2" [ngClass]="color">
        <div
          class="event-image-bg"
          [ngStyle]="{
            'background-image': course.mainImagePath ? 'url(' + course.mainImagePath + ')' : ''
          }"
        ></div>
        <div class="event-header-column">
          <div class="event-page-age">{{ getAges() }}</div>
        </div>
        <div class="event-header-column _2">
          <div class="event-page-h1-div">
            <h1 class="event-page-h1">{{ course.name }}</h1>
          </div>
        </div>
      </div>
      <div class="event-page-content">
        <div class="event-page-description-row">
          <div class="event-page-description-column">
            <h2 class="event-page-h2">{{ 'COURCES.PAGE.DESCRIPTION' | translate }}</h2>

            <div class="event-page-descr-row">
              <div *ngIf="course.courses && course.courses.length > 0" class="event-page-descr-column">
                <h4 class="event-page-h4">{{ 'SHARED.THEMATIC' | translate }}</h4>
                <ul class="event-description-list">
                  <li *ngFor="let field of course.courses">
                    <div class="event-description-title">{{ field }}</div>
                  </li>
                </ul>
              </div>

              <div *ngIf="course.courseGroups && course.courseGroups.length" class="event-page-descr-column">
                <h4 class="event-page-h4">{{ 'SIDE.PROFILE' | translate }}</h4>
                <ul class="event-description-list">
                  <li *ngFor="let group of course.courseGroups">
                    <div class="event-description-title">{{ group }}</div>
                  </li>
                </ul>
              </div>

              <div *ngIf="complexity" class="event-page-descr-column">
                <h4 class="event-page-h4">{{ 'SHARED.LEVEL' | translate }}</h4>
                <ul class="event-description-list">
                  <li>
                    <div class="event-description-title">{{ complexity }}</div>
                  </li>
                </ul>
              </div>
            </div>

            <p class="event-page-description-text" innerHtml="{{ course.description }}"></p>
          </div>
          <div class="event-page-description-column _2">
            <div class="event-page-text">{{ 'SHARED.ORGANIZER' | translate }}</div>
            <h3 class="event-page-h3">{{ course.institution }}</h3>
            <div class="event-page-date" *ngIf="course.startDate">{{ startDate }}</div>
            <div class="event-page-price">
              <div class="event-final-price">
                {{
                  calculateRealPrice() > 0
                    ? calculateRealPrice() + ' ' + ('COMMON.MONEY_SYMBOL' | translateByDefault)
                    : ('COURCES.PAGE.FREE' | translate)
                }}
              </div>
              <div class="event-page-price-row" *ngIf="course.unitDiscount && course.unitDiscount > 0">
                <div class="starting-price">{{ +course.unitPrice }}</div>
                <div class="discount" *ngIf="course.unitDiscount > 0 && (course.isPercent === true || course.isPercent === null)">
                  {{ 'CART.DISCOUNT' | translate }} {{ +course.unitDiscount }}%
                </div>
                <div class="discount" *ngIf="course.unitDiscount > 0 && course.isPercent === false">
                  {{ 'CART.DISCOUNT' | translate }} {{ +course.unitDiscount }}
                  {{ 'COMMON.MONEY_SYMBOL' | translateByDefault }}
                </div>
              </div>
            </div>
            <div class="event-page-address">
              <div class="event-address event-pg">{{ course.address }}</div>
              <div class="event-metro-row metro-li" *ngFor="let station of course.metroStations">
                <div class="metro-color" [ngClass]="metroColors.getStationByLine(station)"></div>
                <div class="event-metro">{{ station.name }}</div>
              </div>
            </div>
          </div>
        </div>

        <div class="event-page-map-block" [class.no-display]="!course.address || !mapsLoaded">
          <h2 class="event-page-h2">{{ mapHeader }}</h2>
          <prf-yandex-maps [location]="course.address" (loaded)="mapsLoaded = $event"></prf-yandex-maps>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-container *ngIf="popUpConfirming">
  <div class="perform-task-lb lb show">
    <div class="lb-bg"></div>
    <div class="lb-block task-lb">
      <a class="close-lb w-inline-block" (click)="popUpConfirming = !popUpConfirming">
        <img src="./assets/images/icons/close-lb.svg" />
      </a>
      <div class="tasl-lb-row">
        <div class="task-lb-column _2">
          <h4 class="task-lb-heading-result">{{ 'SHARED.DELETING_A_COURSE' | translate }}</h4>
          <div class="task-result-text">{{ 'SHARED.WANT_TO_DELETE_COURSE' | translate }}</div>
          <a class="button attach-file-btn w-button" (click)="deleteCourse(course.id)">{{ 'BUTTONS.DELETE' | translate }}</a>
        </div>
      </div>
    </div>
  </div>
</ng-container>
<ng-container *ngIf="addtoArchive">
  <div class="perform-task-lb lb show">
    <div class="lb-bg"></div>
    <div class="lb-block task-lb">
      <a class="close-lb w-inline-block" (click)="addtoArchive = !addtoArchive">
        <img src="./assets/images/icons/close-lb.svg" />
      </a>
      <div class="tasl-lb-row">
        <div class="task-lb-column _2">
          <h4 class="task-lb-heading-result">{{ 'SHARED.ADDING_TO_ARCHIVE' | translate }}</h4>
          <div class="task-result-text">{{ 'SHARED.WANT_TO_ADD_COURSE' | translate }}</div>
          <a class="button attach-file-btn w-button" (click)="archive(course)">{{ 'SHARED.ADD' | translate }}</a>
        </div>
      </div>
    </div>
  </div>
</ng-container>
