import { Injectable } from '@angular/core';
import { HttpService } from 'app/core/services/http.service';
import { IMunicipality } from 'app/shared/interfaces/imunicipality';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { IMunicipalitiesResponse } from '../admin.interface';
import { Helper } from '../../../../shared/common/helpers/helper';

@Injectable({
  providedIn: 'root',
})
export class MunicipalitiesService {
  constructor(private http: HttpService) {}

  public getMunicipalitiesByRegion(regionId: string): Observable<IMunicipality[]> {
    return this.http.get(`/api/v1.0/catalog/municipalities/getbyregion?regionId=${regionId}`).pipe(
      map((r: IMunicipalitiesResponse) => r.municipalities),
      catchError(Helper.handleError),
    );
  }

  getMunicipality(municipalityId: string): Observable<any> {
    return this.http.get('/api/v1.0/catalog/municipalities/one?municipalityId=' + municipalityId).pipe(
      map(r => r.municipality),
      catchError(Helper.handleError),
    );
  }

  updateMunicipality(municipality: IMunicipality): Observable<any> {
    return this.http.put('/api/v1.0/catalog/municipalities', municipality).pipe(
      map(r => r),
      catchError(Helper.handleError),
    );
  }

  deleteMunicipality(municipalityId: string): Observable<any> {
    return this.http.delete('/api/v1.0/catalog/municipalities?municipalityId=' + municipalityId).pipe(
      map(r => r),
      catchError(Helper.handleError),
    );
  }

  createMunicipality(municipalityObj: any): Observable<any> {
    return this.http.post('/api/v1.0/catalog/municipalities', municipalityObj).pipe(
      map(r => r),
      catchError(Helper.handleError),
    );
  }
}
