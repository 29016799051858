import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { InstitutionClass } from 'app/shared/classes/institution.class';
import { AdminAccessLevel } from 'app/shared/enums/admins.enums';
import { Stands } from 'app/shared/enums/stands.enums';
import { EmptyGuid } from 'app/shared/global-constants/constants';
import { IFilterClasses, ITalentsFilter } from 'app/shared/interfaces/ifilterclasses.interface';

import { AppSettingsService } from 'app/shared/services/appsettings.service';
import { throwError, BehaviorSubject, Observable, TimeoutError } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { HttpService } from '../../../../../core/services/http.service';

@Injectable()
export class CoursesFiltersService {
  private coursesFilters = new BehaviorSubject<IFilterClasses>({});
  private talentsFilters = new BehaviorSubject<ITalentsFilter>({});
  public schoolID: string = '';

  constructor(private http: HttpService) {}

  // обработка ошибок
  handleError(err: any): Observable<any> {
    if (err instanceof TimeoutError) {
      console.error(`Frontend returned timeout error: ${err['error']}`);
      return throwError(err['error']);
    }
    if (err.error instanceof Error) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', err.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(`Backend returned code ${err.status}, body was: ${err.error}`);
      let errorText = err.error ? (err.error.comment ? err.error.comment : err.error) : null;
    }
    throw throwError(err);
  }

  getCoursesFilters(): Observable<IFilterClasses> {
    return this.coursesFilters.asObservable();
  }
  setCoursesFilter(filters: any, schoolID?: any) {
    if (schoolID) {
      this.schoolID = schoolID;
    }
    this.coursesFilters.next(filters);
  }

  getTalentsFilters(): Observable<ITalentsFilter> {
    return this.talentsFilters.asObservable();
  }
  setTalentsFilter(filters: any) {
    this.talentsFilters.next(filters);
  }

  public getFilteredClasses(filters: IFilterClasses, fromTo) {
    let url = '/api/v1.0/catalog/classes/elasticsearch/search/filters';

    let credentials: IFilterClasses = {};
    for (let key in filters) {
      if (key) {
        credentials[key] = filters[key];
      }
    }
    if (fromTo) {
      filters.from = fromTo.currentPage;
      filters.size = fromTo.pageSize;
    }
    filters.stand = Stands.Talent;

    return this.http.post(url, filters).pipe(map(resp => resp));
  }

  public getFilteredClassesMongo(filters: IFilterClasses, fromTo) {
    const credentials = {
      filters: filters,
    };
    credentials.filters.from = fromTo.currentPage;
    credentials.filters.size = fromTo.pageSize;

    credentials.filters.stand = Stands.Talent;
    return this.http.post('/api/v1.0/catalog/classes/mongo/search/filters', credentials).pipe(map(r => r.classes));
  }

  public getClassMongoByHrid(hrid: string) {
    return this.http.get('/api/v1.0/catalog/classes/mongo/onebyhrid?hrid=' + hrid).pipe(
      map(r => r.class),
      catchError((err: HttpErrorResponse) => {
        return this.handleError(err);
      }),
    );
  }

  public setRegionMunicipalityFilters(filters: IFilterClasses) {
    let regionId = localStorage.getItem('regionId');
    let municipalityId = localStorage.getItem('municipalityId');
    let userRole = localStorage.getItem('userRole');
    if (userRole === 'admin') {
      let adminLevel = localStorage.getItem('adminLevel');
      switch (adminLevel) {
        case AdminAccessLevel.MUNICIPALITY:
          {
            filters.regionId = regionId;
            filters.municipalityId = municipalityId;
          }
          break;
        case AdminAccessLevel.REGION:
          {
            filters.regionId = regionId;
          }
          break;
      }
    } else if (
      userRole === 'schooladmin' &&
      AppSettingsService.settings.b2c &&
      localStorage.getItem('schoolId') === AppSettingsService.settings.b2c.schoolId
    ) {
      // для шк. админа b2c
      filters.regionId = EmptyGuid;
    } else {
      filters.regionId = regionId;
      filters.municipalityId = municipalityId;
    }
    return filters;
  }

  // todo: перенести в сервис с админскими методами CoursesService
  public getFilteredClassesArchived(filters: IFilterClasses, fromTo) {
    const credentials = {
      filters: filters,
    };
    credentials.filters.from = fromTo.currentPage;
    credentials.filters.size = fromTo.pageSize;
    credentials.filters.stand = Stands.Talent;
    credentials.filters.isArchived = true;
    return this.http.post('/api/v1.0/catalog/classes/mongo/search/filters', credentials).pipe(map(resp => resp.classes));
  }

  public getTypesCourse() {
    return this.http.get('/api/v1.0/catalog/courses/all').pipe(map(resp => resp.courses));
  }

  getInstitutionsAll(): Observable<InstitutionClass[]> {
    return this.http.get('/api/v1.0/catalog/institutions/all').pipe(
      map(r => r.institutions),
      catchError((err: HttpErrorResponse) => {
        return this.handleError(err);
      }),
    );
  }

  getTitleByNumber(n, titles: string[]) {
    return titles[n % 10 === 1 && n % 100 !== 11 ? 0 : n % 10 >= 2 && n % 10 <= 4 && (n % 100 < 10 || n % 100 >= 20) ? 1 : 2];
  }
}
