import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from 'app/shared/shared.module';
import { AdminPanelComponent } from './admin-panel.component';
import { AdminPanelService } from './admin-panel.service';
import { AdminRoutingModule } from './admin-routing.module';
import { AddCenterComponent } from './centers/add-center/add-center.component';
import { CentersComponent } from './centers/centers.component';
import { DeleteCenterComponent } from './centers/delete-center/delete-center.component';
import { EditCenterComponent } from './centers/edit-center/edit-center.component';
import { AddCityComponent } from './cities/add-city/add-city.component';
import { CitiesComponent } from './cities/cities.component';
import { DeleteCityComponent } from './cities/delete-city/delete-city.component';
import { EditCityComponent } from './cities/edit-city/edit-city.component';
import { AddMunicipalityComponent } from './municipalities/add-municipality/add-municipality.component';
import { DeleteMunicipalityComponent } from './municipalities/delete-municipality/delete-municipality.component';
import { EditMunicipalityComponent } from './municipalities/edit-municipality/edit-municipality.component';
import { MunicipalitiesComponent } from './municipalities/municipalities.component';
import { AddProfessionComponent } from './professions/professions/add-profession/add-profession.component';
import { RegionsService } from './regions/regions.service';
import { AddSchoolComponent } from './schools/add-school/add-school.component';
import { EditSchoolComponent } from './schools/edit-school/edit-school.component';
import { SchoolsComponent } from './schools/schools.component';
import { SystemReportsComponent } from './system/reports/system-reports.component';
import { SystemStatisticsComponent } from './system/statistics/system-statistics.component';
import { SystemComponent } from './system/system.component';
import { AddUserComponent } from './users/add-users/add-user.component';
import { UsersComponent } from './users/users.component';
import { AdminClassTemplateComponent } from './schools/admin-class-template/admin-class-template.component';
import { SystemDashboardComponent } from './system/dashboard/system-dashboard.component';
import { EditUserComponent } from './users/edit-user/edit-user.component';
import { ProfessionsComponent } from './professions/professions/professions.component';
import { EditProfessionComponent } from './professions/professions/edit-profession/edit-profession.component';
import { ProfessionsPanelComponent } from './professions/professions-panel.component';
import { LessonsComponent } from './lessons/lessons.component';
import { AddLessonComponent } from './lessons/add-lesson/add-lesson.component';
import { DeleteLessonComponent } from './lessons/delete-lesson/delete-lesson.component';
import { EditLessonComponent } from './lessons/edit-lesson/edit-lesson.component';
import { TasksComponent } from './lessons/tasks/tasks.component';
import { DropdownWithFilterModule } from 'app/shared/common-components/dropdown-with-filter/dropdown-with-filter.module';
import { SchoolClassComponent } from './schools/school-class/school-class.component';
import { SimpleLoaderModule } from 'app/shared/common-components/simple-loader/simple-loader.module';
import { ReassignTeacherComponent } from './users/reassign-teacher/reassign-teacher.component';
import { ButterflyControlModule } from '../../../shared/common-components/butterfly-control/butterfly-control.module';
import { MatLegacySlideToggleModule as MatSlideToggleModule } from '@angular/material/legacy-slide-toggle';
import { AnswersMatrixComponent } from './system/answers-matrix/answers-matrix.component';
import { DropdownMultiComponent } from '../../../shared/common-components/dropdown-multi/dropdown-multi.component';
import { MatLegacyFormFieldModule } from '@angular/material/legacy-form-field';
import { FullDiagnosticReportComponent } from './system/reports/full-diagnostic-report/full-diagnostic-report.component';

@NgModule({
  declarations: [
    AdminPanelComponent,
    AddProfessionComponent,
    ProfessionsComponent,
    EditProfessionComponent,
    UsersComponent,
    AddUserComponent,
    EditUserComponent,
    CentersComponent,
    EditCenterComponent,
    AddCenterComponent,
    DeleteCenterComponent,
    SchoolsComponent,
    AddSchoolComponent,
    EditSchoolComponent,
    CitiesComponent,
    AddCityComponent,
    EditCityComponent,
    DeleteCityComponent,
    MunicipalitiesComponent,
    AddMunicipalityComponent,
    EditMunicipalityComponent,
    DeleteMunicipalityComponent,
    SystemComponent,
    SystemReportsComponent,
    SystemStatisticsComponent,
    AdminClassTemplateComponent,
    SystemDashboardComponent,
    ProfessionsPanelComponent,
    LessonsComponent,
    AddLessonComponent,
    DeleteLessonComponent,
    EditLessonComponent,
    TasksComponent,
    SchoolClassComponent,
    ReassignTeacherComponent,
    AnswersMatrixComponent,
  ],
  imports: [
    CommonModule,
    AdminRoutingModule,
    SharedModule,
    DropdownWithFilterModule,
    SimpleLoaderModule,
    ButterflyControlModule,
    MatSlideToggleModule,
    DropdownMultiComponent,
    MatLegacyFormFieldModule,
    FullDiagnosticReportComponent,
  ],
  providers: [AdminPanelService, RegionsService],
})
export class AdminModule {}
