<a class="event-card adm" *ngIf="!viewValue" [routerLink]="this.isCourse ? ['/courses', course.hrid] : ['/events', course.hrid]">
  <ng-container *ngIf="!isCourse; else courseCard">
    <div class="event-card-col">
      <a class="event-gradient-bg adm w-inline-block" [ngClass]="currentType.color">
        <div class="event-header">
          <div class="event-type" [ngClass]="currentType.color">{{ currentType.viewValue }}</div>
          <h3 class="event-heading">{{ course.name }}</h3>
          <div class="event-info-row-2"></div>
        </div>
        <div
          class="event-image-bg"
          [ngStyle]="{
            'background-image': image ? 'url(' + image + ')' : 'url(/assets/images/background/event-bg-2.png)'
          }"
        ></div>
      </a>
      <div class="event-info-div adm">
        <div class="event-date" *ngIf="course.isRegular">{{ 'SHARED.REGULARLY' | translate }}</div>
        <div class="event-date" *ngIf="course.startDate">{{ startDate }}</div>
        <p class="event-description">{{ course.shortDescription }}</p>
        <div class="event-address">{{ course.address }}</div>
        <div class="event-metro-row" *ngIf="course.metroStations[0]; else emptyMetroStation">
          <div class="metro-color white-border" [style.background-color]="metroColors.getStationByLine(course.metroStations[0])"></div>
          <div class="event-metro">{{ course.metroStations[0].name }}</div>
        </div>
        <div class="event-price">
          <div class="event-price-row">
            <div class="final-price adm">
              {{
                calculateRealPrice() > 0
                  ? calculateRealPrice() + ' ' + ('COMMON.MONEY_SYMBOL' | translateByDefault)
                  : ('COURCES.PAGE.FREE' | translate)
              }}
            </div>
            <div class="starting-price" *ngIf="course.unitDiscount > 0">
              {{ course.unitPrice > 0 ? (course.unitPrice | eventCurrencyConvert) : '' }}
            </div>
            <div class="discount" *ngIf="course.unitDiscount > 0 && (course.isPercent === true || course.isPercent === null)">
              {{ 'CART.DISCOUNT' | translate }} {{ +course.unitDiscount }}%
            </div>
            <div class="discount" *ngIf="course.unitDiscount > 0 && course.isPercent === false">
              {{ 'CART.DISCOUNT' | translate }} {{ +course.unitDiscount }}
              {{ 'COMMON.MONEY_SYMBOL' | translateByDefault }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="event-status-block">
      <div class="event-status-text">{{ 'SHARED.ADDED' | translate }} {{ course.publishedDate | date: 'dd MMM yyyy hh:mm' }}</div>
      <a *ngIf="accessAllow" [routerLink]="['/admin/edit-course', course.hrid]" class="event-status-edit-btn w-inline-block edit-event"></a>
    </div>
  </ng-container>
</a>

<ng-template #emptyDiscont>
  <div class="event-price-row">
    <div class="final-price" *ngIf="isCourse">
      {{ calculateRealPrice() > 0 ? calculateRealPrice() + ' ' + ('COMMON.MONEY_SYMBOL' | translateByDefault) : 'Бесплатно' }}
    </div>
    <div class="starting-price"></div>
    <div class="discount"></div>
  </div>
</ng-template>

<ng-template #emptyMetroStation>
  <div class="event-metro-row">
    <!-- <div class="metro-color white-border" *ngIf="isCourse"></div>
    <div class="event-metro"></div> -->
  </div>
</ng-template>

<ng-template #courseCard>
  <a class="event-gradient-bg full-height purple w-inline-block" [ngClass]="currentType.color">
    <div class="event-header full-height">
      <div class="event-type" [ngClass]="currentType.color">{{ currentType.viewValue }}</div>
      <h3 class="event-heading">{{ course.name }}</h3>
      <div class="event-info-row-2">
        <!-- <div class="event-cost">Бесплатно</div>
        <div class="event-date-2">{{ startDate }}</div> -->
      </div>
    </div>
    <div
      class="event-image-bg adm"
      [ngStyle]="{
        'background-image': image ? 'url(' + image + ')' : 'url(/assets/images/background/event-bg-2.png)'
      }"
    ></div>
    <div class="event-info-div adm">
      <div class="event-date white-text" *ngIf="course.isRegular">
        {{ 'SHARED.REGULARLY' | translate }}
      </div>
      <div class="event-date" *ngIf="course.startDate">{{ startDate }}</div>
      <p class="event-description white-text">{{ course.shortDescription }}</p>
      <div class="event-address white-text">{{ course.address }}</div>
      <div class="event-metro-row" *ngIf="course.metroStations[0]; else emptyMetroStation">
        <div class="metro-color white-border" [style.background-color]="metroColors.getStationByLine(course.metroStations[0])"></div>
        <div class="event-metro">{{ course.metroStations[0].name }}</div>
      </div>
      <div class="event-price">
        <div class="event-price-row" *ngIf="course.unitDiscount && course.unitDiscount > 0; else emptyDiscont">
          <div class="final-price adm">
            {{ calculateRealPrice() > 0 ? calculateRealPrice() + ' ' + ('COMMON.MONEY_SYMBOL' | translateByDefault) : 'Бесплатно' }}
          </div>
          <div class="starting-price white-text" *ngIf="course.unitDiscount > 0">
            {{ course.unitPrice | eventCurrencyConvert }}
          </div>
          <div class="discount white-text" *ngIf="course.unitDiscount > 0 && (course.isPercent === true || course.isPercent === null)">
            {{ 'CART.DISCOUNT' | translate }} {{ +course.unitDiscount }}%
          </div>
          <div class="discount white-text" *ngIf="course.unitDiscount > 0 && course.isPercent === false">
            {{ 'CART.DISCOUNT' | translate }} {{ +course.unitDiscount }}
            {{ 'COMMON.MONEY_SYMBOL' | translateByDefault }}
          </div>
        </div>
      </div>
    </div>
  </a>
  <div class="event-status-block">
    <div class="event-status-text">{{ 'SHARED.ADDED' | translate }} {{ course.publishedDate | date: 'dd MMM yyyy hh:mm' }}</div>
    <a *ngIf="accessAllow" [routerLink]="['/admin/edit-course', course.hrid]" class="event-status-edit-btn w-inline-block edit-event"></a>
  </div>
</ng-template>

<div class="courses-list-item" *ngIf="viewValue">
  <a [routerLink]="this.isCourse ? ['/courses', course.hrid] : ['/events', course.hrid]" class="courses-list-item-link w-inline-block">
    <h3 class="course-heading">{{ course.name }}</h3>
    <div class="event-status-block-2">
      <div class="event-status-text-2" *ngIf="course.startDate">{{ startDate }}</div>
      <div class="event-status-text-2" *ngIf="course.isRegular">
        {{ 'SHARED.REGULARLY' | translate }}
      </div>
      <div class="event-status-text-2">Ред {{ course.publishedDate | date: 'dd MMM hh:mm' }}</div>
      <div class="event-status-text-2">{{ course.shortDescription | slice: 0:70 }}</div>
    </div>
    <div *ngIf="course.metroStations[0]; else emptyListMetroStation">
      <div class="course-line" [ngClass]="metroColors.getStationByLine(course.metroStations[0])"></div>
    </div>
  </a>
  <a *ngIf="accessAllow" [routerLink]="['/admin/edit-course', course.hrid]" class="event-status-edit-btn short w-inline-block edit-event">
  </a>
  <a *ngIf="accessAllow" class="delete-attachment w-inline-block remove-event-btn edit-event" (click)="popUpConfirming = !popUpConfirming">
  </a>
</div>

<ng-template #emptyListMetroStation>
  <div class="course-line white"></div>
</ng-template>

<ng-container *ngIf="popUpConfirming">
  <div class="perform-task-lb lb show">
    <div class="lb-bg"></div>
    <div class="lb-block task-lb">
      <a class="close-lb w-inline-block" (click)="popUpConfirming = !popUpConfirming">
        <img src="./assets/images/icons/close-lb.svg" />
      </a>
      <div class="tasl-lb-row">
        <div class="task-lb-column _2">
          <h4 class="task-lb-heading-result">Удаление курса</h4>
          <div class="task-result-text">Вы уверены, что хотите удалить курс?</div>
          <a class="button attach-file-btn w-button" (click)="deleteCourse(course.id)">{{ 'BUTTONS.DELETE' | translate }}</a>
        </div>
      </div>
    </div>
  </div>
</ng-container>
