import { Injectable } from '@angular/core';
import { HttpService } from 'app/core/services/http.service';
import { ICity } from 'app/shared/interfaces/icity';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ICitiesResponse } from '../admin.interface';
import { Helper } from '../../../../shared/common/helpers/helper';

@Injectable({
  providedIn: 'root',
})
export class CitiesService {
  constructor(private http: HttpService) {}

  getAllCities(): Observable<ICity[]> {
    return this.http.get('/api/v1.0/catalog/cities/all').pipe(
      map(r => r.cities),
      catchError(Helper.handleError),
    );
  }

  getCity(cityId): Observable<any> {
    return this.http.get('/api/v1.0/catalog/cities/one?id=' + cityId).pipe(
      map(r => r.city),
      catchError(Helper.handleError),
    );
  }

  updateCity(city): Observable<any> {
    return this.http.put('/api/v1.0/catalog/cities?cityId=' + city.id, city).pipe(
      map(r => r),
      catchError(Helper.handleError),
    );
  }

  deleteCity(cityId): Observable<any> {
    return this.http.delete('/api/v1.0/catalog/cities?cityId=' + cityId).pipe(
      map(r => r),
      catchError(Helper.handleError),
    );
  }

  createCity(city): Observable<any> {
    return this.http.post('/api/v1.0/catalog/cities', city).pipe(
      map(r => r),
      catchError(Helper.handleError),
    );
  }

  public getAllCitiesByMunicipality(municipalityId: string): Observable<ICity[]> {
    return this.http.get(`/api/v1.0/catalog/cities/getbymunicipality?municipalityId=${municipalityId}`).pipe(
      map((r: ICitiesResponse) => r.cities),
      catchError(Helper.handleError),
    );
  }
}
