import { Component, Input, OnInit } from '@angular/core';
import { Gender } from '../../enums/gender.enums';
import { ETestTypesSteps } from '../../enums/testtypes.enum';
import { PlayerService } from '../../services/player.service';
import { take } from 'rxjs/operators';
import { EmptyGuid } from 'app/shared/global-constants/constants';

@Component({
  selector: 'prf-parent-test-panel',
  templateUrl: './parent-test-panel.component.html',
  styleUrls: ['./parent-test-panel.component.scss'],
})
export class ParentTestPanelComponent implements OnInit {
  @Input()
  set pupilUserId(val: string) {
    this._pupilUserId = val;
  }

  @Input() parentSessionComplete: boolean = false;
  @Input() set sessionIDParent(val: string) {
    this._sessionIDParent = val;
    if (val) {
      this.loaded = false;
      this.getSession();
    }
  }
  @Input() isPupil: boolean = false;

  public loaded: boolean = true;
  public currentSlideNumber: number = 1;
  public slidesCount: number = ETestTypesSteps.DEFAULT_STANDART;
  public RefferalUserGender: string = '';
  public sessionStatus: string = '';

  private _sessionIDParent: string = '';
  private _pupilUserId: string = '';

  constructor(private playerService: PlayerService) {
    this.RefferalUserGender = localStorage.getItem('RefferalUserGender');
    this.sessionStatus = localStorage.getItem('sessionStatus');
    if (localStorage.getItem('userRole') === 'parent') {
      this.slidesCount = ETestTypesSteps.DEFAULT_360;
    }
  }

  ngOnInit() {}

  get progressBarWidth(): number {
    return Math.round(100 - ((this.slidesCount - this.currentSlideNumber) / this.slidesCount) * 100);
  }

  get sessionIDParent() {
    return this._sessionIDParent;
  }

  private getSession() {
    this.loaded = false;
    if (this.sessionStatus !== 'Untested user' && this.sessionIDParent && this.sessionIDParent !== EmptyGuid) {
      this.playerService
        .getCurrentSlide(this.sessionIDParent)
        .pipe(take(1))
        .subscribe(slide => {
          if (slide) {
            this.currentSlideNumber = slide.slide.orderNumber;
          }
          this.loaded = true;
        });
    } else {
      this.loaded = true;
    }
  }

  public isMale(gender: string) {
    return gender === Gender.Male;
  }
}
