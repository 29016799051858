import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, throwError, TimeoutError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { HttpService } from '../../../../core/services/http.service';

@Injectable()
export class CourseDetailsService {
  private currentCourse = new BehaviorSubject(null);

  constructor(private http: HttpService) {}

  // обработка ошибок
  handleError(err: any): Observable<any> {
    if (err instanceof TimeoutError) {
      console.error(`Frontend returned timeout error: ${err['error']}`);
      return throwError(err['error']);
    }
    if (err.error instanceof Error) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', err.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(`Backend returned code ${err.status}, body was: ${err.error}`);
      let errorText = err.error ? (err.error.comment ? err.error.comment : err.error) : null;
    }
    throw throwError(err);
  }

  // addToFavorites(productId, userId): Observable<any> {
  //   let params = {
  //     productId: productId,
  //     productType: 'Class',
  //     userId: userId,
  //   };
  //   return this.http.post('/api/v1.0/profiles/favorites/addtofavorites', params).pipe(
  //     map(r => r),
  //     catchError((err: HttpErrorResponse) => {
  //       return this.handleError(err);
  //     })
  //   );
  // }

  addClassEnrollParent(classId: string): Observable<any> {
    return this.http.post('/saas/v1.0/saas/addclassenroll?classId=' + classId).pipe(
      map(r => r),
      catchError((err: HttpErrorResponse) => {
        return this.handleError(err);
      }),
    );
  }

  addClassEnrollPupil(classId: string): Observable<any> {
    return this.http.post('/saas/v1.0/pupils/addclassenroll?classId=' + classId).pipe(
      map(r => r),
      catchError((err: HttpErrorResponse) => {
        return this.handleError(err);
      }),
    );
  }

  removeClassEnroll(classId: string, userId: string, status: number = 2): Observable<any> {
    let params = {
      classId: classId,
      userId: userId,
      status: status,
      isDeleted: true,
    };
    return this.http.put('/saas/v1.0/pupils/updateclassenroll', params).pipe(
      map(r => r),
      catchError((err: HttpErrorResponse) => {
        return this.handleError(err);
      }),
    );
  }

  //todo: не используется
  getClassEnrolls(): Observable<any> {
    return this.http.get('/saas/v1.0/saas/getclassenrolls').pipe(
      map(r => r),
      catchError((err: HttpErrorResponse) => {
        return this.handleError(err);
      }),
    );
  }

  getClassEnroll(userId: string, classId: string): Observable<any> {
    const params = `?userId=${userId}&classId=${classId}`;
    return this.http.get('/saas/v1.0/pupils/getclassenroll' + params).pipe(
      map(r => r),
      catchError((err: HttpErrorResponse) => {
        return this.handleError(err);
      }),
    );
  }

  calculateRealPrice(course: any) {
    let discount = +course.unitDiscount;
    let price = +course.unitPrice;
    if (course.isPercent || course.isPercent === null) {
      return discount ? price - price * discount * 0.01 : price;
    } else {
      return discount ? price - discount : price;
    }
  }

  addCourse(course: any) {
    this.currentCourse.next(course);
  }

  getCurrentCourse(): Observable<any> {
    return this.currentCourse.asObservable();
  }
}
