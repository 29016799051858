import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'enumToArray',
})
export class EnumToArrayPipe implements PipeTransform {
  transform(data: Object) {
    const array = Object.keys(data).filter(f => !isNaN(Number(f)));
    return array;
  }
}
