<div class="content-section">
  <div class="container">
    <div class="tabs" *ngIf="loaded">
      <div class="tabs-professions">
        <div [class.no-display]="tabName !== 'catalog'" class="tabs-content">
          <div class="tab-pane left">
            <prf-profession-catalog [tabName]="tabName"></prf-profession-catalog>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
