<div class="w-tab-pane w--tab-active">
  <div class="tab-pane adm">
    <div class="admin-menu">
      <div class="admin-menu-row">
        <a
          class="professions-menu-link"
          (click)="ActiveArchive = 1"
          [class.w--current]="ActiveArchive === 1"
          [routerLink]="['./active-courses']"
          >{{ 'SHARED.ACTIVE' | translate }}</a
        >
        <!-- <a
          class="professions-menu-link"
          (click)="ActiveArchive = 2"
          [class.w--current]="ActiveArchive === 2"
          [routerLink]="['./archive-courses']"
          >{{ 'SHARED.ARCHIVE' | translate }}</a
        > -->
      </div>
    </div>

    <ng-container>
      <router-outlet></router-outlet>
    </ng-container>
  </div>
</div>
