import { Injectable } from '@angular/core';
import { HttpService } from "../http";
import { Observable } from "rxjs";
import { take } from "rxjs/operators";
import { ApiProgramsUrls } from "./api-programs.collections";


@Injectable({
  providedIn: 'root',
})
export class ApiProgramsService {
  constructor(private httpService: HttpService) {}

  public getProgramsSpoVpoBySpecialityCodes(specialityRequest: {specialityCodes: string[], regionId?: string}): Observable<any> {
    return <Observable<any>>this.httpService.post(ApiProgramsUrls.GetProgramsSpoVpoBySpecialityCodes, specialityRequest).pipe(
      take(1),
    );
  }
}
