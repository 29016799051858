import { Component } from '@angular/core';

@Component({
  selector: 'prf-simple-loader',
  template: ` <svg
    xmlns="http://www.w3.org/2000/svg"
    width="50px"
    height="50px"
    viewBox="0 0 100 100"
    preserveAspectRatio="xMidYMid"
    class="uil-ring"
  >
    <rect x="0" y="0" width="100" height="100" fill="none" class="bk"></rect>
    <circle stroke="#9375fb" cx="50" cy="50" r="45" stroke-dasharray="183.7831702350029 98.96016858807849" fill="none" stroke-width="10">
      <animateTransform
        attributeName="transform"
        type="rotate"
        values="0 50 50;180 50 50;360 50 50;"
        keyTimes="0;0.5;1"
        dur="1s"
        repeatCount="indefinite"
        begin="0s"
      ></animateTransform>
    </circle>
  </svg>`,
  styleUrls: ['./simple-loader.component.scss'],
})
export class SimpleLoaderComponent {
  constructor() {}
}
