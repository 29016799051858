import { Component, EventEmitter, inject, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';
import { switchMap, takeUntil, tap } from 'rxjs/operators';
import * as moment from 'moment';
import { UtilsService } from 'app/shared/dashboard/backend-services/utils.service';
import { AdminAccessLevel } from 'app/shared/enums/admins.enums';
import { ICity } from 'app/shared/interfaces/icity';
import { IMunicipality } from 'app/shared/interfaces/imunicipality';
import { ISchool } from 'app/shared/interfaces/ischool.interface';
import { EmptyGuid, TEXT_MASK } from 'app/shared/global-constants/constants';
import { REG_EXP } from 'app/shared/global-constants/reg-exp';
import { ICompany } from 'app/shared/interfaces/icompany.interface';
import { AdminPanelService } from 'app/pages/control-panel/admin/admin-panel.service';
import { MunicipalitiesService } from 'app/pages/control-panel/admin/municipalities/municipalities.service';
import { RegionsService } from 'app/pages/control-panel/admin/regions/regions.service';
import { IInstitution } from 'app/shared/interfaces/IInstitution.interface';
import { IRegion } from 'app/shared/interfaces/iregion';
import { IAddUserInterface } from '../../../../../shared/interfaces/iregistration.interface';
import { IRoleView } from '../users.interface';
import { USER_VIEW_ROLES } from '../constants/user-roles';
import { UnsubscribeComponent } from '@profilum-components/unsubscribe/unsubscribe.component';

const TEMP_FORM_DATA_KEY = 'tempFormData';

@Component({
  selector: 'prf-add-user',
  templateUrl: './add-user.component.html',
  styleUrls: ['./add-user.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AddUserComponent extends UnsubscribeComponent implements OnInit {
  public adminLevel: any;
  public userRegionId: string = '';
  public userMunicipalityId: string = '';
  public textMasks = TEXT_MASK;
  public allSchools: ISchool[];
  public allCities: ICity[];
  public municipalitiesByRegion: IMunicipality[] = [];
  public citiesByMunicipality: ICity[] = [];
  public schoolsFiltered: ISchool[] = [];
  public institutions: Array<IInstitution> = [];
  public allRegions: Array<any> = [];
  public addUserFlag: boolean = false;
  public cityDisabled: boolean = false;
  public error: boolean = false;
  public roles: IRoleView[] = USER_VIEW_ROLES;
  public roleView: IRoleView = this.roles[0];
  public formUser: UntypedFormGroup = new UntypedFormGroup({
    role: new UntypedFormControl(this.roleView.value, Validators.required),
  });
  private adminPanelService: AdminPanelService = inject(AdminPanelService);
  public companies$: Observable<ICompany[]> = this.adminPanelService.getAllCompanies();

  private guidEmpty: string = EmptyGuid;
  private dateRegExp: RegExp = REG_EXP.dateRegExp;
  private passwordRegExp: RegExp = REG_EXP.passwordRegExp;

  @Output() updateValue = new EventEmitter<any>();

  @Input() set inputInstitutions(institutions: Array<IInstitution>) {
    this.institutions = institutions;
  }
  @Input() set inputRegions(regions: Array<IRegion>) {
    this.allRegions = regions;
  }
  @Input() set inputCities(cities: Array<ICity>) {
    this.allCities = cities;
  }
  @Input() set inputSchools(val: Array<any>) {
    this.allSchools = val;
  }

  constructor(
    private regionsService: RegionsService,
    private municipalitiesService: MunicipalitiesService,
    private translateService: TranslateService,
    public utilsService: UtilsService,
  ) {
    super();
  }

  ngOnInit() {
    this.adminLevel = localStorage.getItem('adminLevel');
    this.userRegionId = localStorage.getItem('regionId');
    this.userMunicipalityId = localStorage.getItem('municipalityId');

    this.checkRolesAvailable();

    this.buildFormByRole();
  }

  // region & municipality admins can't create other admins
  checkRolesAvailable() {
    if (this.adminLevel === AdminAccessLevel.REGION || this.adminLevel === AdminAccessLevel.MUNICIPALITY) {
      this.roles = this.roles.filter(role => role.value != 'admin');
    }
  }

  public toggleAddUserForm() {
    this.clearFormAndSavedData();
    this.addUserFlag = !this.addUserFlag;
    this.updateRole();
  }

  public onSubmit() {
    if (!this.isFormInvalid) {
      this.addUser()
        .pipe(
          switchMap(response => {
            if (response.status == 'Success') {
              return this.getTranslations(['SHARED.USER_CREATE_MSG']).pipe(
                tap(translations => {
                  this.utilsService.openSnackBar(translations['SHARED.USER_CREATE_MSG'], 'success');
                  this.updateValue.emit(this.formUser.get('role').value);
                  this.addUserFlag = false;
                }),
              );
            } else {
              return this.getTranslations(['SHARED.ERROR_CREATE_MSG']).pipe(
                tap(translations => this.utilsService.openSnackBar(translations['SHARED.ERROR_CREATE_MSG'], 'error')),
              );
            }
          }),
          takeUntil(this.unsubscribe),
        )
        .subscribe();
    } else {
      this.getTranslations(['SHARED.ERROR_CREATE_MSG'])
        .pipe(takeUntil(this.unsubscribe))
        .subscribe(translations => this.utilsService.openSnackBar(translations['SHARED.ERROR_CREATE_MSG'], 'error'));
    }
  }

  private addUser(): Observable<any> {
    let userData: IAddUserInterface;

    userData = this.createUserData();

    if (userData.birthday) {
      userData.birthday = moment(userData.birthday, 'DD/MM/YYYY', true).format('YYYY-MM-DD');
    }

    switch (this.userRole) {
      case 'admin':
        userData = this.trimNulledProperty();
        return this.adminPanelService.createAdminUser(userData);
      case 'employer':
        userData = this.trimNulledProperty();
        return this.adminPanelService.createRegisterOpen(userData);
      default:
        userData = this.trimNulledProperty();
        return this.adminPanelService.createUserByRole(userData);
    }
  }

  createUserData() {
    let userData = {};
    Object.keys(this.formUser.controls).forEach(key => {
      userData[key] = this.formUser.get(key).value;
    });
    return userData;
  }

  public updateRole() {
    this.roleView = this.roles.find(role => role.value == this.formUser.get('role').value);
    this.buildFormByRole();
  }

  public buildFormByRole() {
    this.saveFormToTemporaryStorage();
    let userRole = this.userRole;
    this.formUser.reset();
    this.formUser.get('role').setValue(userRole);
    const savedFormData = JSON.parse(localStorage.getItem(TEMP_FORM_DATA_KEY));

    this.formUser = new UntypedFormGroup({
      role: new UntypedFormControl(userRole, Validators.required),
      firstName: new UntypedFormControl(savedFormData?.firstName || null, Validators.required),
      lastName: new UntypedFormControl(savedFormData?.lastName || null, Validators.required),
      middleName: new UntypedFormControl(savedFormData?.middleName || null, Validators.required),
      regionId: new UntypedFormControl(savedFormData?.regionId || null, Validators.required),
      municipalityId: new UntypedFormControl(savedFormData?.municipalityId || null, Validators.required),
      schoolId: new UntypedFormControl(savedFormData?.schoolId || null, Validators.required),
    });

    switch (this.userRole) {
      case 'schooladmin':
      case 'director':
        this.formUser.addControl('city', new UntypedFormControl(savedFormData?.city || null, Validators.required));
        break;

      case 'adminDO':
        this.formUser.removeControl('regionId');
        this.formUser.removeControl('municipalityId');
        break;
      case 'admin':
        this.formUser.get('schoolId').setValue(savedFormData?.schoolId || this.guidEmpty);
        this.formUser.get('municipalityId').setValidators(null);
        break;

      case 'employer':
        this.formUser.get('schoolId').setValue(savedFormData?.schoolId || this.guidEmpty);

        this.formUser.addControl('city', new UntypedFormControl(savedFormData?.city || null, Validators.required));
        this.formUser.addControl('email', new UntypedFormControl(savedFormData?.email || null, [Validators.required, Validators.email]));
        this.formUser.addControl(
          'password',
          new UntypedFormControl(savedFormData?.password || null, [Validators.required, Validators.pattern(this.passwordRegExp)]),
        );
        this.formUser.addControl('position', new UntypedFormControl(savedFormData?.position || null, Validators.required));
        this.formUser.addControl('companyId', new UntypedFormControl(savedFormData?.companyId || null, Validators.required));
        this.formUser.addControl('phoneNumber', new UntypedFormControl(savedFormData?.phoneNumber || null, Validators.required));
        this.formUser.addControl('gender', new UntypedFormControl(savedFormData?.gender || null, Validators.required));
        this.formUser.addControl(
          'birthday',
          new UntypedFormControl(savedFormData?.birthday || null, [Validators.required, Validators.pattern(this.dateRegExp)]),
        );
        break;

      case 'tutor':
        this.formUser.removeControl('regionId');
        this.formUser.removeControl('municipalityId');
        this.formUser.removeControl('schoolId');
        break;
    }
    this.formUser.updateValueAndValidity();
    this.setTerritoryData();
  }

  setTerritoryData() {
    let userRegionId = localStorage.getItem('regionId');
    let userMunicipalityId = localStorage.getItem('municipalityId');
    let userRegion: any;
    let userMunicipality: any;

    let currentObservable$: Observable<any> = of(null);
    switch (this.adminLevel) {
      case AdminAccessLevel.GLOBAL:
        break;
      case AdminAccessLevel.REGION:
        {
          if (this.formUser.get('regionId')) {
            userRegion = this.allRegions.find(region => region.id === userRegionId);
            currentObservable$ = this.setRegion(userRegion).pipe(
              tap(_ => {
                this.formUser.get('regionId').disable();
              }),
            );
          }
        }
        break;
      case AdminAccessLevel.MUNICIPALITY:
        {
          if (this.formUser.get('regionId')) {
            userRegion = this.allRegions.find(region => region.id === userRegionId);
            currentObservable$ = this.setRegion(userRegion).pipe(
              tap(r => {
                this.formUser.get('regionId').disable();
                if (this.formUser.get('municipalityId')) {
                  userMunicipality = this.municipalitiesByRegion.find(r => r.regionId == userRegionId && r.id === userMunicipalityId);
                  this.setMunicipality(userMunicipality);
                  this.formUser.get('municipalityId').disable();
                }
              }),
            );
          }
        }
        break;
    }
    return currentObservable$.pipe(takeUntil(this.unsubscribe)).subscribe();
  }

  public get userRole(): string {
    return this.formUser.get('role').value;
  }

  public get isSelectCityAvailable() {
    return (
      this.formUser.get('municipalityId').value &&
      this.formUser.get('regionId').value &&
      (this.formUser.get('role').value == 'schooladmin' ||
        this.formUser.get('role').value == 'director' ||
        this.formUser.get('role').value == 'employer')
    );
  }

  public get isSelectSchoolAvailable() {
    return (
      (this.formUser.get('role').value == 'schooladmin' && this.formUser.get('city').value) ||
      (this.formUser.get('municipalityId').value &&
        this.formUser.get('role').value != 'admin' &&
        this.formUser.get('role').value != 'schooladmin' &&
        this.formUser.get('role').value != 'employer')
    );
  }

  public get isAdmin() {
    this.adminLevel = localStorage.getItem('adminLevel');
    return this.adminLevel === AdminAccessLevel.GLOBAL;
  }

  public get isFormInvalid() {
    return this.formUser.invalid || this.formUser.pristine || this.formUser.errors;
  }

  private clearFormAndSavedData(): void {
    this.formUser = new UntypedFormGroup({
      role: new UntypedFormControl(this.roleView.value, Validators.required),
    });
    localStorage.removeItem(TEMP_FORM_DATA_KEY);
  }

  private saveFormToTemporaryStorage(): void {
    const previousFormData = JSON.parse(localStorage.getItem(TEMP_FORM_DATA_KEY));
    let updatedFormData = { ...previousFormData, ...this.formUser.value };
    localStorage.setItem(TEMP_FORM_DATA_KEY, JSON.stringify(updatedFormData));
  }

  private trimNulledProperty() {
    let result = {};
    let sourceData = this.createUserData();
    Object.keys(sourceData).forEach(property => {
      if (sourceData[property] != null && sourceData[property] != undefined) {
        result[property] = sourceData[property];
      }
    });

    return result;
  }

  public setTerritory(): Observable<any> {
    this.adminLevel = localStorage.getItem('adminLevel');

    let currentObs$: Observable<any>;
    switch (this.adminLevel) {
      case AdminAccessLevel.GLOBAL:
        {
          currentObs$ = of(null);
        }
        break;
      case AdminAccessLevel.REGION:
        {
          let region = this.allRegions.find(r => r.id == this.userRegionId);
          currentObs$ = this.setRegion(region).pipe(
            switchMap(r => {
              return this.getMunicipalities(this.userRegionId);
            }),
          );
        }
        break;
      case AdminAccessLevel.MUNICIPALITY:
        {
          let region = this.allRegions.find(r => r.id == this.userRegionId);
          currentObs$ = this.setRegion(region).pipe(
            switchMap(r => {
              return this.getMunicipalities(this.userRegionId).pipe(
                tap(r => {
                  let municipality = this.municipalitiesByRegion.find(r => r.id == this.userMunicipalityId);
                  this.setMunicipality(municipality);
                }),
              );
            }),
          );
        }
        break;
    }
    return currentObs$;
  }

  private getMunicipalities(regionId?): Observable<any> {
    return this.municipalitiesService.getMunicipalitiesByRegion(regionId).pipe(
      tap(r => {
        this.municipalitiesByRegion = r.filter(r => r.id != this.guidEmpty).sort((a, b) => (a.name > b.name ? 1 : -1));
      }),
    );
  }

  selectMunicipality(municipality) {
    this.setMunicipality(municipality);
  }

  private setMunicipality(municipality) {
    if (municipality.id != this.formUser.get('municipalityId').value) {
      this.formUser.get('schoolId').reset();
    }
    this.formUser.get('municipalityId').setValue(municipality.id);
    if (!this.isSelectCityAvailable) {
      this.getSchoolsByMunicipality(municipality);
    } else {
      this.getCitiesByMunicipality(municipality);
    }
  }

  selectRegion(region) {
    this.setRegion(region).pipe(takeUntil(this.unsubscribe)).subscribe();
  }

  private setRegion(region): Observable<any> {
    if (region.id != this.formUser.get('regionId').value) {
      this.formUser.get('municipalityId').reset();
    }
    this.formUser.get('regionId').setValue(region.id);
    return this.getMunicipalities(region.id);
  }

  private getCitiesByMunicipality(municipality) {
    this.citiesByMunicipality = this.allCities.filter(c => c.municipalityId == municipality.id).sort((a, b) => (a.name > b.name ? 1 : -1));
  }

  private setCity(city) {
    if (city) {
      if (city.id != this.formUser.get('city').value) {
        this.formUser.get('city').reset();
      }
      this.formUser.get('city').setValue(city.name);
      this.getSchoolsByCity(city);
    }
    return;
  }

  private getSchoolsByCity(city) {
    this.schoolsFiltered = [];
    if (city) {
      let citiesByMunicipality = this.allCities.filter(el => el.id === city.id);
      this.schoolsFiltered = this.allSchools
        .filter(school => {
          let schoolCity = citiesByMunicipality.find(el => el.id === school.cityId);
          return citiesByMunicipality.indexOf(schoolCity) > -1;
        })
        .sort((a, b) => (a.number > b.number ? 1 : -1));
      if (this.schoolsFiltered) {
      }
    }
  }

  getTranslations(keys: string[]): Observable<any> {
    return this.translateService.get(keys);
  }

  private getSchoolsByMunicipality(municipality) {
    this.schoolsFiltered = [];
    if (municipality) {
      let citiesByMunicipality = this.allCities.filter(city => city.municipalityId === municipality.id);
      this.schoolsFiltered = this.allSchools.filter(school => {
        let schoolCity = citiesByMunicipality.find(el => el.id === school.cityId);
        return citiesByMunicipality.indexOf(schoolCity) > -1;
      });
      if (this.schoolsFiltered) {
      }
    }
  }
}
