import { Services } from '../services/services';
import { DevDomains, DomainsByConfiguration, KevinSoftDomainByConfiguration, ProdDomains } from '../domains/domains';
import { Configurations } from '../configurations/configurations';

const isInEnvironment = (service: Services, configuration: Configurations): boolean => {
  if (typeof window === 'undefined') {
    return (globalThis?.process.env.NX_TASK_TARGET_CONFIGURATION ?? Configurations.Production) === configuration;
  }

  return window.location.hostname === new URL(DomainsByConfiguration[configuration][service]).hostname;
};

export const isDevEnvironment = (service: Services): boolean => {
  if (typeof window !== 'undefined' && /(localhost)/g.test(window.location.hostname)) {
    return true;
  }

  return isInEnvironment(service, Configurations.Development);
};

export const isPreProdEnvironment = (service: Services): boolean => {
  return isInEnvironment(service, Configurations.PreProduction);
};

export const isProdEnvironment = (service: Services): boolean => {
  return isInEnvironment(service, Configurations.Production);
};

export const getDomain = (service: Services) => {
  if (typeof window !== 'undefined') {
    return isDevEnvironment(service) ? DevDomains[service] : `${window.location.protocol}//${window.location.hostname}`;
  }

  const configurationFromEnv = globalThis?.process.env.NX_TASK_TARGET_CONFIGURATION ?? Configurations.Production;

  return DomainsByConfiguration[configurationFromEnv]?.[service] ?? ProdDomains[service];
};

export const getKevinSoftDomainForEurochem = () => {
  const domain = getDomain(Services.Eurochem);

  return KevinSoftDomainByConfiguration[domain];
};
