import { Component, Input, OnInit } from '@angular/core';
import {
  SpiderChartFiguresEnum,
  SpiderChartThemeEnum,
} from '@profilum-components/spider-chart-custom/spider-chart-custom.model';

@Component({
  selector: 'prf-test-result',
  templateUrl: './test-result.component.html',
  styleUrls: ['./test-result.component.scss'],
})
export class TestResultComponent implements OnInit {
  @Input() skillsAttainmentData;
  @Input() weAreParent;

  public userRole: string = '';
  public sessionId: string = '';
  public professionTest: boolean = false;

  constructor() {
    this.userRole = localStorage.getItem('userRole');
    this.sessionId = localStorage.getItem('testSessionId');
  }

  ngOnInit() {}

  protected readonly SpiderChartThemeEnum = SpiderChartThemeEnum;
  protected readonly SpiderChartFiguresEnum = SpiderChartFiguresEnum;
}
