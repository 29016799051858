import { catchError, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpService } from 'app/core/services/http.service';
import { BehaviorSubject, Observable, TimeoutError, throwError } from 'rxjs';
import { IInternship } from 'app/shared/interfaces/iinternships.interface';
import { HttpErrorResponse } from '@angular/common/http';
import { Stands } from 'app/shared/enums/stands.enums';

@Injectable()
export class InternshipService {
  private colorMap = {
    'Административная работа': 1,
    'Продажи и торговое дело': 1,
    Управление: 1,
    'Экономика, бизнес и финансы': 1,
    'Инженерия, архитектура и проектирование': 6,
    'Естественные науки': 3,
    'Медицина и здравоохранение': 3,
    'Сельское и лесное хозяйство, рыболовство': 3,
    'Образование и педагогика, управление знаниями': 7,
    'Реклама, PR, развлечения и СМИ': 7,
    'Социальные и гуманитарные науки': 7,
    Юриспруденция: 7,
    'Производство и технологии': 2,
    'Строительные и технические работы, добыча': 2,
    'Транспорт и перевозки': 2,
    'Установка, обслуживание и ремонт': 2,
    'Информационные технологии и математика': 5,
    'Безопасность и обеспечение порядка': 4,
    'Военное дело': 4,
    Спорт: 4,
    'Искусство, творчество, дизайн': 8,
    'Сервис, питание и услуги': 1,
  };
  private currentInternship = new BehaviorSubject<IInternship>(null);

  constructor(private http: HttpService) {}

  // обработка ошибок
  handleError(err: any): Observable<any> {
    if (err instanceof TimeoutError) {
      console.error(`Frontend returned timeout error: ${err['error']}`);
      return throwError(err['error']);
    }
    if (err.error instanceof Error) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', err.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(`Backend returned code ${err.status}, body was: ${err.error}`);
      let errorText = err.error ? (err.error.comment ? err.error.comment : err.error) : null;
    }
    throw throwError(err);
  }

  //TODO: переписать под новую стажировку, когда появятся все поля. Пока для ученика показываются все стажировки через метод getAllInternships()
  // public acquireInternships(filters, size?: number, from?: number) {
  //   let url = '/api/v1.0/catalog/internships/search/filters';
  //   let params = [];
  //   for (let query in filters) {
  //     if (query) {
  //       params.push(query + '=' + filters[query]);
  //     }
  //   }
  //   if (params.length >= 0) {
  //     url = url + '?' + params.join('&');
  //   }
  //   if (from > 0) {
  //     url += '&From=' + from;
  //   }
  //   if (size > 0) {
  //     url += '&Size=' + size;
  //   }
  //   let isPilot = localStorage.getItem('isPilot') == 'true' ? true : false;
  //   let regionId = localStorage.getItem('regionId');
  //   let regionIdValue =
  //     regionId && regionId != 'null' && !isPilot
  //       ? regionId
  //       : EmptyGuid;
  //   url += `&RegionId=${regionIdValue}`;

  //   if (!isPilot) {
  //     let municipalityId = localStorage.getItem('municipalityId');
  //     let municipalityIdValue = municipalityId && municipalityId != 'null' ? municipalityId : null;
  //     url += `&MunicipalityId=${municipalityIdValue}`;
  //   }

  //   return this.http.post(url).pipe(map(r => r));
  // }

  public getFields() {
    const url = '/api/v1.0/catalog/fields/all';
    return this.http.get(url).pipe(map(r => r.fields));
  }

  public getInternshipByHRID(hrid) {
    const url = '/api/v1.0/internships/internships/onebyhrid?hrid=' + hrid;
    return this.http.get(url).pipe(map(r => r.internship));
  }

  //TODO: переделать, когда станет ясно, как искать похожие стажировки (пока не используется)
  public getSimilarInternship(internships: string[]) {
    let talentsRequest = internships.map(t => encodeURI(t)).join('&Internships=');
    let municipalityParams = `&municipalityId=${localStorage.getItem('municipalityId')}`;
    return this.http
      .post(`/api/v1.0/internships/internships/search/filters?Internships=${talentsRequest}&From=0&Size=13` + municipalityParams)
      .pipe(map(response => response));
  }

  public getOtherInternships(institutionId: string) {
    let municipalityParams = `&municipalityId=${localStorage.getItem('municipalityId')}`;
    let parameters = `?InstitutionId=${institutionId}&From=0&Size=4` + municipalityParams;
    parameters += `&Stand=${Stands.Talent}`;

    return this.http.post(`/api/v1.0/catalog/classes/elasticsearch/search/filters` + parameters).pipe(map(response => response));
  }

  public getColorSchemeNumber(fieldName) {
    return this.colorMap[fieldName] || 1;
  }

  public getResults(sessionId: string): Observable<any> {
    return this.http.get('/api/v1.0/results/page?sessionIds=' + sessionId).pipe(
      map(r => r),
      catchError((err: HttpErrorResponse) => {
        return this.handleError(err);
      }),
    );
  }

  addUserRecommendation(userId: string, productId: string, recommendationType: string, addresserRole: string): Observable<any> {
    const params = `?UserId=${userId}&ProductId=${productId}&RecommendationType=${recommendationType}&AddresserRole=${addresserRole}`;
    return this.http.post('/saas/v1.0/parents/adduserrecommendation' + params).pipe(
      map(r => r),
      catchError((err: HttpErrorResponse) => {
        return this.handleError(err);
      }),
    );
  }

  getUserIdsWithRecommendation(productId: string, recommendationType: string): Observable<any> {
    const params = `?productId=${productId}&recommendationType=${recommendationType}`;
    return this.http.get('/saas/v1.0/parents/getuseridswithrecommendation' + params).pipe(
      map(r => r),
      catchError((err: HttpErrorResponse) => {
        return this.handleError(err);
      }),
    );
  }

  public getUserRecommendations(recommendationType: string, addresserRole: string): Observable<any> {
    return this.http
      .get(`/saas/v1.0/saas/getuserrecommendations?recommendationType=${recommendationType}&addresserRole=${addresserRole}`)
      .pipe(
        map(r => r),
        catchError((err: HttpErrorResponse) => {
          return this.handleError(err);
        }),
      );
  }

  setCurrentInternship(job: IInternship) {
    this.currentInternship.next(job);
  }

  public getCurrentInternship(): Observable<IInternship> {
    return this.currentInternship.asObservable();
  }
}
